import ConversionUtils from '@utils/conversion.utils';
import { CartDiscountTypeEnum, IDiscountItem } from './responseDto/final-cart-calculations.dto';

class DiscountItem {
    private _discountType: CartDiscountTypeEnum;
    private _totalDiscountPrice: number;

    constructor(item: IDiscountItem) {
        this._discountType = item.discountType;
        this._totalDiscountPrice = item.totalDiscountPrice;
    }

    get discountType(): CartDiscountTypeEnum {
        return this._discountType;
    }

    get isCouponDiscount(): boolean {
        return this._discountType === CartDiscountTypeEnum.Coupon;
    }

    get isSkuPointDiscount(): boolean {
        return this._discountType === CartDiscountTypeEnum.SkuPoint;
    }

    get isSubscriptionDiscount(): boolean {
        return this._discountType === CartDiscountTypeEnum.Subscription;
    }

    get totalDiscountPrice(): number {
        return this._totalDiscountPrice;
    }

    get totalDiscountPriceString(): string {
        return ConversionUtils.numberToString(this._totalDiscountPrice);
    }
}

export default DiscountItem;
