import safeWindow from '@services/safe-window.service';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import DomainUtils from '@utils/domain.utils';

enum State {
    Pending,
    Validated,
    Error,
}

type UserValidation = {
    isPending: boolean;
    isValidated: boolean;
};

/**
 * Hook that handles additional validation required by particular marketplaces.
 *
 * @returns whether or not the user is validated
 */
export function useIsUserValidated(): UserValidation {
    const router = useRouter();
    // Currently only setup for NHPay. Refactor as needed for new marketplaces.
    // const requiresValidation = CONFIG.domain === MarketDomain.NhPay && EnvironmentUtils.isProduction;
    const requiresValidation = false;
    const [validatedStatus, setValidatedStatus] = useState<State>(requiresValidation ? State.Pending : State.Validated);

    useEffect(() => {
        // if (requiresValidation && router.isReady) {
        // if (router.isReady) {
        // if (CONFIG.domain === MarketDomain.NhPay) {
        // const payParam = router.query[NHPayValidationQueryParam.PayParam];
        // const verified = router.query[NHPayValidationQueryParam.Verified];
        // if (payParam) {
        //     location.href = `appcall://verifyEncText?data={SV_TYPE: GD, ENC_TXT: ${payParam}}`;
        // } else if (verified) {
        // setValidatedStatus(State.Validated);
        // }
        // }
        // }
    }, [requiresValidation, router.isReady, router.query]);

    useEffect(() => {
        if (DomainUtils.isNhPayNormal) {
            if (isAndroid) {
                if (safeWindow && safeWindow.allonepayApp) {
                    safeWindow.allonepayApp.sessionTimerInit();
                }
            } else if (isIOS) {
                location.href = 'appcall://sessionTimerInit';
            }
        }
    }, []);

    return useMemo<UserValidation>(
        () => ({
            isValidated: validatedStatus === State.Validated,
            isPending: validatedStatus === State.Pending,
            isError: validatedStatus === State.Error,
        }),
        [validatedStatus]
    );
}
