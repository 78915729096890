export enum AuthTypeEnum {
    Kakao = 'kakao',
    Naver = 'naver',
    Google = 'google',
    Facebook = 'facebook',
}

export interface IOAuth {
    authType: AuthTypeEnum | string;
    clientId: string;
}
