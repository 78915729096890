import RoutesEnum from '@routes';
import DomainUtils from '@utils/domain.utils';
import EnvironmentUtils from '@utils/environment.utils';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';

export type KakaoAdTrackerValue = KakaoAdTrackerFunctions & {};

export const KakaoAdTrackerContext = createContext<KakaoAdTrackerValue>({} as KakaoAdTrackerValue);

interface KakaoAdTrackerProviderProps {
    children: ReactNode;
}

/** Context for Kakao ad tracker */
const KakaoAdTrackerProvider: React.FC<KakaoAdTrackerProviderProps> = ({ children }) => {
    const isEnabled = DomainUtils.isToyplus && EnvironmentUtils.isProduction;
    const router = useRouter();

    const [isScriptLoaded, setIsScriptLoaded] = useState<boolean>(false);

    const isConversionScriptPage = useCallback((pathname: string) => {
        if (
            [
                RoutesEnum.OrderSuccess,
                RoutesEnum.GiftOrderSuccess,
                RoutesEnum.JoinComplete,
                RoutesEnum.Search,
                RoutesEnum.ProductDetail,
                RoutesEnum.Cart,
                RoutesEnum.SubscriptionSuccess,
            ].includes(pathname as RoutesEnum)
        ) {
            return true;
        }

        return false;
    }, []);

    const pageView: KakaoAdTrackerFunctions['pageView'] = useCallback(() => {
        if (!isScriptLoaded) {
            return;
        }

        window.kakaoPixel(`${EnvironmentUtils.KAKAO_AD_KEY}`).pageView();
    }, [isScriptLoaded]);

    const completeRegistration: KakaoAdTrackerFunctions['completeRegistration'] = useCallback(() => {
        if (!isScriptLoaded) {
            return;
        }

        pageView();
        window.kakaoPixel(`${EnvironmentUtils.KAKAO_AD_KEY}`).completeRegistration();
    }, [isScriptLoaded, pageView]);

    const search: KakaoAdTrackerFunctions['search'] = useCallback(
        (params) => {
            if (!isScriptLoaded) {
                return;
            }

            pageView();
            window.kakaoPixel(`${EnvironmentUtils.KAKAO_AD_KEY}`).search(params);
        },
        [isScriptLoaded, pageView]
    );

    const viewContent: KakaoAdTrackerFunctions['viewContent'] = useCallback(
        (params) => {
            if (!isScriptLoaded) {
                return;
            }

            pageView();
            window.kakaoPixel(`${EnvironmentUtils.KAKAO_AD_KEY}`).viewContent(params);
        },
        [isScriptLoaded, pageView]
    );

    const addToCart: KakaoAdTrackerFunctions['addToCart'] = useCallback(
        (params) => {
            if (!isScriptLoaded) {
                return;
            }

            window.kakaoPixel(`${EnvironmentUtils.KAKAO_AD_KEY}`).addToCart(params);
        },
        [isScriptLoaded]
    );

    const addToWishList: KakaoAdTrackerFunctions['addToWishList'] = useCallback(
        (params) => {
            if (!isScriptLoaded) {
                return;
            }

            window.kakaoPixel(`${EnvironmentUtils.KAKAO_AD_KEY}`).addToWishList(params);
        },
        [isScriptLoaded]
    );

    const viewCart: KakaoAdTrackerFunctions['viewCart'] = useCallback(() => {
        if (!isScriptLoaded) {
            return;
        }

        pageView();
        window.kakaoPixel(`${EnvironmentUtils.KAKAO_AD_KEY}`).viewCart();
    }, [isScriptLoaded, pageView]);

    const purchase: KakaoAdTrackerFunctions['purchase'] = useCallback(
        (params) => {
            if (!isScriptLoaded) {
                return;
            }

            pageView();
            window.kakaoPixel(`${EnvironmentUtils.KAKAO_AD_KEY}`).purchase(params);
        },
        [isScriptLoaded, pageView]
    );

    const signUp: KakaoAdTrackerFunctions['signUp'] = useCallback(() => {
        if (!isScriptLoaded) {
            return;
        }

        window.kakaoPixel(`${EnvironmentUtils.KAKAO_AD_KEY}`).signUp();
    }, [isScriptLoaded]);

    const login: KakaoAdTrackerFunctions['login'] = useCallback(() => {
        if (!isScriptLoaded) {
            return;
        }

        window.kakaoPixel(`${EnvironmentUtils.KAKAO_AD_KEY}`).login();
    }, [isScriptLoaded]);

    useEffect(() => {
        if (!router.isReady || isConversionScriptPage(router.pathname) || !isScriptLoaded || !isEnabled) {
            return;
        }

        pageView();
    }, [router.isReady, router.asPath, router.pathname, isConversionScriptPage, pageView, isScriptLoaded, isEnabled]);

    return (
        <>
            {isEnabled && (
                <>
                    <Script src="//t1.daumcdn.net/kas/static/kp.js" onLoad={() => setIsScriptLoaded(true)} />
                </>
            )}
            <KakaoAdTrackerContext.Provider
                value={{
                    pageView,
                    completeRegistration,
                    search,
                    viewContent,
                    addToCart,
                    addToWishList,
                    viewCart,
                    purchase,
                    signUp,
                    login,
                }}
            >
                {children}
            </KakaoAdTrackerContext.Provider>
        </>
    );
};

export default KakaoAdTrackerProvider;

export function useKakaoTracking() {
    return useContext(KakaoAdTrackerContext);
}
