import { useHarmony } from '@hooks/use-harmony';
import { useAlert } from '@legacy/hooks/use-alert-drawer';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect, useRef } from 'react';

const BLOCKER_DISPLAY_CYCLE = 5000;

const DraftedMarketBlocker = () => {
    const { settings } = useHarmony();

    if (!settings?.isDrafted) {
        return <></>;
    }

    return <DraftedMarketBlockerInner />;
};

const DraftedMarketBlockerInner = () => {
    const { showAlert } = useAlert();
    const timerRef = useRef<NodeJS.Timeout | null>();
    const t = useTranslations();

    const showBlocker = useCallback(() => {
        timerRef.current = setTimeout(() => {
            showAlert({
                children: (
                    <>
                        {t('common.draftedChannel')
                            .split('\n')
                            .map((line) => (
                                <>
                                    {line}
                                    <br />
                                </>
                            ))}
                    </>
                ),
                onClose: () => {
                    showBlocker();
                },
            });
        }, BLOCKER_DISPLAY_CYCLE);
    }, []);

    useEffect(() => {
        showBlocker();
        return () => {
            timerRef.current && clearTimeout(timerRef.current);
        };
    }, []);

    return <></>;
};

export default DraftedMarketBlocker;
