import { useExtension } from '@hooks/use-extension';
import RoutesEnum from '@routes';
import isMobile from 'is-mobile';
import { useRouter } from 'next/router';
import Script from 'next/script';

const AcrossClosingPanel = () => {
    const { acrossSettings } = useExtension();
    const router = useRouter();

    if (!acrossSettings || !acrossSettings.panelScripts.closingPanelDisplayAreas.includes(router.pathname as RoutesEnum)) {
        return null;
    }

    return isMobile() ? (
        <>
            <Script id="across-adn-closing-panel">{`
                var adn_mobile_panel_param = adn_mobile_panel_param || [];
                adn_mobile_panel_param.push([{
                    ui:'${acrossSettings.panelScripts.mainPanelValue.ui}',	
                    ci:'${acrossSettings.panelScripts.mainPanelValue.ci}',
                    gi:'${acrossSettings.panelScripts.mainPanelValue.gi}'
                }]);
            `}</Script>
            <Script src="//fin.rainbownine.net/js/across_adn_closed_mobile_ad_1.0.4.js"></Script>
        </>
    ) : (
        <>
            <Script id="across-adn-closing-panel">{`
                var adn_panel_param = adn_panel_param || [];
                adn_panel_param.push([{
                    ui:'${acrossSettings.panelScripts.mainPanelValue.ui}',	
                    ci:'${acrossSettings.panelScripts.mainPanelValue.ci}',
                    gi:'${acrossSettings.panelScripts.mainPanelValue.gi}'
                }]);
            `}</Script>
            <Script src="//fin.rainbownine.net/js/across_adn_closed_pc_ad_1.0.4.js"></Script>
        </>
    );
};

export default AcrossClosingPanel;
