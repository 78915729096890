import { useDesignComponents } from '@hooks/use-design-components';

import { useHarmony } from '@hooks/use-harmony';
import { IThemeProps } from '@type/common';
import { Design } from '@type/harmony-config';
import React from 'react';
import ToastA from './designA/toast';
import ToastB from './designB/toast';
import ToastC from './designC/toast';

export interface ToastProps {
    color?: string;
    children: React.ReactNode;
}

export interface InternalToastProps extends IThemeProps, ToastProps {
    getFontSize?: (size: string) => string | undefined;
}

const Toast: React.FC<ToastProps> = ({ color, children }) => {
    const Component = useDesignComponents<typeof ToastA | typeof ToastB | typeof ToastC>({
        [Design.DesignA]: ToastA,
        [Design.DesignB]: ToastB,
        [Design.DesignC]: ToastC,
    });
    const { theme, designSettings } = useHarmony();

    return (
        <Component theme={theme} getFontSize={(size: string) => designSettings?.getLegacyFontSize(size)} color={color}>
            {children}
        </Component>
    );
};

export default Toast;
