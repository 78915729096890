const inquiry = {
    inquiry: {
        questionType: '문의유형',
        title: '제목',
        contents: '문의내용',
        customerPhoneNumber: '휴대폰 번호',
        customerEmail: '이메일',
        viewMoreImage: '이미지 더보기',
    },
};

export default inquiry;
