import Cart from '@models/cart';
import SubscriptionOrder from '@models/subscription-order';
import { HTMLProps } from 'react';
import { UrlObject } from 'url';
import { IHarmonyTheme } from './theme';

export enum StatusEnum {
    Pending,
    Complete,
    Error,
}

interface RequestPending {
    status: StatusEnum.Pending;
}

interface RequestError {
    status: StatusEnum.Error;
    error: any;
}

interface RequestComplete<T> {
    status: StatusEnum.Complete;
    data: T;
}

/**
 * Generic type for fetching states using discriminated unions.
 */
export type RequestState<T> = RequestPending | RequestError | RequestComplete<T>;

export interface IThemeProps {
    theme: IHarmonyTheme;
    fontSize?: string;
}

export type ScreenSizeProps = {
    maxScreenWidth: number | undefined;
};

export interface HTMLDivElementProps extends HTMLProps<HTMLDivElement> {}

export enum GenderEnum {
    Male = 'male',
    Female = 'female',
}

export enum GenderCodeEnum {
    M = 'M',
    F = 'F',
}

export type Url = string | UrlObject;

/**Days of week where 0 is Sunday, 1 is Monday, etc. */
export enum DayOfWeek {
    Sunday,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
}

export type Order = Cart | SubscriptionOrder;

export interface IError<T> {
    message: string;
    type: string;
    code: string;
    data: T;
}
