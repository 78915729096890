import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import BlockLoader from '../Loader';
import { BaseProps } from '../types';
import { getSizeStyles, getVariantStyles, Size, Variant } from './utils';

export type ButtonProps = BaseProps &
    ButtonHTMLAttributes<HTMLButtonElement> & {
        variant?: Variant;
        size?: Size;
        fontSize?: string;
        color?: string;
        full?: boolean;
        disabled?: boolean;
        isLoading?: boolean;
        /**
         * When true, this will render a pill shaped button.
         */
        rounded?: boolean;
        children: ReactNode;
        className?: string;
    };

const LINE_HEIGHT = '1.75rem';

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ variant = 'solid', size = 'sm', disabled = false, isLoading = false, rounded = false, full = false, onClick, children, color, ...props }: ButtonProps, ref) => {
        return (
            <StyledButton
                className={props.className}
                test-data-id={props.testId}
                size={size}
                variant={variant}
                ref={ref}
                disabled={disabled || isLoading}
                onClick={onClick}
                full={full}
                color={color}
                rounded={rounded}
                {...props}
            >
                {isLoading ? (
                    <LoadingContainer>
                        <BlockLoader size={LINE_HEIGHT} />
                    </LoadingContainer>
                ) : (
                    children
                )}
            </StyledButton>
        );
    }
);

const StyledButton = styled.button<{
    size: Size;
    color?: string;
    variant: Variant;
    full: boolean;
    disabled: boolean;
    rounded: boolean;
    fontSize?: string;
}>`
    ${({ size }) => getSizeStyles(size)}
    ${({ variant, color, theme }) => getVariantStyles(variant, theme, color)}
  ${({ full }) => {
        return css`
            width: ${full && '100%'};
        `;
    }}
  ${({ disabled }) => {
        return css`
            cursor: ${disabled ? 'not-allowed' : 'pointer'};
        `;
    }}
  border-radius: ${({ rounded }) => (rounded ? '100px' : 'none')};
    line-height: 2rem;
    font-size: ${({ fontSize }) => fontSize || '1.25rem'};
    font-weight: 800;
    line-height: ${LINE_HEIGHT};
    letter-spacing: -0.01em;
    text-align: center;
`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
`;

Button.displayName = 'Button';

export default Button;
