import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import { PartialAbstractedTextProps } from '../../../../components/abstracted-text';
import Body2CD from './designD/body-2-c';

export interface Body2CProps extends PartialAbstractedTextProps {}

const Body2C: React.FC<Body2CProps> = (props) => {
    const Component = useDesignComponents<ComponentType<Body2CProps>>({
        [Design.DesignD]: Body2CD,
    });

    return <Component {...props} />;
};

export default Body2C;
