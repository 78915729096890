import { useDesignComponents } from '@hooks/use-design-components';

import { authClient } from '@api/auth/auth.client';
import AuthCacheKeys from '@api/auth/cache-keys';
import { IconButtonProps } from '@designs/primitives/common/icon-button';
import { useCustomer } from '@hooks/use-customer/use-customer';
import { useExtension } from '@hooks/use-extension';
import { INotificationCollectionDto } from '@models/notification/responseDto/notification-collection.dto';
import RoutesEnum from '@routes';
import { Design } from '@type/harmony-config';
import { ComponentType, useMemo } from 'react';
import useSWR from 'swr';
import NotificationButtonD from './designD/notification-button';

interface NotificationButtonProps extends Omit<IconButtonProps, 'children'> {}

export interface NotificationButtonInnerProps extends NotificationButtonProps {
    unreadCount: number;
}

const NotificationButton: React.FC<NotificationButtonProps> = (props) => {
    const { isAuth } = useCustomer();
    const { extensionStatus } = useExtension();

    if (!isAuth || !extensionStatus?.notification.isEnabled) {
        return <></>;
    }

    return <NotificationButtonInner {...props} />;
};

const NotificationButtonInner: React.FC<NotificationButtonProps> = (props) => {
    const Component = useDesignComponents<ComponentType<NotificationButtonInnerProps>>({
        [Design.DesignD]: NotificationButtonD,
    });

    const { data } = useSWR<INotificationCollectionDto | undefined>(AuthCacheKeys.notificationsCacheKey(true, AuthCacheKeys.NOTIFICATION_ALERT_QUERY), () =>
        authClient.getCustomerNotifications(AuthCacheKeys.NOTIFICATION_ALERT_QUERY)
    );
    const unreadCount = useMemo<number>(() => data?.isUnreadTotal || 0, [data]);

    return <Component unreadCount={unreadCount} href={RoutesEnum.Notification} {...props} />;
};

export default NotificationButton;
