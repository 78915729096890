import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import { PartialAbstractedTextProps } from '../../../../components/abstracted-text';
import Body2BD from './designD/body-2-a';

export interface Body2BProps extends PartialAbstractedTextProps {}

const Body2B: React.FC<Body2BProps> = (props) => {
    const Component = useDesignComponents<ComponentType<Body2BProps>>({
        [Design.DesignD]: Body2BD,
    });

    return <Component {...props} />;
};

export default Body2B;
