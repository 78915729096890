import { Text, TextProps } from '@chakra-ui/react';
import { useTypographyStyles } from '@hooks/use-typography-styles';
import { FontType, FontWeight } from '@type/theme';

export const fontWeights: Record<FontWeight, number> = {
    thin: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
};

interface AbstractedTextProps extends TextProps {
    font?: FontType;
    weight: FontWeight;
    staticSized?: boolean;
}

export type PartialAbstractedTextProps = Partial<AbstractedTextProps>;

/**
 * This is the base of primitive typography components.
 */
const AbstractedText: React.FC<AbstractedTextProps> = ({ font = 'default', fontSize = '16px', lineHeight = '16px', weight = 'regular', staticSized, ...props }) => {
    const typographyStyles = useTypographyStyles({ fontSize, lineHeight, font, weight });

    return (
        <Text
            as="div"
            fontSize={staticSized ? fontSize : typographyStyles.fontSize}
            fontWeight={fontWeights[weight]}
            lineHeight={staticSized ? lineHeight : typographyStyles.lineHeight}
            fontFamily={typographyStyles.fontFamily}
            letterSpacing={typographyStyles.letterSpacing}
            {...props}
        >
            {props.children}
        </Text>
    );
};

export default AbstractedText;
