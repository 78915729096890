import { DeliveryFrequencyOptionsTypeEnum, ISubscriptionInfoDto, PaymentTypeEnum } from '@models/product/responseDto/component-product.dto';
import { BasicDate, ImageUrl, ProductClassEnum, ProductId } from '@type/models';
import { IProductSellableSkuDto } from './product-sellable-sku.dto';

export enum DeliveryPricingMethodEnum {
    Free = 'free',
    RegularCharge = 'regular_charge',
    ConditionalCharge = 'conditional_charge',
    UnitCharge = 'unit_charge',
}

export interface ISubscriptionPricingItemDto {
    totalProductSubscriptionPrice: number;
    totalProductSubscriptionPricePerMonth: number;
    totalDeliveryCount: number;
    deliveryDates: BasicDate[];
    firstDeliveryDate: BasicDate;
    deliveryFrequencyOption: DeliveryFrequencyOptionsTypeEnum;
    discountRate: number;
    deliveryFrequency: number;
    subscriptionPaymentPeriod: number;
    subscriptionPaymentPolicy: PaymentTypeEnum;
    nextPaymentCalculation: ISubscriptionPricingItemDto;
    futurePaymentCalculations: ISubscriptionPricingItemDto[];
}

export interface IGroupedProductDto {
    baseDeliveryFee: number;
    calculatedProductDeliveryFee: number;
    calculatedProductPrice: number;
    conditionalChargeStandard: number;
    deliveryGroupId: null;
    deliveryPricingMethod: DeliveryPricingMethodEnum | string;
    deliveryPricingUnit: string;
    division2Fee: number;
    division3JejuFee: number;
    division3OutsideJejuFee: number;
    divisionDeliveryFee: number;
    image: ImageUrl;
    isAdditionalPricingSet: boolean;
    isGroupDelivery: boolean;
    isProductSelected: boolean;
    marketPrice: number;
    minSellPrice: number;
    productSellableId: ProductId;
    productSellableSkus: IProductSellableSkuDto[];
    subscriptionInfo: ISubscriptionInfoDto | null;
    regionDivisionLevel: number;
    skuCount: number;
    title: string;
    unitChargeStandard: number;
    productClass: ProductClassEnum;
    subscriptionPricingList?: ISubscriptionPricingItemDto[];
    userSubscriptionInfo?: ISubscriptionPricingItemDto;
    pgSettingId: string | null;
}
