import { AuthSettingDto, IPassSetting, IdPwSetting } from '@models/auth-setting/responseDto/auth-setting.dto';
import { IOAuth } from '@models/auth-setting/responseDto/oauth.dto';
import { CustomerDtoKey } from '@models/oauth-terms/responseDto/oauth-terms.dto';
import camelcase from 'camelcase';

export class AuthSetting {
    private _authenticationType: string;
    private _customerIdentifiers: string[];
    private _requiredRegistrationFields: string[];
    private _allFieldsInUse: string[];
    private _isEmailVerificationRequired: boolean;
    private _initialSetUpDt: Date;
    private _nonEditableFields: string[];
    private _idPwSetting: IdPwSetting;
    private _oauths: IOAuth[];
    private _isUsingIdPwRegistrationProcess: boolean;
    private _passSetting: IPassSetting;

    private _userInfoFields: string[];

    constructor(dto: AuthSettingDto) {
        this._authenticationType = dto.authenticationType;
        this._customerIdentifiers = dto.customerIdentifiers.map((value) => camelcase(value) as CustomerDtoKey);
        this._allFieldsInUse = dto.allFieldsInUse.map((field) => camelcase(field));
        this._requiredRegistrationFields = dto.requiredRegistrationFields.map((value) => camelcase(value) as CustomerDtoKey);
        this._isEmailVerificationRequired = dto.isEmailVerificationRequired;
        this._initialSetUpDt = dto.initialSetUpDt;
        this._nonEditableFields = dto.nonEditableFields.map((value) => camelcase(value) as CustomerDtoKey);
        this._idPwSetting = dto.idPwSetting;
        this._oauths = dto.oauths;
        this._isUsingIdPwRegistrationProcess = dto.isUsingIdPwRegistrationProcess;
        this._isEmailVerificationRequired = dto.isEmailVerificationRequired;
        this._passSetting = dto.passSetting;

        this._userInfoFields = this._getSortedUserInfoFields(this._allFieldsInUse);
    }

    private _getSortedUserInfoFields = (allFieldsInUse: string[]): string[] => {
        const ordering = Object.fromEntries(
            ['username', 'name', 'phoneNumber', 'nickname', 'email', 'birthdate', 'offlineStoreMembership', 'gender'].map((field, index) => [field, index])
        );

        return [...allFieldsInUse].filter((field) => field in ordering).sort((a, b) => ordering[a] - ordering[b]);
    };

    get isEmailVerificationRequired(): boolean {
        return this._isEmailVerificationRequired;
    }

    get isUsingIdPwRegistrationProcess(): boolean {
        return this._isUsingIdPwRegistrationProcess;
    }

    get oauths(): IOAuth[] {
        return this._oauths;
    }

    get idPwSetting(): IdPwSetting {
        return this._idPwSetting;
    }

    get customerIdentifiers(): string[] {
        if (this.allFieldsInUse.includes('nickname')) {
            return this._customerIdentifiers.concat('nickname');
        }
        return this._customerIdentifiers;
    }

    get requiredRegistrationFields(): string[] {
        if (this._requiredRegistrationFields.includes('password')) {
            return this._requiredRegistrationFields.concat('confirmPassword');
        }
        return this._requiredRegistrationFields;
    }

    get nonEditableFields(): string[] {
        if (this._nonEditableFields.includes('email')) {
            return this._nonEditableFields.concat('emailVerificationCode');
        }
        return this._nonEditableFields;
    }

    get allFieldsInUse(): string[] {
        return this._allFieldsInUse;
    }

    get userInfoFields(): string[] {
        return this._userInfoFields;
    }

    get editableFields(): string[] {
        return this._userInfoFields.filter((field) => !this._nonEditableFields.includes(field));
    }

    get passSetting(): IPassSetting {
        return this._passSetting;
    }
}
