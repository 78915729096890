import styled from '@emotion/styled';
import { HTMLAttributes } from 'react';
import { BaseProps } from '../types';

export type LoaderProps = BaseProps &
    HTMLAttributes<HTMLDivElement> & {
        color?: string;
        backgroundColor?: string;
        /**
         * Size in px, percentage, rem, etc.
         * @default: 40px or 100% of container if less than 40px
         */
        size?: string;
    };

/**
 * Spinner loader indicator
 */
const BlockLoader = ({ testId, ...props }: LoaderProps) => {
    return <LoadingWheel test-data-id={testId} {...props} />;
};

export default BlockLoader;

const LoadingWheel = styled.div<LoaderProps>`
    ${({ theme, color, backgroundColor, size = '40px' }) => `
    border: 4px solid ${backgroundColor || theme.gray[50]};
    border-top: 4px solid ${color || theme.primary.default};
    border-radius: 50%;
    width: ${size};
    height: ${size};
    max-width: 100%;
    max-height: 100%;
    animation: spin 0.75s linear infinite;

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`}
`;
