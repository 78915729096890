/**
 * Used for checking which marketplace is being used when handling conditional logic throughout the app.
 */
export enum MarketDomain {
    Klife = 'klife',
    Joymall = 'joymall',
    NhPay = 'nh-pay',
    NhPayNormal = 'nh-pay-normal',
    Shoppingeasy = 'shoppingeasy',
    Freed = 'freed',
    KbPay = 'kb-pay',
    KbOnnuriShop = 'kb-onnurishop',
    ToyPlus = 'toyplus',
    Onnuri = 'onnuri',
    JejuPass = 'jejupass',
    Tamrain = 'tamrain',
    Lge = 'lge',
}
