import styled from '@emotion/styled';
import { FocusEventHandler } from 'react';

export type TextAreaProps = {
    value?: string;
    onChange?: (value: string) => void;
    onBlur?: FocusEventHandler<HTMLTextAreaElement>;
    width?: number | string;
    height?: number | string;
    padding?: string;
    placeholder?: string;
    maxCharacter?: number;
    borderColor?: string;
    controlled?: boolean;
    id?: string;
    name?: string;
    formatTextLength?: (currentLength: number, maxLength: number) => string;
};

const TextArea = ({
    value,
    onChange = () => {
        console.log('todo');
    },
    onBlur,
    width = '100%',
    height = '100%',
    padding = '0.5rem 1rem',
    placeholder = '',
    maxCharacter,
    borderColor,
    controlled = true,
    formatTextLength = (currentLength: number, maxLength: number) => `${currentLength} / ${maxLength}`,
    ...props
}: TextAreaProps) => {
    const changeValue = (value: string) => {
        if (maxCharacter && maxCharacter < value.length) return;
        onChange(value);
    };

    return (
        <Container width={width} height={height} padding={padding} borderColor={borderColor}>
            <textarea
                value={controlled ? value : undefined}
                onChange={(e) => changeValue(e.target.value)}
                onBlur={onBlur}
                placeholder={placeholder}
                maxLength={maxCharacter}
                {...props}
            />
            {maxCharacter && <p>{formatTextLength(value?.length || 0, maxCharacter)}</p>}
        </Container>
    );
};

const Container = styled.div<TextAreaProps>`
    position: relative;
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    padding: ${({ padding }) => padding};
    border: ${({ borderColor, theme }) => `1px solid ${borderColor || theme.gray[200]}`};
    & > textarea {
        width: 100%;
        height: 100%;
        border: none;
        resize: none;
        line-height: 1rem;
        color: ${(props) => props.theme.gray[900]};
        &:focus {
            outline: none;
        }
        &::after {
            display: none;
        }
        &::placeholder {
            color: ${(props) => props.theme.gray[600]};
        }
    }
    & > p {
        position: absolute;
        bottom: -1.125rem;
        right: 0;
        font-weight: 300;
        font-size: 10px;
        line-height: 14px;
        color: ${(props) => props.theme.gray[600]};
    }
`;

export default TextArea;
