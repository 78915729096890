import palette from './palette';
import { BlockUITheme } from './types';

export const base: BlockUITheme = {
    primary: {
        default: palette.blue.primary,
        extraLight: palette.skyBlue[50],
        light: palette.skyBlue.primary,
        dark: palette.blue[900],
    },
    gray: {
        default: palette.purple.primary,
        light: palette.purple.primary,
        dark: palette.purple.primary,
    },
    success: {
        default: palette.green.primary,
        light: palette.green[200],
        dark: palette.green[600],
    },
    warning: {
        default: palette.yellow.primary,
        light: palette.yellow[200],
        dark: palette.yellow[600],
        extraLight: palette.yellow[50],
    },
    danger: {
        default: palette.red.primary,
        extraLight: palette.red[50],
        light: palette.red[200],
        dark: palette.red[600],
    },
    info: {
        default: palette.blue[400],
        light: palette.skyBlue[50],
        dark: palette.skyBlue[700],
    },
    background: {
        primary: palette.grey.secondary,
        secondary: palette.white,
        success: palette.white,
        warning: palette.white,
        danger: palette.white,
        info: palette.white,
        dark: palette.black,
        light: palette.white,
        grey: palette.grey.secondary,
        darkGrey: palette.grey[300],
        skyBlue: palette.skyBlue.primary,
        hoverSkyBlue: palette.skyBlue[50] + '90',
        table: palette.grey.secondary,
    },
    border: {
        default: palette.grey[100],
        black: palette.black,
        light: palette.grey[50],
        grey: palette.grey[100],
        lightDarkGrey: palette.grey[200],
        darkGrey: palette.grey[300],
    },
    text: {
        primary: palette.blue.primary,
        black: palette.black,
        white: palette.white,
        grey: palette.grey[500],
        greySpan: palette.grey[600],
        darkGrey: palette.grey[500],
        selectedGrey: palette.grey[800],
        red: palette.red.primary,
        darkRed: palette.red[900],
        green: palette.green[400],
        disabled: palette.grey[300],
        placeholder: palette.grey[100],
        link: palette.blue.primary,
        skyBlue: palette.skyBlue.primary,
        table: palette.grey[500],
        tooltipIcon: palette.grey[400],
        tooltip: palette.grey[700],
        orange: palette.orange.primary,
        error: palette.red.error,
    },
    dark: {
        default: palette.black,
    },
    light: {
        default: palette.white,
    },
    font: {
        primary: 'Nanum Square',
    },
    dropShadow: {
        sm: '',
        md: '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
        lg: '',
    },
    bps: {
        MOBILE: 768,
        TABLET: 992,
        PC: 1200,
    },
};

export const breakpointsStr = {
    MOBILE: '768px',
    TABLET: '992px',
    PC: '1200px',
};

export const breakpointsNum = {
    MOBILE: 768,
    TABLET: 992,
    PC: 1200,
};
