const search = {
    search: {
        enterSearch: '검색어를 입력하세요',
        enterSearch2: '새로운 편리함을 찾아보세요',
        enterSearch3: '궁금한 상품을 검색해주세요.',
        enterSearchPopout: '궁금한 상품을 검색해보세요!',
        recent: '최근 검색어',
        deleteAll: '전체삭제',
        noRecent: '최근 검색어가 없습니다.',
        popular: '인기 검색어',
        recommend: '추천 검색어',
        results1: '총 ',
        results2: '{items}개',
        results3: '의 검색결과',
        searchResult: '검색 결과',
        showSearchResult: '검색결과 보기',
        tryDiff: '다른 검색어로 검색해보세요!',
        recentlyViewed: '최근 본 상품',
        noRecentlyViewed: '최근 본 상품이 없습니다.',
        noResults: '검색 결과가 없습니다.',
        cases: '건',
    },
};

export default search;
