import { Center } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { PaddedContainer } from '@styles/styled-components';
import { IThemeProps, ScreenSizeProps } from '@type/common';
import ThemeUtils from '@utils/theme.utils';
import { BlockHStack, BlockVStack, Modal, ModalContent } from 'src/legacy/components/blockui';
import CustomIcon from 'src/legacy/components/common/custom-icon';
import CustomIconButton from 'src/legacy/components/common/custom-icon-button';
import RoundedDrawer from 'src/legacy/components/common/rounded-drawer';
import BodyText from 'src/legacy/components/common/text/body-text';
import { AlertProps, AlertStatusEnum, AlertTypeEnum, Buttons } from '..';
import AlertButtons from '../alert-buttons';
import { useExtension } from '@hooks/use-extension';

const AlertB: React.FC<AlertProps> = ({ type = AlertTypeEnum.Drawer, status = AlertStatusEnum.Alert, buttons = { type: 'default' }, open, children, onClose, dismissible }) => {
    const { theme } = useHarmony();
    const { extensionStatus } = useExtension();

    return type === AlertTypeEnum.Modal ? (
        <Modal open={open}>
            <StyledModalContent maxScreenWidth={extensionStatus?.maxScreenWidth}>
                <PaddedContainer padding="16px 0 0 0">
                    <Center>
                        <BodyText wordBreak="break-word" whiteSpace="pre-wrap" weight="bold" size="16px" lineHeight="18px" textAlign="center">
                            {children}
                        </BodyText>
                    </Center>
                </PaddedContainer>
                <PaddedContainer padding="32px 0 0 0">
                    <ButtonContainer theme={theme} buttons={buttons}>
                        <AlertButtons buttons={buttons} status={status} onClose={onClose} />
                    </ButtonContainer>
                </PaddedContainer>
            </StyledModalContent>
        </Modal>
    ) : (
        <RoundedDrawer anchor="bottom" open={open} onClose={() => onClose?.()}>
            <BlockHStack align="right" padding="10px 10px 0">
                {dismissible && <CustomIconButton onClick={() => onClose?.()}>close</CustomIconButton>}
            </BlockHStack>
            <BlockVStack alignItems="center" align={'center'} spacing="0">
                {status !== AlertStatusEnum.Info && (
                    <BlockVStack spacing="16px" mb="16px">
                        {status === AlertStatusEnum.Alert && (
                            <CustomIcon size="30px" fill color="#FFD600">
                                warning
                            </CustomIcon>
                        )}
                        {status === AlertStatusEnum.Complete && (
                            <CustomIcon size="30px" color={theme.primary.default}>
                                check_circle
                            </CustomIcon>
                        )}
                    </BlockVStack>
                )}
                <PaddedContainer padding="16px 16px 24px 16px">
                    <BlockHStack align="center">
                        <BodyText textAlign="center" whiteSpace="pre-wrap" lineHeight="22px" size="18px" weight="bold">
                            {children}
                        </BodyText>
                    </BlockHStack>
                </PaddedContainer>
                {status !== AlertStatusEnum.Info ? (
                    <FullWidthPadded padding="16px 16px 12px 16px">
                        <ButtonContainer theme={theme} buttons={buttons}>
                            <AlertButtons buttons={buttons} status={status} onClose={onClose} />
                        </ButtonContainer>
                    </FullWidthPadded>
                ) : (
                    <PaddedContainer padding="40px 0" />
                )}
            </BlockVStack>
        </RoundedDrawer>
    );
};

export default AlertB;

const StyledModalContent = styled(ModalContent)<ScreenSizeProps>`
    max-width: min(100vw, ${({ maxScreenWidth }) => (maxScreenWidth ? maxScreenWidth - 32 : 0)}px);
    padding: 16px;
    background: white;
    border-radius: 16px;
    overflow: hidden;
    z-index: ${ThemeUtils.zIndex.alert} !important;
`;

const FullWidthPadded = styled(PaddedContainer)`
    width: 100%;
`;

const ButtonContainer = styled.div<IThemeProps & { buttons: Buttons }>`
    ${({ theme, buttons }) => `
        display: flex;
        column-gap: 8px;
        width: 100%;
        font-family: ${theme.font.default.bold};
    `}
`;
