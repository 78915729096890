import { Body2CProps } from '..';
import AbstractedText from '@components/abstracted-text';

const Body2CD: React.FC<Body2CProps> = (props) => {
    return (
        <AbstractedText fontSize="14px" weight="bold" lineHeight="20px" {...props}>
            {props.children}
        </AbstractedText>
    );
};

export default Body2CD;
