import { authClient } from '@api/auth/auth.client';
import { useToast } from '@hooks/use-toast';
import { AlertStatusEnum, AlertTypeEnum } from '@legacy/designs/alert';
import { useAlert } from '@legacy/hooks/use-alert-drawer';
import { ConfirmResetPasswordEmailVerificationCodeDto } from '@models/customer/requestDto/confirm-reset-password-email-verification-code.dto';
import { FindEmailVerificationCodeDto } from '@models/customer/requestDto/find-email-verification-code.dto';
import { ResetPasswordEmailVerificationCodeDto } from '@models/customer/requestDto/reset-password-email-verification-code.dto';
import OauthTerms from '@models/oauth-terms';
import { CustomerDtoKey } from '@models/oauth-terms/responseDto/oauth-terms.dto';
import DateUtils from '@utils/date.utils';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

export enum DuplicateFieldName {
    Username = 'username',
    Nickname = 'nickname',
    PhoneNumber = 'phone_number',
    Email = 'email',
}

export const useDuplicateCheck = () => {
    const t = useTranslations();
    const [isUsernameVerified, setIsUsernameVerified] = useState<boolean>(false);
    const [isNicknameVerified, setIsNicknameVerified] = useState<boolean>(false);
    const [isPhoneNumberVerified, setIsPhoneNumberVerified] = useState<boolean>(false);
    const [isCodeVerified, setIsCodeVerified] = useState<boolean>(false);
    const [showCodeInput, setShowCodeInput] = useState<boolean>(false);
    const [showCodeError, setShowCodeError] = useState<boolean>(false);
    const [codeSentAt, setCodeSentAt] = useState<Date>();
    const [verifiedUsername, setVerifiedUsername] = useState<string | null>(null);
    const [isNewPasswordDuplicate, setIsNewPasswordDuplicate] = useState<boolean>(false);
    const { showAlert } = useAlert();
    const { showToast } = useToast();

    const setToIsVerified = (fieldName: DuplicateFieldName) => {
        switch (fieldName) {
            case DuplicateFieldName.Username:
                setIsUsernameVerified(true);
                break;
            case DuplicateFieldName.Nickname:
                setIsNicknameVerified(true);
                break;
            case DuplicateFieldName.PhoneNumber:
                setIsPhoneNumberVerified(true);
                break;
            case DuplicateFieldName.Email:
                setIsCodeVerified(true);
        }
    };

    const checkDuplicate = async (fieldName: DuplicateFieldName, value: string) => {
        try {
            await authClient.checkDuplicate(fieldName, value);
            setToIsVerified(fieldName);
        } catch (e) {
            showModalAlert(t(`duplicate.${fieldName}`));
        }
    };

    const requestEmailVerification = async (email: string, clearEmailInput: () => void) => {
        try {
            const codeRequestedTimeDifference = DateUtils.diff(new Date(), codeSentAt, 'seconds');
            if (codeSentAt === undefined || codeRequestedTimeDifference > 10) {
                await authClient.requestEmailVerification(email);
                setCodeSentAt(new Date());
                showToast({ description: t('signup.codeSent') });
                setShowCodeInput(true);
                setShowCodeError(false);
                return;
            }
            showToast({ description: t('signup.timeLeftForCode', { seconds: 10 - codeRequestedTimeDifference }) });
            return;
        } catch (e) {
            showModalAlert(t('signup.duplicateEmail'), clearEmailInput);
            throw e;
        }
    };

    const confirmEmailVerification = async (email: string, code: string) => {
        try {
            await authClient.confirmEmailVerification(email, code);
            setIsCodeVerified(true);
        } catch (e) {
            setIsCodeVerified(false);
            setShowCodeError(true);
        }
    };

    const requestEmailVerificationToFindUsername = async (email: string) => {
        try {
            const codeRequestedTimeDifference = DateUtils.diff(new Date(), codeSentAt, 'seconds');
            if (codeSentAt === undefined || codeRequestedTimeDifference > 10) {
                await authClient.requestEmailVerificationToFindUsername(email);
                setCodeSentAt(new Date());
                showToast({ description: t('signup.codeSent') });
                setShowCodeInput(true);
                setShowCodeError(false);
                return;
            }
            showToast({ description: t('signup.timeLeftForCode', { seconds: 10 - codeRequestedTimeDifference }) });
            return;
        } catch (e) {
            showToast({ description: t('signup.emailDoesNotExist') });
        }
    };

    const confirmEmailVerificationToFindUsername = async (findEmailVerificationCodeDto: FindEmailVerificationCodeDto) => {
        try {
            const customerUsernameFound = await authClient.confirmEmailVerificationToFindUsername(findEmailVerificationCodeDto);
            setIsCodeVerified(true);
            setVerifiedUsername(customerUsernameFound.username);
        } catch (e) {
            setIsCodeVerified(false);
            setShowCodeError(true);
        }
    };

    const requestEmailVerificationToResetPassword = async (resetPasswordEmailVerificationCodeDto: ResetPasswordEmailVerificationCodeDto) => {
        try {
            const codeRequestedTimeDifference = DateUtils.diff(new Date(), codeSentAt, 'seconds');
            if (codeSentAt === undefined || codeRequestedTimeDifference > 10) {
                await authClient.requestEmailVerificationToResetPassword(resetPasswordEmailVerificationCodeDto);
                setCodeSentAt(new Date());
                showToast({ description: t('signup.codeSent') });
                setShowCodeInput(true);
                setShowCodeError(false);
                return;
            }
            showToast({ description: t('signup.timeLeftForCode', { seconds: 10 - codeRequestedTimeDifference }) });
            return;
        } catch (e) {
            showToast({ description: t('signup.emailDoesNotExist') });
        }
    };

    const confirmEmailVerificationToResetPassword = async (confirmResetPasswordEmailVerificationCodeDto: ConfirmResetPasswordEmailVerificationCodeDto) => {
        try {
            const username = await authClient.confirmEmailVerificationToToResetPassword(confirmResetPasswordEmailVerificationCodeDto);
            setIsCodeVerified(true);
            setVerifiedUsername(username);
        } catch (e) {
            setIsCodeVerified(false);
            setShowCodeError(true);
        }
    };

    const showModalAlert = (message: string, onClose?: () => void) => {
        showAlert({
            status: AlertStatusEnum.Alert,
            type: AlertTypeEnum.Modal,
            children: message,
            onClose: onClose,
        });
    };

    const validateForm = (requiredFields: CustomerDtoKey[], form: ReturnType<typeof OauthTerms.getOauthCustomerData>) => {
        let validatedKeys = [];
        for (const requiredField of requiredFields) {
            if (!form[requiredField as keyof typeof form]) {
                validatedKeys.push(requiredField);
            }
        }
        return validatedKeys;
    };

    return {
        checkDuplicate,
        requestEmailVerification,
        confirmEmailVerification,
        requestEmailVerificationToFindUsername,
        confirmEmailVerificationToFindUsername,
        requestEmailVerificationToResetPassword,
        confirmEmailVerificationToResetPassword,
        isUsernameVerified,
        isCodeVerified,
        setIsCodeVerified,
        setShowCodeInput,
        isPhoneNumberVerified,
        setIsPhoneNumberVerified,
        isNicknameVerified,
        setIsUsernameVerified,
        setIsNicknameVerified,
        showCodeInput,
        showCodeError,
        validateForm,
        codeSentAt,
        verifiedUsername,
        isNewPasswordDuplicate,
        setIsNewPasswordDuplicate,
    };
};
