import { useDesignComponents } from '@hooks/use-design-components';

import { BoxProps, ComponentWithAs, forwardRef } from '@chakra-ui/react';
import { Design } from '@type/harmony-config';
import DesignedContainerD from './designD/designed-container';

export interface DesignedContainerProps extends BoxProps {}

const DesignedContainer = forwardRef<DesignedContainerProps, 'div'>((props, ref) => {
    const Component = useDesignComponents<ComponentWithAs<'div', DesignedContainerProps>>({
        [Design.DesignD]: DesignedContainerD,
    });

    return <Component {...props} ref={ref} />;
});

export default DesignedContainer;
