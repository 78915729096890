import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import { PartialAbstractedTextProps } from '../../../../components/abstracted-text';
import Body1CD from './designD/body-1-c';

export interface Body1CProps extends PartialAbstractedTextProps {}

const Body1C: React.FC<Body1CProps> = (props) => {
    const Component = useDesignComponents<ComponentType<Body1CProps>>({
        [Design.DesignD]: Body1CD,
    });

    return <Component {...props} />;
};

export default Body1C;
