import { useExtension } from '@hooks/use-extension';
import RoutesEnum from '@routes';
import { useRouter } from 'next/router';
import Script from 'next/script';

const AcrossMessagePanel = () => {
    const { acrossSettings } = useExtension();
    const router = useRouter();

    if (!acrossSettings || !acrossSettings.panelScripts.messagePanelDisplayAreas.includes(router.pathname as RoutesEnum)) {
        return null;
    }

    return (
        <>
            <Script id="across-adn-message-panel">{`
                var adn_messagead_param = adn_messagead_param || [];
                adn_messagead_param.push([{ui:'${acrossSettings.panelScripts.messagePanelValue.ui}'}]);
            `}</Script>
            <Script src="//fin.rainbownine.net/js/across_adn_message_ad_1.0.4.js"></Script>
        </>
    );
};

export default AcrossMessagePanel;
