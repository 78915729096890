import { IDateBaseDto } from '@models/base-model/responseDto/date-base.dto';
import { SalesTypeEnum } from '@models/product/responseDto/component-product.dto';
import { ColorHex, ColorPalette, CountryCode, DesignId, GlobalFontSize, ImageUrl, ISODate, ThemeId } from '@type/models';

export enum ProductCardSetting {
    /** product image, title, sell price, wishlist button */
    Min = 'min',
    /** {min} + discount rate */
    MinPlus = 'min_plus',
    /** {min_plus} + market price */
    Max = 'max',
}

export enum DesignThemeEnum {
    SimpleInNavy = 'Simple in Navy',
    Subscription = 'Subscription',
    ModernDesign = 'Modern Design',
    PopoutDesign = 'Popout Design',
}

export interface IPartnerDisplayScopeDto {
    createDt: ISODate;
    updateDt: ISODate;
    isPartnerDisplayed: boolean;
    isDisplayedOnProductDetail: boolean;
    isDisplayedOnReview: boolean;
    isDisplayedOnProductQa: boolean;
}

export interface IChannelSettingsDto extends IDateBaseDto {
    channelType: string;
    channelStatus: ChannelStatusEnum;
    platformType: string;
    channelName: string;
    description: string;
    country: CountryCode;
    isUsingCustomDomain: boolean;
    isGuestOrderEnabled: boolean;
    domain: string;
    logoImage: ImageUrl;
    customerServiceInfo: ICustomerServiceInfoDto;
    partnerDisplayScope: IPartnerDisplayScopeDto;
    themeDesignSetting: IThemeDesignSettingDto;
    salesSettingType: SalesTypeEnum;
    shareSetting: IShareSettingDto;
}

export enum ChannelStatusEnum {
    Draft = 'draft',
    Selling = 'selling',
    Stop = 'stop',
    Banned = 'banned',
    Deleted = 'deleted',
}

export interface ICustomerServiceInfoDto {
    createDt: ISODate;
    updateDt: ISODate;
    contactNumber: string;
    csHours: string;
}

export interface IColorSchemeSettingDto {
    code: string;
    description: string;
    name: string;
    primaryColor: ColorHex;
    primaryPalette: ColorPalette;
    secondaryColor: ColorHex;
    secondaryPalette: ColorPalette;
}

export interface IFontCollectionDto {
    lang: CountryCode;
    code: string;
}

export interface IDesignSettingDto {
    id: DesignId;
    code: string;
    name: DesignThemeEnum;
}

export interface IFontsDto {
    primary?: string;
    secondary?: string;
}

export interface IThemeDesignSettingDto {
    id: string;
    createDt: ISODate;
    updateDt: ISODate;
    colorSchemeSetting: IColorSchemeSettingDto | null;
    themeSetting: IThemeSettingsDto;
    designSetting: IDesignSettingDto;
    fontSize: GlobalFontSize;
    productCardSetting: ProductCardSetting;
    guestUserProductCardSetting: IGuestUserProductCardSettingDto;
    fonts?: IFontsDto;
    isStockBadgeOn?: boolean;
    isTextBadgeOn?: boolean;
    textBadgeContent?: string;
    desktopImageUrl: string | null;
}

export interface IThemeSettingsDto {
    id: ThemeId;
    code: string;
    name: string;
    description: string;
}

export interface IEventCommentItemDto {
    id: string;
    userId: string;
    nickname: string;
    participateAt: string;
    comment: string;
}

export interface IShareSettingDto {
    platformConfig: {
        kakao: {
            jsKey: string;
            templateId: string | null;
            isConfigured: boolean;
        };
    };
    basicOgData: IOgData;
}

export interface IOgData {
    title: string;
    description: string;
    url: string;
    imageUrl: string;
}

export interface IGuestUserProductCardSettingDto {
    alternativeTextForPrice: string;
    id: string;
    isSalesPriceDisplayed: boolean;
    productCardSetting: ProductCardSetting;
}
