import { PropsWithChildren, createContext, useCallback, useContext, useState } from 'react';

type WishlistContextValue = {
    wishlistItems: Set<string>;
    assignWishlistItems: (productIds: string[]) => void;
    addToWishlistItems: (productId: string) => void;
    removeFromWishlistItems: (productId: string) => void;
};

export const WishlistItemsContext = createContext<WishlistContextValue | null>(null);

export const WishlistItemsContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [wishlistItems, setWishlistItems] = useState<Set<string>>(new Set());

    const assignWishlistItems = useCallback((productIds: string[]) => {
        setWishlistItems(new Set(productIds));
    }, []);

    const addToWishlistItems = useCallback((productId: string) => {
        setWishlistItems((prevWishlist) => {
            const newWishlist = new Set(prevWishlist);
            return newWishlist.add(productId);
        });
    }, []);

    const removeFromWishlistItems = useCallback((productId: string) => {
        setWishlistItems((prevWishlist) => {
            const newWishlist = new Set(prevWishlist);
            newWishlist.delete(productId);
            return newWishlist;
        });
    }, []);

    return <WishlistItemsContext.Provider value={{ assignWishlistItems, wishlistItems, addToWishlistItems, removeFromWishlistItems }}>{children}</WishlistItemsContext.Provider>;
};

export const useWishlistItems = () => {
    const value = useContext(WishlistItemsContext);

    if (!value) {
        throw Error('Cannot find WishlistItemsContextProvider');
    }

    return value;
};
