import { useDesignComponents } from '@hooks/use-design-components';

import { useExtension } from '@hooks/use-extension';
import { useReferrer } from '@hooks/use-referrer';
import BaseOrder from '@models/base-order';
import RoutesEnum from '@routes';
import { Design } from '@type/harmony-config';
import { useRouter } from 'next/router';
import PopupHeaderA from './designA/popup-header';
import PopupHeaderB from './designB/popup-header';
import PopupHeaderC from './designC/popup-header';

export interface PopupHeaderProps {
    title?: string;
    logoOnDirect?: boolean;
    showCart?: boolean;
    showSearch?: boolean;
    showHome?: boolean;
    showNotifications?: boolean;
    showCategory?: boolean;
    showBack?: boolean;
    showLogo?: boolean;
    showClose?: boolean;
    hasShadow?: boolean;
    onBack?: () => void;
    className?: string;
}

const PopupHeader: React.FC<PopupHeaderProps> = ({ showBack = true, onBack, showNotifications, className = 'top-navbar', ...props }) => {
    const Component = useDesignComponents<typeof PopupHeaderA | typeof PopupHeaderB | typeof PopupHeaderC>({
        [Design.DesignA]: PopupHeaderA,
        [Design.DesignB]: PopupHeaderB,
        [Design.DesignC]: PopupHeaderC,
    });
    const { extensionStatus } = useExtension();
    const router = useRouter();
    const { currentPath } = useReferrer();

    const handleBack = () => {
        const orderPreviousRoute = BaseOrder.getOrderPreviousRouteStorage();
        if (currentPath === orderPreviousRoute) {
            router.push(RoutesEnum.Home);
        } else {
            router.back();
        }
    };

    return (
        <Component
            showBack={showBack}
            onBack={onBack || handleBack}
            showNotifications={!extensionStatus?.notification.isEnabled ? false : showNotifications}
            className={className}
            {...props}
        />
    );
};

export default PopupHeader;
