const category = {
    category: {
        categories: '카테고리',
        seeMoreCategories: '카테고리 더보기',
        all: '전체보기',
        allShort: '전체',
        results1: '총 ',
        results2: '{items}개',
        results3: '의 상품',
        empty: '조건에 맞는 검색결과가 없습니다.',
        empty2: '필터를 다시 선택해주세요.',
        empty3: '조건에 맞는 결과가 없습니다.',
        moveToCategory: '선택된 카테고리로 이동',
        changeCategory: '카테고리 변경',
    },
};

export default category;
