const productDetail = {
    productDetail: {
        '001': '의류',
        '001fabric': '제품 소재',
        '001color': '색상',
        '001size': '치수',
        '001manufacturer': '제조자, 수입품의 경우 수입자를 함께 표기',
        '001manufacturerCountry': '제조국',
        '001instruction': '세탁방법 및 취급시 주의사항',
        '001createdDt': '제조연월',
        '001qa': '품질보증기준',
        '001qaContact': 'A/S 책임자와 전화번호',
        '002': '구두/신발',
        '002fabric1': '제품 주소재',
        '002color': '색상',
        '002size': '치수',
        '002manufacturer': '제조자, 수입품의 경우 수입자를 함께 표기',
        '002manufacturerCountry': '제조국',
        '002instruction': '취급시 주의사항',
        '002qa': '품질보증기준',
        '002qaContact': 'A/S 책임자와 전화번호',
        '003': '가방',
        '003kind': '종류',
        '003fabric': '소재',
        '003color': '색상',
        '003size': '크기',
        '003manufacturer': '제조자, 수입품의 경우 수입자를 함께 표기',
        '003manufacturerCountry': '제조국',
        '003instruction': '취급시 주의사항',
        '003qa': '품질보증기준',
        '003qaContact': 'A/S 책임자와 전화번호',
        '004': '패션잡화(모자/벨트/액세서리)',
        '004kind': '종류',
        '004fabric': '소재',
        '004size': '치수',
        '004manufacturer': '제조자, 수입품의 경우 수입자를 함께 표기',
        '004manufacturerCountry': '제조국',
        '004instruction': '취급시 주의사항',
        '004qa': '품질보증기준',
        '004qaContact': 'A/S 책임자와 전화번호',
        '005': '침구류/커튼',
        '005fabric': '제품 소재',
        '005color': '색상',
        '005size': '치수',
        '005components': '제품구성',
        '005manufacturer': '제조자, 수입품의 경우 수입자를 함께 표기',
        '005manufacturerCountry': '제조국',
        '005instruction': '세탁방법 및 취급시 주의사항',
        '005qa': '품질보증기준',
        '005qaContact': 'A/S 책임자와 전화번호',
        '006': '가구(침대/소파/싱크대/DIY제품)',
        '006name': '품명',
        '006isGuaranteed': 'KC 인증 필 유무',
        '006color': '색상',
        '006components': '구성품',
        '006fabric': '주요 소재',
        '006manufacturer': '제조자, 수입품의 경우 수입자를 함께 표기',
        '006manufacturerCountry': '제조국',
        '006size': '크기',
        '006deliveryPrice': '배송·설치 비용',
        '006qa': '품질보증기준',
        '006qaContact': 'A/S 책임자와 전화번호',
        '007': '영상가전(TV류)',
        '007name': '품명 및 모델명',
        '007isGuaranteed': 'KC 인증 필 유무',
        '007ratedVoltage': '정격전압/소비전력',
        '007energyGrade': '에너지소비효율등급',
        '007releaseDtOfSameModel': '동일모델의 출시년월',
        '007manufacturer': '제조자, 수입품의 경우 수입자를 함께 표기',
        '007manufacturerCountry': '제조국',
        '007size': '크기',
        '007specification': '화면사양',
        '007qa': '품질보증기준',
        '007qaContact': 'A/S 책임자와 전화번호',
        '008': '가정용 전기제품(냉장고/세탁기/식기세척기/전자레인지)',
        '008name': '품명 및 모델명',
        '008isGuaranteed': 'KC 인증 필 유무',
        '008ratedVoltage': '정격전압 / 소비전력',
        '008energyGrade': '에너지소비효율등급',
        '008releaseDtOfSameModel': '동일모델의 출시년월',
        '008manufacturer': '제조자, 수입품의 경우 수입자를 함께 표기',
        '008manufacturerCountry': '제조국',
        '008size': '크기',
        '008qa': '품질보증기준',
        '008qaContact': 'A/S 책임자와 전화번호',
        '009': '계절가전(에어컨/온풍기)',
        '009name': '품명 및 모델명',
        '009isGuaranteed': 'KC 인증 필 유무',
        '009ratedVoltage': '정격전압 / 소비전력',
        '009energyGrade': '에너지소비효율등급',
        '009releaseDtOfSameModel': '동일모델의 출시년월',
        '009manufacturer': '제조자, 수입품의 경우 수입자를 함께 표기',
        '009manufacturerCountry': '제조국',
        '009size': '크기',
        '009area': '냉난방면적',
        '009additionalCost': '추가설치비용',
        '009qa': '품질보증기준',
        '009qaContact': 'A/S 책임자와 전화번호',
        '010': '사무용기기(컴퓨터/노트북/프린터)',
        '010name': '품명 및 모델명',
        '010isGuaranteed': 'KC 인증 필 유무',
        '010ratedVoltage': '정격전압 / 소비전력',
        '010energyGrade': '에너지소비효율등급',
        '010releaseDtOfSameModel': '동일모델의 출시년월',
        '010manufacturer': '제조자, 수입품의 경우 수입자를 함께 표기',
        '010manufacturerCountry': '제조국',
        '010size': '크기, 무게',
        '010specification': '주요 사양',
        '010qa': '품질보증기준',
        '010qaContact': 'A/S 책임자와 전화번호',
        '011': '광학기기(디지털카메라/캠코더)',
        '011name': '품명 및 모델명',
        '011isGuaranteed': 'KC 인증 필 유무',
        '011releaseDtOfSameModel': '동일모델의 출시년월',
        '011manufacturer': '제조자, 수입품의 경우 수입자를 함께 표기',
        '011manufacturerCountry': '제조국',
        '011size': '크기, 무게',
        '011specification': '주요 사양',
        '011qa': '품질보증기준',
        '011qaContact': 'A/S 책임자와 전화번호',
        '012': '소형전자(MP3/전자사전 등)',
        '012name': '품명 및 모델명',
        '012isGuaranteed': 'KC 인증 필 유무',
        '012ratedVoltage': '정격전압 / 소비전력',
        '012releaseDtOfSameModel': '동일모델의 출시년월',
        '012manufacturer': '제조자, 수입품의 경우 수입자를 함께 표기',
        '012manufacturerCountry': '제조국',
        '012size': '크기, 무게',
        '012specification': '주요 사양',
        '012qa': '품질보증기준',
        '012qaContact': 'A/S 책임자와 전화번호',
        '013': '휴대폰',
        '013name': '품명 및 모델명',
        '013isGuaranteed': 'KC 인증 필 유무',
        '013releaseDtOfSameModel': '동일모델의 출시년월',
        '013manufacturer': '제조자, 수입품의 경우 수입자를 함께 표기',
        '013manufacturerCountry': '제조국',
        '013size': '크기, 무게',
        '013mobileOperator': '이동통신사',
        '013registrationProcess': '가입 절차',
        '013additionalBurdenOnConsumer': '소비자의 추가적인 부담사항',
        '013specification': '주요 사양',
        '013qa': '품질보증기준',
        '013qaContact': 'A/S 책임자와 전화번호',
        '014': '네비게이션',
        '014name': '품명 및 모델명',
        '014isGuaranteed': 'KC 인증 필 유무',
        '014ratedVoltage': '정격전압 / 소비전력',
        '014releaseDtOfSameModel': '동일모델의 출시년월',
        '014manufacturer': '제조자, 수입품의 경우 수입자를 함께 표기',
        '014manufacturerCountry': '제조국',
        '014size': '크기, 무게',
        '014specification': '주요 사양',
        '014costAndFreePeriod': '맵 업데이트 비용 및 무상기간',
        '014qa': '품질보증기준',
        '014qaContact': 'A/S 책임자와 전화번호',
        '015': '자동차용품(자동차부품/기타 자동차용품)',
        '015name': '품명 및 모델명',
        '015releaseDtOfSameModel': '동일모델의 출시년월',
        '015isGuaranteed': 'KC 인증 필 유무',
        '015manufacturer': '제조자, 수입품의 경우 수입자를 함께 표기',
        '015manufacturerCountry': '제조국',
        '015size': '크기',
        '015car': '적용차종',
        '015instruction': '제품사용으로 인한 위험 및 유의사항',
        '015qa': '품질보증기준',
        '015passNumber': '검사 합격증 번호',
        '015qaContact': 'A/S 책임자와 전화번호',
        '016': '의료기기',
        '016name': '품명 및 모델명',
        '016permitNumber': '의료기기법상 허가·신고 번호',
        '016isGuaranteed': '광고사전심의필 유무',
        '016ratedVoltage': '정격전압 / 소비전력',
        '016releaseDtOfSameModel': '동일모델의 출시년월',
        '016manufacturer': '제조자, 수입품의 경우 수입자를 함께 표기',
        '016manufacturerCountry': '제조국',
        '016howToUse': '제품의 사용목적 및 사용방법',
        '016instruction': '취급시 주의사항',
        '016qa': '품질보증기준',
        '016qaContact': 'A/S 책임자와 전화번호',
        '017': '주방용품',
        '017name': '품명 및 모델명',
        '017fabric': '재질',
        '017components': '구성품',
        '017size': '크기',
        '017releaseDtOfSameModel': '동일모델의 출시년월',
        '017manufacturer': '제조자, 수입품의 경우 수입자를 함께 표기',
        '017manufacturerCountry': '제조국',
        '017text': '수입식품안전관리특별법에 따른 수입 기구·용기의 경우 "수입식품안전관리특별법에 따른 수입신고를 필함"의 문구',
        '017qa': '품질보증기준',
        '017qaContact': 'A/S 책임자와 전화번호',
        '018': '화장품',
        '018volume': '용량 또는 중량',
        '018specification': '제품 주요 사양',
        '018dateOfUse': '사용기한 또는 개봉 후 사용기간',
        '018howToUse': '사용방법',
        '018manufacturer': '화장품제조업자 및 화장품책임판매업자',
        '018manufacturerCountry': '제조국',
        '018ingredients': '화장품법에 따라 기재 표기해야 하는 모든 성분',
        '018isGuaranteed': '기능성 화장품의 경우 화장품법에 따른 식품의약품안전처 심사 필 유무',
        '018instructions': '사용할 때 주의사항',
        '018qa': '품질보증기준',
        '018qaContact': '소비자상담관련 전화번호',
        '019': '귀금속/보석/시계류',
        '019fabric': '소재/순도/밴드재질',
        '019weight': '중량',
        '019manufacturer': '제조자, 수입품의 경우 수입자를 함께 표기',
        '019manufacturerCountry': '제조국',
        '019size': '치수',
        '019instruction': '착용 시 주의사항',
        '019specification': '주요 사양',
        '019grade': '귀금속, 보석류 - 등급',
        '019function': '시계 - 기능, 방수 등',
        '019guarantee': '보증서 제공여부',
        '019qa': '품질보증기준',
        '019qaContact': 'A/S 책임자와 전화번호',
        '020': '식품(농수축산물)',
        '020name': '품목 또는 명칭',
        '020size': '포장단위별 내용물의 용량(중량), 수량, 크기',
        '020manufacturer': '생산자, 수입품의 경우 수입자를 함께 표기',
        '020origin': '농수산물의 원산지 표시에 관한 법률에 따른 원산지',
        '020expirationDate': '제조연월일, 유통기한',
        '020agriculturalProducts': '농산물 - 농수산물품질관리법상 유전자변형농산물 표시, 지리적 표시',
        '020livestock': '축산물 - 축산법에 따른 등급 표시',
        '020seafood': '수산물 - 농수산물품질관리법상 유전자변형수산물 표시, 지리적 표시',
        '020importedFood': '수입식품에 해당하는 경우 "수입식품안전관리특별법에 따른 수입신고를 필함"의 문구',
        '020composition': '상품구성',
        '020handlingMethod': '보관방법 또는 취급방법',
        '020instruction': '식품등에표시·광고에관한법률에 따른 소비자안전을 위한 주의사항',
        '020consumerContact': '소비자상담관련 전화번호',
        '021': '가공식품',
        '021name': '제품명',
        '021categories': '식품의 유형',
        '021manufacturerAndLocation': '생산자 및 소재지',
        '021expirationDate': '제조연월일, 유통기한 또는 품질유지기한',
        '021weight': '포장단위별 내용물의 용량(중량), 수량',
        '021materialName': '원재료명 및 함량',
        '021ingredients': '영양성분',
        '021geneticModification': '유전자변형식품에 해당하는 경우의 표시',
        '021instruction': '소비자안전을 위한 주의사항',
        '021isGuaranteed': '수입식품에 해당하는 경우 "수입식품안전관리특별법에 따른 수입신고를 필함"의 문구',
        '021consumerContact': '소비자상담관련 전화번호',
        '022': '건강기능식품',
        '022name': '제품명',
        '022categories': '식품의 유형',
        '022manufacturer': '제조업소의 명칭과 소재지',
        '022expirationDate': '제조연월일, 유통기한',
        '022weight': '포장단위별 내용물의 용량(중량), 수량',
        '022materialName': '원재료명 및 함량',
        '022nutrition': '영양정보',
        '022function': '기능정보',
        '022intakeDescription': '섭취량, 섭취방법 및 섭취 시 주의사항 및 부작용 가능성',
        '022description': '질병의 예방 및 치료를 위한 의약품이 아니라는 내용의 표현',
        '022geneticModification': '유전자변형건강기능식품에 해당하는 경우의 표시',
        '022isGuaranteed': '수입식품에 해당하는 경우 "수입식품안전관리특별법에 따른 수입신고를 필함"의 문구',
        '022instruction': '소비자안전을 위한 주의사항',
        '022consumerContact': '소비자상담관련 전화번호',
        '023': '영유아용품',
        '023name': '품명 및 모델명',
        '023isGuaranteed': '어린이제품안전특별법상 안전인증대상어린이제품, 안전확인대상어린이제품, 공급자적합성확인대상어린이제품에 대한 KC인증 필 유무',
        '023size': '크기, 중량',
        '023color': '색상',
        '023fabric': '재질',
        '023ageOfUse': '사용연령 또는 체중범위',
        '023releaseDtOfSameModel': '동일모델의 출시년월',
        '023manufacturer': '제조자, 수입품의 경우 수입자를 함께 표기',
        '023manufacturerCountry': '제조국',
        '023instruction': '취급방법 및 취급시 주의사항, 안전표시',
        '023qa': '품질보증기준',
        '023qaContact': 'A/S 책임자와 전화번호',
        '024': '악기',
        '024name': '품명 및 모델명',
        '024size': '크기',
        '024color': '색상',
        '024fabric': '재질',
        '024components': '제품 구성',
        '024releaseDtOfSameModel': '동일모델의 출시년월',
        '024manufacturer': '제조자, 수입품의 경우 수입자를 함께 표기',
        '024manufacturerCountry': '제조국',
        '024specification': '상품별 세부 사양',
        '024qa': '품질보증기준',
        '024qaContact': 'A/S 책임자와 전화번호',
        '025': '스포츠용품',
        '025name': '품명 및 모델명',
        '025size': '크기, 중량',
        '025color': '색상',
        '025fabric': '재질',
        '025components': '제품 구성',
        '025releaseDtOfSameModel': '동일모델의 출시년월',
        '025manufacturer': '제조자, 수입품의 경우 수입자를 함께 표기',
        '025manufacturerCountry': '제조국',
        '025specification': '상품별 세부 사양',
        '025qa': '품질보증기준',
        '025qaContact': 'A/S 책임자와 전화번호',
        '026': '서적',
        '026name': '도서명',
        '026publisher': '저자, 출판사',
        '026size': '크기',
        '026pages': '쪽수',
        '026components': '제품 구성',
        '026releaseDt': '출간일',
        '026index': '목차 또는 책 소재',
        '027': '호텔/펜션 예약',
        '027country': '국가 또는 지역명',
        '027typeOfAccomodation': '숙소 형태',
        '027grade': '등급, 객실타입',
        '027capacity': '사용가능 인원, 인원 추가 시 비용',
        '027amenities': '부대시설, 제공 서비스',
        '027cancellationPolicy': '취소규정',
        '027reservationContact': '예약담당 연락처',
        '028': '여행패키지',
        '028travelAgency': '여행사',
        '028flight': '이용항공편',
        '028calendar': '여행기간 및 일정',
        '028totalNumber': '총 예정 인원, 출발 가능 인원',
        '028accomodation': '숙박정보',
        '028price': '여행상품 가격',
        '028optionalPrice': '선택경비 유무',
        '028alternativeItinerary': '선택관광 및 대체일정',
        '028tip': '가이드 팁',
        '028cancellationPolicy': '취소 규정',
        '028travelAlertStage': '해외여행의 경우 외교부가 지정하는 여행경보단계',
        '028reservationContact': '예약담당 연락처',
        '029': '항공권',
        '029feeTerms': '요금조건, 왕복·편도 여부',
        '029duration': '유효기간',
        '029restrictions': '제한사항',
        '029howToReceiveTicket': '티켓수령방법',
        '029typeOfSeats': '좌석종류',
        '029priceNotIncluded': '가격에 포함되지 않은 내역 및 금액',
        '029cancellationPolicy': '취소 규정',
        '029reservationContact': '예약담당 연락처',
        '030': '자동차 대여 서비스(렌터카)',
        '030carType': '차종',
        '030transferOfOwnership': '소유권 이전 조건',
        '030additionalCost': '추가 선택 시 비용',
        '030howToSettle': '차량 반환 시 연료대금 정산 방법',
        '030consumerResponsibility': '차량의 고장·훼손시 소비자책임',
        '030refundCriteria': '예약 취소 또는 중도 해약 시 환불 기준',
        '030consumerContact': '소비자상담 관련 전화번호',
        '031': '물품대여 서비스(정수기/비데/공기청정기 등)',
        '031name': '품명 및 모델명',
        '031transferOfOwnership': '소유권 이전 조건',
        '031maintenanceConditions': '유지보수 조건',
        '031consumerResponsibility': '상품의 고장·분실·훼손시 소비자책임',
        '031refundCriteria': '중도 해약 시 환불 기준',
        '031specification': '제품 사양',
        '031consumerContact': '소비자상담 관련 전화번호',
        '032': '물품대여 서비스(서적/유아용품/행사용품 등)',
        '032name': '품명 및 모델명',
        '032transferOfOwnership': '소유권 이전 조건',
        '032consumerResponsibility': '상품의 고장·분실·훼손시 소비자책임',
        '032refundCriteria': '중도 해약 시 환불 기준',
        '032consumerContact': '소비자상담 관련 전화번호',
        '033': '디지털 콘텐츠(음원, 게임, 인터넷강의 등)',
        '033manufacturer': '제작자 또는 공급자',
        '033duration': '이용조건, 이용기간',
        '033deliveryMethod': '상품 제공 방식',
        '033minimumSpecifications': '최소 시스템 사양, 필수 소프트웨어',
        '033withdrawal': '청약철회 또는 계약의 해제·해지에 따른 효과',
        '033consumerContact': '소비자상담 관련 전화번호',
        '034': '상품권/쿠폰',
        '034publisher': '발행자',
        '034duration': '유효기간, 이용조건',
        '034availableStore': '이용 가능 매장',
        '034fullRefundTerms': '전액 환급 조건',
        '034consumerContact': '소비자상담 관련 전화번호',
        '035': '모바일 쿠폰',
        '035publisher': '발행자',
        '035duration': '유효기간, 이용조건',
        '035availableStore': '이용 가능 매장',
        '035refundTerms': '환불조건 및 방법',
        '035consumerContact': '소비자상담 관련 전화번호',
        '036': '영화·공연',
        '036hosting': '주최 또는 기획',
        '036mainActor': '주연',
        '036filmRating': '관람등급',
        '036screenTime': '상영·공연시간',
        '036theater': '상영·공연장소',
        '036conditionsOfCancellation': '예매 취소 조건',
        '036howToCancel': '취소·환불방법',
        '036consumerContact': '소비자상담 관련 전화번호',
        '037': '생활화학제품',
        '037name': '품목 및 제품명',
        '037purpose': '용도',
        '037expirationDate': '제조연월 및 유통기한',
        '037size': '중량·용량·매수',
        '037effect': '효과·효능',
        '037manufacturer': '수입자, 제조국 및 제조사',
        '037whetherProductsForChildren': '어린이 보호포장 대상 제품 유무',
        '037chemicalSubstance': '제품에 사용된 화학물질 명칭',
        '037instruction': '사용상 주의사항',
        '037approvalNumber': '안전기준 적합 확인 신고번호 또는 안전확인대상 생활화학제품 승인번호',
        '037consumerContact': '소비자상담관련 전화번호',
        '038': '살생물제품',
        '038name': '제품명 및 제품유형',
        '038size': '중량 또는 용량 및 표준사용량',
        '038effect': '효과·효능',
        '038target': '사용대상자 및 사용범위',
        '038manufacturer': '수입자, 제조국 및 제조사',
        '038whetherProductsForChildren': '어린이 보호포장 대상 제품 유무',
        '038chemicalSubstance': '살생물물질, 나노물질, 기타 화학물질의 명칭',
        '038hazardStatements': '제품 유해성·위해성 표시',
        '038instruction': '사용방법 및 사용상 주의사항',
        '038approvalNumber': '승인번호',
        '038consumerContact': '소비자상담관련 전화번호',
        '039': '기타 용역',
        '039businessman': '서비스 제공 사업자',
        '039isGuaranteed': '법에 의한 인증·허가 등을 받았음을 확인할 수 있는 경우 그에 대한 사항',
        '039condition': '이용조건',
        '039refundCriteria': '취소·중도해약·해지조건 및 환불기준',
        '039howToCancel': '취소·환불 방법',
        '039qaContact': '소비자상담 관련 전화번호',
        '040': '기타 재화',
        '040name': '품명 및 모델명',
        '040isGuaranteed': '법에 의한 인증·허가 등을 받았음을 확인할 수 있는 경우 그에 대한 사항',
        '040originCountry': '제조국 또는 원산지',
        '040manufacturer': '제조자, 수입품의 경우 수입자를 함께 표기',
        '040qaContact': 'A/S 책임자와 전화번호 또는 소비자상담 관련 전화번호',
    },
};

export default productDetail;
