import { useDesignComponents } from '@hooks/use-design-components';

import { IconButtonProps } from '@designs/primitives/common/icon-button';
import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import BackButtonD from './designD/back-button';

export interface BackButtonProps extends Omit<IconButtonProps, 'children'> {}

const BackButton: React.FC<BackButtonProps> = (props) => {
    const Component = useDesignComponents<ComponentType<BackButtonProps>>({
        [Design.DesignD]: BackButtonD,
    });

    return <Component {...props} />;
};

export default BackButton;
