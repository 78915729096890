import ApiService from '@api/api.service';
import { authClient } from '@api/auth/auth.client';
import { IExtensionStatusDto } from '@models/extension-status/responseDto/extension-status.dto';
import { ListResult } from '@type/models';
import EnvironmentUtils from '@utils/environment.utils';

/**
 * Api client handling billing related logic
 * @link https://jupiter-dev.harmonycvm.com/billing/doc
 */
class BillingClient {
    private static readonly BILLING_API_PREFIX = '/billing/api';
    private static readonly _client = new ApiService(`https://jupiter${EnvironmentUtils.API_ENV}.harmonycvm.com`, authClient, { isCamelcaseApi: true });

    static async getSimpleExtensionStatus(): Promise<ListResult<IExtensionStatusDto>> {
        /** NOTE: use instance temporary to exclude `Authorization` header */
        return (
            await this._client.instance.get<ListResult<IExtensionStatusDto>>(`${this.BILLING_API_PREFIX}/external/extension/channel/simple`, {
                params: {
                    channelId: EnvironmentUtils.CHANNEL_ID,
                },
            })
        ).data;
    }
}

export { BillingClient };
