import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import FailureSymbolA from './designA/failure-symbol';
import FailureSymbolB from './designB/failure-symbol';
import FailureSymbolC from './designC/failure-symbol';

export interface FailureSymbolProps {}

const FailureSymbol: React.FC<FailureSymbolProps> = (props) => {
    const Component = useDesignComponents<typeof FailureSymbolA | typeof FailureSymbolB | typeof FailureSymbolC>({
        [Design.DesignA]: FailureSymbolA,
        [Design.DesignB]: FailureSymbolB,
        [Design.DesignC]: FailureSymbolC,
    });

    return <Component {...props} />;
};

export default FailureSymbol;
