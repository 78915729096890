import { useDesignComponents } from '@hooks/use-design-components';

import { CompletePageDetails } from '@designs/pages/complete-page-content';
import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import DetailsBoxA from './designA/details-box';
import DetailsBoxB from './designB/details-box';
import DetailsBoxC from './designC/details-box';
import DetailsBoxD from './designD/details-box';

export interface DetailsBoxProps {
    details: CompletePageDetails;
    isInfoVertical?: boolean;
}

const DetailsBox: React.FC<DetailsBoxProps> = (props) => {
    const Component = useDesignComponents<ComponentType<DetailsBoxProps>>({
        [Design.DesignA]: DetailsBoxA,
        [Design.DesignB]: DetailsBoxB,
        [Design.DesignC]: DetailsBoxC,
        [Design.DesignD]: DetailsBoxD,
    });

    if (Object.keys(props.details.data).length === 0) {
        return null;
    }

    return <Component {...props} />;
};

export default DetailsBox;
