import { useState } from 'react';
import MainButton, { MainButtonProps } from '../main-button';

export interface MainButtonAsyncProps extends Omit<MainButtonProps, 'onClick' | 'href'> {
    onClickAsync: () => Promise<void>;
}

const MainButtonAsync: React.FC<MainButtonAsyncProps> = ({ onClickAsync, ...props }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleClickAsync = async () => {
        try {
            setIsLoading(true);
            await onClickAsync();
        } catch (e) {
            throw e;
        } finally {
            setIsLoading(false);
        }
    };

    return <MainButton onClick={handleClickAsync} isLoading={isLoading} {...props} />;
};

export default MainButtonAsync;
