import Address from '@models/address';
import IdBaseModel from '@models/base-model/id-base';
import Tier, { TierEnum } from '@models/customer/tier';
import { BasicDate, ChannelId, GenderCode, ImageUrl } from '@type/models';
import { IUpdateCustomerDto } from './requestDto/update-customer.dto';
import { ICustomerDto, IOfflineStoreMembershipDto } from './responseDto/customer.dto';

class Customer extends IdBaseModel {
    private _addressList: Address[];
    private _channelId: ChannelId;
    private _email: string;
    private _name: string;
    private _nickname: string;
    private _phoneNumber: string;
    private _profileImage: ImageUrl;
    private _gender: GenderCode | null;
    private _birthdate: BasicDate | null;
    private _tier: Tier | null;
    private _username: string;
    private _offlineStoreMembership: IOfflineStoreMembershipDto | null;
    public readonly rawData: ICustomerDto;

    constructor(customerDto: ICustomerDto) {
        super(customerDto.id);
        this._addressList = customerDto.addressList.map((address) => new Address(address));
        this._channelId = customerDto.channelId;
        this._email = customerDto.email;
        this._name = customerDto.name;
        this._nickname = customerDto.nickname;
        this._phoneNumber = customerDto.phoneNumber;
        this._profileImage = customerDto.profileImage;
        this._gender = customerDto.gender;
        this._birthdate = customerDto.birthdate;
        this._tier = customerDto.tier ? new Tier(customerDto.tier) : null;
        this._username = customerDto.username;
        (this._offlineStoreMembership = customerDto.offlineStoreMembership), (this.rawData = customerDto);
    }

    public createEditUserInfoDto(): Partial<IUpdateCustomerDto> {
        return {
            nickname: this._nickname || undefined,
            birthdate: this._birthdate || undefined,
            gender: this._gender || undefined,
            name: this._name || undefined,
            username: this._username || undefined,
            email: this._email || undefined,
            phoneNumber: this._phoneNumber || undefined,
            offlineStoreMembership: this._offlineStoreMembership || undefined,
        };
    }

    get email(): string {
        return this._email || '';
    }

    get name(): string {
        return this._name;
    }

    get nickname(): string {
        return this._nickname;
    }

    get displayedName(): string {
        return this._name || this._nickname || '';
    }

    get phoneNumber(): string {
        return this._phoneNumber;
    }

    get gender(): GenderCode | null {
        return this._gender;
    }

    get username(): string {
        return this._username;
    }

    get birthdate(): string | null {
        return this._birthdate;
    }

    get tier(): Tier | null {
        return this._tier;
    }

    get isBlueTier(): boolean {
        return this._tier?.name === TierEnum.Blue;
    }

    get isSilverTier(): boolean {
        return this._tier?.name === TierEnum.Silver;
    }

    get isGoldTier(): boolean {
        return this._tier?.name === TierEnum.Gold;
    }

    get isBlackTier(): boolean {
        return this._tier?.name === TierEnum.Black;
    }
}

export default Customer;
