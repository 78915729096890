type BaseProps = {
    testId?: string;
    className?: string;
    margin?: string;
    mt?: string;
    ml?: string;
    mr?: string;
    mb?: string;
    padding?: string;
    pt?: string;
    pl?: string;
    pr?: string;
    pb?: string;
};

export const getMargin = ({ margin, mt = '0px', ml = '0px', mr = '0px', mb = '0px' }: { margin?: string; mt?: string; ml?: string; mr?: string; mb?: string }) => {
    return margin || `${mt} ${mr} ${mb} ${ml}`;
};

export const getPadding = ({ padding, pt = '0px', pl = '0px', pr = '0px', pb = '0px' }: { padding?: string; pt?: string; pl?: string; pr?: string; pb?: string }) => {
    return padding || `${pt} ${pr} ${pb} ${pl}`;
};

export default BaseProps;
