import { useDisclosure } from '@chakra-ui/react';
import Alert, { AlertProps, Buttons } from '@legacy/designs/alert';
import { useRouter } from 'next/router';
import { ReactElement, ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';
import Portal from 'src/legacy/components/common/portal';

export interface IDrawerOptions extends Omit<AlertProps, 'open' | 'onClose' | 'buttons'> {
    onClose?: () => void;
    /**
     * Tells the alert drawer not to close and remain covering the app.
     * @default true
     */
    buttons?: Buttons;
}

/**
 * Launches an alert drawer portal with the default button settings.
 *
 * For more granular control over the drawer's open state and it's buttons,
 * use the Alert component directly in the component tree instead.
 */
type Drawer = (options: IDrawerOptions) => void;

type AlertContextType = {
    showAlert: Drawer;
};

export const AlertContext = createContext<AlertContextType>({} as AlertContextType);

interface AlertProviderProps {
    children: ReactNode;
}

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }): ReactElement => {
    const [alert, setAlert] = useState<IDrawerOptions | undefined>(undefined);
    const [render, setRender] = useState<boolean>(false);
    const [dismissible, setDismissible] = useState<boolean>(true);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const router = useRouter();

    useEffect(() => {
        if (render && !isOpen) {
            setTimeout(() => {
                setRender(false);
            }, 1000);
        }
    }, [render, isOpen]);

    useEffect(() => {
        onClose();
    }, [router.asPath]);

    const showAlert: Drawer = useCallback(
        (options) => {
            setDismissible(options.dismissible !== undefined ? options.dismissible : true);
            setAlert(options);
            onOpen();
            setRender(true);
        },
        [onOpen]
    );

    return (
        <AlertContext.Provider value={{ showAlert: showAlert }}>
            {children}
            {render && (
                <Portal portalId="portal">
                    <Alert
                        {...alert}
                        open={isOpen}
                        onClose={() => {
                            if (dismissible) {
                                onClose();
                            }
                            if (alert?.onClose) {
                                alert.onClose();
                            }
                        }}
                    />
                </Portal>
            )}
        </AlertContext.Provider>
    );
};

export const useAlert = (): AlertContextType => useContext(AlertContext);
