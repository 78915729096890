import { VStack } from '@chakra-ui/react';
import DesignedContainer from '@designs/primitives/common/designed-container';
import { useHarmony } from '@hooks/use-harmony';
import { DetailsBoxProps } from '..';
import DetailItem from '../../detail-item';

const DetailsBoxD: React.FC<DetailsBoxProps> = ({ details }) => {
    const { theme } = useHarmony();

    return (
        <DesignedContainer mt="40px" p="12px">
            <VStack spacing="16px" align="stretch">
                {Object.entries(details.data).map(([label, value]) => !!value && <DetailItem key={label} label={label} value={value} />)}
            </VStack>
        </DesignedContainer>
    );
};

export default DetailsBoxD;
