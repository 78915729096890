import { DesignComponentMap } from '@type/theme';
import { useMemo } from 'react';
import { useHarmony } from './use-harmony';
import ThemeDesignSetting from '@models/channel-settings/theme-design-setting';

/**
 * When provided a design component map, this hook will return the component based on the design
 * specifications from the channel settings.
 *
 * **If there is no component matches the design, returns the first component exist.**
 */
export function useDesignComponents<T>(components: DesignComponentMap): T {
    const { settings } = useHarmony();

    return useMemo<T>(() => {
        return settings?.themeDesignSetting.getDesignComponent<T>(components) ?? ThemeDesignSetting.resolveDesignComponents<T>(components);
    }, [components, settings?.themeDesignSetting]);
}
