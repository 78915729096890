import BaseOrder from '@models/base-order';
import RoutesEnum from '@routes';
import { useRouter } from 'next/router';
import { useReferrer } from './use-referrer';

export const useDefaultBackAction = () => {
    const router = useRouter();
    const { currentPath } = useReferrer();

    const defaultBackAction = () => {
        const orderPreviousRoute = BaseOrder.getOrderPreviousRouteStorage();
        if (currentPath === orderPreviousRoute) {
            router.push(RoutesEnum.Home);
        } else {
            router.back();
        }
    };

    return { defaultBackAction };
};
