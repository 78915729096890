const gift = {
    gift: {
        giftSuccess: '선물완료',
        giftSuccessHeading: '선물 주문이 완료되었습니다.',
        giftFail: '선물실패',
        giftFailHeading: `선물 결제에 실패하였습니다.\n다시 시도해 주세요.`,
        cancelGift: '선물취소',
        cancelGiftWarning: '선물취소는 받는 사람이 배송지를\n등록하기 전까지 가능합니다.',
        isItOkToCancel: '선물을 취소하시겠습니까?',
        cancelGiftDone: '선물이 취소되었습니다.',
        giftHistory: '보낸 선물함',
        all: '전체',
        giftOrderOk: '선물완료',
        giftOrderRegistered: '등록완료',
        giftOrderCancelled: '선물취소',
        giftOrderRefunded: '환불완료',
        giftOrderDetails: '선물 상세',
        notRegistered1: '아직 등록된 배송지가 없습니다.',
        notRegistered2: '배송지 등록 기한이 <style>{days}일</style> 남았어요!',
        notRegistered3: '선물 받는 분에게 선물 전송 링크를 전달해 주세요.',
        notRegistered4: '선물 후 7일 자정까지 상대방이 배송지를 등록하지 않을 경우, 자동으로 선물이 취소됩니다.',
        notRegistered5: '‘마이페이지 > 보낸 선물함’에서 선물 링크를 재확인할 수 있습니다.',
        registerWarning1: '{expireDt}까지 배송지를 등록해 주세요.',
        registerWarning2: '기한 내에 배송지를 등록하지 않으면 선물이 자동취소됩니다.',
        giftArrived: '<style>{name}</style>님으로부터 선물이 도착했어요!',
        receiveGiftAfterRegisterAddress: '배송지 등록 후 선물 받기',
        giftRegistered1: '배송지 등록이 완료되었습니다.',
        giftRegistered2: '선물 배송은 출고업체 일정에 따라 배송될 예정입니다.',
        giftExchangeRefundNotice: '반품 및 교환 관련 안내',
        giftExchangeRefundDescription: '배송 상품 선물의 반품 및 교환은 선물 보낸 분이 요청할 수 있습니다.',
        pleaseShareGiftLink: '선물받는 분에게 선물 전송 링크를 전달해 주세요!',
        notShippingYet: '아직 운송장번호가 등록되지 않았습니다.',
        receiveGiftFailed: '선물 받기에 실패하였습니다.\n다시 시도해 주세요.',
        receiveGiftExpired: '등록 기한이 경과된 선물입니다.',
        receiveGiftCancelled: '취소된 선물입니다.',
    },
};

export default gift;
