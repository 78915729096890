import CONFIG from '@config';
import DateBaseModel from '@models/base-model/date-base';
import theme from '@styles/theme';
import { IColorToken } from '@type/color-token';
import { Design, Fonts } from '@type/harmony-config';
import { ColorHex, ColorPalette, ColorPaletteRecord, GlobalFontSize } from '@type/models';
import { DesignComponentMap, FontSizes, IHarmonyFont, IHarmonyTheme } from '@type/theme';
import EnvironmentUtils from '@utils/environment.utils';
import {
    DesignThemeEnum,
    IColorSchemeSettingDto,
    IDesignSettingDto,
    IFontsDto,
    IGuestUserProductCardSettingDto,
    IThemeDesignSettingDto,
    IThemeSettingsDto,
    ProductCardSetting,
} from './responseDto/channel-settings.dto';

class ThemeDesignSetting extends DateBaseModel {
    /**This rem value equals 1px when the standard value of 1rem is 16px.
     * This can be used to calculate the rem value from pixels
     * */
    private readonly _REM_PIXEL_FACTOR = 0.0625;

    private _colorSchemeSetting: IColorSchemeSettingDto | null;
    private _themeSetting: IThemeSettingsDto;
    private _designSetting: IDesignSettingDto;
    private _fontSize: GlobalFontSize;
    private _productCardSetting: ProductCardSetting;
    private _theme: IHarmonyTheme;
    private _fonts: Required<IFontsDto>;
    private _isStockBadgeOn?: boolean;
    private _isTextBadgeOn?: boolean;
    private _textBadgeContent?: string;
    private _desktopImageUrl: string | null;
    readonly guestUserProductCardSetting: IGuestUserProductCardSettingDto;

    constructor(dto: IThemeDesignSettingDto) {
        super(dto.id, dto.createDt, dto.updateDt);
        this._colorSchemeSetting = dto.colorSchemeSetting;
        this._themeSetting = dto.themeSetting;
        this._designSetting = dto.designSetting;
        this._fontSize = dto.fontSize;
        this._productCardSetting = dto.productCardSetting;
        this._isStockBadgeOn = dto.isStockBadgeOn;
        this._isTextBadgeOn = dto.isTextBadgeOn;
        this._textBadgeContent = dto.textBadgeContent;
        this._desktopImageUrl = dto.desktopImageUrl;
        this._fonts = this._sanitizeFonts(dto.fonts);
        this._theme = this._getTheme();
        this.guestUserProductCardSetting = dto.guestUserProductCardSetting;
    }

    private _getColorPaletteRecord(primary: ColorHex, palette: ColorPalette): ColorPaletteRecord {
        if (palette.length === 8) {
            return {
                100: palette[7],
                200: palette[6],
                300: palette[5],
                400: palette[4],
                500: primary,
                600: palette[3],
                700: palette[2],
                800: palette[1],
                900: palette[0],
            };
        }

        return {
            100: palette[5],
            200: palette[5],
            300: palette[4],
            400: palette[3],
            500: primary,
            600: palette[2],
            700: palette[1],
            800: palette[0],
            900: palette[0],
        };
    }

    private _getTheme(): IHarmonyTheme {
        const harmonyTheme: IHarmonyTheme = {
            ...theme,
            fontSize: this.globalFontSize,
            productStyle: 2,
            ...(this?.primaryColorPalette &&
                this.primaryColor && {
                    primary: {
                        default: this.primaryColor,
                        lighten: {
                            100: this.primaryColorPalette[400],
                            200: this.primaryColorPalette[300],
                            300: this.primaryColorPalette[200],
                        },
                        darken: {
                            100: this.primaryColorPalette[600],
                            200: this.primaryColorPalette[700],
                            300: this.primaryColorPalette[800],
                        },

                        ...this.primaryColorPalette,
                    },
                }),
            ...(this?.secondaryColorPalette &&
                this.secondaryColor && {
                    secondary: {
                        default: this.secondaryColor,
                        lighten: {
                            100: this.secondaryColorPalette[400],
                            200: this.secondaryColorPalette[300],
                            300: this.secondaryColorPalette[200],
                        },
                        darken: {
                            100: this.secondaryColorPalette[600],
                            200: this.secondaryColorPalette[700],
                            300: this.secondaryColorPalette[800],
                        },
                        ...this.secondaryColorPalette,
                    },
                }),
            ...(this?.fonts && {
                font: {
                    primary: `${this._getFontFace(this.fonts.secondary, 'regular')}, ${this._getFontFace(this.fonts.primary, 'regular')}`,
                    default: {
                        light: `${this._getFontFace(this.fonts.secondary, 'light')}, ${this._getFontFace(this.fonts.primary, 'light')}`,
                        medium: `${this._getFontFace(this.fonts.secondary, 'medium')}, ${this._getFontFace(this.fonts.primary, 'medium')}`,
                        regular: `${this._getFontFace(this.fonts.secondary, 'regular')}, ${this._getFontFace(this.fonts.primary, 'regular')}`,
                        semiBold: `${this._getFontFace(this.fonts.secondary, 'semiBold')}, ${this._getFontFace(this.fonts.primary, 'semiBold')}`,
                        bold: `${this._getFontFace(this.fonts.secondary, 'bold')}, ${this._getFontFace(this.fonts.primary, 'bold')}`,
                        extraBold: `${this._getFontFace(this.fonts.secondary, 'extraBold')}, ${this._getFontFace(this.fonts.secondary, 'extraBold')}`,
                    },
                    secondary: {
                        light: this._getFontFace(this.fonts.secondary, 'light'),
                        medium: this._getFontFace(this.fonts.secondary, 'medium'),
                        regular: this._getFontFace(this.fonts.secondary, 'regular'),
                        semiBold: this._getFontFace(this.fonts.secondary, 'semiBold'),
                        bold: this._getFontFace(this.fonts.secondary, 'bold'),
                        extraBold: this._getFontFace(this.fonts.secondary, 'extraBold'),
                    },
                },
            }),
        };

        const colorToken = this._getColorToken(harmonyTheme);

        return {
            ...harmonyTheme,
            ...colorToken,
            background: {
                ...harmonyTheme.background,
                ...colorToken.background,
            },
        };
    }

    /**
     * Get design-specific "Color Token" from theme.
     */
    private _getColorToken(harmonyTheme: IHarmonyTheme): IColorToken {
        if (this.isDesignA) {
        }

        if (this.isDesignB) {
        }

        if (this.isDesignC) {
        }

        return {
            text: {
                title1: harmonyTheme.gray[900],
                body1: harmonyTheme.gray[800],
                body2: harmonyTheme.gray[600],
                placeholder: harmonyTheme.gray[500],
                disabled: harmonyTheme.gray[400],
                white: harmonyTheme.white,
                error: harmonyTheme.status.error,
                success: harmonyTheme.status.success,
                secondary: harmonyTheme.secondary.default,
                info: harmonyTheme.status.information,
            },
            background: {
                primary: harmonyTheme.primary.default,
                lighten200: harmonyTheme.primary.lighten[200],
                lighten300: harmonyTheme.primary.lighten[300],
                disabled: harmonyTheme.gray[400],
                gray200: harmonyTheme.gray[200],
                gray100: harmonyTheme.gray[100],
                gray50: harmonyTheme.gray[50],
                error: harmonyTheme.status.error,
                black: harmonyTheme.black,
                white: harmonyTheme.white,
                info: harmonyTheme.status.information,
            },
            stroke: {
                primary: harmonyTheme.primary.default,
                divider: harmonyTheme.gray[50],
                gray100: harmonyTheme.gray[100],
                gray200: harmonyTheme.gray[200],
                disabled: harmonyTheme.gray[400],
                black: harmonyTheme.black,
                white: harmonyTheme.white,
            },
            icon: {
                gray400: harmonyTheme.gray[400],
                gray600: harmonyTheme.gray[600],
                black: harmonyTheme.black,
                white: harmonyTheme.white,
                secondary: harmonyTheme.secondary.default,
                info: harmonyTheme.status.information,
                error: harmonyTheme.status.error,
            },
        };
    }

    private _sanitizeFonts(fonts: IFontsDto | undefined): Required<IFontsDto> {
        if (!fonts) {
            return { primary: CONFIG.primaryFont, secondary: CONFIG.secondaryFont };
        }
        const primaryFont = this._getFontName(fonts.primary);

        return {
            primary: primaryFont,
            secondary: fonts.secondary ? this._getFontName(fonts.secondary) : primaryFont,
        };
    }

    private _getFontName(font: string | undefined): Fonts {
        switch (font) {
            case 'Poppins':
                return 'poppins';
            case 'Apple':
            case 'Apple SD Gothic Neo':
                return 'apple';
            case 'Montserrat':
                return 'montserrat';
            case 'BMWTypeNext KR':
                return 'bmw';
            case 'Pretendard':
                return 'pretendard';
            case 'KBFG Text':
                return 'kb';
            case 'Noto Sans CJK K':
            default:
                return 'noto';
        }
    }

    private _getFontFace(font: string, weight: keyof IHarmonyFont) {
        const variableFonts = ['noto', 'montserrat'];

        if (variableFonts.includes(font)) {
            return font;
        }

        const weightString = `${weight}`;
        return font + weightString.charAt(0).toUpperCase() + weightString.slice(1);
    }

    private static _getDesignFromThemeSetting(theme: DesignThemeEnum | string): Design {
        switch (theme) {
            case DesignThemeEnum.Subscription:
                return Design.DesignB;
            case DesignThemeEnum.ModernDesign:
                return Design.DesignC;
            case DesignThemeEnum.SimpleInNavy:
                return Design.DesignA;
            case DesignThemeEnum.PopoutDesign:
                return Design.DesignD;
            default:
                return CONFIG.designTemplate;
        }
    }

    /**
     * When provided a design component map, this method will return the component based on the design
     * provided as an environment variable, with multiple backup options.
     *
     * **If there is no component matches the design, returns the first component exist.**
     */
    static resolveDesignComponents<T>(components: DesignComponentMap): T {
        return components[this._getDesignFromThemeSetting(EnvironmentUtils.DESIGN_NAME ?? '')] ?? components[CONFIG.designTemplate] ?? Object.values(components)[0];
    }

    static get environmentDesign(): Design {
        return this._getDesignFromThemeSetting(EnvironmentUtils.DESIGN_NAME ?? '');
    }

    get primaryColor(): ColorHex | undefined {
        return this._colorSchemeSetting?.primaryColor;
    }

    get secondaryColor(): ColorHex | undefined {
        return this._colorSchemeSetting?.secondaryColor;
    }

    get primaryColorPalette(): ColorPaletteRecord | undefined {
        return this._colorSchemeSetting ? this._getColorPaletteRecord(this._colorSchemeSetting.primaryColor, this._colorSchemeSetting.primaryPalette || []) : undefined;
    }

    get secondaryColorPalette(): ColorPaletteRecord | undefined {
        return this._colorSchemeSetting ? this._getColorPaletteRecord(this._colorSchemeSetting.secondaryColor, this._colorSchemeSetting.secondaryPalette || []) : undefined;
    }

    get globalFontSize(): GlobalFontSize {
        return this._fontSize || 'large';
    }

    get fonts(): Required<IFontsDto> {
        return this._fonts;
    }

    get design(): Design {
        return ThemeDesignSetting._getDesignFromThemeSetting(this._themeSetting.name);
    }

    get isDesignA(): boolean {
        return this.design === Design.DesignA;
    }

    get isDesignB(): boolean {
        return this.design === Design.DesignB;
    }

    get isDesignC(): boolean {
        return this.design === Design.DesignC;
    }

    get isDesignD(): boolean {
        return this.design === Design.DesignD;
    }

    get isStockBadgeOn(): boolean {
        return this._isStockBadgeOn ?? true;
    }

    get isTextBadgeOn(): boolean {
        return this._isTextBadgeOn ?? true;
    }

    get textBadgeContent(): string {
        return this._textBadgeContent ?? '';
    }

    get desktopImageUrl(): string | null {
        return this._desktopImageUrl;
    }

    get isShowDiscountRate(): boolean {
        return this._productCardSetting === ProductCardSetting.MinPlus || this._productCardSetting === ProductCardSetting.Max;
    }

    get isShowMarketPrice(): boolean {
        return this._productCardSetting === ProductCardSetting.Max;
    }

    get isGuestShowDiscountRate(): boolean {
        return this.guestUserProductCardSetting.productCardSetting === ProductCardSetting.MinPlus || this.guestUserProductCardSetting.productCardSetting === ProductCardSetting.Max;
    }

    get isGuestShowMarketPrice(): boolean {
        return this.guestUserProductCardSetting.productCardSetting === ProductCardSetting.Max;
    }

    get theme() {
        return this._theme;
    }

    /**
     * Converts standard pixel based font sizes into rem for accessability improvements.
     * This considers that 1 rem = 16px.
     */
    getLegacyFontSize(fontSize: FontSizes | string): string {
        fontSize = fontSize.trim();
        if (fontSize.endsWith('px')) {
            let pixelNumber = Number(fontSize.slice(0, -2));
            pixelNumber = this.globalFontSize === 'medium' ? pixelNumber - 2 : pixelNumber;
            return `${this._REM_PIXEL_FACTOR * pixelNumber}rem`;
        } else {
            return fontSize;
        }
    }

    /**
     * Converts standard pixel based font sizes into rem for accessability improvements.
     * This considers that 1 rem = 16px.
     * The default globalFontSize is `medium`.
     */
    getFontSize(fontSize: FontSizes | string): string {
        fontSize = fontSize.trim();
        if (fontSize.endsWith('px')) {
            let pixelNumber = Number(fontSize.slice(0, -2));
            pixelNumber = this.globalFontSize === 'large' ? pixelNumber + 2 : pixelNumber;
            return `${this._REM_PIXEL_FACTOR * pixelNumber}rem`;
        }

        return fontSize;
    }

    getLetterSpacing() {
        if (this.isDesignA) {
            return '-0.03em';
        }

        if (this.isDesignB) {
            return '0';
        }

        if (this.isDesignC) {
            return '-0.01em';
        }

        if (this.isDesignD) {
            return '-0.01em';
        }

        return '-0.01em';
    }

    /**
     * Returns the correct design component based on the configuration design template.
     */
    getDesignComponent<T>(components: DesignComponentMap): T {
        return components[this.design] ?? ThemeDesignSetting.resolveDesignComponents<T>(components);
    }
}

export default ThemeDesignSetting;
