import { useDisclosure } from '@chakra-ui/react';
import styled from '@emotion/styled';
import * as PrimitivePopover from '@radix-ui/react-popover';
import { ReactNode } from 'react';

type ContentProps = {
    zIndex?: number;
};

export interface PopoverProps extends PrimitivePopover.PopoverProps, ContentProps {
    trigger: ReactNode;
    children: ReactNode;
}

const Popover = ({ trigger, zIndex = 1200, children, ...props }: PopoverProps) => {
    const { isOpen, onToggle, onClose } = useDisclosure();

    return (
        <PrimitivePopover.Root {...props} open={isOpen} onOpenChange={onToggle}>
            <StyledTrigger>{trigger}</StyledTrigger>
            <PrimitivePopover.Portal>
                <StyledContent zIndex={zIndex} onClick={onClose}>
                    {children}
                    {isOpen && <FocusGuard />}
                </StyledContent>
            </PrimitivePopover.Portal>
        </PrimitivePopover.Root>
    );
};

export default Popover;

const StyledContent = styled(PrimitivePopover.Content)<ContentProps>`
    z-index: ${({ zIndex }) => zIndex};
`;

const StyledTrigger = styled(PrimitivePopover.Trigger)`
    background: none;
`;

const FocusGuard = styled.div`
    position: fixed;
    inset: 0;
    z-index: -1;
`;
