import styled from '@emotion/styled';
import { ReactNode } from 'react';

type ContentProps = {
    fontSize?: string;
};

type ChipSizes = 'sm' | 'md' | 'lg';
export type ChipProps = ContentProps & {
    icon?: ReactNode;
    text: string;
    backgroundColor?: string;
    borderColor?: string;
    color?: string;
    hasBottomMargin?: boolean;
    size?: ChipSizes;
};

const Chip = ({ icon, text, backgroundColor, hasBottomMargin, color, borderColor = 'transparent', size = 'md', fontSize, ...props }: ChipProps) => (
    <Container backgroundColor={backgroundColor} color={color} borderColor={borderColor} hasBottomMargin={hasBottomMargin} size={size} {...props}>
        {icon && <div className="leading-icon">{icon}</div>}
        <StyledContent fontSize={fontSize}>{text}</StyledContent>
    </Container>
);

const getChipSizing = (size?: ChipSizes): string => {
    switch (size) {
        case 'sm':
            return `
        padding: 0.3rem 0.5rem;
        font-size: 0.7rem;
      `;
        case 'lg':
            return `
        padding: 0.6rem 1rem;
        font-size: 1rem;
      `;
        case 'md':
        default:
            return `
        padding: 0.5rem 0.875rem;
        font-size: 0.875rem;
      `;
    }
};

const Container = styled.div<Pick<ChipProps, 'backgroundColor' | 'color' | 'borderColor' | 'hasBottomMargin' | 'size'>>`
    display: inline-flex;
    align-items: center;
    margin-bottom: ${({ hasBottomMargin }) => hasBottomMargin && '1.5rem'};
    border-radius: 40px;
    border: 1px solid ${({ borderColor }) => borderColor};
    background-color: ${(props) => props.backgroundColor || props.theme.primary.lighten[300]};
    color: ${(props) => props.color || props.theme.gray[900]};
    ${({ size }) => getChipSizing(size)}

    .leading-icon {
        margin-right: 0.5rem;
        display: flex;
    }
`;

const StyledContent = styled.p<ContentProps>`
    ${({ fontSize }) => `
    font-size: ${fontSize || 'inherit'};
  `}
`;

export default Chip;
