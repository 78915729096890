import { Center } from '@chakra-ui/react';
import Loader from '@designs/primitives/common/loader';
import { useExtension } from '@hooks/use-extension';
import { FullPageLoaderProps } from '..';

const FullPageLoaderD: React.FC<FullPageLoaderProps> = ({ ...props }) => {
    const { extensionStatus } = useExtension();

    return (
        <Center pos="absolute" inset="0" w={`min(100vw, ${extensionStatus?.maxScreenWidth}px)`}>
            <Loader width={'30px'} height={'30px'} {...props} />
        </Center>
    );
};

export default FullPageLoaderD;
