export const palette = {
    white: '#FFFFFF',
    black: '#292929',
    blue: {
        primary: '#010787',
        secondary: '#010ABA',
        50: '#BBBEFE',
        100: '#898EFE',
        200: '#565EFE',
        300: '#242DFD',
        400: '#020CEC',
        500: '#010ABA',
        600: '#010454',
        700: '#010787',
        800: '#010454',
        900: '#000222',
    },
    skyBlue: {
        primary: '#007AFF',
        secondary: '#66AFFF',
        50: '#CCE4FF',
        100: '#99CAFF',
        200: '#66AFFF',
        300: '#3395FF',
        400: '#007AFF',
        500: '#0062CC',
        600: '#004999',
        700: '#003166',
        800: '#001833',
    },
    grey: {
        primary: '#C2C2C2',
        secondary: '#F5F5F5',
        50: '#F5F5F5',
        100: '#DBDBDB',
        200: '#C2C2C2',
        300: '#A8A8A8',
        400: '#8F8F8F',
        500: '#757575',
        600: '#5C5C5C',
        700: '#424242',
        800: '#292929',
    },
    yellow: {
        primary: '#FFD600',
        secondary: '#FFE666',
        50: '#FFFAE2',
        100: '#FFEF99',
        200: '#FFE666',
        300: '#FFDE33',
        400: '#FFD600',
        500: '#CCAB00',
        600: '#998000',
        700: '#665600',
        800: '#332B00',
    },
    green: {
        primary: '#00D749',
        secondary: '#3EFF80',
        50: '#A4FFC3',
        100: '#71FFA1',
        200: '#3EFF80',
        300: '#0BFF5E',
        400: '#00D749',
        500: '#00A438',
        600: '#007126',
        700: '#003E15',
        800: '#000B04',
    },
    red: {
        primary: '#F42C00',
        secondary: '#FF795B',
        error: '#E42B17',
        50: '#FFCCC1',
        100: '#FFA28E',
        200: '#FF795B',
        300: '#FF4F28',
        400: '#F42C00',
        500: '#C12300',
        600: '#8E1A00',
        700: '#5B1000',
        800: '#280700',
        900: '#D23B27',
    },
    purple: {
        primary: '#985EFF',
        secondary: '#985EFF',
    },
    orange: {
        primary: '#F2994A',
        secondary: '#F2994A',
    },
};

export default palette;
