import { AlertStatusEnum, AlertTypeEnum } from '@legacy/designs/alert';
import { useAlert } from '@legacy/hooks/use-alert-drawer';
import { useTranslations } from 'next-intl';
import { ReactNode } from 'react';
import { useHarmony } from '../../hooks/use-harmony';

type ErrorDrawer = (error?: string | ReactNode, callback?: () => void) => void;

export function useErrorDrawer(): ErrorDrawer {
    const { showAlert } = useAlert();
    const { designSettings } = useHarmony();
    const t = useTranslations();

    const errorDrawer: ErrorDrawer = (error, callback) => {
        showAlert({
            status: AlertStatusEnum.Alert,
            type: designSettings?.isDesignB ? AlertTypeEnum.Modal : AlertTypeEnum.Drawer,
            children: error || t('common.genericError'),
            onClose: callback,
        });
    };

    return errorDrawer;
}
