import { DayOfWeek } from '@type/common';
import { ISODate } from '@type/models';
import DateUtils from '@utils/date.utils';
import {
    DeliveryFrequencyOptionsTypeEnum,
    DeliveryFrequencyTypeEnum,
    DiscountAfterPaymentRuleEnum,
    ISubscriptionInfoDto,
    PaymentTypeEnum,
    PriceDisplayTypeEnum,
    SubscriptionEndTypeEnum,
} from './responseDto/component-product.dto';

class SubscriptionInfo {
    private readonly _EARLIEST_START_DAYS_FROM_TODAY = 6;
    private readonly _LATEST_START_DAYS_FROM_TODAY = 27;

    private _deliveryFrequencyType: DeliveryFrequencyTypeEnum;
    private _deliveryFrequencyOptionsTypeList: DeliveryFrequencyOptionsTypeEnum[];
    private _onceEveryNWeeksSetting: string | null;
    private _onceEveryNMonthsSetting: string | null;
    private _nDaysPerWeekSetting: number;
    private _nDaysPerMonthSetting: number;
    private _subscriptionEndType: SubscriptionEndTypeEnum;
    private _subscriptionEndDate: ISODate;
    private _subscriptionEndRound: ISODate;
    private _paymentType: PaymentTypeEnum;
    private _perMonthPaymentRuleSettings: string;
    private _discountPerPaymentRuleSettings: string;
    private _discountAfterPaymentType: DiscountAfterPaymentRuleEnum;
    private _paymentsUntilDiscountChange: number;
    private _discountToChangeTo: number;
    private _priceDisplayType: PriceDisplayTypeEnum;

    constructor(subscriptionInfo: ISubscriptionInfoDto) {
        this._deliveryFrequencyType = subscriptionInfo.deliveryFrequencyType;
        this._deliveryFrequencyOptionsTypeList = subscriptionInfo.deliveryFrequencyOptionsTypeList;
        this._onceEveryNWeeksSetting = subscriptionInfo.onceEveryNWeeksSetting;
        this._onceEveryNMonthsSetting = subscriptionInfo.onceEveryNMonthsSetting;
        this._nDaysPerWeekSetting = subscriptionInfo.nDaysPerWeekSetting;
        this._nDaysPerMonthSetting = subscriptionInfo.nDaysPerMonthSetting;
        this._subscriptionEndType = subscriptionInfo.subscriptionEndType;
        this._subscriptionEndDate = subscriptionInfo.subscriptionEndDate;
        this._subscriptionEndRound = subscriptionInfo.subscriptionEndRound;
        this._paymentType = subscriptionInfo.paymentType;
        this._perMonthPaymentRuleSettings = subscriptionInfo.perMonthPaymentRuleSettings;
        this._discountPerPaymentRuleSettings = subscriptionInfo.discountPerPaymentRuleSettings;
        this._discountAfterPaymentType = subscriptionInfo.discountAfterPaymentType;
        this._paymentsUntilDiscountChange = subscriptionInfo.paymentsUntilDiscountChange;
        this._discountToChangeTo = subscriptionInfo.discountToChangeTo;
        this._priceDisplayType = subscriptionInfo.priceDisplayType;
    }

    /**Deliveries are only possible from Monday to Friday (1-5) */
    get possibleDeliveryDaysOfWeek(): DayOfWeek[] {
        return [1, 2, 3, 4, 5];
    }

    get earliestDeliveryStartDate(): Date {
        return DateUtils.add(new Date(), this._EARLIEST_START_DAYS_FROM_TODAY, 'd');
    }

    get latestDeliveryStartDate(): Date {
        return DateUtils.add(new Date(), this._LATEST_START_DAYS_FROM_TODAY, 'd');
    }

    get deliveryFrequencyType(): DeliveryFrequencyTypeEnum {
        return this._deliveryFrequencyType;
    }

    get deliveryFrequencyTypeIsUser(): boolean {
        return this._deliveryFrequencyType === DeliveryFrequencyTypeEnum.User;
    }

    get deliveryFrequencyOptionsTypeList(): DeliveryFrequencyOptionsTypeEnum[] {
        return this._deliveryFrequencyOptionsTypeList;
    }

    get hasMultipleDeliveryFrequencyOptionType(): boolean {
        return this._deliveryFrequencyOptionsTypeList.length > 1;
    }

    get deliveryFrequencyIsInWeeksOrMonths(): boolean {
        return (
            this.hasMultipleDeliveryFrequencyOptionType &&
            (this._deliveryFrequencyOptionsTypeList.includes(DeliveryFrequencyOptionsTypeEnum.OnceEveryNWeeks) ||
                this._deliveryFrequencyOptionsTypeList.includes(DeliveryFrequencyOptionsTypeEnum.OnceEveryNMonths))
        );
    }

    get deliveryFrequencyIsInWeeks(): boolean {
        return !this.hasMultipleDeliveryFrequencyOptionType && this._deliveryFrequencyOptionsTypeList[0] === DeliveryFrequencyOptionsTypeEnum.OnceEveryNWeeks;
    }

    get deliveryFrequencyIsInMonths(): boolean {
        return !this.hasMultipleDeliveryFrequencyOptionType && this._deliveryFrequencyOptionsTypeList[0] === DeliveryFrequencyOptionsTypeEnum.OnceEveryNMonths;
    }

    get onceEveryNWeeksSetting(): number[] {
        if (!this._onceEveryNWeeksSetting) return [];
        return this._onceEveryNWeeksSetting
            .split(',')
            .map((interval) => Number.parseInt(interval))
            .sort();
    }

    get onceEveryNMonthsSetting(): number[] {
        if (!this._onceEveryNMonthsSetting) return [];
        return this._onceEveryNMonthsSetting
            .split(',')
            .map((interval) => Number.parseInt(interval))
            .sort();
    }

    /**The minimum setting is required to calculate pricing */
    get minOnceEveryNWeeksSetting(): number | null {
        return this._onceEveryNWeeksSetting ? Math.min(...this.onceEveryNWeeksSetting) : null;
    }

    /**The minimum setting is required to calculate pricing */
    get minOnceEveryNMonthsSetting(): number | null {
        return this.onceEveryNMonthsSetting ? Math.min(...this.onceEveryNMonthsSetting) : null;
    }

    get subscriptionEndType(): SubscriptionEndTypeEnum {
        return this._subscriptionEndType;
    }

    get paymentType(): PaymentTypeEnum {
        return this._paymentType;
    }

    get perMonthPaymentRuleSettings(): number[] {
        return this._perMonthPaymentRuleSettings.split(',').map((interval) => Number.parseInt(interval));
    }

    get minPerMonthPaymentRuleSettings(): number {
        return Math.min(...this.perMonthPaymentRuleSettings);
    }

    get discountPerPaymentRuleSettings(): number[] {
        return this._discountPerPaymentRuleSettings.split(',').map(Number.parseInt);
    }

    get priceDisplayType(): PriceDisplayTypeEnum {
        return this._priceDisplayType;
    }

    get priceDisplayTypeIsMinSubPrice(): boolean {
        return this.priceDisplayType === PriceDisplayTypeEnum.MinSubPrice;
    }

    get priceDisplayTypeIsMinPurPrice(): boolean {
        return this.priceDisplayType === PriceDisplayTypeEnum.MinPurPrice;
    }
}

export default SubscriptionInfo;
