import styled from '@emotion/styled';
import { Button } from 'src/legacy/components/blockui';
import { IThemeProps } from '@type/common';
import { CustomButtonProps } from '../custom-button';

const CustomButtonA = styled(Button)<IThemeProps & CustomButtonProps>`
    ${({ theme, variant, color, disabled, borderWeight = '2px', height, width }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${height || '48px'};
    font-family: ${theme.font.default.regular};
    border: ${disabled ? undefined : `solid ${variant !== 'outline' ? '2px' : borderWeight} ${color} !important;`}
    &:hover {
        background-color: ${disabled ? '' : `${variant === 'outline' ? 'white' : theme.primary.default} !important`} ;
        opacity: ${disabled ? '' : '1'} !important;
    }
    &:disabled {
        color: ${variant === 'outline' ? theme.gray[300] : theme.white} !important;
        background-color: ${variant === 'outline' ? theme.white : theme.gray[300]} !important;
        opacity: 100% !important;
        border: 1px solid ${theme.gray[300]};
    }
    ${width ? `width: ${width};` : ''}
    `}
`;

export default CustomButtonA;
