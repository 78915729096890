import ChannelCacheKeys from '@api/channel/cache-keys';
import { ChannelClient } from '@api/channel/channel.client';
import { useDesignComponents } from '@hooks/use-design-components';
import { useRecentKeywords } from '@hooks/use-recent-keywords';
import Keyword from '@models/keyword';
import { IKeywordDto } from '@models/keyword/responseDto/keyword.dto';
import RoutesEnum from '@routes';
import { Design } from '@type/harmony-config';
import { useRouter } from 'next/router';
import { ComponentType, useEffect, useMemo } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import useSWR from 'swr';
import DesktopSearchPanelB from './designB/desktop-search-panel';
import DesktopSearchPanelA from './designA/desktop-search-panel';

type SearchFormValues = { search: string };

interface DesktopSearchPanelProps {}

export interface DesktopSearchPanelInnerProps extends DesktopSearchPanelProps {
    form: UseFormReturn<SearchFormValues, any, undefined>;
    onSubmit: (values: SearchFormValues) => void;
    popularKeywords: Keyword[];
}

const DesktopSearchPanel: React.FC<DesktopSearchPanelProps> = (props) => {
    const Component = useDesignComponents<ComponentType<DesktopSearchPanelInnerProps>>({
        [Design.DesignA]: DesktopSearchPanelA,
        [Design.DesignB]: DesktopSearchPanelB,
    });

    const router = useRouter();
    const { addKeyword } = useRecentKeywords();

    const { data: popularKeywordsDto } = useSWR<IKeywordDto[]>(ChannelCacheKeys.popularKeywordsCacheKey(), () => ChannelClient.getPopularKeywords());
    const popularKeywords = useMemo<Keyword[]>(() => {
        return popularKeywordsDto?.filter(({ keyword }) => !!keyword).map((keyword) => new Keyword(keyword)) || [];
    }, [popularKeywordsDto]);

    const form = useForm({ defaultValues: { search: '' } });

    const handleSubmit = ({ search }: SearchFormValues) => {
        if (!search) {
            return;
        }

        if (search === router.query.search) {
            router.reload();
            return;
        }

        addKeyword(search);
        router.push({ pathname: RoutesEnum.Search, query: { search } });
    };

    useEffect(() => {
        form.reset();
    }, [router.asPath]);

    return <Component {...props} onSubmit={handleSubmit} form={form} popularKeywords={popularKeywords} />;
};

export default DesktopSearchPanel;
