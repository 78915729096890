import { useDesignComponents } from '@hooks/use-design-components';
import { Design } from '@type/harmony-config';
import { ComponentType, ReactNode } from 'react';
import FailHeadingA from './designA/fail-heading';
import FailHeadingB from './designB/fail-heading';
import FailHeadingC from './designC/fail-heading';
import FailHeadingD from './designD/fail-heading';

export interface FailHeadingProps {
    children: ReactNode;
}

const FailHeading: React.FC<FailHeadingProps> = (props) => {
    const Component = useDesignComponents<ComponentType<FailHeadingProps>>({
        [Design.DesignA]: FailHeadingA,
        [Design.DesignB]: FailHeadingB,
        [Design.DesignC]: FailHeadingC,
        [Design.DesignD]: FailHeadingD,
    });

    return <Component {...props} />;
};

export default FailHeading;
