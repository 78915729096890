const address = {
    address: {
        noRegisteredAddress: '등록된 주소가 없습니다.',
        addShippingAddress: '배송지를 추가해주세요.',
        addShippingAddressShort: '배송지 추가',
        defaultShippingAddress: '기본배송지',
        addressSearch: '주소검색',
        recipientName: '수령자명',
        detailedAddress: '상세주소',
        receiverMobilePhone: '휴대폰번호',
        pleaseEnterPhoneWithoutDash: '휴대폰번호(-없이 입력)',
        address: '주소',
        pleaseEnterZip: '우편번호를 입력해주세요.',
        pleaseEnterAddress: '주소를 입력해주세요.',
        pleaseEnterRecipientName: '수령자명을 입력해주세요.',
        pleaseEnterShippingAddress: '배송지명을 입력해주세요',
        pleaseEnterPhoneNumber: '휴대폰번호를 입력해주세요.',
        shippingAddress: '배송지명',
        zipCode: '우편번호',
        selectDeliveryRequest: '배송요청사항 선택',
        pleaseEnterDetailedAddress: '상세주소를 입력해주세요.',
        registerAsDefaultShipping: '기본배송지로 등록하기',
        confirmDeleteAddress: '배송지를 삭제하시겠습니까?',
        confirmUpdateAddress: '배송지를 수정하시겠습니까?',
        findAddress: '주소찾기',
        leaveWithSecurityOffice: '부재시 경비실에 맡겨주세요',
        leaveInDeliveryBox: '부재시 택배함에 넣어주세요',
        leaveInFrontOfHouse: '부재시 집앞에 놔주세요',
        contactBeforeShipping: '배송전 연락주세요.',
        handleWithCare: '배송시 주의해주세요',
        wrongFormat: '배송지 정보를 입력해 주세요.',
        wrongPhoneFormat: '휴대폰 번호를 정확하게 입력해 주세요.',
        requiredValidation: '여기를 채워주세요',
        requiredValidation2: '특수문자는 사용할수 없습니다.',
        confirmDelete: '{address}이 삭제되었습니다.',
    },
};

export default address;
