import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import React from 'react';
import { BaseProps } from '../types';

export enum IconVariant {
    default = 'default',
    outlined = 'outlined',
    rounded = 'rounded',
    sharp = 'sharp',
}

export type IconProps = BaseProps & {
    /**
     * Icon style variant
     * @default IconVariant.outlined
     * @link https://fonts.google.com/icons
     */
    variant?: IconVariant;

    /**
     * Render the filled version of the icon if any.
     * Make sure icon script tag includes filled versions.
     * @default false
     */
    fill?: boolean;

    /**
     * Sets the color of the icon
     */
    color?: string;

    /**
     * Indicates the icon size
     */
    size?: string;

    weight?: number;

    /**
     * String key representing a particular material icon
     * @link https://fonts.google.com/icons
     */
    children: string;

    className?: string;
};

/**
 * Simple icon that uses material icons under the hood.
 * Pass the material icon key in as a child
 * Must include stylesheet tag for each icon variation used somewhere in the project. outlined variation example: <link href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined" rel="stylesheet" />
 * @link https://fonts.google.com/icons
 */
const BlockIcon: React.FC<IconProps> = ({ testId, variant = IconVariant.outlined, children, className, ...props }) => {
    const { isFontLoaded } = useHarmony();
    return (
        <StyledIcon
            test-data-id={testId}
            isFontLoaded={isFontLoaded}
            className={`${variant === IconVariant.default ? 'material-icons' : `material-symbols-${variant}`} ${className ? className : ''}`}
            {...props}
        >
            {children}
        </StyledIcon>
    );
};

export default BlockIcon;

const StyledIcon = styled.span<IconProps & { isFontLoaded: boolean }>`
    ${({ size, fill, color, isFontLoaded, weight = 400 }) => `
        font-size: ${size} !important;
        color: ${color};
        font-weight: ${weight};
        font-variation-settings: ${fill ? `'FILL' 1, 'wght' ${weight}, 'GRAD' 0, 'opsz' 48` : `'FILL' 0`};
        opacity: ${isFontLoaded ? '1' : '0'};
    `}
`;
