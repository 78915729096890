import DbScripts from './db-scripts';
import PanelScripts from './panel-scripts';
import { IAcrossAdnDto } from './responseDto/across-adn.dto';

class AcrossSettings {
    private _ui: string;
    private _panelScripts: PanelScripts;
    private _dbScripts: DbScripts;

    constructor(dto: IAcrossAdnDto) {
        this._ui = dto.scriptSetting.ui;
        this._panelScripts = new PanelScripts(dto.scriptSetting.panelScripts);
        this._dbScripts = new DbScripts(dto.scriptSetting.dbScripts);
    }

    get ui() {
        return this._ui;
    }

    get panelScripts() {
        return this._panelScripts;
    }

    get dbScripts() {
        return this._dbScripts;
    }
}

export default AcrossSettings;
