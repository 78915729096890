import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import * as CheckBoxPrimitive from '@radix-ui/react-checkbox';
import { BaseProps } from '../types';
import CheckIndeterminateSmall from './CheckIndeterminateSmall';
import Done from './Done';
import BlockLoader from '../Loader';

export type Checked = boolean | 'indeterminate';

export type CheckBoxProps = BaseProps & {
    name?: string;
    label?: string;
    color?: string;
    borderRadius?: string;
    checked?: Checked;
    onCheckedChange?: (checked: boolean, name?: string) => void;
    disabled?: boolean;
    isLoading?: boolean;
    children?: ReactNode;
    labelColor?: string;
};

const StyledCheckBox = styled(CheckBoxPrimitive.Root)<CheckBoxProps>`
    all: unset;
    background: ${(props) => (props.checked ? (props.color ? props.color : props.theme.primary.lighten[300]) : 'transparent')};
    width: 15px;
    height: 15px;
    border-radius: ${(props) => props.borderRadius && props.borderRadius};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    cursor: pointer;
    box-shadow: 0 0 0 2px ${(props) => (props.checked ? (props.color ? props.color : props.theme.primary.lighten[300]) : props.theme.gray[50])};
    &:hover {
        box-shadow: 0 0 0 2px ${(props) => (props.color ? props.color : props.theme.primary.lighten[300])};
    }
    &:focus {
        box-shadow: 0 0 0 2px ${(props) => (props.color ? props.color : props.theme.primary.lighten[300])};
    }
    &:disabled {
        opacity: 0.4;
        cursor: not-allowed !important;
        pointer-events: none;
    }
`;

const StyledIndicator = styled(CheckBoxPrimitive.Indicator)`
    color: ${(props) => props.theme.primary.lighten[300]};
`;

const Flex = styled.div`
    display: flex;
    align-items: center;
`;
const Label = styled.label<CheckBoxProps>`
    color: ${({ labelColor }) => labelColor || 'black'};
    line-height: 1;
    user-select: none;
    padding-left: ${({ label }) => (label === undefined ? '0' : '0.4rem')};
    display: flex;
    align-items: center;
    cursor: pointer;
    ${({ disabled }) =>
        disabled
            ? `
    cursor: not-allowed;
    pointer-events: none;
  `
            : ''}
`;

const LoadingContainer = styled.div`
    margin-right: 8px;
`;

const CheckBox = React.forwardRef<HTMLDivElement, CheckBoxProps>(
    ({ name, label, color, checked, onCheckedChange, disabled, children, isLoading, labelColor, borderRadius, ...props }: CheckBoxProps, ref) => {
        return (
            <Flex ref={ref} test-data-id={props.testId} {...props}>
                <Label label={label} disabled={disabled} labelColor={labelColor}>
                    {!isLoading && (
                        <StyledCheckBox
                            name={name}
                            checked={checked}
                            onCheckedChange={(checked: boolean) => onCheckedChange && onCheckedChange(checked, name)}
                            onClick={(e) => e.stopPropagation()}
                            disabled={disabled}
                            color={color}
                            borderRadius={borderRadius}
                        >
                            <StyledIndicator>
                                {checked === 'indeterminate' && (
                                    <CheckIndeterminateSmall
                                        viewBox="0 0 45 40"
                                        style={{
                                            height: '1.2rem',
                                            width: '1.2rem',
                                            fill: 'white',
                                        }}
                                    />
                                )}
                                {checked === true && (
                                    <Done
                                        viewBox="0 0 46 40"
                                        style={{
                                            height: '1.1rem',
                                            width: '1.1rem',
                                            fill: 'white',
                                        }}
                                    />
                                )}
                            </StyledIndicator>
                        </StyledCheckBox>
                    )}
                    {isLoading && (
                        <LoadingContainer>
                            <BlockLoader size="16px" />
                        </LoadingContainer>
                    )}
                    {label || children}
                </Label>
            </Flex>
        );
    }
);

CheckBox.displayName = 'CheckBox';

export default CheckBox;
