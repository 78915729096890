import { HeaderAProps } from '..';
import AbstractedText from '@components/abstracted-text';

const HeaderAD: React.FC<HeaderAProps> = (props) => {
    return (
        <AbstractedText fontSize="18px" weight="thin" lineHeight="24px" {...props}>
            {props.children}
        </AbstractedText>
    );
};

export default HeaderAD;
