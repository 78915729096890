import IconButton from '@designs/primitives/common/icon-button';
import { useHarmony } from '@hooks/use-harmony';
import { BackButtonProps } from '..';

const BackButtonD: React.FC<BackButtonProps> = (props) => {
    const { theme } = useHarmony();

    return (
        <IconButton {...props} size={20} color={theme.gray[800]}>
            arrow_back_ios_new
        </IconButton>
    );
};

export default BackButtonD;
