import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { BaseProps } from '../types';
import { getMargin, getPadding } from '../types/BaseProps';

export type BlockHStackProps = BaseProps & {
    spacing?: 'none' | 'xs' | 'sm' | 'md' | 'lg' | string;
    align?: 'left' | 'right' | 'distribute' | 'center';
    alignItems?: 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline';
    width?: string;
    height?: string;
    children: ReactNode;
};

const getSpacing = (spacing: string) => {
    switch (spacing) {
        case 'none':
            return '0rem';
        case 'xs':
            return '0.5rem';
        case 'sm':
            return '1rem';
        case 'md':
            return '2rem';
        case 'lg':
            return '4rem';
        default:
            return spacing;
    }
};
const getAlign = (align: string) => {
    switch (align) {
        case 'left':
            return 'flex-start';
        case 'right':
            return 'flex-end';
        case 'distribute':
            return 'space-between';
        case 'center':
            return 'center';
        default:
            throw new Error('invalid align input');
    }
};

/**
 * 이 콤포넌트는 flex box row direction 의 대용으로 사용될것입니다.
 * @param {'none' | 'xs' | 'sm' | 'md' | 'lg'} spacing='defines spacing between children'
 * @param {'left' | 'right' | 'distribute' | 'center' | 'stretch'} align='defines alignment of children. This will map to justify-content property in flex box'
 * @param {ReactNode} children}
 * @returns React Node
 */

const BlockHStack = ({
    spacing = 'md',
    align = 'left',
    alignItems = 'stretch',
    width,
    height,
    children,
    className,
    margin,
    mt = '0px',
    ml = '0px',
    mr = '0px',
    mb = '0px',
    padding,
    pt = '0px',
    pl = '0px',
    pr = '0px',
    pb = '0px',
}: BlockHStackProps) => {
    return (
        <Container
            align={getAlign(align)}
            spacing={getSpacing(spacing)}
            margin={getMargin({ margin, mt, ml, mr, mb })}
            padding={getPadding({ padding, pt, pl, pr, pb })}
            alignItems={alignItems}
            className={className}
            width={width}
            height={height}
        >
            {children}
        </Container>
    );
};

const Container = styled.div<{
    spacing: string;
    align: string;
    alignItems: string;
    margin: string;
    padding: string;
    width?: string;
    height?: string;
}>`
    display: flex;
    justify-content: ${({ align }) => align};
    align-items: ${({ alignItems }) => alignItems};
    gap: ${({ spacing }) => spacing};
    margin: ${({ margin }) => margin};
    padding: ${({ padding }) => padding};
    ${({ width }) => width && `width: ${width};`}
    ${({ height }) => height && `height: ${height};`}
`;

export default BlockHStack;
