import { Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, VStack } from '@chakra-ui/react';
import Body1C from '@designs/primitives/typography/body-1-c';
import Body2B from '@designs/primitives/typography/body-2-b';
import { useHarmony } from '@hooks/use-harmony';
import { PopUpProps } from '..';
import BottomButtons from '../../bottom-buttons';
import MainButton from '@designs/primitives/common/button/main-button';
import ThemeUtils from '@utils/theme.utils';

const PopUpD: React.FC<PopUpProps> = ({ variant, message, errorMessage, isOpen, onClose, buttonText, cancelButtonText, onConfirmButtonClick, onCancelButtonClick, ...props }) => {
    const { theme } = useHarmony();

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered {...props}>
            <ModalOverlay />
            <ModalContent marginX="16px" borderRadius="8px" overflow="hidden" boxShadow={theme.dropShadow.dropdown} zIndex={ThemeUtils.zIndex.alert}>
                <ModalBody pt="32px" pb="24px" px="16px" display="flex" justifyContent="center" alignItems="center">
                    <VStack justifyContent="center" alignItems="center" spacing="4px">
                        <Body1C color={theme.text.title1} whiteSpace="pre-wrap" textAlign="center">
                            {message}
                        </Body1C>
                        <Body2B color={theme.text.error}>{errorMessage}</Body2B>
                    </VStack>
                </ModalBody>
                <ModalFooter p="0">
                    <BottomButtons>
                        {variant === 'confirm' && (
                            <MainButton onClick={onCancelButtonClick} variant="secondary">
                                {cancelButtonText}
                            </MainButton>
                        )}
                        <MainButton onClick={onConfirmButtonClick}>{buttonText}</MainButton>
                    </BottomButtons>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default PopUpD;
