import footer from '../en/footer';
import address from './address';
import cancelHistory from './cancel-history';
import cart from './cart';
import category from './category';
import common from './common';
import coupon from './coupon';
import entertainment from './entertainment';
import { errors } from './errors';
import faq from './faq';
import gift from './gift';
import home from './home';
import inquiry from './inquiry';
import inquiryCategory from './inquiry-category';
import login from './login';
import my from './my';
import notice from './notice';
import notification from './notification';
import order from './order';
import orderDetail from './order-detail';
import orderHistory from './order-history';
import pages from './pages';
import pointHistory from './point-history';
import product from './product';
import productDetail from './product-detail';
import review from './review';
import reviewHistory from './review-history';
import search from './search';
import signup from './signup';
import store from './store';
import sub from './sub';
import userInfo from './user-info';
import wishlist from './wishlist';

const ko = {
    ...common,
    ...home,
    ...footer,
    ...product,
    ...category,
    ...login,
    ...search,
    ...faq,
    ...notice,
    ...review,
    ...my,
    ...userInfo,
    ...wishlist,
    ...notification,
    ...orderHistory,
    ...orderDetail,
    ...cancelHistory,
    ...reviewHistory,
    ...productDetail,
    ...address,
    ...cart,
    ...order,
    ...inquiryCategory,
    ...pages,
    ...pointHistory,
    ...coupon,
    ...sub,
    ...inquiry,
    ...entertainment,
    ...signup,
    ...errors,
    ...store,
    ...gift,
};

export default ko;
