import { BaseProps } from '../types';
import styled from '@emotion/styled';
import palette from '../theme/palette';
import React from 'react';

export type FormLabelProps = BaseProps &
    Omit<React.HTMLProps<HTMLLabelElement>, 'as'> & {
        isRequired?: boolean;
    };

/**
 * Form label to be used in place of html label
 */
const FormLabel: React.FC<FormLabelProps> = ({ testId, isRequired, children, ...props }) => {
    return (
        <StyledLabel test-data-id={testId} aria-required={isRequired} {...props}>
            {children}
            {isRequired && <StyledAsterisk>*</StyledAsterisk>}
        </StyledLabel>
    );
};

export default FormLabel;

const StyledLabel = styled.label`
    font-size: 13.5px;
`;

const StyledAsterisk = styled.span`
    color: ${palette.red[400]};
    margin-left: 2px;
`;
