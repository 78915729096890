import { useDesignComponents } from '@hooks/use-design-components';

import { MainButtonProps } from '@designs/primitives/common/button/main-button';
import { Design } from '@type/harmony-config';
import { ComponentType, ReactNode } from 'react';
import CompletePageContentA from './designA/complete-page-content';
import CompletePageContentB from './designB/complete-page-content';
import CompletePageContentC from './designC/complete-page-content';
import CompletePageContentD from './designD/complete-page-content';

export type CompletePageDetails = {
    title?: string;
    description?: string;
    data: Record<string, ReactNode | undefined>;
};

export interface CompletePageContentProps {
    variant: 'success' | 'error';
    pageTitle?: string;
    heading: string;
    description?: string;
    details?: CompletePageDetails | CompletePageDetails[];
    buttons: MainButtonProps[];
    isLoading?: boolean;
    additionalContent?: ReactNode;
    showLogo?: boolean;
    isInfoVertical?: boolean | undefined;
}

export interface CompletePageContentInnerProps extends Required<Omit<CompletePageContentProps, 'isInfoVertical'>> {
    details: CompletePageDetails[];
    isInfoVertical: boolean | undefined;
}

/**
 * This is the page content component for overall success/failure pages and full modals.
 *
 * Not for specific page.
 */
const CompletePageContent: React.FC<CompletePageContentProps> = ({
    pageTitle = '',
    description = '',
    details = { data: {} },
    isLoading = false,
    showLogo = false,
    isInfoVertical,
    ...props
}) => {
    const Component = useDesignComponents<ComponentType<CompletePageContentInnerProps>>({
        [Design.DesignA]: CompletePageContentA,
        [Design.DesignB]: CompletePageContentB,
        [Design.DesignC]: CompletePageContentC,
        [Design.DesignD]: CompletePageContentD,
    });

    return (
        <Component
            {...props}
            pageTitle={pageTitle}
            description={description}
            details={Array.isArray(details) ? details : [details]}
            isLoading={isLoading}
            additionalContent={props.additionalContent || null}
            showLogo={showLogo}
            isInfoVertical={isInfoVertical}
        />
    );
};

export default CompletePageContent;
