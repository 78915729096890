import { useMediaQuery } from '@chakra-ui/react';
import { BreakpointsEnum } from '@type/theme';
import { useExtension } from './use-extension';

/** Check if it is a desktop via breakpoint.  */
export const useIsDesktop = () => {
    const [isDesktop] = useMediaQuery(`(min-width: ${BreakpointsEnum.Desktop})`);
    const { extensionStatus } = useExtension();

    return isDesktop && !!extensionStatus?.desktop.isEnabled;
};
