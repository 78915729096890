import { default as CONFIG } from '@config';
import { IColorToken } from '@type/color-token';
import { IHarmonyTheme } from '@type/theme';
import { base } from 'src/legacy/components/blockui/theme/base';

const white = '#FFFFFF';
const black = '#000000';
const gray = {
    50: '#F5F5F5',
    100: '#E6E6E6',
    200: '#DBDBDB',
    300: '#C2C2C2',
    400: '#A8A8A8',
    500: '#8F8F8F',
    600: '#757575',
    700: '#5C5C5C',
    800: '#424242',
    900: '#292929',
} as const;

const primaryFallback = gray[800];
const primary = {
    default: primaryFallback,
    lighten: {
        100: primaryFallback,
        200: primaryFallback,
        300: primaryFallback,
    },
    darken: {
        100: primaryFallback,
        200: primaryFallback,
        300: primaryFallback,
    },
    100: primaryFallback,
    200: primaryFallback,
    300: primaryFallback,
    400: primaryFallback,
    500: primaryFallback,
    600: primaryFallback,
    700: primaryFallback,
    800: primaryFallback,
    900: primaryFallback,
};

export const secondaryFallback = gray[200];
const secondary = {
    default: secondaryFallback,
    lighten: {
        100: secondaryFallback,
        200: secondaryFallback,
        300: secondaryFallback,
    },
    darken: {
        100: secondaryFallback,
        200: secondaryFallback,
        300: secondaryFallback,
    },
    100: secondaryFallback,
    200: secondaryFallback,
    300: secondaryFallback,
    400: secondaryFallback,
    500: secondaryFallback,
    600: secondaryFallback,
    700: secondaryFallback,
    800: secondaryFallback,
    900: secondaryFallback,
};

const status = {
    error: '#D23B27',
    success: '#009518',
    information: '#005AE0',
    warning: '#FF8A01',
    successLighten: { 100: '#50C34F', 200: '#76D075', 300: '#9CDD9B' },
    successDarken: { 100: '#339332', 200: '#2D802C', 300: '#266D25' },
} as const;

const colorToken: IColorToken = {
    text: {
        title1: gray[900],
        body1: gray[800],
        body2: gray[600],
        placeholder: gray[500],
        disabled: gray[400],
        white: white,
        error: status.error,
        success: status.success,
        secondary: secondary.default,
        info: status.information,
    },
    background: {
        primary: primary.default,
        lighten200: primary.lighten[200],
        lighten300: primary.lighten[300],
        disabled: gray[400],
        gray200: gray[200],
        gray100: gray[100],
        gray50: gray[50],
        error: status.error,
        black: black,
        white: white,
        info: status.information,
    },
    stroke: {
        primary: primary.default,
        divider: gray[50],
        gray100: gray[100],
        gray200: gray[200],
        disabled: gray[400],
        black: black,
        white: white,
    },
    icon: {
        gray400: gray[400],
        gray600: gray[600],
        black: black,
        white: white,
        secondary: secondary.default,
        info: status.information,
        error: status.error,
    },
};

const theme: IHarmonyTheme = {
    ...colorToken,
    white,
    black,
    primary,
    secondary,
    gray,
    status,
    background: {
        config: CONFIG.backgroundColor || '#ffffff',
        ...colorToken.background,
    },
    dropShadow: {
        ...base.dropShadow,
        lg: '0px 3px 3px 0px rgba(0, 0, 0, 0.2)',
        buttonBottom: '0px -2px 10px 0px rgba(0, 0, 0, 0.10)',
        shadow1: '0px 4px 16px 0px rgba(0, 0, 0, 0.20)',
        shadow2: '0px 1px 4px 0px rgba(0, 0, 0, 0.12)',
        shadow3: '0px 0px 4px 0px rgba(0, 0, 0, 0.20)',
        dropdown: '0px 4px 16px 0px rgba(0, 0, 0, 0.20)',
        rankingItem: '0px 0px 4px 0px rgba(0, 0, 0, 0.10)',
        productSummary: '0px 0px 8px 0px rgba(0, 0, 0, 0.1)',
        wishlist: 'rgba(0, 0, 0, 0.24) 0px 0px 2px',
    },
    font: {
        primary: `${CONFIG.primaryFont}Regular`,
        default: {
            light: `${CONFIG.primaryFont}Light`,
            medium: `${CONFIG.primaryFont}Medium`,
            regular: `${CONFIG.primaryFont}Regular`,
            semiBold: `${CONFIG.primaryFont}SemiBold`,
            bold: `${CONFIG.primaryFont}Bold`,
            extraBold: `${CONFIG.primaryFont}ExtraBold`,
        },
        secondary: {
            light: `${CONFIG.secondaryFont}Light`,
            medium: `${CONFIG.secondaryFont}Medium`,
            regular: `${CONFIG.secondaryFont}Regular`,
            semiBold: `${CONFIG.secondaryFont}SemiBold`,
            bold: `${CONFIG.secondaryFont}Bold`,
            extraBold: `${CONFIG.secondaryFont}ExtraBold`,
        },
    },
    fontSize: 'large',
    productStyle: 2,
    overlay: {
        black: 'rgba(0, 0, 0, 0.4)',
        white: 'rgba(255, 255, 255, 0.4)',
    },
};

export default theme;
