import { AuthTypeEnum } from '@models/auth-setting/responseDto/oauth.dto';
import { ITermDto } from '@models/term/responseDto/term.dto';
import { AccessTokens } from '@type/api';
import { ICustomerDto } from '@models/customer/responseDto/customer.dto';
import { AuthSettingDto } from '@models/auth-setting/responseDto/auth-setting.dto';

export enum OauthErrorCode {
    UserExist = 'user_exist',
    OauthMissingPhone = 'oauth_missing_phone',
    InsufficientRequiredData = 'insufficient_required_data',
}

export type OauthError = {
    error?: {
        code?: OauthErrorCode | string;
        data?: {
            auth_type: AuthTypeEnum | string;
        };
    };
};

export type OauthTermsDto = AccessTokens &
    OauthError & {
        externalAuthId?: string;
        externalOauthType: AuthTypeEnum;
        terms?: ITermDto[];
        customerData: CustomerDto;
        isEmailVerified: string;
        authSetting: AuthSettingDto;
    };

export type CustomerDto = Pick<ICustomerDto, 'id' | 'name' | 'username' | 'email' | 'phoneNumber' | 'birthdate' | 'nickname' | 'gender'>;

export type CustomerDtoKey =
    | 'username'
    | 'name'
    | 'email'
    | 'phoneNumber'
    | 'birthdate'
    | 'nickname'
    | 'gender'
    | 'emailVerificationCode'
    | 'password'
    | 'confirmPassword'
    | 'offlineStoreMembership'
    | (string & {});

export type OauthRegisterDto = AccessTokens & OauthError & {};
