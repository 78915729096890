import { Flex, HStack } from '@chakra-ui/react';
import { useAllocateSpaceForFixedBottomElement } from '@hooks/use-allocate-space-for-fixed-bottom-element';
import { useHarmony } from '@hooks/use-harmony';
import { BottomButtonsProps } from '..';
import { useExtension } from '@hooks/use-extension';

const BottomButtonsD: React.FC<BottomButtonsProps> = ({ children, isFixed, ...props }) => {
    const { theme } = useHarmony();
    const { extensionStatus } = useExtension();
    const { ref, spacingBox } = useAllocateSpaceForFixedBottomElement([children]);

    return (
        <>
            <Flex ref={ref} w="100%" justifyContent="center" bgColor={theme.background.white} boxShadow={isFixed ? theme.dropShadow.buttonBottom : undefined} {...props}>
                <HStack spacing="8px" w="100%" maxW={`min(100vw, ${extensionStatus?.maxScreenWidth}px)`} p="8px 16px 16px">
                    {children}
                </HStack>
            </Flex>
            {isFixed && spacingBox}
        </>
    );
};

export default BottomButtonsD;
