import { AlertProps, AlertStatusEnum, AlertTypeEnum } from '.';
import { useHarmony } from '@hooks/use-harmony';
import CustomButton from '@legacy/designs/custom-button/custom-button';
import AsyncButton from 'src/legacy/components/common/async-button';
import { useTranslations } from 'next-intl';

const AlertButtons: React.FC<AlertProps> = ({ type = AlertTypeEnum.Drawer, status = AlertStatusEnum.Alert, buttons = { type: 'default' }, open, onClose }) => {
    const { theme } = useHarmony();
    const t = useTranslations();

    return (
        <>
            {buttons.type === 'default' && (
                <>
                    <CustomButton full color={theme.primary.default} onClick={onClose}>
                        {buttons.btnText ? buttons.btnText : t('common.confirm')}
                    </CustomButton>
                </>
            )}
            {buttons.type === 'confirm' && (
                <>
                    <CustomButton variant="outline" color={theme.primary.default} full onClick={onClose}>
                        {t('common.cancel')}
                    </CustomButton>
                    <CustomButton full color={theme.primary.default} onClick={buttons.onConfirm}>
                        {buttons.confirmBtnText ? buttons.confirmBtnText : t('common.confirm')}
                    </CustomButton>
                </>
            )}
            {buttons.type === 'async' && (
                <>
                    <CustomButton variant="outline" color={theme.primary.default} full onClick={onClose}>
                        {t('common.cancel')}
                    </CustomButton>
                    <AsyncButton full color={theme.primary.default} onClickAsync={buttons.onClickAsync}>
                        {buttons.asyncBtnText ? buttons.asyncBtnText : t('common.confirm')}
                    </AsyncButton>
                </>
            )}
            {buttons.type === 'custom' && (
                <>
                    <CustomButton variant="outline" color={theme.primary.default} full onClick={buttons.onWhiteButton}>
                        {buttons.whiteBtnText}
                    </CustomButton>
                    <CustomButton full color={theme.primary.default} onClick={buttons.onBlueButton}>
                        {buttons.blueBtnText}
                    </CustomButton>
                </>
            )}
            {buttons.type === 'async-custom' && (
                <>
                    <AsyncButton variant="outline" color={theme.primary.default} full onClickAsync={buttons.onWhiteButtonAsync}>
                        {buttons.whiteBtnText}
                    </AsyncButton>
                    <AsyncButton full color={theme.primary.default} onClickAsync={buttons.onBlueButtonAsync}>
                        {buttons.blueBtnText}
                    </AsyncButton>
                </>
            )}
            {buttons.type === 'async-confirm' && (
                <AsyncButton full color={theme.primary.default} onClickAsync={buttons.onClickAsync}>
                    {buttons.asyncBtnText ? buttons.asyncBtnText : t('common.confirm')}
                </AsyncButton>
            )}
        </>
    );
};

export default AlertButtons;
