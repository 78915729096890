import SubscriptionInfo from '@models/product/subscription-info';
import { ImageUrl, ProductClassEnum, ProductId } from '@type/models';
import ConversionUtils from '@utils/conversion.utils';
import ProductSellableSku from './product-sellable-sku';
import { DeliveryPricingMethodEnum, IGroupedProductDto } from './responseDto/grouped-product.dto';
import SubscriptionPricingItem from './subscription-pricing-item';

class GroupedProduct {
    protected _baseDeliveryFee: number;
    protected _calculatedProductDeliveryFee: number;
    protected _calculatedProductPrice: number;
    protected _conditionalChargeStandard: number;
    protected _deliveryGroupId: null;
    protected _deliveryPricingMethod: DeliveryPricingMethodEnum | string;
    protected _productClass: ProductClassEnum;
    protected _subscriptionInfo: SubscriptionInfo | null;
    protected _deliveryPricingUnit: string;
    protected _division2Fee: number;
    protected _division3JejuFee: number;
    protected _division3OutsideJejuFee: number;
    protected _divisionDeliveryFee: number;
    protected _image: ImageUrl;
    protected _isAdditionalPricingSet: boolean;
    protected _isGroupDelivery: boolean;
    protected _isProductSelected: boolean;
    protected _marketPrice: number;
    protected _minSellPrice: number;
    protected _productSellableId: ProductId;
    protected _productSellableSkus: ProductSellableSku[];
    protected _regionDivisionLevel: number;
    protected _skuCount: number;
    protected _title: string;
    protected _unitChargeStandard: number;
    protected _subscriptionPricingList: SubscriptionPricingItem[];
    protected _userSubscriptionInfo?: SubscriptionPricingItem;

    constructor(groupedProductDto: IGroupedProductDto) {
        this._baseDeliveryFee = groupedProductDto.baseDeliveryFee;
        this._calculatedProductDeliveryFee = groupedProductDto.calculatedProductDeliveryFee;
        this._calculatedProductPrice = groupedProductDto.calculatedProductPrice;
        this._conditionalChargeStandard = groupedProductDto.conditionalChargeStandard;
        this._deliveryGroupId = groupedProductDto.deliveryGroupId;
        this._deliveryPricingMethod = groupedProductDto.deliveryPricingMethod;
        this._productClass = groupedProductDto.productClass;
        this._deliveryPricingUnit = groupedProductDto.deliveryPricingUnit;
        this._division2Fee = groupedProductDto.division2Fee;
        this._division3JejuFee = groupedProductDto.division3JejuFee;
        this._division3OutsideJejuFee = groupedProductDto.division3OutsideJejuFee;
        this._divisionDeliveryFee = groupedProductDto.divisionDeliveryFee;
        this._image = groupedProductDto.image;
        this._isAdditionalPricingSet = groupedProductDto.isAdditionalPricingSet;
        this._isGroupDelivery = groupedProductDto.isGroupDelivery;
        this._isProductSelected = groupedProductDto.isProductSelected;
        this._marketPrice = groupedProductDto.marketPrice;
        this._minSellPrice = groupedProductDto.minSellPrice;
        this._productSellableId = groupedProductDto.productSellableId;
        this._subscriptionInfo = groupedProductDto.subscriptionInfo ? new SubscriptionInfo(groupedProductDto.subscriptionInfo) : null;
        this._productSellableSkus = groupedProductDto.productSellableSkus.map((sku) => new ProductSellableSku(sku, groupedProductDto));
        this._regionDivisionLevel = groupedProductDto.regionDivisionLevel;
        this._skuCount = groupedProductDto.skuCount;
        this._title = groupedProductDto.title;
        this._unitChargeStandard = groupedProductDto.unitChargeStandard;
        this._subscriptionPricingList = groupedProductDto.subscriptionPricingList?.map((item) => new SubscriptionPricingItem(item)) || [];
        this._userSubscriptionInfo = groupedProductDto.userSubscriptionInfo ? new SubscriptionPricingItem(groupedProductDto.userSubscriptionInfo) : undefined;
    }

    get subscriptionInfo(): SubscriptionInfo | undefined {
        return this._subscriptionInfo || undefined;
    }

    private get _deliveryPricingIsProduct(): boolean {
        return this._deliveryPricingUnit === 'product';
    }

    get conditionalDeliveryPricingMethod(): boolean {
        return this._deliveryPricingMethod === DeliveryPricingMethodEnum.ConditionalCharge;
    }

    get hasProductDeliveryFee(): boolean {
        return this._deliveryPricingIsProduct;
    }

    get hasSkuDeliveryFee(): boolean {
        if (this._deliveryPricingIsProduct) {
            return this._productSellableSkus.length <= 1;
        } else {
            return true;
        }
    }

    get conditionalChargeStandard(): number {
        return this._conditionalChargeStandard;
    }

    get conditionalChargeStandardString(): string {
        return ConversionUtils.numberToString(this._conditionalChargeStandard);
    }

    get calculatedProductDeliveryFee(): number {
        return this._calculatedProductDeliveryFee;
    }

    get calculatedProductDeliveryFeeString(): string {
        return ConversionUtils.numberToString(this._calculatedProductDeliveryFee);
    }

    get productSellableSkus(): ProductSellableSku[] {
        return this._productSellableSkus;
    }

    get firstProductSellableSku(): ProductSellableSku | undefined {
        return this._productSellableSkus[0];
    }

    get title(): string {
        return this._title;
    }

    get image(): ImageUrl {
        return this._image;
    }

    get unitChargeStandard(): number {
        return this._unitChargeStandard;
    }

    get productSellableId(): ProductId {
        return this._productSellableId;
    }

    get skuCount(): number {
        return this._skuCount;
    }

    get deliveryPriceIsConditional(): boolean {
        return this._deliveryPricingMethod === DeliveryPricingMethodEnum.ConditionalCharge;
    }

    /**
     * Includes physical products
     */
    get isTangible(): boolean {
        // TODO: temporary hardcoded intangible product for NHPay demoing purposes
        // return this._productClass === ProductClassEnum.Tangible
        return this._productSellableId !== 'c6fae661-a493-4b63-b6ed-219c26aa7d00';
    }

    /**
     * Includes products like tickets
     */
    get isIntangible(): boolean {
        return this._productClass !== ProductClassEnum.Tangible;
    }

    get subscriptionPricingList(): SubscriptionPricingItem[] {
        return this._subscriptionPricingList.sort((a, b) => a.subscriptionPaymentPeriod - b.subscriptionPaymentPeriod) || [];
    }

    get firstSubscriptionPricingListItem(): SubscriptionPricingItem | undefined {
        return this._subscriptionPricingList[0];
    }

    get userSubscriptionInfo(): SubscriptionPricingItem | undefined {
        return this._userSubscriptionInfo;
    }

    get hasOnlyOneOption(): boolean {
        return this._subscriptionPricingList.length === 1;
    }

    get allSkusAreSoldOut(): boolean {
        if (this._productSellableSkus.length === 1) return false;
        return this._productSellableSkus.every((sku) => sku.isSoldOut);
    }
}

export default GroupedProduct;
