import CONFIG from '@config';
import { MarketDomain } from '@type/markets';

export default class DomainUtils {
    static get isNhPay(): boolean {
        return CONFIG.domain === MarketDomain.NhPay;
    }

    static get isNhPayNormal(): boolean {
        return CONFIG.domain === MarketDomain.NhPayNormal;
    }

    static get isKlife(): boolean {
        return CONFIG.domain === MarketDomain.Klife;
    }

    static get isJoymall(): boolean {
        return CONFIG.domain === MarketDomain.Joymall;
    }

    static get isShoppingeasy(): boolean {
        return CONFIG.domain === MarketDomain.Shoppingeasy;
    }

    static get isDmarket(): boolean {
        return CONFIG.domain === MarketDomain.Freed;
    }

    static get isKbPay(): boolean {
        return CONFIG.domain === MarketDomain.KbPay;
    }

    static get isKbOnnuriShop(): boolean {
        return CONFIG.domain === MarketDomain.KbOnnuriShop;
    }

    static get isOnnuri(): boolean {
        return CONFIG.domain === MarketDomain.Onnuri;
    }

    static get isToyplus(): boolean {
        return CONFIG.domain === MarketDomain.ToyPlus;
    }

    static get isJejuPass(): boolean {
        return CONFIG.domain === MarketDomain.JejuPass;
    }

    static get isTamrain(): boolean {
        return CONFIG.domain === MarketDomain.Tamrain;
    }

    static get isLge(): boolean {
        return CONFIG.domain === MarketDomain.Lge;
    }
}
