import { Box, Button, Flex, HStack, Input, Text, VStack } from '@chakra-ui/react';
import IconButton from '@designs/primitives/common/icon-button';
import Logo from '@designs/primitives/main/header/logo';
import { useHarmony } from '@hooks/use-harmony';
import BodyText from '@legacy/components/common/text/body-text';
import { useTranslations } from 'next-intl';
import { DesktopSearchPanelInnerProps } from '..';

const DesktopSearchPanelA: React.FC<DesktopSearchPanelInnerProps> = ({ onSubmit, form, popularKeywords }) => {
    const { theme } = useHarmony();
    const t = useTranslations();

    return (
        <>
            {/* SEARCH BAR */}
            <VStack as="section" align="start" spacing="24px">
                <Logo height={48} />
                <Box as="form" onSubmit={form.handleSubmit(onSubmit)} w="100%">
                    <HStack spacing="8px" h="40px" px="16px" bgColor={theme.white} borderRadius="8px" boxShadow="0px 0px 20px 0px rgba(0, 0, 0, 0.03)">
                        <Input variant="unstyled" {...form.register('search')} placeholder={t('search.enterSearch')} _placeholder={{ color: theme.gray[500] }} />
                        <IconButton type="submit">search</IconButton>
                    </HStack>
                </Box>
            </VStack>

            {/* POPULAR KEYWORDS */}
            {popularKeywords.length > 0 && (
                <Box as="section" mt="8px">
                    <Box as="hgroup" py="16px">
                        <BodyText as="h2" size="18px" weight="bold">
                            {t('search.popular')}
                        </BodyText>
                    </Box>
                    <Flex flexWrap="wrap" gap="8px">
                        {popularKeywords.map(({ keyword, index }) => (
                            <Button
                                key={index}
                                variant="unstyled"
                                onClick={() => onSubmit({ search: keyword })}
                                p="6px 10px"
                                border="1px solid"
                                borderColor={theme.white}
                                borderRadius="full"
                                bgColor="rgba(0, 0, 0, 0.30)"
                                boxShadow=" 0px 0px 8px 0px rgba(0, 0, 0, 0.12)"
                                maxW="100%"
                            >
                                <Text color={theme.white} fontSize="15px" fontWeight="bold" textAlign="left" noOfLines={1} display="block">
                                    {keyword}
                                </Text>
                            </Button>
                        ))}
                    </Flex>
                </Box>
            )}
        </>
    );
};

export default DesktopSearchPanelA;
