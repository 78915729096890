import { AuthTypeEnum } from '@models/auth-setting/responseDto/oauth.dto';
import { DayOfWeek, GenderCodeEnum, GenderEnum } from '@type/common';
import { SortProductByEnum } from '@type/product';
import { StatusButtonEnum } from '@utils/transaction-status.utils';

const common = {
    common: {
        home: '홈',
        goToPage: '{page} 페이지로 이동',
        kakaoButton: '바로가기',
        share: '공유하기',
        copied: '클립보드에 복사되었습니다.',
        cancel: '취소',
        confirm: '확인',
        update: '수정하기',
        login: 'Log in',
        logout: '로그아웃',
        deleteAcc: '회원탈퇴',
        withdraw: '탈퇴',
        confirmDeleteAccount: '정밀 탈퇴하시겠습니까?\n탈퇴 버튼 클릭 시, 계정 내 모든 정보는 삭제되며 복구되지 않습니다.',
        deleteAccountComplete: '회원탈퇴가 완료되었습니다.',
        oauthError: '동일한 {existType}로 가입된 {authType} 계정이 있습니다.\n기존 계정으로 로그인해주세요.',
        emailAddress: '이메일 주소',
        phone: '휴대폰 번호',
        missingPhone: '선택하신 로그인 서비스에 핸드폰 번호가 등록되지 않았습니다.',
        missingPhone2: '다른 로그인 서비스를 이용하시거나 직접 해당 서비스에 핸드폰 번호를 등록 후 다시 시도해주세요.',
        agreement: '약관동의',
        agreeAll: '약관 모두 동의',
        details: '자세히',
        required: '필수',
        optional: '선택',
        apply: '적용',
        apply2: '적용하기',
        search: '검색',
        filter: '상세필터',
        price: '가격',
        priceRange: '가격대',
        priceSetting: '직접 설정',
        minPrice: '최소금액',
        maxPrice: '최고금액',
        viewMore: '더보기',
        fold: '접기',
        delete: '삭제하기',
        deleteShort: '삭제',
        completion: '완료',
        contactUs: '문의하기',
        favorites: '찜한상품',
        top: 'TOP',
        best: '베스트',
        goToCart: '장바구니로 이동',
        continueShopping: '쇼핑 계속하기',
        goToOrder: '주문으로 이동',
        genericError: '죄송합니다. 예상치 못한 서버 장애로 사용이 원활하지 못합니다.',
        copyUrl: 'Copy URL',
        special: '특가',
        soldOut: '품절',
        soldOut2: '일부품절',
        inactive: '구매불가',
        backToPrevious: '이전 페이지로 돌아가기',
        four: '페이지를 찾을 수 없습니다.',
        five: '페이지를 표시할 수 없습니다.',
        point: '포인트',
        points: '포인트명',
        reset: '초기화',
        error1: '에러가 발생했습니다.',
        error2: '문제가 지속될 시 고객센터에 문의바랍니다.',
        tier: '등급',
        month: '월',
        pluralMonth: '{month, plural, =1 {월} other {#개월}}',
        dayOfWeek: `{dayOfWeek, plural, 
                =${DayOfWeek.Sunday} {일} 
                =${DayOfWeek.Monday} {월} 
                =${DayOfWeek.Tuesday} {화} 
                =${DayOfWeek.Wednesday} {수} 
                =${DayOfWeek.Thursday} {목} 
                =${DayOfWeek.Friday} {금} 
                =${DayOfWeek.Saturday} {토}
            other {}}`,
        sun: '일',
        mon: '월',
        tue: '화',
        wed: '수',
        thu: '목',
        fri: '금',
        sat: '토',
        calendarYear: '년',
        calendarMonth: '월',
        calendarDay: '일',
        invalidAge: '만 19세 미만 이용자는',
        invalidAge2: '서비스 이용이 불가합니다',
        filterError: '*최저가는 최고가보다 낮아야합니다',
        minText: '최소 {count}자 이상 입력해 주세요.',
        option: '옵션',
        quantity: '{quantity}개',
        totalCount: '총 {count}개',
        close: '닫기',
        sort: '정렬',
        formError: '필수정보를 입력 해주세요.',
        formError2: '필수 입력 사항을 입력해주세요.',
        requiredForm: '필수약관',
        requiredForm2: '필수 약관 동의',
        membershipError: '잘못된 접근입니다.',
        pleaseLogin: '로그인 후 이용 가능한 서비스 입니다.',
        pleaseLogin2: '{domain}에서 로그인 후 이용해 주세요.',
        draftedChannel: '운영대기 중인 채널입니다!\n접근하시려면 운영시작으로 상태를 변경해주세요.\n운영시작으로 변경되지 않고 2주 경과 시\n해당 채널은 자동으로 삭제됩니다.',
        available: '선택 가능',
        outro: '입니다.',
        expirationTo: '{date} 까지',
        [AuthTypeEnum.Naver]: AuthTypeEnum.Naver,
        [AuthTypeEnum.Kakao]: AuthTypeEnum.Kakao,
        [AuthTypeEnum.Google]: AuthTypeEnum.Google,
        [SortProductByEnum.SoldCount]: '판매순',
        [SortProductByEnum.ViewCount]: '조회순',
        [SortProductByEnum.CreateDateDesc]: '최신순',
        [SortProductByEnum.MinSellPrice]: '낮은 가격순',
        [SortProductByEnum.MinSellPriceDesc]: '높은 가격순',
        [SortProductByEnum.Ratings]: '평점 높은순',
        recommend: '추천순',
        [GenderEnum.Male]: '남성',
        [GenderEnum.Female]: '여성',
        [GenderCodeEnum.M]: '남성',
        [GenderCodeEnum.F]: '여성',
        [StatusButtonEnum.DeliveryTracking]: StatusButtonEnum.DeliveryTracking,
        [StatusButtonEnum.WriteReview]: StatusButtonEnum.WriteReview,
        [StatusButtonEnum.WithdrawOrder]: StatusButtonEnum.WithdrawOrder,
        [StatusButtonEnum.PurchaseConfirmation]: StatusButtonEnum.PurchaseConfirmation,
        [StatusButtonEnum.ReturnRequest]: StatusButtonEnum.ReturnRequest,
        [StatusButtonEnum.ExchangeRequest]: StatusButtonEnum.ExchangeRequest,
        [StatusButtonEnum.OrderCancelComplete]: StatusButtonEnum.OrderCancelComplete,
        [StatusButtonEnum.ExchangeRequestComplete]: StatusButtonEnum.ExchangeRequest,
        [StatusButtonEnum.CompletedReturnRequest]: StatusButtonEnum.CompletedReturnRequest,
        [StatusButtonEnum.ReviewCompleted]: StatusButtonEnum.ReviewCompleted,
    },
};

export default common;
