import { defineStyle } from '@chakra-ui/react';
import { defineStyleConfig } from '@chakra-ui/styled-system';
import { IHarmonyTheme } from '@type/theme';

export const spinnerTheme = (theme: IHarmonyTheme) =>
    defineStyleConfig({
        baseStyle: defineStyle({
            color: theme.primary.default,
            w: '32px',
            h: '32px',
            borderWidth: '3px',
        }),
    });
