import styled from '@emotion/styled';
import React, { ChangeEventHandler, KeyboardEventHandler, ReactNode } from 'react';
import { BaseProps } from '../types';

export type InputProps = BaseProps &
    Omit<React.HTMLProps<HTMLInputElement>, 'as' | 'onChange' | 'ref'> & {
        value?: string | number;
        placeholder?: string;
        id?: string;
        disabled?: boolean;
        error?: string;
        onChange?: (value: string, name?: string) => void;
        onPressEnter?: (value: string, name?: string) => void;
        onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
        color?: string;
        backgroundColor?: string;
        height?: string;
        type?: 'password' | 'file' | 'text' | 'number' | 'date' | 'time';
        min?: number | string;
        max?: number | string;
        name?: string;
        trailing?: ReactNode;
        icon?: {
            image: ReactNode;
            onClick: () => void;
        };
        align?: 'left' | 'center' | 'right';
        accept?: string;
        maxLength?: number;
        step?: string;
        padding?: string;
    };

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    (
        {
            value,
            placeholder,
            disabled = false,
            icon,
            type = 'text',
            onBlur,
            error,
            color,
            backgroundColor = 'transparent',
            onChange,
            onPressEnter,
            min,
            max,
            name,
            align = 'left',
            accept = '',
            maxLength,
            step = 'any',
            trailing,
            padding,
            ...props
        }: InputProps,
        ref
    ) => {
        const handleChange: ChangeEventHandler<HTMLInputElement> | undefined = (event) => onChange && onChange(event.target.value, event.target.name);

        const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
            if (event.key === 'Enter') {
                onPressEnter && onPressEnter(event.currentTarget.value, event.currentTarget.name);
            }
        };

        return (
            <StyledContainer>
                <StyledInputContainer>
                    <StyledInput
                        test-data-id={props.testId}
                        value={value}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        ref={ref}
                        error={error}
                        type={type}
                        name={name}
                        color={color}
                        backgroundColor={backgroundColor}
                        placeholder={placeholder}
                        disabled={disabled}
                        onBlur={onBlur}
                        min={min}
                        max={max}
                        align={align}
                        accept={accept}
                        maxLength={maxLength}
                        step={step}
                        padding={padding}
                        {...props}
                    />
                    {!!trailing && <StyledTrailingContainer>{trailing}</StyledTrailingContainer>}
                    {icon && (
                        <StyledResetButton color={color} onClick={icon.onClick} disabled={disabled}>
                            {icon.image}
                        </StyledResetButton>
                    )}
                </StyledInputContainer>
                {error && <StyledError>{error}</StyledError>}
            </StyledContainer>
        );
    }
);
Input.displayName = 'Input';

Input.displayName = 'Input';

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    flex-direction: column;
`;

const StyledInputContainer = styled.div`
    position: relative;
    width: 100%;
`;

const StyledInput = styled.input<{
    icon?: {
        image: ReactNode;
        onClick: () => void;
    };
    error?: string;
    backgroundColor?: string;
    color?: string;
    height?: string;
    align?: 'left' | 'center' | 'right';
    padding?: string;
}>`
    height: ${({ height }) => height || '3.25rem'};
    width: 100%;
    background: ${({ backgroundColor }) => backgroundColor || 'transparent'};
    padding: ${({ padding, icon }) => padding || `1rem ${icon ? '52px' : '1rem'} 1rem 1rem`};
    border: 1px solid ${(props) => (props.error ? props.theme.status.error : props.theme.gray[200])};
    font-size: 1rem;
    line-height: 1rem;
    text-align: ${(props) => props.align};

    color: ${(props) => props.color || props.theme.gray[900]};

    &::placeholder {
        color: ${(props) => props.theme.gray[200]};
    }

    &:focus {
        border-color: ${(props) => props.theme.primary.lighten[300]};
    }

    &:disabled {
        background-color: ${(props) => props.theme.gray[50]};
    }

    &[type='password'] {
        color: ${(props) => props.color || props.theme.gray[900]};
    }
`;

const StyledTrailingContainer = styled.div`
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translate(0, -50%);
`;

const StyledResetButton = styled.button<{
    color?: string;
}>`
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translate(0, -50%);
    cursor: pointer;

    & > svg {
        height: 1.2rem;
        width: 1.2rem;
        fill: ${(props) => props.color || props.theme.gray[900]};
    }
`;

const StyledError = styled.div`
    font-style: normal;
    font-size: 14px;
    font-family: 'Nanum Square';
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: ${(props) => props.theme.status.error};
`;

export default Input;
