import { AccessTokens } from '@type/api';

export type LoginDto = AccessTokens & {
    message: string;
    temporaryAccessToken?: string;
    customerData?: {
        isPasswordResetRequired: boolean;
        isAgreedOnRegistrationTerms: boolean;
        isIdentityVerified: boolean;
    };
};

export enum LoginStatus {
    Successful = 'successful',
    VerificationRequired = ' verification-required',
    PasswordResetRequired = 'password-reset-required',
}
