import { ChakraBaseProvider, extendTheme } from '@chakra-ui/react';
import { tabTheme } from '@designs/grouped/common/tab-menu/theme';
import { accordionTheme } from '@designs/primitives/common/accordion-list/theme';
import { buttonTheme } from '@designs/primitives/common/button/theme';
import { checkboxTheme } from '@designs/primitives/common/checkbox/theme';
import { dividerTheme } from '@designs/primitives/common/divider/theme';
import { spinnerTheme } from '@designs/primitives/common/loader/theme';
import { radioTheme } from '@designs/primitives/common/radio/theme';
import { menuTheme } from '@designs/primitives/common/sorting/theme';
import { switchTheme } from '@designs/primitives/common/switch/theme';
import { BreakpointsEnum } from '@type/theme';
import { PropsWithChildren } from 'react';
import { useHarmony } from './use-harmony';

const ChakraWithThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { theme } = useHarmony();

    const chakraTheme = extendTheme({
        components: {
            Switch: switchTheme(theme),
            Tabs: tabTheme(theme),
            Checkbox: checkboxTheme(theme),
            Radio: radioTheme(theme),
            Button: buttonTheme(theme),
            Accordion: accordionTheme(theme),
            Menu: menuTheme(theme),
            Divider: dividerTheme(theme),
            Spinner: spinnerTheme(theme),
        },
        breakpoints: {
            [BreakpointsEnum.Mobile]: BreakpointsEnum.Mobile,
            [BreakpointsEnum.Tablet]: BreakpointsEnum.Tablet,
            [BreakpointsEnum.Desktop]: BreakpointsEnum.Desktop,
        },
    });

    return <ChakraBaseProvider theme={chakraTheme}>{children}</ChakraBaseProvider>;
};

export default ChakraWithThemeProvider;
