import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import { BlockIcon, IconProps, IconVariant } from 'src/legacy/components/blockui';

const CustomIcon: React.FC<IconProps> = ({ children, ...props }) => {
    const iconVariant = useDesignComponents<IconVariant>({
        [Design.DesignA]: IconVariant.outlined,
        [Design.DesignB]: IconVariant.rounded,
        [Design.DesignC]: IconVariant.rounded,
    });
    return (
        <BlockIcon variant={iconVariant} {...props}>
            {children}
        </BlockIcon>
    );
};

export default CustomIcon;
