import TransactionCacheKeys from '@api/transaction/cache-keys';
import { TransactionClient } from '@api/transaction/transaction.client';
import { useCustomer } from '@hooks/use-customer/use-customer';
import { useDesignComponents } from '@hooks/use-design-components';
import { useExtension } from '@hooks/use-extension';
import { IconButtonProps } from '@legacy/components/blockui';
import Cart from '@models/cart';
import { ICartDto } from '@models/cart/responseDto/cart.dto';
import { Design } from '@type/harmony-config';
import { ComponentType, useMemo } from 'react';
import useSWR from 'swr';
import ShoppingCartButtonA from './designA/shopping-cart-button';
import ShoppingCartButtonC from './designC/shopping-cart-button';

interface ShoppingCartButtonProps extends Omit<IconButtonProps, 'children'> {}
export interface ShoppingCartButtonInternalProps extends ShoppingCartButtonProps {
    cart?: Cart;
}

const ShoppingCartButton: React.FC<ShoppingCartButtonProps> = (props) => {
    const Component = useDesignComponents<ComponentType<ShoppingCartButtonInternalProps>>({
        [Design.DesignA]: ShoppingCartButtonA,
        [Design.DesignB]: ShoppingCartButtonA,
        [Design.DesignC]: ShoppingCartButtonC,
    });
    const { extensionStatus } = useExtension();
    const { customer, isAuth } = useCustomer();

    const { data } = useSWR<ICartDto | undefined>(TransactionCacheKeys.cartCacheKey(customer?.id), () => (isAuth ? TransactionClient.getCart() : undefined));
    const cart = useMemo<Cart | undefined>(() => (data ? new Cart(data) : undefined), [data]);

    if (!extensionStatus?.cart.isEnabled) {
        return <></>;
    }

    return <Component cart={cart} {...props} />;
};

export default ShoppingCartButton;
