import { RecommendedType } from '@type/api';
import { ChannelId } from '@type/models';
import { IProductPageSettingsDto } from './responseDto/product-page-settings.dto';

class ProductPageSettings {
    private _channelId: ChannelId;
    private _bannerImages: string[];
    private _recListTitle: string;
    private _recListDisplayType: RecommendedType;
    private _recListDisplaySortOrder: string;
    private _recProductCount: number;
    private _recSettingEnabled: boolean;

    constructor(productPageSettingsDto: IProductPageSettingsDto) {
        this._channelId = productPageSettingsDto.channelId;
        this._bannerImages = productPageSettingsDto.bannerImages;
        this._recListTitle = productPageSettingsDto.recListTitle;
        this._recListDisplayType = productPageSettingsDto.recListDisplayType;
        this._recListDisplaySortOrder = productPageSettingsDto.recListDisplaySortOrder;
        this._recProductCount = productPageSettingsDto.recProductCount;
        this._recSettingEnabled = productPageSettingsDto.recSettingEnabled;
    }

    get bannerImage(): string | undefined {
        return this._bannerImages[0];
    }

    get recListTitle(): string {
        return this._recListTitle;
    }

    get isCategoryDisplayType(): boolean {
        return this._recListDisplayType === 'category';
    }

    get isPartnerDisplayType(): boolean {
        return this._recListDisplayType === 'partner';
    }

    get recListDisplaySortOrder(): string {
        return this._recListDisplaySortOrder;
    }

    get recProductCount(): number {
        return this._recProductCount;
    }

    get recSettingEnabled(): boolean {
        return this._recSettingEnabled;
    }
}

export default ProductPageSettings;
