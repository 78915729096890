import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { IThemeProps } from '@type/common';
import { InnerHtmlProps } from '..';
import { forwardRef } from '@chakra-ui/react';

const InnerHtmlA = forwardRef<InnerHtmlProps, 'div'>(({ content, ...props }, ref) => {
    const { theme } = useHarmony();

    return (
        <div ref={ref} {...props}>
            <StyledInnerHtml theme={theme} dangerouslySetInnerHTML={{ __html: content }} />
        </div>
    );
});

export default InnerHtmlA;

const StyledInnerHtml = styled.div<IThemeProps>`
    white-space: pre-wrap;
    word-break: break-all;
    img {
        width: 100%;
        height: auto;
    }
    > * {
        margin: revert;
        padding: unset;
        font: unset;
        font-size: revert;
    }
    a {
        color: ${({ theme }) => theme.status.information};
        text-decoration: underline;
        :visited {
            color: mediumpurple;
        }
    }
`;
