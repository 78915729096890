export interface IAcrossAdnDto {
    scriptSetting: {
        ui: string;
        dbScripts: {
            registrationDbScript: string;
            participationEventDbScript: string;
        };
        panelScripts: {
            mainPanelScript: string;
            closingPanelScript: string;
            messagePanelScript: string;
            productPanelScript: string;
            closingPanelDisplayAreas: DisplayAreaEnum[];
            messagePanelDisplayAreas: DisplayAreaEnum[];
        };
        pageTargetValue: string;
    };
}

export enum DisplayAreaEnum {
    Home = 'home',
    Product = 'product',
    Custom = 'custom',
    Cart = 'cart',
    Order = 'order',
}

export interface IAcrossPanelParams {
    ui: string | null;
    ci: string | null;
    gi: string | null;
}
