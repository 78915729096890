import { IOfflineStoreItemDto } from '@models/offline-store-item/responseDto/offline-store-item.dto';

export interface IOfflineStoreDto extends Omit<IOfflineStoreItemDto, 'imageUrl' | 'isOpenForBusiness'> {
    information: string;
    businessHours: IBusinessHoursDto[];
    images: IOfflineStoreImageDto[];
}

export interface IBusinessHoursDto {
    id: number;
    weekDays: number[];
    isOpenAllDay: boolean;
    startTime: string;
    endTime: string;
}

export interface IOfflineStoreImageDto {
    id: number;
    isRepresentativeImage: boolean;
    imageUrl: string;
}

export interface IOfflineStoreMembershipDto {
    offlineStoreCode: string;
    offlineStoreMembershipId: string;
}

export interface IOfflineStoreSettingDto {
    isDisplayedOnCategoryPage: boolean;
    isUsingMembership: boolean;
    barcodeType: BarcodeTypeEnum;
}

export enum BarcodeTypeEnum {
    Code128 = 'code_128',
    Code39 = 'code_39',
    Codabar = 'codabar',
    Itf = 'itf',
    EanUpc = 'ean_upc',
}
