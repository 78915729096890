import { BasicDate } from '@type/models';
import moment from 'moment-timezone';
import 'moment/locale/ko';

moment.tz.setDefault('Korea/Seoul');

/**
 * Date manipulation service class
 */
export default class DateUtils {
    /**
     * Returns 2 digit format if digit is under 10
     */
    private static _twoDigitFormat(i: number) {
        return i * 1 < 10 ? '0' + i * 1 : i;
    }

    /**
     * Converts the date into the "BasicDate" type format YYYY-MM-DD
     */
    static dateToBasicDateString(dateToConvert: Date): BasicDate {
        return `${dateToConvert.getFullYear()}-${DateUtils._twoDigitFormat(dateToConvert.getMonth() + 1)}-${DateUtils._twoDigitFormat(dateToConvert.getDate())}`;
    }

    /**
     * Converts the date into the format YYYY.MM.DD
     */
    static dateToDotString(dateToConvert: moment.MomentInput): string {
        return moment(dateToConvert).format('YYYY.MM.DD');
    }

    /**
     * Converts the date into the format YY.MM.DD
     */
    static dateToShortDotString(dateToConvert: Date): string {
        return `${`${dateToConvert.getFullYear()}`.slice(2)}.${DateUtils._twoDigitFormat(dateToConvert.getMonth() + 1)}.${DateUtils._twoDigitFormat(dateToConvert.getDate())}`;
    }

    /**
     * Converts the date into time string format HH:mm:SS
     */
    static dateToTimeString(dateToConvert: Date): string {
        return `${dateToConvert.getHours()}:${DateUtils._twoDigitFormat(dateToConvert.getMinutes())}:${DateUtils._twoDigitFormat(dateToConvert.getSeconds())}`;
    }

    /**
     * Converts the date into time string format HH:mm:SS
     */
    static dateToShortTimeString(dateToConvert: Date): string {
        return `${dateToConvert.getHours()}:${DateUtils._twoDigitFormat(dateToConvert.getMinutes())}`;
    }

    /**
     * Converts the date into time string format YYYY.MM.DD HH:mm:ss
     */
    static dateToDateTimeString(dateToConvert: Date): string {
        return `${DateUtils.dateToDotString(dateToConvert)} ${DateUtils.dateToTimeString(dateToConvert)}`;
    }

    /**
     * Converts the date into time string format HH:mm
     */
    static dateToFullDateTimeString(dateToConvert: Date): string {
        return `${dateToConvert.getFullYear()}${DateUtils._twoDigitFormat(dateToConvert.getMonth() + 1)}${DateUtils._twoDigitFormat(
            dateToConvert.getDate()
        )}${DateUtils._twoDigitFormat(dateToConvert.getHours())}${DateUtils._twoDigitFormat(dateToConvert.getMinutes())}${DateUtils._twoDigitFormat(dateToConvert.getSeconds())}`;
    }

    /**YYYY. MM. DD */
    static dateToYYYYMMDDString(date: moment.MomentInput): string {
        return moment(date).format('YYYY. MM. DD');
    }

    /**YYYY-MM-DD */
    static dateToYYYYMMDDWithDashString(date: Date): string {
        return moment(date).format('YYYY-MM-DD');
    }

    /** YYYY.MM.DD dddd */
    static dateToYYYYMMDDAndDayString(date: moment.MomentInput): string {
        return moment(date).format('YYYY.MM.DD dddd');
    }

    /** YYYY.MM.DD hh:mm */
    static dateToYYYYMMDDAndTimeString(date: moment.MomentInput): string {
        return moment(date).format('YYYY.MM.DD HH:mm');
    }

    /**(dd) */
    static dateToShortDayString(date: moment.MomentInput): string {
        return moment(date).format('dd');
    }

    /** dddd */
    static dateToLongDayString(date: moment.MomentInput): string {
        return moment(date).format('dddd');
    }

    /** LL dddd */
    static dateToLocaleDateAndDayString(date: Date): string {
        return moment(date).format('LL dddd');
    }

    static sortWeekdays(weekdays: string[]): string[] {
        const weekdayOrder = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        return weekdays.sort((a, b) => weekdayOrder.indexOf(a) - weekdayOrder.indexOf(b)).map((day) => moment().day(weekdayOrder.indexOf(day)).format('dd'));
    }

    static sortDateArray(dateArray: number[]): string[] {
        return dateArray.sort((a, b) => a - b).map((date) => moment().date(date).format('Do'));
    }

    static shippingDateToEstimatedDeliveryDate(deliveryPeriod: number, weekdays: string[]): string[] {
        const weekdayOrder = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        const indexOfWeekday = weekdays.map((weekday) => {
            if (weekday === 'wednesday') return deliveryPeriod - 2;
            if (weekday === 'thursday') return deliveryPeriod - 1;
            if (weekday === 'friday' || weekday === 'saturday' || weekday === 'sunday') return deliveryPeriod;
            return weekdayOrder.indexOf(weekday) + deliveryPeriod;
        });
        return Array.from(new Set(indexOfWeekday))
            .sort((a, b) => a - b)
            .map((index) => moment().day(index).format('dd'));
    }

    static dateToDayString(date: number): string {
        return moment().date(date).format('Do');
    }

    static isSame(date1: moment.MomentInput, date2: moment.MomentInput, unit?: moment.unitOfTime.StartOf): boolean {
        return moment(date1).isSame(date2, unit);
    }

    static diff(date1: moment.MomentInput, date2: moment.MomentInput, unit?: moment.unitOfTime.Diff): number {
        return moment(date1).diff(date2, unit);
    }

    static add(date: moment.MomentInput, amount: number, unit?: moment.unitOfTime.DurationConstructor): Date {
        return moment(date).add(amount, unit).toDate();
    }
}
