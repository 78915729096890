import { ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
    children: ReactNode;
    portalId: string;
}

const Portal: React.FC<PortalProps> = ({ portalId, children }) => {
    const [mounted, setMounted] = useState<boolean>(false);

    useEffect(() => {
        setMounted(true);

        return () => setMounted(false);
    }, []);

    if (mounted) {
        let wrapperElement = document.getElementById(portalId);

        if (!wrapperElement) {
            wrapperElement = document.createElement('div');
            wrapperElement.setAttribute('id', portalId);
            document.body.appendChild(wrapperElement);
        }

        return createPortal(children, wrapperElement);
    }

    return null;
};

export default Portal;
