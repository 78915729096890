import AcrossClosingPanel from '@components/across-adn/across-closing-panel';
import AcrossMessagePanel from '@components/across-adn/across-message-panel';
import DraftedMarketBlocker from '@components/drafted-market-blocker';
import ResponsiveLayout from '@components/responsive-layout';
import { TestViewer } from '@components/test-viewer';
import CONFIG from '@config';
import { AlertProvider } from '@hooks/use-alert';
import ChakraWithThemeProvider from '@hooks/use-chakra-with-theme';
import { CompleteFullModalProvider } from '@hooks/use-complete-full-modal';
import { CustomerProvider } from '@hooks/use-customer/use-customer';
import ExtensionProvider from '@hooks/use-extension';
import GATrackingProvider from '@hooks/use-ga-tracking';
import GlobalizationProvider from '@hooks/use-globalization';
import HarmonyProvider from '@hooks/use-harmony';
import KakaoAdTrackerProvider from '@hooks/use-kakao-ad-tracker';
import NaverWcsProvider from '@hooks/use-naver-wcs';
import { ReferrerProvider } from '@hooks/use-referrer';
import { SellPricePolicyProvider } from '@hooks/use-sell-price-policy';
import { ToastProvider } from '@hooks/use-toast';
import { WishlistItemsContextProvider } from '@hooks/use-wishlist';
import { AlertProvider as LegacyAlertProvider } from '@legacy/hooks/use-alert-drawer';
import { MarketDomain } from '@type/markets';
import EnvironmentUtils from '@utils/environment.utils';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { ReactElement, ReactNode } from 'react';
import ErrorBoundaryContainer from 'src/legacy/components/common/error-boundary-container';
import PageLoadingIndicator from 'src/legacy/components/common/page-loading-indicator';
import WebViewOptimization from 'src/legacy/components/web-view-optimization/web-view-optimization';
import SwiperCore, { Autoplay, Swiper } from 'swiper';
import 'swiper/css';
import { SWRConfig } from 'swr';
import '../styles/globals.css';

Swiper.extendDefaults({
    touchStartPreventDefault: false,
});

type NextPageWithLayout = NextPage & {
    getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps, ...props }: AppPropsWithLayout) {
    SwiperCore.use([Autoplay]);

    return (
        <>
            <WebViewOptimization />
            <SWRConfig value={{ errorRetryCount: 3, revalidateOnFocus: false }}>
                <GlobalizationProvider>
                    <HarmonyProvider>
                        <ExtensionProvider>
                            <GATrackingProvider>
                                <NaverWcsProvider>
                                    <KakaoAdTrackerProvider>
                                        <ChakraWithThemeProvider>
                                            <ReferrerProvider>
                                                <ToastProvider>
                                                    <LegacyAlertProvider>
                                                        <AlertProvider>
                                                            <CustomerProvider postData={pageProps.postData}>
                                                                <CompleteFullModalProvider>
                                                                    <SellPricePolicyProvider>
                                                                        <ErrorBoundaryContainer>
                                                                            {CONFIG.domain === MarketDomain.KbPay && !EnvironmentUtils.isProduction && (
                                                                                <TestViewer postData={pageProps.postData} />
                                                                            )}
                                                                            <PageLoadingIndicator />
                                                                            <DraftedMarketBlocker />
                                                                            <WishlistItemsContextProvider>
                                                                                <ResponsiveLayout>
                                                                                    <Component {...pageProps} />
                                                                                </ResponsiveLayout>
                                                                            </WishlistItemsContextProvider>
                                                                            <AcrossClosingPanel />
                                                                            <AcrossMessagePanel />
                                                                        </ErrorBoundaryContainer>
                                                                    </SellPricePolicyProvider>
                                                                </CompleteFullModalProvider>
                                                            </CustomerProvider>
                                                        </AlertProvider>
                                                    </LegacyAlertProvider>
                                                </ToastProvider>
                                            </ReferrerProvider>
                                        </ChakraWithThemeProvider>
                                    </KakaoAdTrackerProvider>
                                </NaverWcsProvider>
                            </GATrackingProvider>
                        </ExtensionProvider>
                    </HarmonyProvider>
                </GlobalizationProvider>
            </SWRConfig>
        </>
    );
}

export default MyApp;
