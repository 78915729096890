import IconButton from '@designs/primitives/common/icon-button';
import Logo from '@designs/primitives/main/header/logo';
import styled from '@emotion/styled';
import { useExtension } from '@hooks/use-extension';
import { useHarmony } from '@hooks/use-harmony';
import { useReferrer } from '@hooks/use-referrer';
import ShoppingCartButton from '@legacy/designs/shopping-cart-button';
import { ScreenSizeProps } from '@type/common';
import DomainUtils from '@utils/domain.utils';
import ThemeUtils from '@utils/theme.utils';
import Link from 'next/link';
import { BlockHStack } from 'src/legacy/components/blockui';
import CustomIconButton from 'src/legacy/components/common/custom-icon-button';
import BodyText from 'src/legacy/components/common/text/body-text';
import RoutesEnum from 'src/routes/routes';
import { PopupHeaderProps } from '..';

const PopupHeaderB: React.FC<PopupHeaderProps> = ({
    title,
    logoOnDirect,
    showNotifications,
    showCategory,
    showSearch,
    showHome,
    showBack,
    showCart,
    showLogo,
    showClose,
    hasShadow = true,
    onBack,
    className,
}) => {
    const { theme, settings } = useHarmony();
    const { canGoBack } = useReferrer();
    const { extensionStatus } = useExtension();

    return (
        <OffsetContainer className={className}>
            <Bar hasShadow={hasShadow} maxScreenWidth={extensionStatus?.maxScreenWidth}>
                <BlockHStack alignItems="center" spacing="4px">
                    {((!canGoBack && logoOnDirect) || showLogo) && <Logo />}
                    {showBack && (canGoBack || !logoOnDirect) && (
                        <CustomIconButton size="24px" padding="0px" onClick={onBack} className="back-button">
                            arrow_back_ios_new
                        </CustomIconButton>
                    )}
                    {title && (
                        <BodyText size={'22px'} weight="bold" color={theme.primary.default}>
                            {title}
                        </BodyText>
                    )}
                </BlockHStack>

                <BlockHStack alignItems="center" spacing="16px">
                    {showNotifications && (
                        <Link href={RoutesEnum.Notification}>
                            <CustomIconButton size="24px" fill color={theme.gray[300]} padding="0px">
                                notifications
                            </CustomIconButton>
                        </Link>
                    )}
                    {showCategory && (
                        <Link href={RoutesEnum.Category}>
                            <CustomIconButton size="24px" fill color={theme.gray[300]} padding="0px">
                                menu
                            </CustomIconButton>
                        </Link>
                    )}
                    {showSearch && (
                        <Link href={RoutesEnum.Search}>
                            <CustomIconButton size="24px" fill color={theme.gray[300]} padding="0px" className="product-search">
                                search
                            </CustomIconButton>
                        </Link>
                    )}
                    {!(DomainUtils.isDmarket || DomainUtils.isNhPay) && settings?.hasNormalSalesType && showCart && <ShoppingCartButton color={theme.gray[300]} fill />}
                    {showHome && (
                        <Link href={RoutesEnum.Home}>
                            <CustomIconButton size="24px" fill color={theme.gray[300]} padding="0px" className="home">
                                home
                            </CustomIconButton>
                        </Link>
                    )}
                    {showClose && (
                        <IconButton size={24} onClick={onBack}>
                            close
                        </IconButton>
                    )}
                </BlockHStack>
            </Bar>
        </OffsetContainer>
    );
};

export default PopupHeaderB;

const OffsetContainer = styled.header`
    height: 52px;
    width: 100%;
`;

const Bar = styled.div<{ hasShadow: boolean } & ScreenSizeProps>`
    position: fixed;
    top: 0px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: min(100vw, ${({ maxScreenWidth }) => maxScreenWidth}px);
    width: 100%;
    height: 52px;
    padding: 0 16px;
    z-index: ${ThemeUtils.zIndex.badge};
    background-color: white;
    box-shadow: ${({ hasShadow }) => `${hasShadow ? '0px 0px 8px rgba(0, 0, 0, 0.12)' : 'none'}`};
`;
