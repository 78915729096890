const cancelHistory = {
    cancelHistory: {
        cancelReturnHistory: '취소/교환/반품 내역',
        empty: '취소/교환/반품 내역이 없습니다',
        cancelled: '주문취소',
        exchanged: '교환요청',
        refund: '반품요청',
        refundCompleted: '환불완료(주문취소)',
        refundOrderRejected: '환불완료(주문거절)',
        refundCompletedPeriod: '환불완료(기간만료)',
    },
};

export default cancelHistory;
