import ChannelCacheKeys from '@api/channel/cache-keys';
import { ChannelClient } from '@api/channel/channel.client';
import { useExtension } from '@hooks/use-extension';
import { useTransformedFetch } from '@hooks/use-transformed-fetch';
import { GoogleAnalytics } from '@models/google-analytics';
import { IGoogleAnalyticsDto } from '@models/google-analytics/responseDto/google-analytics.dto';
import safeWindow from '@services/safe-window.service';
import EnvironmentUtils from '@utils/environment.utils';
import Script from 'next/script';
import { ReactNode, createContext, useContext, useState } from 'react';
import useSWR from 'swr';

type LogEvent = (actions: string | string[], value: Record<string, unknown>) => void;
export type GATrackingProps = {
    clearPromotionIdentifier: () => void;
    logEvent: LogEvent;
};

export const GATrackingContext = createContext<GATrackingProps>({} as GATrackingProps);

interface GATrackingProviderProps {
    children: ReactNode;
}

const GATrackingProvider: React.FC<GATrackingProviderProps> = ({ children }) => {
    const { extensionStatus } = useExtension();
    const [promotionIdentifier, setPromotionIdentifier] = useState<string | undefined>(undefined);
    const { transformedData: googleAnalytics } = useTransformedFetch<GoogleAnalytics | undefined, IGoogleAnalyticsDto>(
        extensionStatus?.googleAnalytics.isEnabled ? ChannelCacheKeys.getGoogleAnalyticsTag() : undefined,
        () => ChannelClient.getGoogleAnalyticsTag(),
        {
            transformer: (googleAnalyticsDto) => (googleAnalyticsDto ? new GoogleAnalytics(googleAnalyticsDto) : undefined),
        }
    );
    const { data: googleAnalyticsPlus } = useSWR(extensionStatus?.googleAnalyticsPlus.isEnabled && ChannelCacheKeys.getGoogleAnalyticsPlus(), () =>
        ChannelClient.getGoogleAnalyticsPlus()
    );

    const logEvent: LogEvent = (actions, value) => {
        if (safeWindow?.gtag && googleAnalyticsPlus?.isActive && EnvironmentUtils.isProduction) {
            const eventType = 'event';
            const selectPromotionEvent = 'select_promotion';
            const promotionName = 'promotion_name';

            if (actions === selectPromotionEvent && typeof value[promotionName] === 'string') {
                setPromotionIdentifier(value[promotionName]);
            }

            const valueWithPromotion = {
                promotion_name: promotionIdentifier,
                ...value,
            };

            if (Array.isArray(actions)) {
                for (const action of actions) {
                    safeWindow.gtag(eventType, action, valueWithPromotion);
                }
            } else {
                safeWindow.gtag(eventType, actions, valueWithPromotion);
            }
        }
    };

    return (
        <>
            {googleAnalytics?.isActive && EnvironmentUtils.isProduction && (
                <>
                    <Script strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id='${googleAnalytics.tagId}'`} />
                    <Script
                        id="gtag-init"
                        strategy="afterInteractive"
                        dangerouslySetInnerHTML={{
                            __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());

                    gtag('config', '${googleAnalytics.tagId}',{
                        page_path: window.location.pathname,
                    });
                    `,
                        }}
                    />
                </>
            )}
            {googleAnalytics?.isActive && !EnvironmentUtils.isProduction && (
                <>
                    <Script strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id='${googleAnalytics.tagId}'`} />
                    <Script
                        id="gtag-init"
                        strategy="afterInteractive"
                        dangerouslySetInnerHTML={{
                            __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());

                    gtag('config', '${googleAnalytics.tagId}',{
                        debug_mode: true
                    });
                    `,
                        }}
                    />
                </>
            )}
            <GATrackingContext.Provider
                value={{
                    clearPromotionIdentifier: () => setPromotionIdentifier(undefined),
                    logEvent,
                }}
            >
                {children}
            </GATrackingContext.Provider>
        </>
    );
};

export default GATrackingProvider;

export function useGATracking(): GATrackingProps {
    return useContext(GATrackingContext);
}
