const my = {
    my: {
        myShopping: '나의 쇼핑정보',
        orderHistory: '주문 내역',
        cancelExchangeReturn: '취소/교환/반품 내역',
        productInquiry: '상품문의 내역',
        reviewHistory: '리뷰 내역',
        wishlist: '찜한 상품',
        wishlist2: '위시리스트',
        manageInfo: '내 정보 관리',
        setShipping: '배송지 설정',
        setShipping2: '배송지 관리',
        newShipping: '배송지 추가',
        oneOnOne: '1:1 문의내역',
        oneOnOne2: '1:1 문의내역',
        oneOnOne3: '1:1 문의',
        oneOnOneInquiryCreate: '1:1 문의 작성',
        oneOnOneInquiryUpdate: '1:1 문의 수정',
        updateShipping: '배송지 수정',
        noInquiry: '문의내역이 없습니다.',
        recent: '최근',
        oneMonth: '1개월',
        threeMonths: '3개월',
        sixMonths: '6개월 ',
        oneYear: '1년',
        confirmEditInquiry: '1:1문의를 수정하시겠습니까?',
        confirmDeleteInquiry: '1:1문의를 삭제하시겠습니까?',
        closeAnswer: '답변접기',
        seeAnswer: '답변보기',
        seeAll: '전체보기',
        detail: '자세히 보기',
        subscriptions: '구독함',
        completeInquiry: '1:1 문의가 등록되었습니다.',
    },
};

export default my;
