import { Box, Center, CloseButton, HStack } from '@chakra-ui/react';
import BackButton from '@designs/primitives/main/header/back-button';
import HomeButton from '@designs/primitives/main/header/home-button';
import Logo from '@designs/primitives/main/header/logo';
import NotificationButton from '@designs/primitives/main/header/notification-button';
import SearchButton from '@designs/primitives/main/header/search-button';
import { useHarmony } from '@hooks/use-harmony';
import ThemeUtils from '@utils/theme.utils';
import { HeaderInnerProps } from '..';
import BodyText from '@legacy/components/common/text/body-text';

const HeaderC: React.FC<HeaderInnerProps> = ({
    type,
    title,
    hasLogo,
    hasTitle,
    hasNotification,
    hasBack,
    hasClose,
    hasSearch,
    hasHome,
    onBackClick,
    onCloseClick,
    hideOnScroll,
    ...props
}) => {
    const { theme } = useHarmony();

    return (
        <Box
            as="nav"
            pos="sticky"
            top={hideOnScroll ? `-${ThemeUtils.headerHeight}px` : '0px'}
            zIndex={ThemeUtils.zIndex.floating}
            bgColor={theme.background.white}
            h={`${ThemeUtils.headerHeight}px`}
            px="16px"
            borderBottom={type !== 'main' ? 'solid 1px' : ''}
            borderColor={theme.stroke.divider}
            flexShrink="0"
            {...props}
        >
            {hasTitle && (
                <Center pos="absolute" inset="0" pointerEvents="none">
                    <BodyText color={theme.text.title1} size="18px" lineHeight="100%" weight="bold">
                        {title}
                    </BodyText>
                </Center>
            )}
            <HStack w="100%" h="100%" justify="space-between">
                {hasLogo && <Logo />}
                {hasBack && <BackButton onClick={onBackClick} />}
                <HStack spacing="8px" w="100%" justify="flex-end">
                    {hasSearch && <SearchButton />}
                    {hasHome && <HomeButton />}
                    {hasNotification && <NotificationButton />}
                    {hasClose && <CloseButton onClick={onCloseClick} />}
                </HStack>
            </HStack>
        </Box>
    );
};

export default HeaderC;
