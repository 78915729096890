import { useDesignComponents } from '@hooks/use-design-components';

import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { IThemeProps } from '@type/common';
import { Design } from '@type/harmony-config';
import { ReactNode } from 'react';

interface SubheadingProps {
    /**
     * Defaults to primary text color as defined in the Harmony theme.
     */
    color?: string;
    children: ReactNode;
}

const Subheading: React.FC<SubheadingProps> = ({ color, children, ...props }) => {
    const { theme, designSettings } = useHarmony();

    const fontSize = useDesignComponents<string>({
        [Design.DesignA]: '18px',
        [Design.DesignB]: '16px',
        [Design.DesignC]: '17px',
    });

    return (
        <StyledSubheading theme={theme} color={color} fontSize={designSettings?.getLegacyFontSize(fontSize)} {...props}>
            {children}
        </StyledSubheading>
    );
};

export default Subheading;

const StyledSubheading = styled.span<SubheadingProps & IThemeProps>`
    ${({ theme, color, fontSize }) => `
        font-family: ${theme.font.default.bold};
        color: ${color || theme.black};
        font-size: ${fontSize};
        letter-spacing: -0.04em;
        line-height: 26px;
    `}
`;
