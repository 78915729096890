import DateBaseModel from '@models/base-model/date-base';
import { HTMLString, ISODate } from '@type/models';
import { ITermDto } from './responseDto/term.dto';

class Term extends DateBaseModel {
    private _title?: string;
    private _content: HTMLString;
    private _enforcedDt: ISODate;
    private _isRequired?: boolean;
    private _code?: string;
    private _histories: Term[];

    private _combinedHistoricalDates: string[] = [];
    private _combinedHistoricalContents: string[] = [];

    constructor(termDto: ITermDto) {
        super(termDto.id, termDto.createDt, termDto.updateDt);
        this._title = termDto.title;
        this._content = termDto.content;
        this._enforcedDt = termDto.enforcedDt;
        this._isRequired = termDto.isRequired;
        this._code = termDto.code;
        this._histories = (termDto.histories || []).map((history) => new Term(history));

        this._setCombinedData();
    }

    private _setCombinedData(): void {
        this._combinedHistoricalDates.push(this._enforcedDt);
        this._combinedHistoricalContents.push(this._content);

        for (const history of this._histories) {
            this._combinedHistoricalDates.push(history.enforcedDate);
            this._combinedHistoricalContents.push(history.content);
        }
    }

    get title(): string | undefined {
        return this._title;
    }

    get enforcedDate(): ISODate {
        return this._enforcedDt;
    }

    get isRequired(): boolean {
        return this._isRequired || false;
    }

    get content(): HTMLString {
        return this._content;
    }

    get histories(): Term[] {
        return this._histories;
    }

    get combinedHistoricalDates(): string[] {
        return this._combinedHistoricalDates;
    }

    get combinedHistoricalContents(): string[] {
        return this._combinedHistoricalContents;
    }
}

export default Term;
