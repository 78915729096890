import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import { ComponentType, ReactNode } from 'react';
import SuccessHeadingB from './designB/success-heading';
import SuccessHeadingD from './designD/success-heading';
import SuccessHeadingC from './designC/success-heading';

export interface SuccessHeadingProps {
    children: ReactNode;
}

const SuccessHeading: React.FC<SuccessHeadingProps> = (props) => {
    const Component = useDesignComponents<ComponentType<SuccessHeadingProps>>({
        [Design.DesignB]: SuccessHeadingB,
        [Design.DesignC]: SuccessHeadingC,
        [Design.DesignD]: SuccessHeadingD,
    });

    return <Component {...props} />;
};

export default SuccessHeading;
