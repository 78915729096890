import { useDesignComponents } from '@hooks/use-design-components';

import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { IThemeProps } from '@type/common';
import { Design } from '@type/harmony-config';
import { FontSizes, FontType, FontWeight } from '@type/theme';
import ThemeUtils from '@utils/theme.utils';
import { CSSProperties, ElementType, HTMLProps, ReactNode } from 'react';
import { fontWeights } from '@components/abstracted-text';

export interface BodyTextProps extends Omit<HTMLProps<HTMLSpanElement>, 'size' | 'as'> {
    as?: ElementType<any>;

    /**
     * Defaults to primary text color as defined in the Harmony theme.
     */
    color?: string;

    /**
     * @default regular
     */
    weight?: FontWeight;

    /**
     * @default default
     */
    font?: FontType;

    /**
     * @default 14px
     */
    size?: FontSizes;

    textAlign?: string;
    flex?: number | string;

    letterSpacing?: string;
    lineHeight?: string;
    children: ReactNode;
    ellipsis?: boolean;
    lineClamp?: number;
    whiteSpace?: CSSProperties['whiteSpace'];
    wordBreak?: CSSProperties['wordBreak'];
    staticSize?: string;
    textDecoration?: string;
}

const BodyText: React.FC<BodyTextProps> = ({ color, children, size = '14px', staticSize, ...props }) => {
    const { theme, designSettings } = useHarmony();

    const defaultLetterSpacing = useDesignComponents<string>({
        [Design.DesignA]: '-0.03em',
        [Design.DesignB]: '0',
        [Design.DesignC]: '-0.01em',
    });

    return (
        <StyledBodyText theme={theme} color={color} fontSize={staticSize ?? designSettings?.getLegacyFontSize(size)} defaultLetterSpacing={defaultLetterSpacing} {...props}>
            {children}
        </StyledBodyText>
    );
};

export default BodyText;

const StyledBodyText = styled.span<BodyTextProps & IThemeProps & { defaultLetterSpacing: string }>`
    ${({
        theme,
        color,
        textDecoration,
        fontSize,
        weight = 'regular',
        font,
        letterSpacing,
        lineHeight,
        ellipsis,
        lineClamp = 2,
        textAlign,
        defaultLetterSpacing,
        whiteSpace,
        wordBreak,
        flex,
    }) => `
        font-family: ${ThemeUtils.getFont(weight, font || 'default', theme)};
        font-weight: ${fontWeights[weight]};
        color: ${color || theme.black};
        font-size: ${fontSize};
        letter-spacing: ${letterSpacing || defaultLetterSpacing};
        line-height: ${lineHeight};
        text-align: ${textAlign || 'initial'};
        display: ${ellipsis ? 'inline-flex' : 'inline'};
        align-items: center;
        text-decoration: ${textDecoration && textDecoration};
        flex: ${flex};
        ${whiteSpace ? `white-space: ${whiteSpace}` : ''};
        ${wordBreak ? `word-break: ${wordBreak}` : ''};
        ${
            ellipsis
                ? `overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: ${lineClamp};-webkit-box-orient: vertical; -webkit-box-pack: start;-webkit-box-align: start;`
                : ''
        }
    `}
`;
