import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import { PartialAbstractedTextProps } from '../../../../components/abstracted-text';
import Body1BD from './designD/body-1-b';

export interface Body1BProps extends PartialAbstractedTextProps {}

const Body1B: React.FC<Body1BProps> = (props) => {
    const Component = useDesignComponents<ComponentType<Body1BProps>>({
        [Design.DesignD]: Body1BD,
    });

    return <Component {...props} />;
};

export default Body1B;
