import IMAGE_DOMAINS from '@imageDomains';
import phone from 'phone';

class ValidationUtils {
    static isValidName(name: string | undefined): boolean {
        if (!name) return false;
        if (name.length < 2) {
            return false;
        }
        return true;
    }

    static isValidNicknameForSignup(nickname: string): boolean {
        return nickname.length >= 2 && nickname.length <= 10 && !this.hasWhiteSpace(nickname);
    }

    static isValidUsernameForSignup(id: string, minimum: number = 2, maximum: number = 10): boolean {
        return id.length >= minimum && id.length <= maximum && !this.hasWhiteSpace(id) && this.isNumberOrAlphabet(id);
    }

    static isValidNameForSignup(name: string): boolean {
        return name.length >= 2 && name.length <= 30 && this.isKoreanOrEnglishText(name);
    }

    static isUrl(urlText: string): boolean {
        try {
            const url = new URL(urlText);
            return url.protocol === 'http:' || url.protocol === 'https:';
        } catch (_) {
            return false;
        }
    }

    static isImageUrlFromKnownDomain(urlText: string): boolean {
        try {
            const url = new URL(urlText);
            if (IMAGE_DOMAINS.includes(url.host)) {
                return true;
            } else {
                return false;
            }
        } catch (_) {
            return false;
        }
    }

    static isKoreanText(text: string): boolean {
        if (!text) return false;
        return /[\u1100-\u11FF\u3130-\u318F\uA960-\uA97F\uAC00-\uD7AF\uD7B0-\uD7FF]/g.test(text);
    }

    static isEnglishText(text: string): boolean {
        return /[a-zA-Z]/.test(text);
    }

    static isKoreanOrEnglishText(text: string): boolean {
        return /^[가-힣a-zA-Z\s]*$/.test(text);
    }

    static hasWhiteSpace(text: string): boolean {
        return /\s/.test(text);
    }

    static isNumberOrAlphabet(text: string): boolean {
        return /^[0-9a-zA-Z]+$/.test(text);
    }

    static isDigit(text: string): boolean {
        return /\d/.test(text);
    }

    static isValidEmailAddress(text: string): boolean {
        return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(text);
    }

    /**
     * This method checks if the string includes any of the following char
     * ! " # $ % & ' ( ) * + , - . / : ; < = > ? @ [ ₩ ] ^ _ ` { | } ~)
     * @param text
     */
    static isSpecialChar(text: string): boolean {
        return /[!"#$%&'()*+,\-./:;<=>?@\[₩\]^_`{|}~]/.test(text);
    }

    static isValidPhoneNumber(value?: string): boolean {
        // TODO: remove country code once
        if (!value) return false;
        value = `+82${value}`;
        return value ? phone(value).isValid : false;
    }

    static isMultipleOfUnit(value: number, unit: number): boolean {
        return value % unit === 0;
    }
}

export default ValidationUtils;
