import { PaymentCodeEnum } from '@models/transaction/requestDto/create-transaction.dto';
import { IBasePaymentMethodDto } from './base-payment-method.dto';

export enum PGTypeEnum {
    Kginicis = 'kginicis',
    Smartro = 'smartro',
    Cjone = 'cjone',
    Payletter = 'payletter',
}

export interface IPaymentMethodDto extends IBasePaymentMethodDto {
    mPaymentType: string;
    payDevice: string;
    paymentType: null | string;
    pgType: PGTypeEnum | string;
    mid?: string;
    paymentCode: PaymentCodeEnum;
    pgSettingId: number;
}
