import { Body1CProps } from '..';
import AbstractedText from '@components/abstracted-text';

const Body1CD: React.FC<Body1CProps> = (props) => {
    return (
        <AbstractedText fontSize="16px" weight="bold" lineHeight="24px" {...props}>
            {props.children}
        </AbstractedText>
    );
};

export default Body1CD;
