import { ImageUrl } from '@type/models';
import FrameFooter from './frame-footer';
import FrameTopMenu from './frame-top-menu';
import { FrameComponentTypeEnum, IFrameArrayDto } from './responseDto/frame.dto';

/**
 * Collection of channel frames returned from the backend
 * @link https://harmony-channel-dev.travelflan.com/docs#/end-user-channel/get_channel_frames_api_v1_external_e_channels__channel_id__frames_get
 */
class FrameCollection {
    private _headerImage?: ImageUrl;
    private _frameTopMenu?: FrameTopMenu;
    private _frameFooter?: FrameFooter;
    // Add other frame models if needed

    constructor(frameArrayDto: IFrameArrayDto) {
        frameArrayDto.forEach((frame) => {
            switch (frame.componentType) {
                case FrameComponentTypeEnum.Header:
                    this._headerImage = frame.headerLogoImage;
                    break;
                case FrameComponentTypeEnum.TopMenu:
                    this._frameTopMenu = new FrameTopMenu(frame);
                    break;
                case FrameComponentTypeEnum.BottomMenu:
                    break;
                case FrameComponentTypeEnum.Footer:
                    this._frameFooter = new FrameFooter(frame);
                    break;
            }
        });
    }

    get headerImage(): ImageUrl | undefined {
        return this._headerImage;
    }

    get topMenuFrame(): FrameTopMenu | undefined {
        return this._frameTopMenu;
    }

    get footerFrame(): FrameFooter | undefined {
        return this._frameFooter;
    }
}

export default FrameCollection;
