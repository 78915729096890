import { useDesignComponents } from '@hooks/use-design-components';

import { Center } from '@chakra-ui/react';
import { useHarmony } from '@hooks/use-harmony';
import { Design } from '@type/harmony-config';
import { ReactNode } from 'react';
import BodyText, { BodyTextProps } from './text/body-text';

interface EmptyContentPageProps {
    children: ReactNode;
    backgroundColor?: string;
}

const EmptyContentPage: React.FC<EmptyContentPageProps> = ({ children, backgroundColor }) => {
    const { theme } = useHarmony();

    const textProps = useDesignComponents<BodyTextProps>({
        [Design.DesignA]: {
            size: '16px',
            lineHeight: '26px',
        },
        [Design.DesignB]: {
            size: '15px',
            lineHeight: '22px',
            color: theme.gray[600],
        },
        [Design.DesignC]: {
            size: '16px',
            lineHeight: '22px',
            color: theme.gray[800],
        },
    });

    return (
        <Center height="80vh" width="100%" backgroundColor={backgroundColor}>
            <BodyText textAlign="center" {...textProps}>
                {children}
            </BodyText>
        </Center>
    );
};

export default EmptyContentPage;
