import { css, SerializedStyles, Theme } from '@emotion/react';

/** Button variant */
export type Variant = 'solid' | 'outline';

/** Button size */
export type Size = 'sm' | 'md' | 'lg';

/**
 * Get size-dependent styles for button
 * @param size Size of button (sm, md, lg);
 * @returns Serialized CSS styles
 */
export const getSizeStyles = (size: Size): SerializedStyles => {
    switch (size) {
        case 'sm':
            return css`
                padding: 0.5rem 1rem;
                font-size: 0.875rem;
            `;
        case 'md':
            return css`
                padding: 0.75rem 1.5rem;
                font-size: 1rem;
            `;
        case 'lg':
            return css`
                padding: 1rem 2rem;
                font-size: 1.25rem;
            `;
    }
};

/**
 * Get variant-dependent styles for button
 * @param variant Button variant (solid, outline)
 * @param theme BlockUi Theme
 * @param color color of button
 * @returns Serialized CSS styles
 */
export const getVariantStyles = (variant: Variant, theme: Theme, color?: string): SerializedStyles => {
    switch (variant) {
        case 'solid':
            return css`
                background-color: ${color || theme.primary.lighten[300]};
                color: ${theme.white};

                &:hover {
                    /* TODO: Replace background color semantically */
                    background-color: ${theme.primary.lighten[300]};
                    opacity: 80%;
                }

                &:disabled {
                    color: ${theme.gray[600]};
                    background-color: ${theme.gray[50]};
                    opacity: 20%;
                }
            `;
        case 'outline':
            return css`
                border: 2px solid ${color || theme.primary.lighten[300]};
                color: ${color || theme.primary.lighten[300]};
                background-color: transparent;

                &:hover {
                    background-color: ${theme.primary.lighten[300]};
                }

                &:disabled {
                    border: none;
                    color: ${theme.gray[600]};
                    background-color: ${theme.gray[50]};
                    opacity: 20%;
                }
            `;
    }
};
