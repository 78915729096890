import { useDesignComponents } from '@hooks/use-design-components';
import { DrawerProps } from '@chakra-ui/react';
import { Design } from '@type/harmony-config';
import { BaseDialogueComponentProps } from '@type/theme';
import { useTranslations } from 'next-intl';
import { ComponentType } from 'react';
import ModalD from './designD';
import ModalB from './designB';

export interface ModalProps extends BaseDialogueComponentProps<DrawerProps> {}

const Modal: React.FC<ModalProps> = ({
    onClose,
    onConfirmButtonClick,
    onConfirmButtonClickAsync,
    onCancelButtonClick,
    buttonText,
    cancelButtonText,
    isCloseOnConfirm = true,
    ...props
}) => {
    const t = useTranslations();
    const Component = useDesignComponents<ComponentType<ModalProps>>({
        [Design.DesignA]: ModalD,
        [Design.DesignB]: ModalB,
        [Design.DesignD]: ModalD,
    });

    return (
        <Component
            onClose={onClose}
            buttonText={buttonText ?? t('common.confirm')}
            cancelButtonText={cancelButtonText ?? t('common.cancel')}
            onConfirmButtonClick={() => {
                onConfirmButtonClick?.();
                if (isCloseOnConfirm) {
                    onClose();
                }
            }}
            onConfirmButtonClickAsync={
                onConfirmButtonClickAsync &&
                (async () => {
                    try {
                        await onConfirmButtonClickAsync();
                    } catch (e) {
                        throw e;
                    } finally {
                        if (isCloseOnConfirm) {
                            onClose();
                        }
                    }
                })
            }
            onCancelButtonClick={() => {
                onCancelButtonClick?.();
                onClose();
            }}
            {...props}
        />
    );
};

export default Modal;
