import { Title1AProps } from '..';
import AbstractedText from '@components/abstracted-text';

const Title1AD: React.FC<Title1AProps> = (props) => {
    return (
        <AbstractedText fontSize="18px" weight="bold" lineHeight="24px" {...props}>
            {props.children}
        </AbstractedText>
    );
};

export default Title1AD;
