import { useDisclosure } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useDesignComponents } from '@hooks/use-design-components';
import { useHarmony } from '@hooks/use-harmony';
import InnerHtml from '@designs/primitives/common/inner-html';
import Toast from '@legacy/designs/toast';
import { Design } from '@type/harmony-config';
import ThemeUtils from '@utils/theme.utils';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import CustomIconButton from 'src/legacy/components/common/custom-icon-button';
import Portal from 'src/legacy/components/common/portal';
import { useIsDesktop } from './use-is-desktop';
import { useExtension } from './use-extension';

type ToastOptions = {
    // title: string;
    /**
     * Toast color
     * @default: primary color
     */
    color?: string;
    description: string;
    /**
     * Time in milliseconds
     * @default 3000
     */
    duration?: number;
    /**
     * @default true
     */
    isClosable?: boolean;
};
type Toast = (options: ToastOptions) => void;

type ToastContextType = {
    showToast: Toast;
};

export const ToastContext = createContext<ToastContextType>({} as ToastContextType);

interface ToastProviderProps {
    children: ReactNode;
}

const DEFAULT_DURATION = 5000;

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
    const [toast, setToast] = useState<ToastOptions | undefined>(undefined);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [render, setRender] = useState<boolean>(false);
    const { theme } = useHarmony();
    const { extensionStatus } = useExtension();

    const isDesktop = useIsDesktop();

    const bottomPosition = useDesignComponents<string>({
        [Design.DesignA]: '40px',
        [Design.DesignB]: '100px',
        [Design.DesignC]: '24px',
    });

    const showToast: Toast = (options) => {
        const toast: ToastOptions = {
            ...options,
            color: options.color || theme.black,
            duration: options.duration || DEFAULT_DURATION,
            isClosable: options.isClosable !== undefined ? options.isClosable : true,
        };
        setToast(toast);
        setRender(true);
        onOpen();
    };

    useEffect(() => {
        if (!isOpen) {
            setTimeout(() => {
                setRender(false);
            }, 180);
        } else {
            setTimeout(
                () => {
                    onClose();
                },
                toast?.duration || DEFAULT_DURATION
            );
        }
    }, [isOpen]);

    return (
        <ToastContext.Provider value={{ showToast }}>
            {children}
            {render && toast && (
                <Portal portalId="toast-portal">
                    <VisibilityContainer isOpen={isOpen} bottomPosition={bottomPosition} isDesktop={isDesktop} maxScreenWidth={extensionStatus?.maxScreenWidth}>
                        <Toast color={toast.color}>
                            <StyledInnerHtml content={toast.description} />
                            {toast.isClosable && (
                                <CustomIconButton color={theme.gray[200]} size="20px" padding="2px" onClick={onClose}>
                                    close
                                </CustomIconButton>
                            )}
                        </Toast>
                    </VisibilityContainer>
                </Portal>
            )}
        </ToastContext.Provider>
    );
};

export const useToast = (): ToastContextType => useContext(ToastContext);

const VisibilityContainer = styled.div<{ isOpen: boolean; bottomPosition: string; isDesktop: boolean; maxScreenWidth?: number }>`
    ${({ isOpen, bottomPosition, isDesktop, maxScreenWidth }) => `        
        position: fixed;
        margin: 0 auto;
        padding: 0 16px;
        left: 0;
        right: 0;
        max-width: min(100vw, ${maxScreenWidth}px);
        bottom: ${bottomPosition};
        transition: all 150ms ease-in;
        will-change: transform, opacity;
        z-index: ${ThemeUtils.zIndex.toast};
        pointer-events: auto;

        ${
            isOpen
                ? `
            transform: translateY(0px) ${isDesktop ? 'translateX(310px)' : ''};
            opacity: 1;
        `
                : `
            transform: translateY(35px) ${isDesktop ? 'translateX(310px)' : ''};
            opacity: 0;
        `
        }
    `}
`;

const StyledInnerHtml = styled(InnerHtml)`
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -webkit-box-pack: start;
    -webkit-box-align: start;
`;
