import RoutesEnum from '@routes';
import DomainUtils from '@utils/domain.utils';
import Extension from './extension';
import { ExtensionCodeEnum, IExtensionStatusDto } from './responseDto/extension-status.dto';

class ExtensionStatus {
    private _extensionMap: Record<ExtensionCodeEnum, Extension>;
    private _invalidPages: RoutesEnum[];

    constructor(installStatusDtoList: IExtensionStatusDto[]) {
        this._extensionMap = this._getExtensionMap(installStatusDtoList);
        this._invalidPages = this._getInvalidPages();
    }

    private _getExtensionMap(installStatusDtoList: IExtensionStatusDto[]) {
        const entries = installStatusDtoList.map<[ExtensionCodeEnum, Extension]>((dto) => {
            return [dto.extension.code, new Extension(dto)];
        });
        return Object.fromEntries(entries) as Record<ExtensionCodeEnum, Extension>;
    }

    private _getInvalidPages() {
        let invalidPages: RoutesEnum[] = [];
        Object.values(this._extensionMap).forEach(({ isEnabled, relatedPages }) => {
            if (!isEnabled) {
                invalidPages = invalidPages.concat(relatedPages);
            }
        });

        return invalidPages;
    }

    private _getExtension(code: ExtensionCodeEnum) {
        return this._extensionMap[code] || { isEnabled: false };
    }

    get wishlist() {
        return this._getExtension(ExtensionCodeEnum.Wishlist);
    }

    get review() {
        return this._getExtension(ExtensionCodeEnum.Review);
    }

    get userDeliveryFrequencyType() {
        return this._getExtension(ExtensionCodeEnum.UserDeliveryFrequencyType);
    }

    get singlePurchaseSubscription() {
        return this._getExtension(ExtensionCodeEnum.SinglePurchaseSubscription);
    }

    get productInquiry() {
        return this._getExtension(ExtensionCodeEnum.ProductInquiry);
    }

    get search() {
        return this._getExtension(ExtensionCodeEnum.Search);
    }

    get cart() {
        return this._getExtension(ExtensionCodeEnum.Cart);
    }

    get notification() {
        return this._getExtension(ExtensionCodeEnum.Notification);
    }

    get coupon() {
        return this._getExtension(ExtensionCodeEnum.Coupon);
    }

    get point() {
        if (DomainUtils.isToyplus) {
            return { isEnabled: true, relatedPages: [] };
        }

        return this._getExtension(ExtensionCodeEnum.ExternalPoint) || this._getExtension(ExtensionCodeEnum.InternalPoint);
    }

    get externalPoint() {
        return this._getExtension(ExtensionCodeEnum.ExternalPoint);
    }

    get internalPoint() {
        return this._getExtension(ExtensionCodeEnum.InternalPoint);
    }
    get tier() {
        if (DomainUtils.isToyplus) {
            return { isEnabled: true, relatedPages: [] };
        }

        return this._getExtension(ExtensionCodeEnum.ExternalTier) || this._getExtension(ExtensionCodeEnum.InternalTier);
    }

    get externalTier() {
        return this._getExtension(ExtensionCodeEnum.ExternalTier);
    }

    get internalTier() {
        return this._getExtension(ExtensionCodeEnum.InternalTier);
    }

    get shareWith() {
        return this._getExtension(ExtensionCodeEnum.ShareWith);
    }

    get idPwLogin() {
        return this._getExtension(ExtensionCodeEnum.IdPwLogin);
    }

    get googleAnalytics() {
        return this._getExtension(ExtensionCodeEnum.Ga);
    }

    get googleAnalyticsPlus() {
        return this._getExtension(ExtensionCodeEnum.GaPlus);
    }

    get acrossAdn() {
        return this._getExtension(ExtensionCodeEnum.AcrossAdn);
    }

    get gift() {
        return this._getExtension(ExtensionCodeEnum.Gift);
    }

    get offlineStore() {
        return this._getExtension(ExtensionCodeEnum.OfflineStore);
    }

    get gps() {
        return this._getExtension(ExtensionCodeEnum.Gps);
    }

    get popup() {
        return this._getExtension(ExtensionCodeEnum.Popup);
    }

    get kakaoLogin() {
        return this._getExtension(ExtensionCodeEnum.KakaoLogin);
    }

    get naverLogin() {
        return this._getExtension(ExtensionCodeEnum.NaverLogin);
    }

    get googleLogin() {
        return this._getExtension(ExtensionCodeEnum.GoogleLogin);
    }

    get desktop() {
        return this._getExtension(ExtensionCodeEnum.Desktop);
    }

    get noticeBoard() {
        return this._getExtension(ExtensionCodeEnum.NoticeBoard);
    }

    get inquiry() {
        return this._getExtension(ExtensionCodeEnum.Inquiry);
    }

    get maxScreenWidth(): number {
        return this.desktop.isEnabled ? 580 : 860;
    }

    isInvalidPage(route: RoutesEnum) {
        return this._invalidPages.includes(route);
    }
}

export default ExtensionStatus;
