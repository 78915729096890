import { Box, Center, Flex } from '@chakra-ui/react';
import CONFIG from '@config';
import DesktopSearchPanel from '@designs/grouped/main/desktop-search-panel';
import { useExtension } from '@hooks/use-extension';
import { useHarmony } from '@hooks/use-harmony';
import CustomImage from '@legacy/components/common/custom-image';
import { BreakpointsEnum } from '@type/theme';
import ThemeUtils from '@utils/theme.utils';
import { ReactNode } from 'react';

interface ResponsiveLayoutProps {
    children: ReactNode;
}

const ResponsiveLayout: React.FC<ResponsiveLayoutProps> = ({ children }) => {
    const { extensionStatus } = useExtension();
    const { designSettings } = useHarmony();

    return extensionStatus?.desktop.isEnabled ? (
        <>
            <Flex aria-label="responsive" pos="relative" w="min-content" maxW={BreakpointsEnum.Desktop} mx="auto" zIndex={ThemeUtils.zIndex.above}>
                <Box
                    as="aside"
                    aria-label="search"
                    w={`calc(${BreakpointsEnum.Desktop} - ${extensionStatus?.maxScreenWidth}px)`}
                    display={{
                        [BreakpointsEnum.Mobile]: 'none',
                        [BreakpointsEnum.Desktop]: 'block',
                    }}
                >
                    <Center pos="fixed" top="0" bottom="0" w="524px">
                        <Box pb="378px" w="100%">
                            <DesktopSearchPanel />
                        </Box>
                    </Center>
                </Box>
                <Box
                    as="section"
                    aria-label="marketplace"
                    w={`min(100vw, ${extensionStatus?.maxScreenWidth}px)`}
                    h="100%"
                    minH="0px"
                    bgColor={CONFIG.backgroundColor}
                    boxShadow="0px 0px 10px 0px rgba(0, 0, 0, 0.10)"
                    pos="relative"
                    sx={{ main: { minH: 'var(--chakra-vh)' } }}
                >
                    {children}
                </Box>
            </Flex>
            {designSettings?.desktopImageUrl && (
                <Box
                    aria-label="background"
                    display={{ [BreakpointsEnum.Mobile]: 'none', [BreakpointsEnum.Tablet]: 'block' }}
                    w="100%"
                    h="100%"
                    maxH="100vh"
                    maxW="100vw"
                    pos="fixed"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                >
                    <CustomImage src={designSettings.desktopImageUrl} alt={CONFIG.title} layout="fill" objectFit="cover" priority unoptimized />
                </Box>
            )}
        </>
    ) : (
        <>{children}</>
    );
};

export default ResponsiveLayout;
