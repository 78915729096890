import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { IThemeProps } from '@type/common';
import { InnerHtmlProps } from '..';
import { forwardRef } from '@chakra-ui/react';

const InnerHtmlC = forwardRef<InnerHtmlProps, 'div'>(({ content, textColor, ...props }, ref) => {
    const { theme } = useHarmony();

    return (
        <div ref={ref} {...props}>
            <StyledInnerHtml theme={theme} dangerouslySetInnerHTML={{ __html: content }} color={textColor} />
        </div>
    );
});

export default InnerHtmlC;

const StyledInnerHtml = styled.div<IThemeProps & { color?: string }>`
    font-family: ${({ theme }) => theme.font.primary};
    white-space: pre-wrap;
    word-break: break-all;
    line-height: 22px;
    font-size: 14px;

    p {
        color: ${({ color }) => color};
        line-height: 22px;
    }
    img {
        width: 100%;
        height: auto;
    }
    > * {
        margin: unset;
        padding: unset;
        font: unset;
        font-size: revert;
    }
    a {
        color: ${({ theme }) => theme.status.information};
        text-decoration: underline;
        :visited {
            color: mediumpurple;
        }
    }
`;
