import { IGoogleAnalyticsDto } from '@models/google-analytics/responseDto/google-analytics.dto';

// NOTE: (James) This set up is temporary as Backend and BO are not ready yet.
// Setting properties optional and return hard-coded data for testing purpose.
export class GoogleAnalytics {
    private readonly _isActive: boolean;
    private readonly _tagId: string;
    constructor(googleAnalyticsDto: IGoogleAnalyticsDto) {
        this._isActive = googleAnalyticsDto.isActive;
        this._tagId = googleAnalyticsDto.tagId;
    }

    get isActive(): boolean {
        return this._isActive;
    }

    get tagId(): string {
        return this._tagId;
    }
}
