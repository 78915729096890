import { GenderCodeEnum } from '@type/common';

const userInfo = {
    userInfo: {
        profile: '회원정보',
        profileEdit: '회원정보 수정',
        id: '아이디',
        idPlaceholder: '아이디를 입력해주세요',
        name: '이름',
        namePlaceholder: '이름을 입력해주세요',
        phone: '전화번호',
        phoneNumberPlaceholder: '전화번호를 입력해주세요',
        email: '이메일',
        emailPlaceholder: '이메일을 입력해주세요',
        correction: '수정',
        moreInfo: '추가정보',
        nickname: '닉네임',
        nicknamePlaceholder: '닉네임을 입력해주세요',
        birthDate: '생년월일',
        birthDatePlaceholder: '생년월일을 선택해주세요',
        gender: '성별',
        update: '저장하기',
        mobilePhoneNumber: '휴대폰 번호',
        infoSaved: '성공적으로 수정되었습니다',
        cannotBeUsed: '사용할 수 없는 닉네임입니다.',
        minChars: '두 글자 이상 입력해주세요.',
        updated: '프로필 정보가 수정되었습니다.',
        [GenderCodeEnum.M]: '남자',
        [GenderCodeEnum.F]: '여자',
        edit: '수정하기',
        save: '저장하기',
        pleaseEnterName: '이름을 설정해주세요',
        namePlaceholder2: '최대 10자까지 입력 가능합니다',
        pleaseEnterGender: '성별을 설정해주세요',
        pleaseEnterBirthDate: '생년월일을 설정해주세요',
        helloUser: '<style>{name}</style>님 안녕하세요',
    },
};

export default userInfo;
