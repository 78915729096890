import ChannelCacheKeys from '@api/channel/cache-keys';
import { ChannelClient } from '@api/channel/channel.client';
import OAuthTerms from '@models/oauth-terms';
import Term from '@models/term';
import { IAgreeTermDto } from '@models/term/requestDto/agree-term.dto';
import { ITermDto } from '@models/term/responseDto/term.dto';
import RoutesEnum from '@routes';
import { TermDisplayArea } from '@type/api';
import TermsUtils from '@utils/terms.utils';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';
import useSWR from 'swr';

export enum TermsAgreeType {
    Oauth = 'oauth',
    Normal = 'normal',
    Membership = 'membership',
}
export type UseTermsProps = {
    termsAgreeType: TermsAgreeType;
};

export enum TermsType {
    PasswordChange = 'password-change',
}

export const useTerms = ({ termsAgreeType }: UseTermsProps) => {
    const router = useRouter();
    const [currentTerm, setCurrentTerm] = useState<Term | undefined>(undefined);
    const [agreeTerms, setAgreeTerms] = useState<Set<string>>(new Set());

    const externalUserId = router.query.external_user_id;
    const termsType = router.query.type as TermsType;
    const shouldFetchTerms = (termsAgreeType: TermsAgreeType) => {
        return termsAgreeType !== TermsAgreeType.Oauth;
    };

    const termDtos = useSWR<ITermDto[]>(
        () => (shouldFetchTerms(termsAgreeType) ? ChannelCacheKeys.termsCacheKey(TermDisplayArea.Register) : null),
        () => ChannelClient.getTerms(TermDisplayArea.Register)
    );
    const terms = useMemo<Term[]>(() => {
        if (termDtos && termDtos.data && shouldFetchTerms(termsAgreeType)) {
            return termDtos.data.map((term) => new Term(term));
        }

        const oauthTerms = OAuthTerms.getOauthTerms();
        if (oauthTerms && termsAgreeType === TermsAgreeType.Oauth) {
            return oauthTerms.terms;
        }
        return [];
    }, [termDtos]);

    const requiredTermsAgreed = useMemo<boolean>(() => {
        for (const term of terms || []) {
            if (term.isRequired && !agreeTerms.has(term.id)) {
                return false;
            }
        }
        return true;
    }, [agreeTerms, terms]);

    const getAgreedTerms = () => {
        const agreedTerms: IAgreeTermDto[] = [];
        terms?.forEach((term) => {
            if (agreeTerms.has(term.id)) {
                agreedTerms.push({
                    id: Number(term.id),
                    title: term.title || '',
                    isAgreed: true,
                    isRequired: term.isRequired,
                    enforcedDt: term.enforcedDate,
                    content: term.content,
                });
            }
        });
        return agreedTerms;
    };

    const saveAgreedTerms = () => {
        const agreedTerms = getAgreedTerms();
        TermsUtils.saveAgreedTerms(agreedTerms);
    };

    const navigateToSignupPage = () => {
        saveAgreedTerms();
        router.push({
            pathname: signupRoute[router.query.signup as TermsAgreeType],
            query: {
                external_user_id: externalUserId ?? undefined,
            },
        });
        return;
    };

    const navigateToPasswordChangePage = () => {
        saveAgreedTerms();
        router.push(RoutesEnum.PasswordChange);
    };

    const signupRoute: Record<TermsAgreeType, RoutesEnum> = {
        [TermsAgreeType.Oauth]: RoutesEnum.OauthSignup,
        [TermsAgreeType.Normal]: RoutesEnum.NormalSignup,
        [TermsAgreeType.Membership]: RoutesEnum.MembershipSignup,
    };

    return {
        requiredTermsAgreed,
        currentTerm,
        setCurrentTerm,
        setAgreeTerms,
        terms,
        agreeTerms,
        saveAgreedTerms,
        navigateToSignupPage,
        navigateToPasswordChangePage,
        termsType,
    };
};
