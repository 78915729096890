import { ActiveStatusEnum } from '@models/product/responseDto/component-product.dto';
import { ImageUrl, ProductId, SkuId } from '@type/models';
import ConversionUtils from '@utils/conversion.utils';
import { IGroupedProductDto } from './responseDto/grouped-product.dto';
import { IProductSellableSkuDto } from './responseDto/product-sellable-sku.dto';

class ProductSellableSku {
    private _activeStatus: ActiveStatusEnum;
    private _calculatedSkuDeliveryPrice: number;
    private _calculatedSkuSellPrice: number;
    private _isSelected: boolean;
    private _leftInventoryCount: number;
    private _leftInventoryStatus: string;
    private _options: string[];
    private _quantity: number;
    private _sellPrice: number;
    private _sellableProductId: ProductId;
    private _skuId: SkuId;
    private _title: string;

    private _productTitle: string;
    private _image: ImageUrl;
    private _pgSettingId: string | null;

    constructor(productSellableSkuDto: IProductSellableSkuDto, groupedProductDto: IGroupedProductDto) {
        this._activeStatus = productSellableSkuDto.activeStatus;
        this._calculatedSkuDeliveryPrice = productSellableSkuDto.calculatedSkuDeliveryPrice;
        this._calculatedSkuSellPrice = productSellableSkuDto.calculatedSkuSellPrice;
        this._isSelected = productSellableSkuDto.isSelected;
        this._leftInventoryCount = productSellableSkuDto.leftInventoryCount;
        this._leftInventoryStatus = productSellableSkuDto.leftInventoryStatus;
        this._options = productSellableSkuDto.options;
        this._quantity = productSellableSkuDto.quantity;
        this._sellPrice = productSellableSkuDto.sellPrice;
        this._sellableProductId = productSellableSkuDto.sellableProductId;
        this._skuId = productSellableSkuDto.skuId;
        this._title = productSellableSkuDto.title;

        this._productTitle = groupedProductDto.title;
        this._image = groupedProductDto.image;

        this._pgSettingId = groupedProductDto.pgSettingId;
    }

    get id(): SkuId {
        return this._skuId;
    }

    get title(): string {
        return this._title;
    }

    get sellPrice(): number {
        return this._sellPrice;
    }

    get sellPriceString(): string {
        return ConversionUtils.numberToString(this._sellPrice);
    }

    get quantity(): number {
        return this._quantity;
    }

    set quantity(quantity: number) {
        this._quantity = quantity;
    }

    get quantityString(): string {
        return ConversionUtils.numberToString(this._quantity);
    }

    get leftInventoryCount(): number {
        return this._leftInventoryCount;
    }

    get calculatedSkuSellPrice(): number {
        return Math.floor(this._calculatedSkuSellPrice);
    }

    get calculatedSkuSellPriceString(): string {
        return ConversionUtils.numberToString(Math.round(this._calculatedSkuSellPrice));
    }

    get calculatedSkuDeliveryPrice(): number {
        return this._calculatedSkuDeliveryPrice;
    }

    get calculatedSkuDeliveryPriceString(): string {
        return ConversionUtils.numberToString(Math.round(this._calculatedSkuDeliveryPrice));
    }

    get options(): string[] {
        return this._options;
    }

    get skuQuantityQuery(): string {
        return `${this._skuId}__${this._quantity}`;
    }

    get isSoldOut(): boolean {
        return this._leftInventoryStatus === 'sold_out' || this._leftInventoryCount < 1;
    }

    get isSelected(): boolean {
        return this._isSelected;
    }

    get sellableProductId(): ProductId {
        return this._sellableProductId;
    }

    get totalPrice(): number {
        return this._sellPrice * this._quantity;
    }

    get totalPriceString(): string {
        return ConversionUtils.numberToString(this.totalPrice);
    }

    get productTitle(): string {
        return this._productTitle;
    }

    get image(): ImageUrl {
        return this._image;
    }

    get isActive(): boolean {
        return this._activeStatus === ActiveStatusEnum.Active;
    }

    get pgSettingId(): string | null {
        return this._pgSettingId;
    }

    get logName(): string {
        return `${this.productTitle}${this.title ? `-${this.title}` : ''}`;
    }
}

export default ProductSellableSku;
