import styled from '@emotion/styled';
import * as PrimitiveTabs from '@radix-ui/react-tabs';

const StyledList = styled(PrimitiveTabs.List)`
    display: flex;
    gap: 0.125rem;
`;

const StyledTrigger = styled(PrimitiveTabs.Trigger)`
    height: 2.875rem;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.75rem 2rem;
    gap: 0.625px;
    border-radius: 10px 10px 0px 0px;

    font-family: ${(props) => props.theme.font.default.regular};
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.375rem;
    color: ${(props) => props.theme.gray[600]};

    background-color: ${(props) => props.theme.gray[50]};

    &[data-state='active'] {
        color: ${(props) => props.theme.primary.default};

        background-color: ${(props) => props.theme.white};
    }
`;

/** Tabs component root */
export const Tabs = PrimitiveTabs.Tabs;

/** List of tab triggers at top of Tabs root */
export const TabsList = StyledList;

/** Tabs button at top edge of content */
export const TabsTrigger = StyledTrigger;

/** Tab content */
export const TabsContent = PrimitiveTabs.Content;
