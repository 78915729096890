import { useDesignComponents } from '@hooks/use-design-components';

import { Box } from '@chakra-ui/react';
import { Design } from '@type/harmony-config';
import { ReactNode } from 'react';

type IconVariant = 'outlined' | 'rounded' | 'sharp';
type IconWeight = '100' | '200' | '300' | '400' | '500' | '600' | '700';
type IconGrade = '-25' | '0' | '200';
type IconSize = 20 | 24 | 40 | 48 | (number & {});

type MaterialSymbolsProps = {
    weight?: IconWeight;
    grade?: IconGrade;
    size?: IconSize;
    fill?: boolean;
};

const getMaterialSymbolsCss = ({ fill, weight, grade, size }: MaterialSymbolsProps) => {
    return { 'font-variation-settings': `"FILL" ${fill ? 1 : 0}, "wght" ${weight}, "GRAD" ${grade}, "opsz" ${size}` };
};

export interface IconProps extends MaterialSymbolsProps {
    iconVariant?: IconVariant;
    color?: string;
    children: ReactNode;
}

/** A component for using Google Material Symbols.
 *  @link https://fonts.google.com/icons?icon.set=Material+Symbols
 */
const Icon: React.FC<IconProps> = ({ color = 'inherit', iconVariant = 'outlined', size, grade, weight, fill, children, ...props }) => {
    const sizeString = `${size}px`;

    const defaultProps: MaterialSymbolsProps = {
        size: 24,
        grade: '0',
        weight: '400',
        fill: false,
    };

    const designProps = useDesignComponents<MaterialSymbolsProps & Pick<IconProps, 'iconVariant'>>({
        [Design.DesignA]: {
            weight: '400',
            iconVariant: 'rounded',
            fill: false,
        },

        [Design.DesignD]: {
            weight: '400',
            iconVariant: 'rounded',
            fill: true,
        },
    });

    return (
        <Box
            className={`material-symbols-${designProps.iconVariant || iconVariant}`}
            pointerEvents="none"
            color={color}
            minW={sizeString}
            minH={sizeString}
            maxW={sizeString}
            maxH={sizeString}
            fontSize={sizeString}
            __css={getMaterialSymbolsCss({
                size: size ?? designProps.size ?? defaultProps.size,
                fill: fill ?? designProps.fill ?? defaultProps.fill,
                weight: weight ?? designProps.weight ?? defaultProps.weight,
                grade: grade ?? designProps.grade ?? defaultProps.grade,
            })}
            {...props}
        >
            {children}
        </Box>
    );
};

export default Icon;
