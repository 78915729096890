import RoutesEnum from '@routes';
import { DisplayAreaEnum, IAcrossAdnDto, IAcrossPanelParams } from './responseDto/across-adn.dto';

const areaToRoutesMap: Record<DisplayAreaEnum, RoutesEnum[]> = {
    [DisplayAreaEnum.Home]: [RoutesEnum.Home],
    [DisplayAreaEnum.Product]: [RoutesEnum.ProductDetail],
    [DisplayAreaEnum.Custom]: [RoutesEnum.Custom],
    [DisplayAreaEnum.Cart]: [RoutesEnum.Cart],
    [DisplayAreaEnum.Order]: [RoutesEnum.DirectOrder, RoutesEnum.CartOrder, RoutesEnum.GiftOrder, RoutesEnum.GuestOrder],
};

class PanelScripts {
    private _mainPanelParams: IAcrossPanelParams;
    private _closingPanelParams: IAcrossPanelParams;
    private _messagePanelParams: IAcrossPanelParams;
    private _productPanelParams: IAcrossPanelParams;
    private _closingPanelDisplayAreas: RoutesEnum[];
    private _messagePanelDisplayAreas: RoutesEnum[];

    constructor(dto: IAcrossAdnDto['scriptSetting']['panelScripts']) {
        this._mainPanelParams = this._extractScriptParams(dto.mainPanelScript);
        this._closingPanelParams = this._extractScriptParams(dto.closingPanelScript);
        this._messagePanelParams = this._extractScriptParams(dto.messagePanelScript);
        this._productPanelParams = this._extractScriptParams(dto.productPanelScript);
        this._closingPanelDisplayAreas = dto.closingPanelDisplayAreas.flatMap(this._convertDisplayAreaToRoutesEnums);
        this._messagePanelDisplayAreas = dto.messagePanelDisplayAreas.flatMap(this._convertDisplayAreaToRoutesEnums);
    }

    private _extractScriptParams(script: string): IAcrossPanelParams {
        const values: IAcrossPanelParams = { ui: null, ci: null, gi: null };
        const re = /ui:[\s\t\n]*['"`]([^'"`]+)['"`]|ci:[\s\t\n]*['"`]([^'"`]+)['"`]|gi:[\s\t\n]*['"`]([^'"`]+)['"`]/g;
        let match;

        while ((match = re.exec(script)) !== null) {
            if (match[1]) {
                values.ui = match[1];
            }

            if (match[2]) {
                values.ci = match[2];
            }

            if (match[3]) {
                values.gi = match[3];
            }
        }

        return values;
    }

    private _convertDisplayAreaToRoutesEnums(displayArea: DisplayAreaEnum): RoutesEnum[] {
        return areaToRoutesMap[displayArea];
    }

    get mainPanelValue() {
        return this._mainPanelParams;
    }

    get closingPanelValue() {
        return this._closingPanelParams;
    }

    get messagePanelValue() {
        return this._messagePanelParams;
    }

    get productPanelValue() {
        return this._productPanelParams;
    }

    get closingPanelDisplayAreas() {
        return this._closingPanelDisplayAreas;
    }

    get messagePanelDisplayAreas() {
        return this._messagePanelDisplayAreas;
    }
}

export default PanelScripts;
