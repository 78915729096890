/**
 * Text manipulation service class
 */
export default class TextUtils {
    /**
     * If the text exceeds the specified character limit, then the text will be
     * truncated and an ellipses will be added to the end.
     */
    static limitTextLength(text: string, limit: number): string {
        return text.length > limit ? `${text.slice(0, limit)}...` : text;
    }

    /**
     * Takes descriptive text for a page and returns the optimal description tag as specified by
     * search engine suggestions.
     */
    static optimalDescriptionTag(text: string): string {
        const maxLength = 155;
        if (text.length < maxLength) {
            return text;
        } else {
            return `${text.slice(0, maxLength)}...`;
        }
    }

    /**
     * Formats phone number currently to Korean phone format.
     *
     * NOTE: If more country phone formats need to be supported in the future,
     * consider updating this implementation with google's libphonenumber package.
     */
    static formatPhoneNumber(phone: string): string {
        return `${phone.slice(0, 3)}-${phone.slice(3, 7)}-${phone.slice(7, 11)}`;
    }

    /**
     * Format countdown time
     * To show time as count down, if the length of time is less than 0, it will add 0.
     * ex: 9:4:2 -> 09:04:02
     */
    static formatCountTime(time: number): string {
        return `${time}`.padStart(2, '0');
    }
}
