import RoutesEnum from '@routes';
import { Url } from '@type/common';
import Frame from './frame';
import { IFrameMenuItemDto } from './responseDto/frame.dto';

class FrameMenuItem extends Frame {
    private _name: string;
    private _isForPage: boolean;
    private _categoryName: string | null;
    private _categoryId: string | null;
    private _pageUrl: string | null;

    private _isHome: boolean;

    constructor(frameMenuItemDto: IFrameMenuItemDto, isHome: boolean) {
        super(frameMenuItemDto);
        this._name = frameMenuItemDto.name;
        this._isForPage = frameMenuItemDto.isForPage;
        this._categoryName = frameMenuItemDto.categoryName;
        this._categoryId = frameMenuItemDto.categoryId;
        this._pageUrl = frameMenuItemDto.pageUrl;

        this._isHome = isHome;
    }

    get label(): string {
        return this._name || this._categoryName || '';
    }

    get pageUrl(): Url {
        if (this._isHome) {
            return RoutesEnum.Home;
        }

        if (this._pageUrl) {
            return this._pageUrl;
        }

        if (this._categoryId) {
            return { pathname: RoutesEnum.CategoryDetail, query: { categoryId: this._categoryId } };
        }

        return '';
    }
}

export default FrameMenuItem;
