import Sku from '@models/sku';

export enum SortProductByEnum {
    SoldCount = '-sold_count',
    ViewCount = '-view_count',
    CreateDateDesc = '-create_dt',
    MinSellPrice = 'min_sell_price',
    MinSellPriceDesc = '-min_sell_price',
    Ratings = '-ratings',
}

export type PriceRange = {
    minSellPrice?: number;
    maxSellPrice?: number;
};

export type ProductFilter = PriceRange & {
    orderBy?: SortProductByEnum | string;
};

export type ReviewFilter = {
    age: string[];
    score: string[];
    gender: string[];
};

export type QuantityType = {
    count: number;
    sku: Sku;
};
