import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { useIsDesktop } from '@hooks/use-is-desktop';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import ThemeUtils from '@utils/theme.utils';
import React from 'react';
import { BaseProps } from '../types';
import { useExtension } from '@hooks/use-extension';
import { ScreenSizeProps } from '@type/common';

type OverlayStyleProps = {
    /**
     * Max width to be applied to the drawer and the overlay
     */
    maxWidth?: string;
    isDesktop?: boolean;
};

export type DrawerProps = BaseProps &
    OverlayStyleProps & {
        /**
         * Side from which the drawer will appear.
         * @default 'right'
         */
        anchor?: 'left' | 'top' | 'right' | 'bottom';
        /**
         * The content of the component.
         */
        children?: React.ReactNode;
        /**
         * Callback fired when the component requests to be closed.
         *
         * @param {object} event The event source of the callback.
         */
        onClose?: () => void;
        /**
         * If `true`, the component is shown.
         * @default false
         */
        open?: boolean;
        isDesktop?: boolean;
    };

const overlayShow = keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
});

const getContentShow = (anchor: string | undefined, xPos?: string) => {
    if (anchor === 'left') {
        return keyframes({
            '0%': { transform: 'translateX(-100%)' },
            '100%': { transform: 'translate(0%)' },
        });
    } else if (anchor === 'right') {
        return keyframes({
            '0%': { transform: 'translateX(100%)' },
            '100%': { transform: 'translate(0%)' },
        });
    } else if (anchor === 'top') {
        return keyframes({
            '0%': { transform: 'translateY(-100%)' },
            '100%': { transform: 'translate(0%)' },
        });
    } else if (anchor === 'bottom') {
        return keyframes({
            '0%': { transform: `translate(${xPos}, 100%)` },
            '100%': { transform: `translate(${xPos},0%)` },
        });
    }
};

const StyledOverlay = styled(DialogPrimitive.Overlay)<OverlayStyleProps & ScreenSizeProps>`
    ${({ maxScreenWidth, maxWidth, isDesktop }) => `
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        width: ${`min(100vw, ${maxScreenWidth}px)`};
        top: 0px;
        bottom: 0px;
        left: calc(50% - (${`min(100vw, ${maxScreenWidth}px)`} * 0.5)) !important;
        transform: translateX(${isDesktop ? '310px' : ''});
        @media (prefers-reduced-motion: no-preference) {
            animation: ${overlayShow} 200ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
        }
        z-index: ${ThemeUtils.zIndex.drawer};
        max-width: ${maxWidth || 'none'};
    `}
`;

const StyledContent = styled(DialogPrimitive.Content)<DrawerProps>`
    background-color: white;
    box-shadow:
        hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
        hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    position: fixed;
    top: ${(props) => (props.anchor !== 'bottom' ? '0px' : 'auto')};
    bottom: ${(props) => (props.anchor !== 'top' ? '0px' : 'auto')};
    left: ${(props) => (props.anchor !== 'right' ? '0px' : 'auto')};
    right: ${(props) => (props.anchor !== 'left' ? '0px' : 'auto')};
    width: ${(props) => (props.anchor === 'top' || props.anchor === 'bottom' ? '100vw' : 'auto')};
    height: ${(props) => (props.anchor === 'right' || props.anchor === 'left' ? '100vh' : 'auto')};
    z-index: ${ThemeUtils.zIndex.drawer};

    @media (prefers-reduced-motion: no-preference) {
        animation: ${(props) => getContentShow(props.anchor, props.isDesktop ? '310px' : undefined)} 250ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
    }

    transition: all 0.15s ease-out 0s;
    &:focus {
        outline: none;
    }
`;

/**
 * Drawer that can slide in from any anchor point and is triggered by a boolean value.
 */
const Drawer: React.FC<DrawerProps> = ({ children, open, onClose, anchor = 'right', maxWidth, ...props }) => {
    const isDesktop = useIsDesktop();
    const { extensionStatus } = useExtension();

    return (
        <DialogPrimitive.Root open={open} onOpenChange={(open: boolean) => !open && onClose && onClose()}>
            <DialogPrimitive.Portal>
                <StyledOverlay maxWidth={maxWidth} isDesktop={isDesktop} maxScreenWidth={extensionStatus?.maxScreenWidth} />
                <StyledContent anchor={anchor} isDesktop={isDesktop} {...props}>
                    {children}
                </StyledContent>
            </DialogPrimitive.Portal>
        </DialogPrimitive.Root>
    );
};

export default Drawer;
