import { Box, VStack } from '@chakra-ui/react';
import BottomButtons from '@designs/grouped/common/bottom-buttons';
import DetailsBox from '@designs/grouped/common/complete-page/details-box';
import FailHeading from '@designs/grouped/common/complete-page/fail-heading';
import SuccessHeading from '@designs/grouped/common/complete-page/success-heading';
import FullPageLoader from '@designs/grouped/common/full-page-loader';
import MainButton from '@designs/primitives/common/button/main-button';
import { useHarmony } from '@hooks/use-harmony';
import BodyText from '@legacy/components/common/text/body-text';
import PopupHeader from '@legacy/designs/popup-header';
import ThemeUtils from '@utils/theme.utils';
import React from 'react';
import { CompletePageContentInnerProps } from '..';

const CompletePageContentA: React.FC<Required<CompletePageContentInnerProps>> = ({
    variant,
    pageTitle,
    heading,
    description,
    details,
    buttons,
    isLoading,
    additionalContent,
    showLogo,
}) => {
    const { theme } = useHarmony();

    return (
        <>
            <PopupHeader title={showLogo ? '' : pageTitle} showBack={false} showHome={!showLogo} showLogo={showLogo} />
            {isLoading ? (
                <FullPageLoader />
            ) : (
                <>
                    <Box w="100%" minH={`calc(var(--chakra-vh) - (${ThemeUtils.headerHeight}px + ${ThemeUtils.bottomBarHeight}px))`} pt="44px">
                        <VStack spacing="40px" w="100%" px="16px" align="stretch">
                            <VStack spacing="4px">
                                {variant === 'success' && <SuccessHeading>{heading}</SuccessHeading>}
                                {variant === 'error' && <FailHeading>{heading}</FailHeading>}
                                {description && (
                                    <BodyText size="16px" lineHeight="26px" textAlign="center" whiteSpace="pre-wrap">
                                        {description}
                                    </BodyText>
                                )}
                            </VStack>
                            <VStack spacing="40px" align="stretch">
                                {additionalContent}
                                {details.map((details, index) => (
                                    <DetailsBox key={index} details={details} />
                                ))}
                            </VStack>
                        </VStack>
                    </Box>
                    <BottomButtons isFixed>
                        {buttons.map(({ children, ...props }, index) => (
                            <MainButton key={index} {...props}>
                                {children}
                            </MainButton>
                        ))}
                    </BottomButtons>
                </>
            )}
        </>
    );
};

export default CompletePageContentA;
