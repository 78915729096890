import { CouponUsageScopeEnum, DisplayedIssuanceStatusEnum } from '@models/coupon/responseDto/coupon.dto';

const coupon = {
    coupon: {
        couponBox: '쿠폰함',
        couponBox2: '보유 쿠폰',
        all: '전체',
        available: '사용가능',
        used: '사용완료',
        expiration: '기간만료',
        orderOfIssuance: '신규발급순',
        details: '쿠폰상세',
        couponNumber: '쿠폰번호',
        periodOfUse: '사용기간',
        howToUse: '사용방법',
        notice: '유의사항',
        copyCouponCode: '쿠폰번호 복사',
        couponList: '보유쿠폰',
        totalNumber: '{number}개의 쿠폰이 있습니다.',
        empty: '보유한 쿠폰이 없습니다.',
        apply: '사용하기',
        condition: '{amount}원 이상 구매 시 사용 가능',
        noCondition: '최소 주문 금액 제한 없이 사용가능',
        conditionShorten: '{amount}원 이상 구매 시',
        conditionShorten2: '{amount}원 이상 결제 시',
        expirationNearing: '기한임박',
        page: '장',
        expirationComing: '만료임박',
        discountAmountOrder: '할인순',
        latest: '최신순',
        hasCoupons: '개의 쿠폰이 있습니다.',
        noCoupon: '보유한 쿠폰이 없습니다.',
        noAvailableCoupon: '사용가능한 쿠폰이 없습니다.',
        noUsedCoupon: '사용완료한 쿠폰이 없습니다.',
        noExpirationCoupon: '기간만료된 쿠폰이 없습니다.',
        minPurchaseAmount: '최소 주문 금액: {amount}원',
        maxDiscountAmount: '최대 할인 금액: {amount}원',
        expirationDate: '유효기간 : {date}까지',
        expirationDateVariant: '~ {date}까지',
        expirationDateVariant2: '{date} 까지',
        allProduct: '모든 상품에 적용 가능',
        allProduct2: '모든 상품에 사용 가능한 쿠폰입니다.',
        allTangibleProduct: '모든 배송상품에 적용 가능',
        allTangibleProduct2: '모든 배송상품에 사용 가능한 쿠폰입니다.',
        description: '쿠폰 관련 안내 사항',
        forAllProducts: '모든 상품에 사용 가능합니다.',
        forAllTangibleProducts: '모든 배송상품에 사용 가능합니다.',
        onlyTheseProducts: '해당 쿠폰 적용 가능한 상품입니다.',
        availableProducts: '적용 가능 상품',
        usedCoupon: '사용쿠폰',
        couponCountUnit: '개',
        noRestoreCouponsWhenCancelOrRefund: '취소/환불 시 사용한 쿠폰은 복구되지않습니다.',
        availableOnTrx: '주문별로 사용 가능한 쿠폰입니다.',
        availableOnSku: '옵션별로 사용 가능한 쿠폰입니다',
        noAvailableCouponDiscountAmount1: '모든 상품에 포인트를 전액 사용하면',
        noAvailableCouponDiscountAmount2: '쿠폰을 사용할 수 없습니다.',
        total: '총',
        couponYouHave: '보유중인 쿠폰',
        couponCode: '쿠폰코드',
        couponCode2: '코드번호',
        registerCouponPlaceholder: '쿠폰코드를 입력해주세요',
        registerCoupon: '쿠폰등록',
        successfullyRegistered: '쿠폰이 정상적으로 발급되었습니다.',
        couponRegisterFailed: '쿠폰 발급에 실패하였습니다.',
        alreadyRegistered: '이미 동일한 쿠폰이 발급되었습니다.',
        incorrectCouponCode: '쿠폰코드가 올바르지 않습니다.',
        expiredCouponCode: '만료된 쿠폰입니다.',
        downloadCoupon: '쿠폰 받기',
        downloadAll: '쿠폰 모두 받기',
        issued: '발급완료',
        couponIssued: '쿠폰 발급 완료',
        couponExpiryDate: '유효기간 {date}까지',
        couponExpiryDate2: '~ {date}까지',
        couponExpiryDate3: '발급일로부터 {day}일 이내 사용 가능',
        availableCoupon: '사용가능한 쿠폰이 있어요!',
        [DisplayedIssuanceStatusEnum.OutOfStock]: '쿠폰소진',
        [CouponUsageScopeEnum.Online]: '온라인 전용',
        [CouponUsageScopeEnum.Offline]: '오프라인 전용',
        [CouponUsageScopeEnum.Integrated]: '오프라인 사용가능',
        offlineCouponDescription: '오프라인 결제 시에만 적용이 가능합니다.',
        integratedCouponDescription: '오프라인에서도 사용 가능합니다.',
    },
};

export default coupon;
