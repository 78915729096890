import { QuestionTypeEnum } from '@models/inquiry-collection/requestDto/create-inquiry.dto';

const inquiryCategory = {
    inquiryCategory: {
        pleaseChooseType: '문의 유형 선택',
        pleaseChooseType2: '문의 유형을 선택해주세요.',
        return: '교환',
        refund: '환불',
        cancel: '취소',
        delivery: '배송',
        afterService: 'A/S',
        orderPayment: '주문/결제',
        membership: '회원관련/탈퇴',
        other: '기타 문의',
        report: '신고',
        operationFault: '기능/작동 오류',
    },
    inquiryType: {
        [QuestionTypeEnum.Placeholder]: '문의 유형 선택',
        [QuestionTypeEnum.Return]: '교환',
        [QuestionTypeEnum.Refund]: '환불',
        [QuestionTypeEnum.Cancel]: '취소',
        [QuestionTypeEnum.Delivery]: '배송',
        [QuestionTypeEnum.AfterService]: 'A/S',
        [QuestionTypeEnum.OrderPayment]: '주문/결제',
        [QuestionTypeEnum.Membership]: '회원관련/탈퇴',
        [QuestionTypeEnum.Other]: '기타 문의',
        [QuestionTypeEnum.Report]: '신고',
        [QuestionTypeEnum.OperationFault]: '기능/작동 오류',
    },
};

export default inquiryCategory;
