import styled from '@emotion/styled';
import { InternalToastProps } from '..';

const ToastA = styled.div<InternalToastProps>`
    ${({ theme, getFontSize, color }) => `
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px;
        height: 40px;
        background-color: ${color || theme.black};
        font-family: ${theme.font.default.bold};
        font-size: ${getFontSize ? getFontSize('14px') : '14px'};
        line-height: 1;
        color: ${theme.white};
    `}
`;

export default ToastA;
