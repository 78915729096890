import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import { IHarmonyTheme } from '@type/theme';

export const buttonTheme = (theme: IHarmonyTheme) =>
    defineStyleConfig({
        variants: {
            unstyled: defineStyle({
                fontWeight: 'unset',
                borderRadius: 'unset',
                height: 'auto',
                minWidth: 'auto',
                paddingInlineStart: 'unset',
                paddingInlineEnd: 'unset',
                _disabled: {
                    opacity: 1,
                },
            }),
            solid: defineStyle({
                fontWeight: 'unset',
                minWidth: 'auto',
                bgColor: theme.primary.default,
                _hover: {
                    bgColor: theme.primary.darken[100],
                    _disabled: {
                        bgColor: theme.gray[400],
                    },
                },
                _active: { bgColor: theme.primary.darken[200] },
                _disabled: {
                    pointerEvents: 'none',
                    opacity: 1,
                    color: theme.text.white,
                    bgColor: theme.gray[400],
                },
                _loading: {
                    bgColor: theme.primary.default,
                },
                '@media (pointer:coarse)': {
                    _hover: { bgColor: theme.primary.default },
                },
            }),
            'solid-gray': defineStyle({
                fontWeight: 'unset',
                minWidth: 'auto',
                paddingInlineStart: 'unset',
                paddingInlineEnd: 'unset',
                bgColor: theme.background.white,
                _hover: { bgColor: theme.gray[50] },
                _active: { bgColor: theme.gray[100] },
                _disabled: {
                    pointerEvents: 'none',
                    opacity: 1,
                },
                '@media (pointer:coarse)': {
                    _hover: { bgColor: theme.background.white },
                },
            }),
            outline: defineStyle({
                fontWeight: 'unset',
                minWidth: 'auto',
                bgColor: theme.background.white,
                _hover: { bgColor: theme.primary.lighten[300] },
                _active: { bgColor: theme.primary.lighten[200] },
                _disabled: {
                    pointerEvents: 'none',
                    opacity: 1,
                    color: theme.text.disabled,
                    borderColor: theme.gray[400],
                },
                _loading: {
                    bgColor: theme.background.white,
                },
                '@media (pointer:coarse)': {
                    _hover: { bgColor: theme.background.white },
                },
            }),
            'outline-gray': defineStyle({
                fontWeight: 'unset',
                minWidth: 'auto',
                border: '1px solid',
                bgColor: theme.background.white,
                _hover: { bgColor: theme.gray[50] },
                _active: { bgColor: theme.gray[100] },
                _disabled: {
                    pointerEvents: 'none',
                    opacity: 1,
                },
                '@media (pointer:coarse)': {
                    _hover: { bgColor: theme.background.white },
                },
            }),
        },
    });
