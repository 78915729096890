import { CurrencyCodeEnum, LocaleEnum } from '@locales/types';
import { MarketDomain } from './markets';
import { ColorHex } from './models';

export type Fonts = 'apple' | 'poppins' | 'noto' | 'montserrat' | 'bmw' | 'pretendard' | 'kb';
export enum Design {
    // design template for BASIC (gyowon, shoppingeasy)
    DesignA = 'designA',

    // design template for SOFT (NH Pay)
    DesignB = 'designB',

    // design template for MODERN (BMW)
    DesignC = 'designC',

    // design template for POPOUT (KB)
    DesignD = 'designD',
}

export enum LoginType {
    // Marketplace has membership only login (no logout button)
    Membership = 'membership',
    // Marketplace has authorization token provided in the request headers (no logout button)
    AuthHeader = 'authHeader',
    // Marketplace has login functionality inside marketplace
    Login = 'login',
}

export enum MarketplaceTemplate {
    Tangible = 'tangible',
    Intangible = 'intangible',
}

export interface IHarmonyConfig {
    /**
     * Title of marketplace
     */
    title: string;

    /**
     * Marketplace description for head tag
     */
    description: string;

    /**
     * Open graph description for head tag
     */
    ogDescription?: string;

    /**
     * marketplace specific domain name
     */
    domain: MarketDomain;

    /**
     * Membership type
     */
    membership: string;

    /**
     * Member group name to be displayed around the mention of points
     */
    memberGroup: string;

    /**
     * Display name for point system used
     */
    pointSystem?: string;

    testEmail: string;
    testPassword: string;

    /**
     * Linking to another page as a dropdown from the header logo
     */
    linkedPage?: string;

    /**
     * Language options to be offered for the app
     * @example en, ko, etc.
     */
    locales: LocaleEnum[];

    /**
     * Default 2-4 letter language to be used for the app
     * @example en, ko, zh-HK etc.
     */
    defaultLocale: LocaleEnum;

    /**
     * @example KRW, HKD, CNY, etc.
     */
    defaultCurrency: CurrencyCodeEnum;

    /**
     * Background color to override default theming (hex color)
     * Standard: #ffffff
     */
    backgroundColor: ColorHex;

    /**
     * Secondary background color (hex color)
     * Standard: #F5F5F5
     */
    secondaryBackgroundColor: ColorHex;

    /**
     * Primary font to be used.
     */
    primaryFont: Fonts;

    /**
     * Secondary font to be used.
     */
    secondaryFont: Fonts;

    /**
     * Specifies the types of functionality that are included in the marketplace
     */
    marketplaceTemplate: MarketplaceTemplate[];

    /**
     * Specifies whether the marketplace has its own login or membership login
     */
    loginType: LoginType[];

    /**
     * Specifies whether the marketplace is a PWA
     */
    isPwa: boolean;

    /**
     * Design template to be used in market place.
     */
    designTemplate: Design;
}
