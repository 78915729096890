import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { useIsDesktop } from '@hooks/use-is-desktop';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import ThemeUtils from '@utils/theme.utils';
import { ReactNode } from 'react';
import BlockIcon from '../Icon';

export type ModalContentProps = {
    size?: 'mini' | 'sm' | 'md' | 'lg' | 'full';
    anchorTop?: string;
    children?: ReactNode;
    showCloseButton?: boolean;
    onInteractOutside?: (event: unknown) => void;
    isDesktop?: boolean;
};

const overlayShow = keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
});

const contentShow = keyframes({
    '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
    '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

const StyledOverlay = styled(DialogPrimitive.Overlay)({
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    position: 'fixed',
    inset: 0,
    '@media (prefers-reduced-motion: no-preference)': {
        animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards`,
    },
    zIndex: ThemeUtils.zIndex.drawer,
});

const getSize = (size: ModalContentProps['size']) => {
    switch (size) {
        case 'mini':
            return '30vw';
        case 'sm':
            return '50vw';
        case 'md':
            return '65vw';
        case 'lg':
            return '80vw';
        case 'full':
            return '100%';
        default:
            return 'initial';
    }
};

const StyledContent = styled(DialogPrimitive.Content)<ModalContentProps>`
    background-color: white;
    box-shadow:
        hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
        hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    position: fixed;
    top: ${(props) => props.anchorTop ?? '50%'};
    left: ${({ isDesktop }) => (isDesktop ? `calc(50% + 310px)` : '50%')};
    transform: translate(-50%, -50%);
    width: 90vw;
    overflow-y: auto;
    z-index: ${ThemeUtils.zIndex.drawer};
    max-width: ${(props) => props.size && getSize(props.size)};
    max-height: 85vh;

    &:focus {
        outline: none;
    }
`;

const CloseButtonContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    padding: 16px;

    button {
        cursor: pointer;
        background-color: inherit;
    }
`;

function NewModalContent({ children, showCloseButton, onInteractOutside, ...rest }: ModalContentProps) {
    const isDesktop = useIsDesktop();

    return (
        <DialogPrimitive.Portal>
            <StyledOverlay />
            <StyledContent onInteractOutside={onInteractOutside} isDesktop={isDesktop} {...rest}>
                {showCloseButton && onInteractOutside && (
                    <CloseButtonContainer>
                        <button onClick={onInteractOutside} role="button">
                            <BlockIcon size="30px">close</BlockIcon>
                        </button>
                    </CloseButtonContainer>
                )}
                {children}
            </StyledContent>
        </DialogPrimitive.Portal>
    );
}

export default NewModalContent;
