import CacheKeys from '@api/cache-keys';
import { IQueryCouponDto } from '@models/coupon/requestDto/query-coupon.dto';
import { IGetOfflineStoresDto } from '@models/offline-store/requestDto/get-offline-stores.dto';
import { TermDisplayArea } from '@type/api';
import EnvironmentUtils from '@utils/environment.utils';

abstract class ChannelCacheKeys extends CacheKeys {
    static termsCacheKey(displayArea: TermDisplayArea): string {
        return `/terms/${displayArea}`;
    }

    static inquiriesCacheKey(): string {
        return `/inquiries`;
    }

    static faqCategoriesCacheKey(): string {
        return `/faqs/categories`;
    }

    static pageCacheKey(path: string): string {
        return `/pages/${path}`;
    }

    static channelFrameCacheKey(): string {
        return `/channel-frame`;
    }

    static channelComponentsCacheKey(): string {
        return `/components`;
    }

    static inquiryCacheKey(inquiryId: string): string {
        return `/inquiries/${inquiryId}`;
    }

    static basicSettingCacheKey(): string {
        return `/basic-setting`;
    }

    static categoriesCacheKey(): string {
        return `/categories`;
    }

    static framesCacheKey(): string {
        return `/frames`;
    }

    static popularKeywordsCacheKey(): string {
        return `/popular-keywords`;
    }

    static productPageSettingsCacheKey(): string {
        return `/product-page-setting`;
    }

    static extensionInstallStatusCacheKey(): string {
        return `/extensions/install-status`;
    }

    static channelComponentProductsCacheKey(componentIndex: number): string {
        return `/component/${componentIndex}`;
    }

    static commentListCacheKey(eventId: string, currentPage: number): string {
        return `/events/${eventId}/participant-comments/${currentPage}`;
    }

    static couponsCacheKey(query: IQueryCouponDto): string {
        return `/coupon-app/coupons/${Object.entries(query).toString()}`;
    }

    static specificProductCouponsCacheKey(query: IQueryCouponDto): string {
        return `/product-coupons/${Object.entries(query).toString()}`;
    }

    static popupCacheKey(): string {
        return `/active-pop-up`;
    }

    static brandCacheKey(brandId: string): string {
        return `/brands/${brandId}`;
    }

    static getBrandCategories(): string {
        return `/brand-categories`;
    }

    static getBrandStatus(brandId: string): string {
        return `/brands/${brandId}/status`;
    }

    static getSellPriceUnitPolicy(): string {
        return `/channels/${EnvironmentUtils.CHANNEL_ID}/sell-price-unit-policy`;
    }

    static getGoogleAnalyticsTag(): string {
        return '/marketing-settings/google-analytics';
    }

    static getGoogleAnalyticsPlus(): string {
        return '/marketing-settings/google-analytics-plus';
    }

    static getAcrossAdn(): string {
        return '/marketing-settings/across-adn';
    }

    static getProductCoupons(productId: string): string {
        return `/products/${productId}/coupons`;
    }

    static getEventPageCoupons(eventPageId: string): string {
        return `/event-detail-page/${eventPageId}/coupons`;
    }

    static offlineStoresCacheKey(params: IGetOfflineStoresDto): string {
        return `/offline-stores?search=${JSON.stringify(params)}`;
    }

    static getOfflineStoreCacheKey(offlineStoreId: string): string {
        return `/offline-stores/${offlineStoreId}`;
    }

    static postOfflineStoreCacheKey(offlineStoreIds: string[]): string {
        return `/offline/stores/${JSON.stringify(offlineStoreIds)}`;
    }

    static getOfflineStoreSettingCacheKey(): string {
        return `/offline-store-setting`;
    }

    static getGiftSettingCacheKey(): string {
        return '/gift-setting';
    }

    static getGpsSettingCacheKey(): string {
        return '/gps-setting';
    }

    static getOfflineStoreMembership(customerId: string): string {
        return `/offline-store-membership/${customerId}`;
    }

    static getAllOfflineStores(): string {
        return '/offline-stores-all';
    }

    static getCheckParticipation(): string {
        return '/is-participated';
    }
}

export default ChannelCacheKeys;
