import { GenderCodeEnum, GenderEnum } from '@type/common';
import { ImageRatio } from '@type/models';

export default class ConversionUtils {
    /**
     * Converts array of objects to map with a common property as a key
     */
    static arrayToMap<T, K extends keyof T>(array: T[], key: K): Map<T[K], T> {
        return array.reduce((accum: Map<T[K], T>, item: T) => {
            if (!item[key]) return accum;
            accum.set(item[key], item);
            return accum;
        }, new Map<T[K], T>());
    }

    /**
     * Converts number to string with appropriate symbols.
     *
     * NOTE: Currently uses locale string which is based off the client's device.
     * This may need to be updated to format numbers based on the locale/currency instead.
     */
    static numberToString(number: number): string {
        if (typeof number !== 'number') {
            return '-';
        }

        return number.toLocaleString();
    }

    /**
     * Multiply this by the desired width of the image to get the ratio adjusted height
     */
    static imageRatioToHeightFactor(imageRatio?: ImageRatio): number {
        if (!imageRatio) return 1;
        return imageRatio[1] / imageRatio[0];
    }

    static genderToGenderCode(gender: GenderEnum): GenderCodeEnum {
        switch (gender) {
            case GenderEnum.Male:
                return GenderCodeEnum.M;
            case GenderEnum.Female:
                return GenderCodeEnum.F;
        }
    }

    static paginateItemList<T>(itemList: T[], itemsPerPage: number, page: number) {
        return itemList.slice((page - 1) * itemsPerPage, page * itemsPerPage);
    }
}
