import * as React from 'react';
import { SVGProps } from 'react';

const SvgDone = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
        <path d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15z" />
    </svg>
);

export default SvgDone;
