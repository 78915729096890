import { useDesignComponents } from '@hooks/use-design-components';
import { Design } from '@type/harmony-config';
import FontFaceObserver from 'fontfaceobserver';
import { useEffect, useState } from 'react';

enum FontEnum {
    MaterialIconsOutlined = 'Material Symbols Outlined',
    MaterialIconsRounded = 'Material Symbols Rounded',
}

/**
 * @returns boolean indicating whether or not the font has loaded
 */
function useFontLoader(): boolean {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const iconFont = useDesignComponents<string>({
        [Design.DesignA]: FontEnum.MaterialIconsOutlined,
        [Design.DesignB]: FontEnum.MaterialIconsRounded,
        [Design.DesignC]: FontEnum.MaterialIconsRounded,
    });

    useEffect(() => {
        const font = new FontFaceObserver(iconFont);
        font.load(null, 100000)
            .then(() => {
                setIsLoaded(true);
            })
            .catch((error) => {
                console.log('Error loading the font:', error);
            });
    }, [iconFont]);

    return isLoaded;
}

export default useFontLoader;
