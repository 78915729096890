import Logo from '@designs/primitives/main/header/logo';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { useReferrer } from '@hooks/use-referrer';
import ShoppingCartButton from '@legacy/designs/shopping-cart-button';
import { IThemeProps } from '@type/common';
import ThemeUtils from '@utils/theme.utils';
import Link from 'next/link';
import { BlockHStack } from 'src/legacy/components/blockui';
import CustomIconButton from 'src/legacy/components/common/custom-icon-button';
import BodyText from 'src/legacy/components/common/text/body-text';
import RoutesEnum from 'src/routes/routes';
import { PopupHeaderProps } from '..';

const PopupHeaderC: React.FC<PopupHeaderProps> = ({ logoOnDirect, title, showNotifications, showCart, showSearch, showHome, showBack, onBack, className }) => {
    const { theme } = useHarmony();
    const { canGoBack } = useReferrer();

    return (
        <Bar
            theme={theme}
            css={css`
                position: sticky;
                top: 0px;
                left: 0px;
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                height: 54px;
                padding: 0px 16px;
                background-color: white;
                .title_wrap {
                    flex: 1 1 0;
                    min-width: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            `}
            className={className}
        >
            {!canGoBack && logoOnDirect && <Logo />}
            {showBack && (canGoBack || !logoOnDirect) && (
                <CustomIconButton color={theme.gray[800]} size="24px" padding="0" onClick={onBack}>
                    chevron_left
                </CustomIconButton>
            )}
            <div className="title_wrap">
                {title && (
                    <BodyText as="h1" size="18px" weight="bold" font="default">
                        {title}
                    </BodyText>
                )}
            </div>
            <BlockHStack alignItems="center" spacing="sm">
                {showNotifications && (
                    <Link href={RoutesEnum.Notification}>
                        <CustomIconButton size="24px" padding="0">
                            notifications
                        </CustomIconButton>
                    </Link>
                )}
                {showSearch && (
                    <Link href={RoutesEnum.Search}>
                        <CustomIconButton size="24px" padding="0">
                            search
                        </CustomIconButton>
                    </Link>
                )}
                {showCart && <ShoppingCartButton padding="0" />}
                {showHome && (
                    <Link href={RoutesEnum.Home}>
                        <CustomIconButton size="24px" padding="0">
                            home
                        </CustomIconButton>
                    </Link>
                )}
            </BlockHStack>
        </Bar>
    );
};

export default PopupHeaderC;

const Bar = styled.header<IThemeProps>`
    position: sticky;
    top: 0px;
    left: 0px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    padding: 0px 16px;
    z-index: ${ThemeUtils.zIndex.header};
    background-color: white;
    border-bottom: ${({ theme }) => `1px solid ${theme.gray['50']}`};
`;
