import { VStack } from '@chakra-ui/react';
import Body2B from '@designs/primitives/typography/body-2-b';
import Body2C from '@designs/primitives/typography/body-2-c';
import { useHarmony } from '@hooks/use-harmony';
import { DetailItemProps } from '..';

const DetailItemD: React.FC<DetailItemProps> = ({ label, value }) => {
    const { theme } = useHarmony();

    return (
        <VStack spacing="4px" w="100%">
            <Body2C color={theme.text.title1} textAlign="center">
                {label}
            </Body2C>
            <Body2B color={theme.text.body1} textAlign="center">
                {value}
            </Body2B>
        </VStack>
    );
};

export default DetailItemD;
