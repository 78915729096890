import GroupedProduct from '@models/grouped-product';
import { IDeliveryGroupedProductDto, IDeliveryGroupedProductInfoDto } from './responseDto/delivery-grouped-product.dto';
import ConversionUtils from '@utils/conversion.utils';

class DeliveryGroupedProduct {
    private _groupInfo: IDeliveryGroupedProductInfoDto;
    private _products: GroupedProduct[];

    constructor(deliveryGroupedDto: IDeliveryGroupedProductDto) {
        this._groupInfo = deliveryGroupedDto.groupInfo;
        this._products = deliveryGroupedDto.products.map((product) => new GroupedProduct(product));
    }

    get calculatedGroupDeliveryFee(): number {
        return this._groupInfo.calculatedGroupDeliveryFee;
    }

    get calculatedGroupDeliveryFeeString(): string {
        return ConversionUtils.numberToString(this._groupInfo.calculatedGroupDeliveryFee);
    }

    get products(): GroupedProduct[] {
        return this._products;
    }
}

export default DeliveryGroupedProduct;
