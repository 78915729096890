import { AuthSetting } from '@models/auth-setting';
import { IdPwSetting } from '@models/auth-setting/responseDto/auth-setting.dto';
import { AuthTypeEnum } from '@models/auth-setting/responseDto/oauth.dto';
import Term from '@models/term';
import safeWindow from '@services/safe-window.service';
import { SignupForm } from '../../schema/signup-schema';
import { CustomerDto, CustomerDtoKey, OauthTermsDto } from './responseDto/oauth-terms.dto';

class OAuthTerms {
    private static _OAUTH_TERMS = 'oauth-terms';

    private _externalAuthId?: string;
    private _externalOauthType?: AuthTypeEnum;
    private _terms: Term[];
    private _customerData: CustomerDto;
    private _isEmailVerified: string;
    private _authSetting: AuthSetting;
    private _hasCustomerUsername: boolean;
    private _hasCustomerName: boolean;
    private _hasCustomerEmail: boolean;
    private _hasCustomerPhoneNumber: boolean;

    constructor(oauthCustomer: OauthTermsDto) {
        this._externalAuthId = oauthCustomer.externalAuthId;
        this._terms = oauthCustomer.terms?.map((term) => new Term(term)) || [];
        this._externalOauthType = oauthCustomer.externalOauthType;
        this._customerData = oauthCustomer.customerData;
        this._isEmailVerified = oauthCustomer.isEmailVerified;
        this._authSetting = new AuthSetting(oauthCustomer.authSetting);
        this._hasCustomerName = !!oauthCustomer.customerData.name;
        this._hasCustomerEmail = !!oauthCustomer.customerData.email;
        this._hasCustomerUsername = !!oauthCustomer.customerData.username;
        this._hasCustomerPhoneNumber = !!oauthCustomer.customerData.phoneNumber;
    }

    public static getOauthCustomerData = (allFieldsInUse: CustomerDtoKey[], form: SignupForm) => {
        Object.keys(form).forEach((key) => {
            if (!allFieldsInUse.includes(key as CustomerDtoKey)) {
                delete form[key as keyof typeof form];
            }
        });
        return form;
    };

    public static setOauthTerms = (dto: OauthTermsDto) => {
        if (!safeWindow) {
            return;
        }

        sessionStorage.setItem(this._OAUTH_TERMS, JSON.stringify(dto));
    };

    public static getOauthTerms = () => {
        if (!safeWindow || !sessionStorage.getItem(this._OAUTH_TERMS)) {
            return;
        }

        return new OAuthTerms(JSON.parse(`${sessionStorage.getItem(this._OAUTH_TERMS)}`) as OauthTermsDto);
    };

    public static removeOauthTerms = () => {
        if (!safeWindow) {
            return;
        }

        sessionStorage.removeItem(this._OAUTH_TERMS);
    };

    get isEmailVerificationRequired(): boolean {
        return this.authSetting.isEmailVerificationRequired;
    }

    get isUsingIdPwRegistrationProcess(): boolean {
        return this.authSetting.isUsingIdPwRegistrationProcess;
    }

    get idPwSetting(): IdPwSetting {
        return this.authSetting.idPwSetting;
    }

    get externalAuthId(): string | undefined {
        return this._externalAuthId;
    }

    get allFieldsInUse(): CustomerDtoKey[] {
        return this.authSetting.allFieldsInUse;
    }

    get externalOauthType(): AuthTypeEnum | undefined {
        return this._externalOauthType;
    }

    get terms(): Term[] {
        return this._terms;
    }

    get customerData(): CustomerDto {
        return this._customerData;
    }

    get authSetting(): AuthSetting {
        return this._authSetting;
    }
    get customerIdentifiers() {
        return this._authSetting.customerIdentifiers;
    }

    get requiredRegistrationFields() {
        return this.authSetting.requiredRegistrationFields;
    }

    get hasCustomerUsername(): boolean {
        return this._hasCustomerUsername;
    }

    get hasCustomerName(): boolean {
        return this._hasCustomerName;
    }

    get hasCustomerEmail(): boolean {
        return this._hasCustomerEmail;
    }

    get hasCustomerPhoneNumber(): boolean {
        return this._hasCustomerPhoneNumber;
    }
}

export default OAuthTerms;
