import { AuthErrorsEnum, ChannelErrorsEnum, TransactionErrorsEnum } from '@type/errors';

export const errors = {
    errors: {
        [TransactionErrorsEnum.NotFoundTransaction]: '일치하는 주문번호가 없습니다.',
        [TransactionErrorsEnum.GuestUserPhoneError]: '휴대폰번호가 일치하지 않습니다.',
        [TransactionErrorsEnum.GuestUserPasswordError]: '비밀번호가 일치하지 않습니다.',
        [TransactionErrorsEnum.ExternalPointErrorMustBeResolved]: '해당 주문의 포인트 환불이 정상적으로 처리되지 않았습니다.\n고객센터로 문의하시기 바립니다.',

        [AuthErrorsEnum.InvalidPassword]: '올바르지 않은 비밀번호입니다.',
        [AuthErrorsEnum.IdVerificationFailed]: '본인인증을 실패하였습니다. 다시 시도해주세요.',
        [AuthErrorsEnum.UserNotExist]: '입력하신 정보와 일치하는 회원정보를 찾을 수 없습니다. 소셜 회원가입의 경우 `ID 찾기`가 불가능합니다.',
        [AuthErrorsEnum.DuplicatedPassword]: '이전에 사용했던 비밀번호는\n사용할 수 없습니다.',
        [AuthErrorsEnum.PwResetRequired]: '임시 비밀번호를 발급받은 고객은\n먼저 안내 받은 비밀번호로 로그인해주세요.',

        [ChannelErrorsEnum.CouponDeprecated]: '운영상의 정책 변경으로 사용이\n불가능한 쿠폰입니다.\n고객센터로 문의해 주세요.',
        [ChannelErrorsEnum.LgeAppPushDisabled]: '앱 마케팅 푸시 수신 동의 후 참여 가능합니다.\n[메뉴 > 설정] 에서 마케팅/혜택 알림 받기를 켜주세요.',
        [ChannelErrorsEnum.CouponDownloaded]: '이미 발급받은 쿠폰입니다.\n쿠폰함을 확인해주세요.',
        [ChannelErrorsEnum.CouponOutOfStock]: '쿠폰이 모두 소진되었습니다.',
        [ChannelErrorsEnum.CouponExpired]: '유효기간이 만료되어\n쿠폰을 받을 수 없습니다.',
        [ChannelErrorsEnum.CouponIssuanceStopped]: '쿠폰 발급이 중단되어\n쿠폰을 받을 수 없습니다.',
    },
};
