import { ChannelId } from '@type/models';
import { ISellPricePolicyDto } from './responseDto/sell-price-policy.dto';
import { CurrencyCodeEnum } from '@locales/types';
import ConversionUtils from '@utils/conversion.utils';

class SellPriceUnitPolicy {
    private _channelId: ChannelId;
    private _sellPriceUnitType: string;
    private _currencyType: CurrencyCodeEnum;

    constructor(sellPricePolicyDto: ISellPricePolicyDto) {
        this._channelId = sellPricePolicyDto.channelId;
        this._sellPriceUnitType = sellPricePolicyDto.sellPriceUnitType;
        this._currencyType = sellPricePolicyDto.currencyType;
    }

    get sellPriceUnitType(): string {
        return this._sellPriceUnitType;
    }

    get currencyType(): CurrencyCodeEnum {
        return this._currencyType;
    }

    getPriceStringWithUnit(price: number | undefined): string {
        if (typeof price === 'undefined') return '';
        const unit = Number(this.sellPriceUnitType);
        return ConversionUtils.numberToString(Math.ceil(price / unit) * unit);
    }
}

export default SellPriceUnitPolicy;
