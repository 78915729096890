const store = {
    store: {
        storeInfo: '매장 안내',
        open: '영업 중',
        ready: '영업 준비 중',
        searchPlaceholder: '매장명을 입력해 주세요',
        allDay: '24시간 영업',
        businessHours: '영업시간',
        information: '안내사항',
        location: '매장위치',
        membership: '멤버십 정보',
        nearest: '가까운 순',
        barcodeInfo: '<style>{name}</style> 님의 멤버십 정보입니다.',
        barcodeInfo2: '{name}님의\n멤버십 정보입니다.',
        barcodeInfo3: '{name}님의 멤버십 정보입니다.',
        noStores: '매장을 찾지 못했습니다.\n더보기를 클릭하여 매장을 찾아보세요.',
        noAvailableStores: '이용 가능한 매장이 없습니다.',
        noSearchResults: '검색된 매장이 없습니다.\n검색 조건을 다시 확인해 주세요.',
    },
};

export default store;
