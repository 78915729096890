import { VStack } from '@chakra-ui/react';
import { useHarmony } from '@hooks/use-harmony';
import BodyText from '@legacy/components/common/text/body-text';
import FailureSymbol from '@legacy/designs/failure-symbol';
import { FailHeadingProps } from '..';

const FailHeadingA: React.FC<FailHeadingProps> = ({ children }) => {
    const { theme } = useHarmony();

    return (
        <VStack spacing="0px" w="100%">
            <FailureSymbol />
            <BodyText size="18px" weight="bold" lineHeight="26px" textAlign="center" whiteSpace="pre-wrap">
                {children}
            </BodyText>
        </VStack>
    );
};

export default FailHeadingA;
