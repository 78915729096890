import ApiService, { CustomHeaderEnum, HttpStatusCode, InterceptedErrorResponse } from '@api/api.service';
import { authClient } from '@api/auth/auth.client';
import { IAvailablePointsDto } from '@models/available-points/responseDto/available-points.dto';
import { OrderType } from '@models/base-order';
import { ICreateCartLineDto } from '@models/cart-line/requestDto/create-cart-line.dto';
import { IDeleteCartLineDto } from '@models/cart-line/requestDto/delete-cart-line.dto';
import { IUpdateCartLineDto } from '@models/cart-line/requestDto/update-cart-line.dto';
import { ICreateCartOrderDto } from '@models/cart/requestDto/create-cart.dto';
import { ICartDto } from '@models/cart/responseDto/cart.dto';
import { IGetOrderCouponsDto } from '@models/coupon/requestDto/get-order-coupons.dto';
import { ICouponDto } from '@models/coupon/responseDto/coupon.dto';
import { IDeliveryTrackingDto } from '@models/delivery-tracking/responseDto/delivery-tracking.dto';
import { IFinalCartCalculationsDto } from '@models/final-cart-calculations/responseDto/final-cart-calculations.dto';
import { IRefetchCalculationDto } from '@models/final-cart-calculations/responseDto/requestDto/refetch-calculation.dto';
import { IIntangibleOrderAggregateDto } from '@models/order-aggregate/responseDto/intangible-order-aggregate.dto';
import { IOrderAggregateDto } from '@models/order-aggregate/responseDto/order-aggregate.dto';
import { ITangibleOrderAggregateDto } from '@models/order-aggregate/responseDto/tangible-order-aggregate.dto';
import { IOrderStatusDto } from '@models/order-status/responseDto/order-status.dto';
import { PGTypeEnum } from '@models/payment-method/responseDto/payment-method.dto';
import { IMIDTypeDto } from '@models/pg-type/responseDto/mid-type.dto';
import { PGTypeDto } from '@models/pg-type/responseDto/pg-type.dto';
import { IPointHistoryDto } from '@models/point-history/responseDto/point-history.dto';
import { ICancelledSkuClaimDto } from '@models/sku-claim/responseDto/cancelled-sku-claim.dto';
import { ISkuClaimDto } from '@models/sku-claim/responseDto/sku-claim.dto';
import { IQuerySubscriptionDeliveriesDto } from '@models/subscription-deliveries/requestDto/query-subscription-deliveries.dto';
import { ISubscriptionDeliveriesDto } from '@models/subscription-deliveries/responseDto/subscription-deliveries.dto';
import { ICreateSubscriptionOrderDto } from '@models/subscription-order/requestDto/create-subscription-order.dto';
import { ISubscriptionOrderDto } from '@models/subscription-order/responseDto/subscription-order.dto';
import { IQuerySubscriptionDto } from '@models/subscription/requestDto/query-subscritption.dto';
import { ILeanSubscriptionDto } from '@models/subscription/responseDto/lean-subscription.dto';
import { ISubscriptionDto, ISubscriptionPaymentDetailsDto } from '@models/subscription/responseDto/subscription.dto';
import { ICreateTransactionDto } from '@models/transaction/requestDto/create-transaction.dto';
import { IBaseTransactionDto } from '@models/transaction/responseDto/base-transaction.dto';
import { IBaseTransactionInfoDto } from '@models/transaction/responseDto/transaction-info.dto';
import { ITransactionDto } from '@models/transaction/responseDto/transaction.dto';
import { ImageUploadContextEnum, ImageUploadPaths, PointHistoryQuery, SkuClaimUpdateContext, SkuClaimUpdateContextEnum, TransactionQuery, UpdatedCartLine } from '@type/api';
import { CouponId, PaginatedResults, ProductClassEnum, SkuId, SubscriptionId, TransactionId } from '@type/models';
import DomainUtils from '@utils/domain.utils';
import EnvironmentUtils from '@utils/environment.utils';
import GuestUtils from '@utils/guest.utils';
import { AxiosResponse } from 'axios';
import isMobile from 'is-mobile';
import Cookies from 'js-cookie';
import snakecaseKeys from 'snakecase-keys';
import TransactionCacheKeys from './cache-keys';

export enum CheckoutTypeEnum {
    Cart,
    DirectOrder,
    Subscription,
}

/**
 * Api client handling transaction related logic
 * @link https://api-transaction-dev.harmonycvm.com/docs#/
 */

class TransactionClient extends TransactionCacheKeys {
    private static readonly _TRANSACTION_API_PREFIX = '/api/v1/external/e/channels/';
    private static readonly _client = new ApiService(`https://api-transaction${EnvironmentUtils.API_ENV}.harmonycvm.com/`, authClient).registerErrorMiddleware(async (_, error) => {
        if (error.response?.status === HttpStatusCode.BadRequest && (error.response?.data as InterceptedErrorResponse).error.code === 'invalid_mid') {
            return error.response;
        }
    });

    private static _requiresMobilePayment(pgType: string): boolean {
        return isMobile() || pgType === PGTypeEnum.Kginicis;
    }

    /**
     * Get tangible order aggregate counts
     */
    static async getTangibleOrderAggregate(monthRange?: number, claim?: boolean): Promise<ITangibleOrderAggregateDto> {
        return this._client.get<ITangibleOrderAggregateDto>(`${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/skus/status/counts`, {
            params: {
                channel_id: EnvironmentUtils.CHANNEL_ID,
                month_range: monthRange,
                product_class: ProductClassEnum.Tangible,
                claim,
            },
        });
    }

    /**
     * Get intangible order aggregate counts
     */
    static async getIntangibleOrderAggregate(monthRange?: number, claim?: boolean): Promise<IIntangibleOrderAggregateDto> {
        return this._client.get<IIntangibleOrderAggregateDto>(`${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/skus/status/counts`, {
            params: {
                channel_id: EnvironmentUtils.CHANNEL_ID,
                month_range: monthRange,
                product_class: ProductClassEnum.GiftCard,
                claim,
            },
        });
    }

    /**
     * Get tangible order aggregate counts
     */
    static async getGiftOrderAggregate(): Promise<IOrderAggregateDto> {
        return this._client.get<IOrderAggregateDto>(`${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/skus/status/counts`, {
            params: {
                channel_id: EnvironmentUtils.CHANNEL_ID,
                product_class: ProductClassEnum.Tangible,
                is_gift_order: true,
            },
        });
    }

    static async getTransactions(query: TransactionQuery): Promise<PaginatedResults<IBaseTransactionDto>> {
        return this._client.get<PaginatedResults<IBaseTransactionDto>>(`${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/transactions`, {
            params: {
                channel_id: EnvironmentUtils.CHANNEL_ID,
                month_range: query.monthRange,
                status_in: query.statuses?.length ? query.statuses.join(',') : undefined,
                product_class: query.productClass,
                ordering: query.ordering || '-paid_date',
                items_per_page: query.itemsPerPage || 10,
                page: query.page || 1,
                is_gift_order: query.isGiftOrder,
            },
        });
    }

    static async getTransaction(transactionId?: string): Promise<ITransactionDto> {
        if (GuestUtils.isGuest) {
            return this.getGuestTransaction();
        }

        return this.getTransactionById(transactionId!);
    }

    static async getGiftTransaction(transactionId: string) {
        return this._client.get<ITransactionDto>(`${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/gift/transactions/${transactionId}`);
    }

    private static async getTransactionById(transactionId: TransactionId): Promise<ITransactionDto> {
        return this._client.get<ITransactionDto>(`${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/transactions/${transactionId}`, {
            params: {
                transaction_identifier: transactionId,
            },
        });
    }

    private static async getGuestTransaction(): Promise<ITransactionDto> {
        return this._client.get<ITransactionDto>(`${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/guest/transactions/${GuestUtils.getGuestInfo()?.transactionSn}`, {
            headers: { ...GuestUtils.getGuestInfoHeaders() },
        });
    }

    static async getDeliveryTracking(skuId: SkuId): Promise<IDeliveryTrackingDto> {
        return this._client.get<IDeliveryTrackingDto>(`${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/skus/${skuId}/delivery-tracking`, {
            params: {
                sku_id: skuId,
            },
        });
    }

    static async postImages(uploadContext: ImageUploadContextEnum, file: File): Promise<ImageUploadPaths> {
        try {
            const formData = new FormData();
            formData.append('images', file);

            const response = await this._client.instance.post<ImageUploadPaths>(
                `${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/image_upload?upload_context=${uploadContext}`,
                formData,
                this._client.configWithAuth({
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async getSkuClaims(skuId: SkuId): Promise<ISkuClaimDto> {
        return this._client.get<ISkuClaimDto>(`${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}${GuestUtils.isGuest ? '/guest' : ''}/skus/${skuId}/claims`, {
            headers: { ...GuestUtils.getGuestInfoHeaders() },
        });
    }

    static async getCancelledSkuClaims(query: TransactionQuery): Promise<PaginatedResults<ICancelledSkuClaimDto>> {
        return this._client.get<PaginatedResults<ICancelledSkuClaimDto>>(`${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/skus/claims/refund-return-exchange`, {
            params: {
                channel_id: EnvironmentUtils.CHANNEL_ID,
                month_range: query.monthRange,
                product_class: query.productClass,
                ordering: query.ordering || '-update_dt',
                items_per_page: query.itemsPerPage || 10,
                page: query.page || 1,
            },
        });
    }

    static async patchSkuClaim(skuId: SkuId, updateContext: SkuClaimUpdateContext): Promise<void> {
        let data: Record<string, any> = {
            sku_id: skuId,
            update_context: updateContext.type,
        };

        switch (updateContext.type) {
            case SkuClaimUpdateContextEnum.RefundRequested:
                data = {
                    ...data,
                    user_notes: {
                        note: updateContext.refundReason,
                    },
                    image_urls: {
                        default: [],
                        mobile: updateContext.imagePaths,
                    },
                };
                break;
            case SkuClaimUpdateContextEnum.ExchangeRequested:
                data = {
                    ...data,
                    user_notes: {
                        note: updateContext.exchangeReason,
                        attribute_options: {
                            title: updateContext.selectedSkuTitle,
                            sellable_sku_id: updateContext.selectedSkuId,
                        },
                    },
                    image_urls: {
                        default: [],
                        mobile: updateContext.imagePaths,
                    },
                };
                break;
            case SkuClaimUpdateContextEnum.OrderFinished:
                break;
        }

        return this._client.patch<void>(
            `${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}${GuestUtils.isGuest ? '/guest' : ''}/skus/${skuId}/claims?update_context=${updateContext.type}`,
            data,
            {
                headers: { ...GuestUtils.getGuestInfoHeaders() },
            }
        );
    }

    /**
     * Cancel one sku of a transaction
     */
    static async patchSku(skuId: SkuId, note: string): Promise<AxiosResponse<void>> {
        return this._client.instance.patch<void>(
            `${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}${GuestUtils.isGuest ? '/guest' : ''}/transactions/skus/${skuId}`,
            {
                payment_method: 'Card',
                installment_plan: '일시불',
                user_notes: {
                    note,
                },
            },
            {
                ...this._client.configWithAuth(),
                headers: {
                    ...this._client.configWithAuth()?.headers,
                    ...GuestUtils.getGuestInfoHeaders(),
                },
            }
        );
    }

    /**
     * Cancel entire transaction
     */
    static async patchTransaction(transactionId: TransactionId, note: string): Promise<AxiosResponse<void>> {
        const guestInfoHeaders = GuestUtils.getGuestInfoHeaders();

        return this._client.instance.patch<void>(
            `${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}${GuestUtils.isGuest ? '/guest' : ''}/transactions/${transactionId}`,
            {
                payment_method: 'Card',
                installment_plan: '일시불',
                user_notes: {
                    note,
                },
            },
            {
                ...this._client.configWithAuth(),
                headers: {
                    ...this._client.configWithAuth()?.headers,
                    ...GuestUtils.getGuestInfoHeaders(),
                },
            }
        );
    }

    /**
     * Add items to cart
     */
    static async postCartLines(createCartLineDtos: ICreateCartLineDto[]): Promise<void> {
        return this._client.post<void>(`${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/cart/lines`, {
            lines: createCartLineDtos,
        });
    }

    static async patchCartLines(updateCartLineDto: IUpdateCartLineDto): Promise<UpdatedCartLine> {
        return this._client.patch<UpdatedCartLine>(`${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/cart/lines`, updateCartLineDto);
    }

    /**
     * Updated the selected status of multiple cart lines.
     */
    static async patchToggleSelectedCartLines(skuIds: SkuId[], checked: boolean): Promise<void> {
        try {
            await Promise.all(skuIds.map((skuId) => this.patchCartLines({ skuId, changeSelectStatus: checked })));
            return;
        } catch (error) {
            throw error;
        }
    }

    static async deleteCartLines(deleteCartLineDto: IDeleteCartLineDto): Promise<void> {
        return this._client.delete<void>(`${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/cart/lines`, {
            params: { delete: deleteCartLineDto.delete },
            data: {
                sku_id: deleteCartLineDto.skuId,
            },
        });
    }

    static async deleteMultipleCartLines(skuIds: SkuId[]): Promise<void> {
        try {
            const promises = skuIds.map((skuId) => this.deleteCartLines({ skuId }));
            await Promise.all(promises);
            return;
        } catch (error) {
            throw error;
        }
    }

    static async getCart(): Promise<ICartDto> {
        return this._client.get<ICartDto>(`${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/cart`, {
            params: {
                channel_id: EnvironmentUtils.CHANNEL_ID,
            },
        });
    }

    static async getSubscriptionOrder(subscriptionOrder: ICreateSubscriptionOrderDto): Promise<ISubscriptionOrderDto> {
        try {
            const order = await this._client.get<ISubscriptionOrderDto>(`${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/subscription/order`, {
                params: {
                    ...snakecaseKeys(subscriptionOrder),
                    skus: this._skuQuantitiesToQuery(subscriptionOrder.skus),
                },
            });

            return {
                ...order,
                type: OrderType.Subscription,
            };
        } catch (err) {
            throw err;
        }
    }

    /**
     *
     * @param isCart: Indicates whether or not this order is for the cart items.
     * @param callbackUrl: The url to return to upon completing the order.
     * @param createTransactionDto: Order details
     */
    static async postOrder(checkoutType: CheckoutTypeEnum, callbackUrl: string, createTransactionDto: ICreateTransactionDto): Promise<AxiosResponse<PGTypeDto>> {
        const config = this._client.configWithAuth();
        return this._client.instance.post<PGTypeDto>(
            `${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}${!!createTransactionDto.guestUserPassword ? '/guest' : ''}${
                this._requiresMobilePayment(createTransactionDto.paymentMethod.pgType) ? '/m' : ''
            }/transactions`,
            snakecaseKeys(createTransactionDto),
            {
                ...config,
                params: {
                    is_gift_order: createTransactionDto.isGiftOrder,
                    is_cart_order: checkoutType === CheckoutTypeEnum.Cart,
                    web_url: encodeURIComponent(callbackUrl),
                    product_class: createTransactionDto.productClass,
                    ...(checkoutType === CheckoutTypeEnum.Subscription && { sales_type: 'subscription' }),
                    ...((DomainUtils.isNhPay || DomainUtils.isNhPayNormal) && {
                        is_card_point_used_first: createTransactionDto.isCardPointUsedFirst,
                    }),
                    ...(Cookies.get('isInApp') && { is_in_app: Cookies.get('isInApp') }),
                },
                headers: {
                    ...config?.headers,
                    [CustomHeaderEnum.WithoutDeepCaseChange]: true,
                },
            }
        );
    }

    static async getCartOrder(postalCode?: string, transactionCouponId?: CouponId): Promise<ICartDto> {
        return this._client.get<ICartDto>(`${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/cart/order${this._cartOrderQuery(postalCode, transactionCouponId)}`);
    }

    /**
     * Get total cart price calculation
     */
    static async getDirectOrder(directOrder: ICreateCartOrderDto): Promise<ICartDto> {
        try {
            const cart = await this._client.get<ICartDto>(`${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/direct/order${this._directOrderQuery(directOrder)}`);
            return {
                ...cart,
                type: OrderType.Cart,
            };
        } catch (err) {
            throw err;
        }
    }

    /**
     * Get total points available
     */
    static async getAvailablePoints(): Promise<IAvailablePointsDto> {
        return this._client.get<IAvailablePointsDto>(`${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/users/point`);
    }

    /**
     * Get order status detail
     */
    static async getOrderStatusList(skuId: string): Promise<IOrderStatusDto[]> {
        return this._client.get<IOrderStatusDto[]>(`${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}${GuestUtils.isGuest ? '/guest' : ''}/skus/${skuId}/logs`, {
            headers: { ...GuestUtils.getGuestInfoHeaders() },
        });
    }

    /**
     * Get subscription history
     */
    static async getSubscriptionHistory(query: IQuerySubscriptionDto): Promise<PaginatedResults<ILeanSubscriptionDto>> {
        return this._client.get<PaginatedResults<ILeanSubscriptionDto>>(`${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/subscriptions`, {
            params: snakecaseKeys(query),
        });
    }

    /**
     * Get subscription by ID
     */
    static async getSubscription(subscriptionId: SubscriptionId): Promise<ISubscriptionDto> {
        return this._client.get<ISubscriptionDto>(`${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/subscriptions/${subscriptionId}`);
    }

    /**
     * Cancel subscription
     */
    static async patchSubscription(subscriptionId: SubscriptionId, note: string): Promise<void> {
        return this._client.patch<void>(`${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/subscriptions/${subscriptionId}`, {
            user_notes: {
                note,
            },
        });
    }

    /**
     * Get subscription next delivery and previous deliveries
     */
    static async getSubscriptionDeliveries(query: IQuerySubscriptionDeliveriesDto): Promise<ISubscriptionDeliveriesDto> {
        const { subscriptionSn, ...remainingQuery } = query;
        return this._client.get<ISubscriptionDeliveriesDto>(`${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/subscriptions/${subscriptionSn}/delivery-details`, {
            params: snakecaseKeys(remainingQuery),
        });
    }

    /**
     * Get subscription payment details by ID
     */
    static async getSubscriptionPaymentDetails(subscriptionId: SubscriptionId, monthRange?: number): Promise<ISubscriptionPaymentDetailsDto> {
        return this._client.get<ISubscriptionPaymentDetailsDto>(`${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/subscriptions/${subscriptionId}/payment-details`, {
            params: snakecaseKeys({ monthRange }),
        });
    }

    static async getMIDType(pgSettingId: string): Promise<IMIDTypeDto> {
        return this._client.get<IMIDTypeDto>(`${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/pg-setting/${pgSettingId}`);
    }

    static async getFirstDeliveryDates(productId: string): Promise<string[]> {
        return this._client.get<string[]>(`${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/subscription/first-delivery-dates`, {
            params: snakecaseKeys({ productId }),
        });
    }

    static async getPointHistory(query: PointHistoryQuery): Promise<PaginatedResults<IPointHistoryDto>> {
        return this._client.get<PaginatedResults<IPointHistoryDto>>(`${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/users/point/history`, {
            params: snakecaseKeys(query),
        });
    }

    static async patchDeliveryInfo(transactionId: string, data: IBaseTransactionInfoDto) {
        return this._client.patch(`${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/transactions/${transactionId}/delivery-info`, data);
    }

    static async cancelGift(transactionId: string) {
        return this._client.patch(`${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/transactions/${transactionId}/gift-cancel`, { userNotes: { note: '' } });
    }

    static async refetchCalculation({ isCartOrder, isGiftOrder, isSubscription, ...data }: IRefetchCalculationDto) {
        return this._client.post<IFinalCartCalculationsDto>(`${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/order/calculation`, data, {
            params: { is_cart_order: isCartOrder, is_gift_order: isGiftOrder, is_subscription: isSubscription },
        });
    }

    static async getOrderCoupons({ isSubscription = false, page = 1, itemsPerPage = 20, ...data }: IGetOrderCouponsDto) {
        return this._client.post<PaginatedResults<ICouponDto>>(`${this._TRANSACTION_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/order/coupons`, data, {
            params: snakecaseKeys({ isSubscription, page, itemsPerPage }),
        });
    }
}

export { TransactionClient };
