import { DeliveryStepsEnum } from '@models/delivery-tracking/responseDto/delivery-tracking.dto';

const orderHistory = {
    orderHistory: {
        orderHistory: '주문내역',
        all: '전체',
        deliveryProduct: '배송상품',
        viewOrderDetail: '주문상태 자세히 보기',
        orderHistoryDetail: '주문상태 내역',
        orderHistoryDescription: '주문상태 설명',
        ticket: '모바일상품권',
        month: '{number}개월',
        year: '{number}년',
        recent: '최근',
        recentMonth: '최근 {number}개월',
        recentYear: '최근 {number}년',
        noHistory: '주문내역이 없습니다',
        details: '자세히',
        waitPayment: '결제대기',
        completePayment: '결제완료',
        pendingShipment: '배송준비',
        inTransit: '배송중',
        deliveryCompleted: '배송완료',
        orderConfirmed: '구매확정',
        quantity: '수량: {quantity}',
        withdrawOrder: '주문취소',
        cancelConfirmBody: '선택한 상품을 주문취소 하시겠습니까?',
        confirmPurchaseQ: '구매확정 하시겠습니까?',
        afterConfirmPurchase: '구매확정하신 뒤에는',
        returnNotPossible: '반품/교환이 불가능합니다.',
        requestReturnQ: '해당 상품을 반품요청 하시겠습니까?',
        requestExchangeQ: '해당 상품을 교환요청 하시겠습니까?',
        deliveryTracking: '배송 조회',
        writeReview: '리뷰 작성',
        registerReview: '리뷰 등록하기',
        howWasTheProduct: '상품은 어떠셨나요?',
        selectStars: '별점을 선택해 주세요.',
        howWasTheProductVariant: '해당 상품은 어떠셨나요?',
        selectStarsVariant: '별점을 클릭해 후기를 남겨주세요!',
        leaveReview: '상품 리뷰를 작성해주세요.',
        leaveReviewVariant: '후기를 작성해주세요.',
        registerPhoto: '사진을 등록해 주세요.',
        registerPhotoUpToTen: '사진을 등록해주세요. (최대 10장)',
        upToTen: '최대 10개까지 등록 가능합니다.',
        attachPhotoWarning: '상품의 하자로 인해 반품하는 경우, 사진을 첨부하시면 빠른 처리가 가능합니다.',
        alreadyReviewed: '이미 리뷰를 작성한 상품입니다.',
        cannotUpload: '{size}MB이상 사진은 업로드 할 수 없습니다.',
        cancelOrder: '주문취소 요청',
        cancelOnlyPossible: '주문취소는 접수 전까지만 가능합니다.',
        cancelOnlyPossible2: '주문취소는 쿠폰사용 전까지만 가능합니다.',
        orderInformation: '주문정보',
        senderName: '주문자 명',
        senderPhone: '연락처',
        address: '배송지',
        paymentMethod: '결제수단',
        lumpSum: '일시불',
        quota: '{quota}개월 할부',
        enterReason: '사유입력(필수)',
        enterReason2: '사유 입력',
        exchangeOption: '교환 옵션',
        pleaseEnter: '취소 사유를 입력해 주세요.',
        continueShopping: '쇼핑 계속하기',
        checkOrderHistory: '주문내역 확인',
        orderCancellationComplete: '주문취소가 완료되었습니다.',
        refundMayVary: '결제수단에 따라 실제 환불 시점은 상이할수 있습니다.',
        businessDays: '(영업일 기준 1~3일 소요)',
        orderProductNum: '주문상품번호',
        productTitle: '상품명',
        cardInfo: '카드정보',
        confirmed: '구매확정 완료',
        cancelComplete: '주문취소 완료',
        purchaseConfirmed: '구매확정이 완료되었습니다!',
        purchaseConfirmComp: '구매확정 완료',
        writePurchaseReview: '리뷰 작성하기',
        returnRequest: '반품요청',
        returnMayBeRejected: '반품 요청은 사유에 따라 거절될 수 있습니다.',
        additionalShipping: '반품 시 배송비가 추가적으로 발생할 수 있습니다.',
        returnInformation: '반품정보',
        exchangeInformation: '교환정보',
        whereToSend: '보내실 곳',
        returnCost: '반품 배송비',
        exchangeCost: '교환 배송비',
        returnResponsible: '반품 배송비의 부담 주체는 반품접수 후에 확인할 수 있습니다.',
        exchangeResponsible: '고객 부담 교환 배송비 발생 시 판매자가 배송비 입금방법을 안내합니다.',
        imageRegistration: '이미지 등록 (최대 10개까지 등록 가능)',
        ifReturn: '*상품의 하자 또는 불량이 있는 부분의 사진을 첨부해 주시면 빠른 처리가 가능합니다.',
        completeReturn: '반품요청 완료',
        returnCompleted: '반품요청이 완료되었습니다.',
        returnCompleted2: '반품 진행사항은 취소/교환/반품내역에서\n확인해 주세요.',
        exchangeRequest: '교환요청',
        noExchangeOption: '교환가능한 동일금액 옵션이 없습니다. 고객센터에 문의해 주세요.',
        validateSkuSelectAlert: '교환옵션을 선택해 주세요.',
        validateCommentAlert: '사유를 입력해 주세요.',
        onlyExchange: '교환 요청 사유에 따라 거절될 수 있습니다.',
        onlyProducts: '주문한 상품과 같은 가격의 옵션으로 교환 가능합니다.',
        changeOptions: '옵션 변경',
        requiredExchangeOption: '교환옵션 (필수)',
        soldOut: '품절',
        remainingQuantity: '남은수량: {quantity}개',
        exchangeReqComplete: '교환요청 완료',
        exchangeCompleted: '교환요청이 완료되었습니다.',
        exchangeCompleted2: '교환 진행사항은 취소/교환/반품내역에서\n확인해 주세요.',
        exchangeProgress: '교환 진행상황은 주문 상세내역의',
        checkOrderStatus: '주문상태 자세히보기에서 확인해주세요.',
        orderDetails: '주문 상세내역',
        orderNumber: '주문번호: ',
        orderDescriptionOrder: '주문',
        paymentCompleted: '결제완료',
        paymentCompletedDesc: '주문하신 상품이 결제완료 되었습니다. 주문접수 전으로, 주문취소가 가능합니다.',
        ackCompleted: '접수완료',
        ackCompletedDesc: '공급업체가 주문을 확인하여 상품을 준비중입니다. 택배사에서 상품이 접수되기 전입니다.',
        fulfillCompleted: '출고완료',
        fulfillCompletedDesc: '상품이 출고지에서 택배사로 접수되었습니다.',
        delivering: '배송중',
        deliveringDesc: '상품이 택배사로 정상적으로 접수되어 배송지 주소로 배송중이며 배송조회가 가능합니다.',
        deliveryCompletedDesc: '상품이 배송지 주소로 도착했습니다.',
        purchaseConfirm: '구매확정',
        purchaseConfirmDesc:
            '상품을 정상적으로 수령하여 구매를 확정합니다. 직접 구매확정하지 않으셔도 결제완료일 기준으로 20일 후 자동으로 구매확정됩니다. 구매확정한 상품은 상품평을 작성할 수 있습니다. 구매확정 시 교환 및 반품 요청이 불가능합니다.',
        orderFailed: '주문실패',
        orderFailedDesc: '결제완료 후 주문이 접수되지 않았거나 주문 접수 후 공급업체 사정으로 출고 거절되었습니다. 이후 환불이 진행됩니다.',
        refundCompleted: '환불완료',
        refundCompletedDesc: '주문취소, 주문실패로 인해 주문금액이 환불됩니다. 실제 환불처리는 결제수단에 따라 다를 수 있습니다.',
        orderDescriptionExchange: '교환',
        exchangeRequested: '교환요청',
        exchangeRequestedDesc: '교환요청이 완료되었습니다. 판매채널과 공급업체가 요청을 접수합니다.',
        exchangeIng: '교환진행중',
        exchangeIngDesc:
            '교환요청이 승인되어 교환요청 상품을 수령합니다. 상품 수령 후 상품을 검수합니다. 검수 결과에 따라 교환 가능한 경우 새상품이 배송되고 교환 불가능한 경우 교환요청 상품이 재배송됩니다.',
        newProductFulfilled: '새상품 출고완료',
        newProductFulfilledDesc: '새상품이 출고지에서 택배사로 전달되었습니다.',
        newProductFulfillDelay: '새상품 출고지연',
        newProductFulfillDelayDesc: '새상품 출고가 지연되어 아직 상품을 준비 중입니다.',
        newProductDeliverying: '새상품 배송중',
        newProductDeliveryingDesc: '새상품이 택배사로 정상적으로 전달되어 배송지 주소로 배송중이며 배송조회가 가능합니다.',
        exchangeComplete: '교환완료',
        exchangeCompleteDesc: '새상품이 배송완료되어 교환이 완료되었습니다.',
        exchangeRejected: '교환거절',
        exchangeRejectedDesc:
            '교환요청이 접수되지 않았거나 검수가 부결되어 교환이 불가능합니다. 기존 상품을 전달한 경우나 새상품 출고를 거절한 경우 기존 상품이 재배송되고 배송조회가 가능합니다. ',
        exchangeFailed: '교환실패',
        exchangeFailedDesc: '교환요청하신 상품이 수령되지 않았습니다.',
        orderDescriptionRefund: '반품',
        refundRequest: '반품요청',
        refundRequestDesc: '반품요청이 완료되었습니다. 판매채널과 공급업체가 요청을 접수합니다.',
        refundProcessing: '반품진행중',
        refundProcessingDesc:
            '반품요청이 접수되어 반품요청 상품을 수령합니다. 상품 수령 후 상품을 검수합니다. 검수 결과에 따라 반품 가능한 경우 환불되고 반품 불가능한 경우 반품요청 상품이 재배송됩니다.',
        refundComplete: '환불완료',
        refundCompleteDesc: '반품이 완료되어 주문금액이 환불됩니다. 실제 환불처리는 결제수단에 따라 다를 수 있습니다.',
        productNotReceived: '반품미수령',
        productNotReceivedDesc: '반품요청하신 상품이 수령되지 않았습니다.',
        refundFailed: '반품실패',
        refundFailedDesc: '반품요청이 접수되지 않았거나 검수가 부결되어 반품이 불가능합니다. 검수가 부결되었다면 기존 상품이 재배송되며 배송조회가 가능합니다.',
        emptyStatus: '주문상태 내역이 없습니다',
        pleaseEnterReason: '사유를 입력해 주세요.',
        waybill: '운송장 번호',
        andNMore: '외 {number}개',
        noDeliveryDetails: '상품이 출고지에서 택배사로 이동 중입니다',
        available: '사용가능',
        used: '사용완료',
        expiration: '기간만료',
        toBeIssued: '발행예정',
        issued: '발행완료',
        cancelled: '취소요청',
        paymentDetails: '결제상세',
        refundAmount: '환불 결제금액',
        refundPoint: '환불 포인트',
        changeOrder: '모바일상품권의 상태가 갱신되었습니다',
        seeOrderDetail: '주문상태 자세히',
        problem: '문제가 있으신가요?',
        cancellingProducts: '취소상품',
        cannotCancel: '취소 불가능한 주문상태입니다. 고객센터에 문의해 주세요.',
        cannotCancelVBank: '가상계좌로 결제한 주문의 취소 및 환불 처리는\n고객센터로 요청 바랍니다.',
        [DeliveryStepsEnum.ProductAcquisition]: DeliveryStepsEnum.ProductAcquisition,
        [DeliveryStepsEnum.ProductIsMoving]: DeliveryStepsEnum.ProductIsMoving,
        [DeliveryStepsEnum.DeliveryArrival]: DeliveryStepsEnum.DeliveryArrival,
        [DeliveryStepsEnum.StartDelivery]: DeliveryStepsEnum.StartDelivery,
        [DeliveryStepsEnum.DeliveryCompleted]: DeliveryStepsEnum.DeliveryCompleted,
    },
};

export default orderHistory;
