import styled from '@emotion/styled';
import React, { ButtonHTMLAttributes } from 'react';
import BlockIcon, { IconProps } from '../Icon';
import BlockLoader from '../Loader';
import { BaseProps } from '../types';

export type IconButtonProps = BaseProps &
    IconProps &
    ButtonHTMLAttributes<HTMLButtonElement> & {
        /**
         * Indicates the icon size
         */
        size?: string;
        weight?: number;
        isLoading?: boolean;
        isDisabled?: boolean;
        padding?: string;
    };

/**
 * Icon button that uses material icons under the hood.
 * Pass the material icon key in as a child
 * Must include stylesheet tag for each icon variation used somewhere in the project. outlined variation example: <link href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined" rel="stylesheet" />
 * @link https://fonts.google.com/icons
 */
const IconButton: React.FC<IconButtonProps> = ({ testId, children, variant, size, weight, fill, color, isLoading, isDisabled, padding, ...props }) => {
    return (
        <StyledButton test-data-id={testId} size={size} disabled={isDisabled || isLoading} isLoading={isLoading} isDisabled={isDisabled || isLoading} padding={padding} {...props}>
            {isLoading && <BlockLoader size={size} />}
            {!isLoading && (
                <BlockIcon variant={variant} fill={fill} color={color} weight={weight}>
                    {children}
                </BlockIcon>
            )}
        </StyledButton>
    );
};

export default IconButton;

const StyledButton = styled('button')<Omit<IconButtonProps, 'children'>>`
    ${({ theme, size, isDisabled, padding }) => `
        background: none;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
        padding: ${padding || '6px'};
        span {
            font-size: ${size};
        }
        &:disabled {
            color: ${theme.gray[600]};
            background-color: ${theme.gray[50]};
            opacity: 20%;
        }
    `}
`;
