import DomainUtils from '@utils/domain.utils';

export default class EnvironmentUtils {
    private static readonly LOCAL_HOST = 'http://localhost:4200';

    private static _APP_ENV = process.env.NEXT_PUBLIC_APP_ENV;

    static API_ENV = process.env.NEXT_PUBLIC_ENVIRONMENT;
    static PUBLIC_URL = process.env.NEXT_PUBLIC_URL;
    static CHANNEL_ID = process.env.NEXT_PUBLIC_CHANNEL_ID;
    static KAKAO_KEY = process.env.NEXT_PUBLIC_KAKAO_KEY;
    static GOOGLE_ANALYTICS = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS;
    static GA_TRACKING = process.env.NEXT_PUBLIC_GA_TRACKING_ID;
    static ACROSS_ADN_ID = process.env.NEXT_PUBLIC_ACROSS_ADN_ID;
    static SENTRY_ENV = process.env.NEXT_PUBLIC_SENTRY_ENV;
    static NHPAY_NORMAL_MALL_URL = process.env.NEXT_PUBLIC_NORMAL_MALL_URL;
    static NHPAY_SUBSCRIPTION_MALL_URL = process.env.NEXT_PUBLIC_SUBSCRIPTION_MALL_URL;
    static DESIGN_NAME = process.env.NEXT_PUBLIC_DESIGN_NAME;
    static SENTRY_DSN = 'https://379c63584a89457783416f5e8e0dd14f@o94454.ingest.sentry.io/4504965007343616';
    static GOOGLE_MAP_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY;
    static NAVER_COMMON_KEY = process.env.NEXT_PUBLIC_NAVER_COMMON_KEY;
    static KAKAO_AD_KEY = process.env.NEXT_PUBLIC_KAKAO_AD_KEY;

    static get cookieDomain() {
        if (!this.isDevelopmentMode && (DomainUtils.isNhPay || DomainUtils.isNhPayNormal)) {
            return process.env.NEXT_PUBLIC_DOMAIN;
        }
    }

    static get publicUrl() {
        if (this.isDevelopmentMode) {
            return this.LOCAL_HOST;
        }
        return `https://${this.PUBLIC_URL}`;
    }

    static get isDev(): boolean {
        return this._APP_ENV === 'development';
    }

    static get isStaging(): boolean {
        return this._APP_ENV === 'staging';
    }

    static get isProduction(): boolean {
        return this._APP_ENV === 'production';
    }

    static get staticImageUrl(): string {
        return `https://harmony-static-dev.travelflan.com/`;
    }

    /**
     * This checks if environment is run by 'yarn dev' script. Please don't be confused with 'dev' environment.
     * reference: https://nextjs.org/docs/pages/building-your-application/configuring/environment-variables
     */
    static get isDevelopmentMode(): boolean {
        return process.env.NODE_ENV === 'development';
    }
}
