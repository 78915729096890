import Product from '@models/product';
import { ProductClassEnum } from '@type/models';
import { GiftAuthMethodEnum, GiftDeliveryFeeSetting, IGiftSettingDto } from './responseDto/gift-setting.dto';

class GiftSettings {
    private _id: string;
    private _applicableProductType: ProductClassEnum;
    private _authMethod: GiftAuthMethodEnum;
    private _deliveryFeeSetting: GiftDeliveryFeeSetting;
    private _addressRegDeadlineDays: number;

    constructor(dto: IGiftSettingDto) {
        this._id = dto.id;
        this._applicableProductType = dto.applicableProductType;
        this._authMethod = dto.authMethod;
        this._deliveryFeeSetting = dto.deliveryFeeSetting;
        this._addressRegDeadlineDays = dto.addressRegDeadlineDays;
    }

    isApplicable(product: Product) {
        return (
            (product.isTangible && this._applicableProductType === ProductClassEnum.Tangible) || (product.isGiftcard && this._applicableProductType === ProductClassEnum.GiftCard)
        );
    }
}

export default GiftSettings;
