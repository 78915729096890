import { VStack } from '@chakra-ui/react';
import Icon from '@designs/primitives/common/icon';
import Title1A from '@designs/primitives/typography/title-1-a';
import { useHarmony } from '@hooks/use-harmony';
import { FailHeadingProps } from '..';

const FailHeadingD: React.FC<FailHeadingProps> = ({ children }) => {
    const { theme } = useHarmony();

    return (
        <VStack spacing="16px" w="100%">
            <Icon size={40} color={theme.status.error}>
                close
            </Icon>
            <Title1A color={theme.text.title1} textAlign="center" whiteSpace="pre-wrap">
                {children}
            </Title1A>
        </VStack>
    );
};

export default FailHeadingD;
