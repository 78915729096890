import { MobileWebviewService } from '@services/mobile-webview.service';
import { IFrameFooterDto } from './responseDto/frame.dto';

class FrameFooter {
    private _footerCopyright: string;
    private _footerBusinessInfo: string;

    constructor(frameFooterDto: IFrameFooterDto) {
        this._footerCopyright = frameFooterDto.footerCopyright;
        this._footerBusinessInfo = frameFooterDto.footerBusinessInfo;
    }

    get footerCopyright(): string {
        if (MobileWebviewService.isWebview()) {
            return this._footerCopyright.replace(/href="([^"]+)"/g, `href="appcall://calllink?url=$1"`);
        }

        return this._footerCopyright;
    }

    get footerBusinessInfo(): string {
        return this._footerBusinessInfo;
    }
}

export default FrameFooter;
