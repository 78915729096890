import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import { PartialAbstractedTextProps } from '../../../../components/abstracted-text';
import HeaderAD from './designD/header-a';

export interface HeaderAProps extends PartialAbstractedTextProps {}

const HeaderA: React.FC<HeaderAProps> = (props) => {
    const Component = useDesignComponents<ComponentType<HeaderAProps>>({
        [Design.DesignD]: HeaderAD,
    });

    return <Component {...props} />;
};

export default HeaderA;
