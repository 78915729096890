import { useDesignComponents } from '@hooks/use-design-components';

import { useEffectOnce } from '@hooks/use-effect-once';
import { Design } from '@type/harmony-config';
import { ComponentType, HTMLProps, useRef } from 'react';
import InnerHtmlA from './designA/inner-html';
import InnerHtmlB from './designB/inner-html';
import InnerHtmlC from './designC/inner-html';
import InnerHtmlD from './designD/inner-html';

export interface InnerHtmlProps extends HTMLProps<HTMLDivElement> {
    content: string;
    textColor?: string;
}

const InnerHtml: React.FC<InnerHtmlProps> = ({ ...props }) => {
    const Component = useDesignComponents<ComponentType<InnerHtmlProps>>({
        [Design.DesignA]: InnerHtmlA,
        [Design.DesignB]: InnerHtmlB,
        [Design.DesignC]: InnerHtmlC,
        [Design.DesignD]: InnerHtmlD,
    });

    const ref = useRef<HTMLDivElement | null>(null);

    useEffectOnce(() => {
        const div = ref.current;
        if (!div) {
            return;
        }
        const scripts = div.querySelectorAll('script');
        const inlineScriptElements: HTMLScriptElement[] = [];
        const syncScriptElements: HTMLScriptElement[] = [];
        const asyncScriptElements: HTMLScriptElement[] = [];
        scripts.forEach((script) => {
            const newScript = document.createElement('script');
            if (script.src) {
                newScript.src = script.src;
            } else {
                newScript.textContent = script.textContent;
            }
            if (script.async === true) {
                asyncScriptElements.push(newScript);
            } else {
                if (!!script.src) {
                    syncScriptElements.push(newScript);
                } else {
                    inlineScriptElements.push(newScript);
                }
            }
            script.remove();
        });

        asyncScriptElements.forEach((script) => {
            document.head.appendChild(script);
        });
        syncScriptElements.forEach((script, index) => {
            if (index < syncScriptElements.length - 1) {
                const callback = () => {
                    document.head.appendChild(syncScriptElements[index + 1]);
                };
                script.onload = callback;
                script.onerror = callback;
            } else {
                const callback = () => {
                    inlineScriptElements.forEach((script) => {
                        document.head.appendChild(script);
                    });
                };
                script.onload = callback;
                script.onerror = callback;
            }
        });

        if (syncScriptElements.length > 0) {
            document.head.appendChild(syncScriptElements[0]);
        }
    }, [props.content]);

    return <Component ref={ref} {...props} />;
};

export default InnerHtml;
