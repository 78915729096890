import { Body2BProps } from '..';
import AbstractedText from '@components/abstracted-text';

const Body2BD: React.FC<Body2BProps> = (props) => {
    return (
        <AbstractedText fontSize="14px" weight="thin" lineHeight="20px" {...props}>
            {props.children}
        </AbstractedText>
    );
};

export default Body2BD;
