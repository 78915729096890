import { Center, Drawer, DrawerBody, DrawerContent, DrawerOverlay } from '@chakra-ui/react';
import MainButton from '@designs/primitives/common/button/main-button';
import MainButtonAsync from '@designs/primitives/common/button/main-button-async';
import Body2C from '@designs/primitives/typography/body-2-c';
import { useHarmony } from '@hooks/use-harmony';
import { useIsDesktop } from '@hooks/use-is-desktop';
import ThemeUtils from '@utils/theme.utils';
import { ModalProps } from '..';
import BottomButtons from '../../bottom-buttons';
import { useExtension } from '@hooks/use-extension';

const ModalD: React.FC<ModalProps> = ({
    variant,
    message,
    onClose,
    buttonText,
    cancelButtonText,
    onConfirmButtonClick,
    onConfirmButtonClickAsync,
    onCancelButtonClick,
    ...props
}) => {
    const { theme } = useHarmony();
    const { extensionStatus } = useExtension();
    const isDesktop = useIsDesktop();

    return (
        <Drawer placement="bottom" onClose={onClose} {...props}>
            <DrawerOverlay zIndex={ThemeUtils.zIndex.overlay} />
            <DrawerContent
                w="auto"
                mx={`max(calc((100vw - ${extensionStatus?.maxScreenWidth}px) / 2), 0px)`}
                borderRadius="8px"
                zIndex={ThemeUtils.zIndex.alert}
                transform={isDesktop ? 'translateX(310px) !important' : undefined}
            >
                <DrawerBody p="32px 16px">
                    <Center>
                        <Body2C color={theme.text.body1} whiteSpace="pre-wrap" textAlign="center">
                            {message}
                        </Body2C>
                    </Center>
                </DrawerBody>
                <BottomButtons>
                    {variant === 'confirm' && (
                        <MainButton onClick={onCancelButtonClick} variant="secondary">
                            {cancelButtonText}
                        </MainButton>
                    )}
                    {!!onConfirmButtonClickAsync ? (
                        <MainButtonAsync onClickAsync={onConfirmButtonClickAsync}>{buttonText}</MainButtonAsync>
                    ) : (
                        <MainButton onClick={onConfirmButtonClick}>{buttonText}</MainButton>
                    )}
                </BottomButtons>
            </DrawerContent>
        </Drawer>
    );
};

export default ModalD;
