import styled from '@emotion/styled';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { keyframes } from '@emotion/react';
import CustomIcon from 'src/legacy/components/common/custom-icon';
import palette from '../theme/palette';

const slideDown = keyframes({
    from: { height: 0 },
    to: { height: 'var(--radix-accordion-content-height)' },
});

const slideUp = keyframes({
    from: { height: 'var(--radix-accordion-content-height)' },
    to: { height: 0 },
});

const StyledAccordion = styled(AccordionPrimitive.Accordion)`
    width: 100%;
    background-color: transparent;
    border-bottom: 1px solid ${palette.grey};
`;

const StyledAccordionTrigger = styled(AccordionPrimitive.AccordionTrigger)<BlockAccordionTriggerProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: ${({ height }) => height || '40px'};
    background-color: transparent;
    border-bottom: 1px solid ${palette.grey};
    cursor: pointer;
`;

const StyledAccordionContent = styled(AccordionPrimitive.AccordionContent)`
    overflow: hidden;
    width: 100%;
    background-color: transparent;

    &[data-state='open'] {
        animation: ${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1);
    }
    &[data-state='closed'] {
        animation: ${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1);
    }
`;

const StyledChevron = styled(CustomIcon)`
    transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)';
    .accordion-trigger[data-state='open'] & {
        transform: rotate(180deg);
    }
`;

export enum BlockAccordionTypeEnum {
    /**
     * Allows only one accordion item to be open at a time.
     */
    single = 'single',
    /**
     * Allows multiple one accordion item to be open at a time.
     */
    multiple = 'multiple',
}

export enum BlockArrowIconEnum {
    line = 'expand_more',
    triangle = 'arrow_drop_down',
}

export type BlockAccordionProps = Omit<AccordionPrimitive.AccordionSingleProps, 'type'> &
    Omit<AccordionPrimitive.AccordionMultipleProps, 'type'> & {
        type: BlockAccordionTypeEnum;
    };
export type BlockAccordionTriggerProps = AccordionPrimitive.AccordionTriggerProps & {
    height?: string;
    size?: string;

    /**
     * Arrow icon style variant
     * @default 'line'
     */
    arrowIcon?: BlockArrowIconEnum;
};

export const BlockAccordion = StyledAccordion;
export const BlockAccordionItem = AccordionPrimitive.AccordionItem;
export const BlockAccordionTrigger = ({ children, arrowIcon = BlockArrowIconEnum.line, ...props }: BlockAccordionTriggerProps) => (
    <StyledAccordionTrigger className="accordion-trigger" {...props}>
        {children}
        <StyledChevron {...props}>{arrowIcon}</StyledChevron>
    </StyledAccordionTrigger>
);
export const BlockAccordionContent = StyledAccordionContent;
