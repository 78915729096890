import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { Design } from '@type/harmony-config';
import { IHarmonyTheme } from '@type/theme';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tabsAnatomy.keys);

export const tabTheme = (theme: IHarmonyTheme) =>
    defineMultiStyleConfig({
        variants: {
            [Design.DesignA]: definePartsStyle({
                tablist: {
                    justifyContent: 'space-around',
                },
                tab: {
                    borderBottom: '2px solid',
                    borderColor: 'transparent',
                    paddingY: '8px',
                    marginX: '8px',

                    _selected: {
                        borderBottom: `3px solid ${theme.secondary.default}`,
                    },
                },
            }),
            [Design.DesignB]: definePartsStyle({
                root: { width: '100%' },
                tablist: {
                    justifyContent: 'space-around',
                    borderTop: `1px solid ${theme.gray['50']}`,
                    borderBottom: `1px solid ${theme.gray['50']}`,
                },
                tab: {
                    padding: '6px 6px 4px 6px',
                    _selected: {
                        borderBottom: `2px solid ${theme.black}`,
                    },
                },
            }),
            [Design.DesignC]: definePartsStyle({
                tablist: {
                    justifyContent: 'space-around',
                    width: '100vw',
                    borderTop: `1px solid ${theme.gray['50']}`,
                    borderBottom: `1px solid ${theme.gray['50']}`,
                    padding: '0 16px',
                },
                tab: {
                    padding: '6px 6px 4px 6px',
                    _selected: {
                        borderBottom: `2px solid ${theme.primary.default}`,
                    },
                },
            }),
            [Design.DesignD]: definePartsStyle({
                tablist: {
                    paddingTop: '8px',
                    borderBottom: `1px inset ${theme.gray['50']}`,
                },
                tab: {
                    height: '36px',
                    borderBottom: '2px solid',
                    borderColor: 'transparent',
                    paddingX: '0px',
                    marginX: '8px',
                    marginBottom: '-1px',
                    _selected: {
                        borderColor: theme.black,
                        borderBottom: '2px solid',
                    },
                },
            }),
        },
    });
