const review = {
    review: {
        deleteReviewQ: '선택한 리뷰를 삭제하시겠습니까?',
        photoReview: '포토리뷰',
        allPhotoReview: '포토리뷰 모아보기',
        foundUseful: '명에게 도움된 리뷰 입니다',
        helped: '도움돼요',
        revContent: '리뷰 내용',
        attach: '사진 첨부',
        showReviews: '{count}개 리뷰 보기',
    },
};

export default review;
