import { Button, ButtonProps } from '@chakra-ui/react';
import Link, { LinkProps } from 'next/link';
import Icon, { IconProps } from '../icon';

export interface IconButtonProps extends IconProps, Omit<ButtonProps, keyof IconProps> {
    href?: LinkProps['href'];
}

const IconButton: React.FC<IconButtonProps> = ({ color, iconVariant, weight, grade, size, fill, children, href, ...props }) => {
    const component = (
        <Button variant="unstyled" display="flex" h="fit-content" minW="fit-content" {...props}>
            <Icon color={color} iconVariant={iconVariant} weight={weight} grade={grade} size={size} fill={fill}>
                {children}
            </Icon>
        </Button>
    );

    if (href) {
        return <Link href={href}>{component}</Link>;
    }

    return component;
};

export default IconButton;
