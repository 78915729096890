import { useDesignComponents } from '@hooks/use-design-components';
import { ButtonProps } from 'src/legacy/components/blockui';
import { useHarmony } from '@hooks/use-harmony';
import { Design } from '@type/harmony-config';
import { ReactNode } from 'react';
import CustomButtonA from './designA/custom-button';
import CustomButtonB from './designB/custom-button';
import CustomButtonC from './designC/custom-button';

export interface CustomButtonProps extends ButtonProps {
    children: ReactNode;
    borderWeight?: string;
    height?: string;
    borderRadius?: string;
    width?: string;
    fontWeight?: string;
}

const CustomButton: React.FC<CustomButtonProps> = ({ children, color, fontSize, fontWeight, borderWeight, borderRadius, ...props }) => {
    const { theme, designSettings } = useHarmony();
    const Component = useDesignComponents<typeof CustomButtonA | typeof CustomButtonB | typeof CustomButtonC>({
        [Design.DesignA]: CustomButtonA,
        [Design.DesignB]: CustomButtonB,
        [Design.DesignC]: CustomButtonC,
    });

    return (
        <Component
            theme={theme}
            color={color || theme.primary.default}
            fontSize={designSettings?.getLegacyFontSize(fontSize || '18px')}
            borderWeight={borderWeight || '2px'}
            borderRadius={borderRadius}
            fontWeight={fontWeight}
            {...props}
        >
            {children}
        </Component>
    );
};

export default CustomButton;
