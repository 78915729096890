import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import { ReactNode } from 'react';
import AlertA from './designA/alert';
import AlertB from './designB/alert';

export enum AlertStatusEnum {
    /**
     * Displays a checkmark icon
     */
    Complete,
    /**
     * Displays an alert icon
     */
    Alert,
    /**
     * Displays info icon
     */
    Info,
    /**
     * Displays no icon
     */
    None,
}

export enum AlertTypeEnum {
    Drawer,
    Modal,
}

/**
 * Confirm button only, calls onClose.
 */
type DefaultButtons = {
    type: 'default';
    btnText?: string;
};

/**
 * One confirm button and one cancel button.
 */
type ConfirmButtons = {
    type: 'confirm';
    onConfirm: () => void;
    confirmBtnText?: string;
};

/**
 * Makes use of AsyncButton for the confirm button which automatically shows a loader until complete.
 */
export type AsyncButtons = {
    type: 'async';
    onClickAsync: () => Promise<void>;
    asyncBtnText?: string;
};

/**
 * AsyncButton for a single confirm button.
 */
type AsyncConfirmButton = {
    type: 'async-confirm';
    onClickAsync: () => Promise<void>;
    asyncBtnText?: string;
};

/**
 * Custom text and callbacks for both buttons.
 */
type CustomButtons = {
    type: 'custom';
    onWhiteButton: () => void;
    onBlueButton: () => void;
    whiteBtnText: string;
    blueBtnText: string;
};

/**
 * Custom text and callbacks for both buttons.
 */
type CustomAsyncButtons = {
    type: 'async-custom';
    onWhiteButtonAsync: () => Promise<void>;
    onBlueButtonAsync: () => Promise<void>;
    whiteBtnText: string;
    blueBtnText: string;
};

export type Buttons = DefaultButtons | ConfirmButtons | AsyncButtons | CustomButtons | CustomAsyncButtons | AsyncConfirmButton;

export interface AlertProps {
    open?: boolean;
    onClose?: () => void;

    /**
     * @default AlertStatusEnum.Alert
     */
    status?: AlertStatusEnum;
    type?: AlertTypeEnum;
    buttons?: Buttons;
    children?: ReactNode;
    dismissible?: boolean;
}

const Alert: React.FC<AlertProps> = (props) => {
    const Component = useDesignComponents<typeof AlertA | typeof AlertB>({
        [Design.DesignA]: AlertA,
        [Design.DesignB]: AlertB,
    });

    return <Component {...props} />;
};

export default Alert;
