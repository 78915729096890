import CONFIG from '@config';
import { CurrencyCodeEnum } from '@locales/types';
import { Design } from '@type/harmony-config';
import { CurrencyData } from './responseDto/types';
import { ICurrencyRatesDto } from './responseDto/currency-rates.dto';
import safeWindow from '@services/safe-window.service';

class CurrencyRates {
    private static _currencyKey = 'currency';

    private _currencyCode: CurrencyCodeEnum;
    private _currencyData: CurrencyData;
    private _conversionMap?: ICurrencyRatesDto['rates'];

    constructor(currency: CurrencyCodeEnum, currencyRatesDto?: ICurrencyRatesDto) {
        const currencyToUse = currencyRatesDto?.base ?? currency;
        this._currencyCode = this._getCurrencyCode(currencyToUse);
        this._currencyData = this._getCurrencyData(currencyToUse);
        this._conversionMap = currencyRatesDto?.rates;
    }

    /**
     * Retrieves the currency code from local storage if it exists,
     * otherwise, the default currency code is returned.
     */
    static getCurrencyFromStorage(): CurrencyCodeEnum {
        const currency = safeWindow?.localStorage.getItem(CurrencyRates._currencyKey);
        if (currency && Object.values(CurrencyCodeEnum).includes(currency as CurrencyCodeEnum)) {
            return currency as CurrencyCodeEnum;
        } else {
            return CONFIG.defaultCurrency;
        }
    }

    static setCurrencyInStorage(currency: CurrencyCodeEnum): void {
        localStorage.setItem(CurrencyRates._currencyKey, currency);
    }

    private _getCurrencyCode(currency: CurrencyCodeEnum | string): CurrencyCodeEnum {
        if (Object.values(CurrencyCodeEnum).includes(currency as CurrencyCodeEnum)) {
            return currency as CurrencyCodeEnum;
        } else {
            return CONFIG.defaultCurrency;
        }
    }

    private _getCurrencyData(currency: CurrencyCodeEnum | string): CurrencyData {
        switch (currency) {
            case CurrencyCodeEnum.Hkd:
                return {
                    currencySymbol: 'HK$',
                    priceRanges: [],
                };
            case CurrencyCodeEnum.Cny:
                return {
                    currencySymbol: '¥',
                    priceRanges: [],
                };
            case CurrencyCodeEnum.Krw:
            default:
                return {
                    currencySymbol: '원',
                    priceRanges:
                        CONFIG.designTemplate === Design.DesignD
                            ? [
                                  { label: '1만원 미만', value: { minSellPrice: 0, maxSellPrice: 10_000 } },
                                  { label: '1만원~3만원', value: { minSellPrice: 10_000, maxSellPrice: 30_000 } },
                                  { label: '3만원~5만원', value: { minSellPrice: 30_000, maxSellPrice: 50_000 } },
                                  { label: '5만원~10만원', value: { minSellPrice: 50_000, maxSellPrice: 100_000 } },
                                  { label: '10만원 이상', value: { minSellPrice: 100_000, maxSellPrice: 999_999_999 } },
                                  { label: '직접입력' },
                              ]
                            : [
                                  { label: '1만원이하', value: { minSellPrice: 0, maxSellPrice: 10000 } },
                                  { label: '1만원~5만원', value: { minSellPrice: 10000, maxSellPrice: 50000 } },
                                  { label: '5만원~10만원', value: { minSellPrice: 50000, maxSellPrice: 100000 } },
                                  { label: '직접입력' },
                              ],
                };
        }
    }

    get currencyCode(): CurrencyCodeEnum {
        return this._currencyCode;
    }

    get currencySymbol(): string {
        return this._currencyData.currencySymbol;
    }

    get currencyData(): CurrencyData {
        return this._currencyData;
    }

    /**
     * Converts given price and currency into base currency code as set in the app
     *
     * @param price price as provided by the backend
     * @param currency 3 letter currency code as provided by the backend
     * @returns price converted into base currency code as set in the app
     */
    convertToBaseCurrency(price: number, currency: string): number {
        if (this._conversionMap && this._conversionMap[currency]) {
            return price / this._conversionMap[currency];
        } else {
            return price;
        }
    }
}

export default CurrencyRates;
