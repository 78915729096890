import CacheKeys from '@api/cache-keys';
import { PrepareVerifyCustomerIdentityDto } from '@models/customer-identity-verification/requestDto/prepare-verify-customer-identity.dto';
import { PaginatedQuery } from '@type/api';

abstract class AuthCacheKeys extends CacheKeys {
    static NOTIFICATION_ALERT_QUERY: PaginatedQuery = { page: 1, itemsPerPage: 1 };

    static customerAddressesCacheKey(isBaseAddress?: boolean): string {
        return `/customer/address?base-address=${isBaseAddress}`;
    }

    static authUrlCacheKey(): string {
        return `/authorization_url`;
    }

    static authOauthMapCacheKey(): string {
        return `/my`;
    }

    static notificationsCacheKey(isAuth: boolean, query: PaginatedQuery): string {
        return `/customer/notifications${Object.values(query).join(',')}-${isAuth}`;
    }

    static notificationSettingCacheKey(): string {
        return `/customer/notification-setting`;
    }

    static endUserSettingCacheKey(): string {
        return '/auth-setting';
    }

    static registerUserCacheKey(): string {
        return '/id-pw-register';
    }

    static editUserCacheKey(): string {
        return '/edit-user';
    }

    static registerMemberCacheKey(): string {
        return '/register-member';
    }

    static resetPasswordCacheKey(): string {
        return '/reset-pw-verification';
    }

    static resetTemporaryPassword(): string {
        return '/reset-temporary-pw';
    }

    static prepareCustomerIdentityVerificationData(dto: Omit<PrepareVerifyCustomerIdentityDto, 'redirectUri'>): string {
        return `/verification/nice-pass/data-prep/${JSON.stringify(dto)}`;
    }

    static verifyCustomerIdentity(): string {
        return '/verification/nice-pass/verification';
    }

    static resetTemporaryPasswordVerification(): string {
        return '/verification/nice-pass/reset-temporary-pw-verification';
    }
}

export default AuthCacheKeys;
