export interface IExtensionStatusDto {
    status: ExtensionStatusEnum;
    extension: {
        code: ExtensionCodeEnum;
        name: string;
    };
}

export enum ExtensionStatusEnum {
    Installed = 'INSTALLED',
    InstalledConfigured = 'INSTALLED_CONFIGURED',
    InstalledConfiguredEnabled = 'INSTALLED_CONFIGURED_ENABLED',
    Configured = 'CONFIGURED',
    Enabled = 'ENABLED',
    Uninstalled = 'UNINSTALLED',
}

export enum ExtensionCodeEnum {
    /** 찜 */
    Wishlist = 'WISHLIST',
    /** 리뷰 */
    Review = 'REVIEW',
    /** 구독 유저선택형 */
    UserDeliveryFrequencyType = 'USER_DELIVERY_FREQUENCY_TYPE',
    /** 구독 단건구매 */
    SinglePurchaseSubscription = 'SINGLE_PURCHASE_SUBSCRIPTION',
    /** ID/PW 로그인 */
    IdPwLogin = 'ID_PW_LOGIN',
    /** 네이버 로그인 */
    NaverLogin = 'NAVER_LOGIN',
    /** 카카오 로그인 */
    KakaoLogin = 'KAKAO_LOGIN',
    /** 구글 로그인 */
    GoogleLogin = 'GOOGLE_LOGIN',
    /** 검색 */
    Search = 'SEARCH',
    /** 고급 검색 */
    AdvancedSearch = 'ADVANCED_SEARCH',
    /** 공유하기 */
    ShareWith = 'SHARE_WITH',
    /** 장바구니 */
    Cart = 'CART',
    /** 알림 */
    Notification = 'NOTIFICATION',
    /** RCS */
    Rcs = 'RCS',
    /** 공지사항 */
    NoticeBoard = 'NOTICE_BOARD',
    /** 1:1 문의 */
    Inquiry = 'INQUIRY',
    /** 상품문의 */
    ProductInquiry = 'PRODUCT_INQUIRY',
    /** PG */
    Pg = 'PG',
    /** PG+(mid) */
    PgMid = 'PG_MID',
    /** 외부 티어 */
    ExternalTier = 'EXTERNAL_TIER',
    /** 내부 티어 */
    InternalTier = 'INTERNAL_TIER',
    /** 외부 포인트 */
    ExternalPoint = 'EXTERNAL_POINT',
    /** 내부 포인트 */
    InternalPoint = 'INTERNAL_POINT',
    /** 팝업 */
    Popup = 'POPUP',
    /** 이벤트 */
    Event = 'EVENT',
    /** 광고? */
    Advertisement = 'ADVERTISEMENT',
    /** Google Analytics */
    Ga = 'GA',
    /** Google Analytics */
    GaPlus = 'GA_PLUS',
    /** Across ADN */
    AcrossAdn = 'ADN',
    /** 쿠폰 */
    Coupon = 'COUPON',
    /** PWA */
    Pwa = 'PWA',
    /** 컴포넌트 페이지 */
    ComponentPage = 'COMPONENT_PAGE',
    /** SSO 로그인 KB Pay */
    SsoLoginKbPay = 'SSO_LOGIN_KB_PAY',
    /** 오프라인 매장 */
    OfflineStore = 'OFFLINE_STORE',
    /** 선물 */
    Gift = 'GIFT',
    /** 앱 패키지 */
    AppPkg = 'APP_PKG',
    /** GPS */
    Gps = 'GPS',
    /** 데스크탑 */
    Desktop = 'DESKTOP',
}
