const product = {
    product: {
        outOfStock: '품절임박',
        moreInfo: '상세정보',
        purchaseInfo: '구매정보',
        review: '리뷰',
        QA: 'Q&A',
        addToCart: '장바구니 담기',
        purchase: '구매하기',
        gift: '선물하기',
        totalProduct: '총 상품금액',
        selectProduct: '구매할 상품을 선택해 주세요.',
        tangibleProduct: '배송상품',
        deliveryFee: '배송비',
        freeShipping: '무료배송',
        goodTogether: '함께 보면 좋은 상품이예요',
        howAbout: '이런 상품은 어떠세요?',
        expandMoreProductDetail: '상품 정보 펼쳐보기',
        expandLessProductDetail: '상품 정보 접기',
        showMore: '상세정보 {more}',
        fold: '접기',
        unfold: '펼쳐보기',
        prodInfo: '상품정보제공고시',
        subCancel: '구독취소/구독해지 안내',
        subDetail1: '출고된 상품이 없는 경우 구독 취소 처리되며, 환불이 진행됩니다.',
        subDetail2: '출고된 상품이 있는 경우 구독 해지 신청으로 처리되며, 신청이 완료되면 고객센터에서 상세히 안내드리겠습니다.',
        subDetail3: '환불 금액은 주문 상품 중 출고되지 않은 상품 금액과 배송비로 계산되며, 정확한 금액은 신청 후 산정됩니다.',
        noticeOfInfo: '고시정보명',
        shipExchangeReturn: '배송/교환/반품 안내',
        deliveryFeeDetails: '기본 {base}원',
        refundDeliveryFee: '반품 배송비',
        priceLabel: '{price}원',
        exchangeDeliveryFee: '교환 배송비',
        jejuDeliveryFee: '제주 추가 배송비',
        outsideJejuDeliveryFee: '제주 외 도서 산간 추가 배송비',
        defaultCarrier: '기본 택배사',
        refundCarrier: '반품 택배사',
        releaseAddress: '출고지',
        refundAddress: '반품 보내실 곳',
        dateOfApp: '반품 교환 신청 기준일',
        dateOfApp2: '단순변심에 의한 교환/반품은 제품 수령 후 7일 이내까지, 교환/반품 제한사항에 해당하지 않은 경우에만 가능 (배송비용과 교환/반품 비용 왕복배송비 고객부담)',
        dateOfApp3: '상품의 내용이 표시/관고의 내용과 다른 경우에는 상품을 수령한 날부터 3개월 이내, 그 사실을 안 날 또는 할 수 있었던 날부터 30일 이내에 청약철회 가능',
        ifReturnNotPoss: '반품 및 교환이 불가한 경우',
        ifReturnNotPoss2: '주문/제작 상품의 경우, 상품의 제작이 이미 진행된 경우',
        ifReturnNotPoss3: '상품 포장을 개볼하여 사용 또는 설치 완료되어 상품의 가치가 훼손된 경우 (단, 내용 확인을 위해 포장 개봉의 경우는 예외)',
        invalidProduct: '구매 불가능한 상품입니다. 이전 페이지로 돌아갑니다.',
        exchangePolicy: '반품 교환지 정책',
        intlShippingPolicy: '국제배송 정책',
        sellerInfo: '판매자 정보',
        businessName: '상호명',
        country: '국가',
        contact: '연락처',
        address: '주소',
        reviews: '개의 리뷰',
        viewPhotoReviews: '포토리뷰 모아보기',
        noPhotoReviews: '등록된 포토리뷰가 없습니다.',
        age: '{age}대',
        dot: '{score}점',
        detailedFilter: '상세필터',
        ageGroup: '연령대',
        scope: '별점',
        points: '{points}점',
        gender: '성별',
        nonMember: '비회원',
        reportIt: '신고하기',
        reportItShorten: '신고',
        seeReplies: '답글보기',
        closeReplies: '답변접기',
        purchaseOption: '구매옵션',
        productInquiry: '문의하기',
        areYouSureReview: '{name} 님의 상품평을 신고하시겠습니까?',
        areYouSureReviewVariant: '{name} 님의 리뷰를 신고하시겠습니까?',
        selectReason: '신고사유를 선택해 주세요',
        irrelevantContent: '상품과 관련없는 내용',
        commercialPromotion: '상업적 홍보',
        riskPersonalInfo: '개인정보 노출 위험',
        reproduction: '저작권 불법 도용 (타인의 글/사진 복제)',
        otherReason: '기타 (직접입력)',
        alreadyReported: '이미 신고한 리뷰입니다.',
        reportComplete: '리뷰 신고하기가 완료되었습니다.',
        enterReason: '신고사유를 입력해 주세요.',
        likeCount: '좋아요순',
        createDate: '최신순',
        scoreDesc: '평점 높은순',
        scoreAsc: '평점 낮은순',
        viewReplies: '리뷰 답글보기',
        answerComplete: '답변완료',
        waitingForReply: '답변대기',
        privateInquiry: '비공개 문의글입니다.',
        seeAnswer: '답변보기',
        inquiryAnswer: '상품문의 답변',
        answer: '답변',
        productInquiryReg: '문의하기',
        enterTitle: '제목을 입력해 주세요.',
        enterInquiry: '문의 내용을 입력해 주세요.',
        enterPhone: '휴대폰 번호를 입력해 주세요',
        pleaseEnterPhone: `휴대폰 번호를 입력해 주세요. ('-'없이 입력)`,
        enterPrivacy: '공개여부를 입력해 주세요.',
        disclosure: '공개여부',
        open: '공개',
        private: '비공개',
        title: '제목',
        pleaseEnterInquiryTitle: '문의 제목을 입력해 주세요.',
        inquiries: '문의내용',
        pleaseEnterInquiry: '문의하실 내용을 입력해 주세요.',
        remainingQuant: '남은수량: {count}개',
        soldOut: '품절',
        chooseOption: '옵션선택',
        notice: '유의사항',
        noNotices: '등록된 유의사항이 없습니다.',
        noReviews: '해당 상품에 등록된 리뷰가 없습니다.',
        noProductInquiryHistory: '상품문의 내역이 없습니다.',
        productInquiryCanBeMadeOnProductDetailsPage: ' 상품문의는 상품 상세 페이지에서 작성 할 수 있습니다.',
        noProductInquiryAnswered: '답변 완료된 문의 내역이 없습니다.',
        noProductInquiryNotAnswered: '답변 대기 상태의 문의 내역이 없습니다.',
        confirmEditProductInquiry: '상품문의를 수정하시겠습니까?',
        confirmDeleteProductInquiry: '상품문의를 삭제하시겠습니까?',
        missingInquiryTitle: '제목을 - 입력해 주세요',
        missingInquiryContent: '문의내용을 - 입력해 주세요',
        missingInquiryDisplayed: '공개여부를 - 입력해 주세요',
        editProductInquiry: '상품 문의 수정',
        colorSelection: '색상 선택',
        soldOutLabel: 'SOLD OUT',
        totalProductPrice: '총 상품금액',
        totalDeliveryFee: '총 배송비',
        expectedTotalPayment: '결제 예정 금액',
        itemsAddedToCart: '장바구니에 상품을 담았습니다.',
        maxOrderLimit: '최대 주문 수량은 {limit}개입니다.',
        allProduct: '전체상품 보기',
        max: '최대 {quantity}자를 초과할 수 없습니다.',
        reviewReport: '리뷰 신고하기',
        report: '신고하기',
        noInquiries: '해당 상품에 등록된 문의사항이 없습니다.',
        specialPrice: '{discountRate}% 특가',
        freeShip: '배송비 무료',
        optionShip: '옵션수량 {number}개마다 배송비 반복 부과',
        productShip: '상품수량 {number}개마다 배송비 반복 부과',
        productSelection: '상품 선택',
        subscribe: '구독하기',
        monthlySubscription: '월 단위 정기결제 상품',
        selectQuantity: '수량을 선택해 주세요.',
        selectProductOption: '상품을 선택해 주세요.',
        deliveryCycle: '배송주기를 선택해 주세요',
        deliveryStart: '첫 배송 희망일을 선택해 주세요.',
        nPerAWeek: '{frequency}주에 1번',
        instructions: '상품 유형 안내 사항',
        instructionsDetails:
            '해당 상품은 쿠폰으로 제공되는 월 정기결제 서비스 입니다. 최종 결제 화면에서 작성한 휴대폰 번호로 쿠폰 번호와 링크가 전송되며, 쿠폰은 사용은 30일 이내에 사용 하실 수 있습니다.',
        notLoggedIn: '로그인 하지 않으셨습니다.\n로그인 후 주문하시겠습니까?',
        subscription: '구독정보',
        selectReasonReport: '신고사유를 선택해 주세요.',
        productOptions: '상품 옵션',
        inquiryTitle: '제목',
        inquiryContents: '문의내용',
        viewContent: '내용보기',
        closeContent: '내용접기',
        totalMinAmount: '합계 최소금액',
        firstDelivery: '첫 배송일',
        nextDelivery: '다음 배송일',
        selectBillingCycle: '결제주기를 선택해 주세요.',
        whatIsTheConv: '월 환산금액이란?',
        whatIsTheConvDetails: '해당 결제회차의 월환산금액입니다.',
        whatIsTheConvDetails2: '다음 결제회차 월환산금액과 다를 수 있습니다.',
        firstPayment: '*해당 금액은 첫 결제금액입니다.',
        priceMayVary: '*결제금액은 다음 결제일 전까지 배송받는 횟수에 따라 변경될 수 있습니다.',
        selectable: '선택가능',
        selected: '선택완료',
        deliveryFrequency: '배송주기',
        nWeeks: '{frequency}주에 한 번',
        nMonths: '{frequency}개월에 1번',
        nWeeksDelivery: '{frequency, plural, =1 {매주 1회 배송} other {#주 1회 배송}}',
        nMonthsDelivery: '{frequency, plural, =1 {매월 1회 배송} other {#개월 1회 배송}}',
        ifHoliday: '(주말 제외)',
        deliveryDate: '출고일',
        subPeriod: '구독기간',
        indefinite: '무기한 구독',
        byDate: '날짜 별로',
        byRound: '횟수 별로',
        billingCycle: '결제주기',
        skuOption: '상품옵션',
        everyMonth: '매월',
        nMonthPayment: '{frequency}개월 마다',
        deliveryWeekChip: '{frequency}주 1회 배송',
        anyDay: '상시출고',
        shipping: '하는 상품입니다.',
        month: '개월',
        paymentCycle: '{paymentCycle, plural, =1 {매월 결제} other {#개월마다 결제}}',
        monthlyPayments: '매월결제',
        nMonthPaymentsCycle: '{frequency}개월마다 결제',
        deliveryCount: '첫 결제 시 총{count}회 배송',
        perMonth: '{frequency}개월',
        monthlyConversion: '월 환산금액',
        alwaysAvailable: '상시배송',
        forDelivery: '가능한 상품입니다',
        exceptHoliday: '(공휴일 제외)',
        percentOff: '{percent}% 할인',
        deliveryDay: '배송요일',
        almostSoldOut: '품절임박',
        deliveredEveryWeek: '{day}요일에',
        deliveredEveryNMonths: '{frequency, plural, =1 {매월} =2 {두 달에 한 번} =3 {세 달에 한 번} =6 {여섯 달에 한 번} other {#달에 한 번}}',
        always: '상시',
        deliveredEvery2: '출고하는 상품입니다.',
        deliveredEvery3: '받을 수 있는 상품입니다.',
        deleteFirst: '상품을 삭제한 뒤 옵션을 변경할 수 있어요.',
        chooseAnOption: '옵션을 선택해 주세요.',
        invalidPhone: '잘못된 휴대폰 번호입니다.',
        invalidEmail: '잘못된 이메일주소 형식입니다.',
        pleaseEnterEmail: '이메일을 입력해 주세요.',
        deliveryDate2: '배송일',
        cancelInquiry: '상품문의 등록을 취소하시겠습니까?',
        cancelInquiry2: '취소하면 작성한 내용은 저장되지 않습니다.',
        inquiryRegistered: '문의가 등록되었습니다.',
        attachImages: '사진 첨부',
        attachImages2: '이미지 등록 (최대 10개까지 등록 가능)',
        attachImagesDescription: '*이미지는 최대 10개까지 등록 가능합니다.',
        inquiryCategory: '문의유형',
        cancelInquiryConf: '1:1문의 등록을 취소하시겠습니까?',
        cancelInquiryConf2: '취소하면 작성한 내용은 저장되지 않습니다.',
        inquiryCount: '개의 문의내역',
        coupon: '보유 쿠폰',
        discountAmount: '할인',
        totalReviews: `{total}건의 리뷰`,
        giftCardDescription:
            '가까운 영업일 이내에 주문하신 상품권이 등록된 휴대전화 번호로 문자 발송되는 상품권입니다. 구매 이후에 장기간 문자를 수신받지 못하신 경우, 고객센터({contactNumber})에 문의해주세요',
        skuSelectDrawerTitle: '옵션 선택',
        skuSelectDrawerSubtitle: '상품 옵션을 선택해주세요.',
        accumulatingPoints: '적립 포인트',
        oncePerNWeeksDelivery: '{frequency, plural, =1 {매주 1회 배송} other {#주마다 1회 배송}}',
        oncePerNMonthsDelivery: '{frequency, plural, =1 {매월 1회 배송} other {#개월마다 1회 배송}}',
        deliveredEveryMonth: '매월 {day}',
        paymentFrequency: '{perMonthPayment, plural, =1 {매월 1회 결제} other {#개월마다 1회 결제}}',
        onceEveryWeeksPayment: '매주 1회 결제',
        onceEveryNWeeksPayment: '{frequency}주마다 1회 결제',
        everyNWeeks: '주 단위로 선택 가능',
        everyNMonths: '월 단위로 선택 가능',
        everyNWeeksOrMonths: '주 / 월 단위로 선택 가능',
        shippingWeekDay: '매주 {weekdays}요일 출고',
        shippingDate: '매월 {shippingDate} 출고',
        endDate: '말일',
        calendarDescriptions1: '선택가능한 첫 배송 희망일은 출고 가능일로부터 3영업일 후의 날짜입니다.',
        calendarDescriptions2: '선택하신 첫 배송일로부터 ±1영업일의 이내로 상품을 수령하실 수 있습니다. (일부 제주/도서산간 지역 제외)',
        openSoon: '오픈 예정',
        brandIsEmpty: '브랜드에 상품이 없습니다.',
        pleaseNote: '알려 드립니다!',
        pleaseNoteBody:
            '가까운 영업일 이내에 주문하신 상품권이 등록된 휴대전화 번호로 문자 발송되는 상품권입니다. 구매 이후에 장기간 문자를 수신받지 못하신 경우, 고객센터({contactNumber})에 문의해주세요',
        pleaseNoteBody2:
            '1~2 영업일 이내에 주문하신 상품권이 등록된 휴대전화 번호로 문자 발송되는 상품권입니다. 구매 이후에 장기간 문자를 수신받지 못하신 경우, 고객센터({contactNumber})에 문의해주세요',
        buyOnce: '1회만 받아보기',
        inquiry: '문의 내용',
        brandBadge: '브랜드 전용관 GO!',
    },
};

export default product;
