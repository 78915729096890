import { ISODate } from '@type/models';

export enum InitiatorTypeEnum {
    Supplier = 'supplier',
    ChannelOwner = 'channel_owner',
    User = 'user',
}

/**
 * 선물일 때 표시되는 텍스트가 일반 주문일 때와 다르다면
 *
 * {일반 주문 시 텍스트} / 선물일 때: {선물 주문일 시 보낸 사람이 보는 텍스트} <{받는 사람이 보는 텍스트}>
 *
 * 와 같이 표기함.
 */
export enum OrderStatusEnum {
    /** 사용가능(발급완료) */
    RECEIVED = 'received',
    /** 기간만료 */
    EXPIRED = 'expired',
    /** 환불완료 (기간만료) */
    EXPIRED_CONFIRMED = 'expired_confirmed',
    /** 사용완료(교환완료) */
    USED = 'used',
    /** 일부만 사용완료 (금액권일 경우만 해당) */
    PARTIALLY_USED = 'partially_used',
    /** 사용완료(기간만료) */
    USED_CONFIRMED = 'used_confirmed',
    /** 환불완료(발급취소) */
    ISSUE_CANCELLED = 'issue_cancelled',
    /** 발급예정(직접발급) */
    MANUALLY_ISSUE_REQUIRED = 'manually_issue_required',
    /** 발급완료(직접발급) */
    MANUALLY_ISSUE_CONFIRMED = 'manually_issue_confirmed',
    /** 취소요청(직접발급) */
    MANUALLY_ISSUE_CANCELLING = 'manually_issue_cancelling',
    /** 거절환불완료(직접발급) */
    REFUND_FINISHED_MANUALLY_ISSUE_REJECTED = 'refund_finished_manually_issue_rejected',
    /** 취소환불완료(직접발급) */
    REFUND_FINISHED_MANUALLY_ISSUE_CANCELLED = 'refund_finished_manually_issue_cancelled',
    /** 새 공지 */
    NEW_NOTICE = 'new_notice',
    /** 결제대기 */
    PAYMENT_REQUIRED = 'payment_required',
    /** 결제인증 실패 (PG 인증 실패) */
    PAYMENT_FAIL_VALIDATION = 'payment_fail_validation',
    /** 잘못된 방식의 결제 유도 */
    PAYMENT_FAIL_INVALID_PAYMENT_TRIAL = 'payment_fail_invalid_payment_trial',
    /** 결제실패 (입금안함) */
    PAYMENT_FAIL_TIME_OUT = 'payment_fail_time_out',
    /** 결제실패 (PG실패) */
    PAYMENT_FAIL_ERROR = 'payment_fail_error',
    /** 접수요망 / 선물일 때: 선물 등록완료 */
    CHECK_REQUIRED = 'check_required',
    /** 주문실패 (접수거절) / 선물일 때: 선물 등록완료 */
    ORDER_FAIL_CHECK_REJECTED = 'order_fail_check_rejected',
    /** 출고요망 / 선물일 때: 선물 등록완료 <배송준비> */
    SHIP_REQUIRED = 'ship_required',
    /** 구매확정 */
    ORDER_FINISHED_NOTICE = 'order_finished_notice',
    /** 직접배송 / 선물일 때: 선물 등록완료 <배송중> */
    SHIP_OK_DIRECT_DELIVERY = 'ship_ok_direct_delivery',
    /** 출고완료 / 선물일 때: 선물 등록완료 <배송준비> */
    SHIP_OK = 'ship_ok',
    /** 주문실패 (출고거절) / 선물일 때: 선물 등록완료 <배송준비> */
    ORDER_FAIL_SHIP_REJECTED = 'order_fail_ship_rejected',
    /** 출고지연 / 선물일 때: 선물 등록완료 <배송준비> */
    SHIP_DELAY = 'ship_delay',
    /** 배송중 / 선물일 때: 선물 등록완료 <배송중> */
    DELIVERY_ING = 'delivery_ing',
    /** 배송완료 / 선물일 때: 선물 등록완료 <배송완료> */
    DELIVERY_OK = 'delivery_ok',
    /** 배송지연 / 선물일 때: 선물 등록완료 <배송중> */
    DELIVERY_DELAY = 'delivery_delay',
    /** 구매확정 / 선물일 때: 선물 등록완료 <구매확정> */
    ORDER_FINISHED = 'order_finished',
    /** 주문취소 */
    ORDER_CANCELLED = 'order_cancelled',
    /** 반품요청 */
    REFUND_REQUESTED = 'refund_requested',
    /** 반품접수완료(동의대기중) */
    REFUND_CHECKED = 'refund_checked',
    /** 반품요청동의 (반품 수령 대기중) */
    REFUND_AGREED = 'refund_agreed',
    /** 반품거절 (접수거절) */
    REFUND_FAIL_CHECK_REJECTED = 'refund_fail_check_rejected',
    /** 반품거절 (동의거절) */
    REFUND_FAIL_AGREE_REJECTED = 'refund_fail_agree_rejected',
    /** 반품거절 (검수부결) */
    REFUND_FAIL_INSPECT_REJECTED = 'refund_fail_inspect_rejected',
    /** 반품거절 (검수부결 - 기존물품 배송완료) */
    REFUND_FAIL_INSPECT_REJECTED_DO = 'refund_fail_inspect_rejected_do',
    /** 반품거절 (검수부결 - 기존물품 배송지연) */
    REFUND_FAIL_INSPECT_REJECTED_DD = 'refund_fail_inspect_rejected_dd',
    /** 반품실패 (반품 미수령) */
    REFUND_FAIL_RETURN_NO = 'refund_fail_return_no',
    /** 반품수령완료 */
    REFUND_RETURN_OK = 'refund_return_ok',
    /** 반품검수통과 */
    REFUND_INSPECT_PASS = 'refund_inspect_pass',
    /** 환불완료 (반품완료) */
    REFUND_FINISHED_NORMAL = 'refund_finished_normal',
    /** 환불완료 (주문취소) */
    REFUND_FINISHED_ORDER_CANCELED = 'refund_finished_order_canceled',
    /** 환불완료 (주문취소확인) */
    REFUND_FINISHED_ORDER_CANCELED_CONFIRMED = 'refund_finished_order_canceled_confirmed',
    /** 환불완료 (접수거절) / 선물일 때: 환불완료 */
    REFUND_FINISHED_CHECK_REJECTED = 'refund_finished_check_rejected',
    /** 환불완료 (출고거절) / 선물일 때: 환불완료 */
    REFUND_FINISHED_SHIP_REJECTED = 'refund_finished_ship_rejected',
    /** 교환요청 */
    EXCHANGE_REQUESTED = 'exchange_requested',
    /** 교환접수완료(동의대기중) */
    EXCHANGE_CHECKED = 'exchange_checked',
    /** 교환동의 (교환품 수령 대기중) */
    EXCHANGE_AGREED = 'exchange_agreed',
    /** 교환거절 (접수거절) */
    EXCHANGE_FAIL_CHECK_REJECTED = 'exchange_fail_check_rejected',
    /** 교환거절 (동의거절) */
    EXCHANGE_FAIL_AGREE_REJECTED = 'exchange_fail_agree_rejected',
    /** 교환거절 (검수부결) */
    EXCHANGE_FAIL_INSPECT_REJECTED = 'exchange_fail_inspect_rejected',
    /** 교환거절 (검수부결 - 기존물품 배송완료) */
    EXCHANGE_FAIL_INSPECT_REJECTED_DO = 'exchange_fail_inspect_rejected_do',
    /** 교환거절 (검수부결 - 기존물품 배송지연) */
    EXCHANGE_FAIL_INSPECT_REJECTED_DD = 'exchange_fail_inspect_rejected_dd',
    /** 교환거절 (재출고거절) */
    EXCHANGE_FAIL_RESHIP_REJECTED = 'exchange_fail_reship_rejected',
    /** 교환거절 (새물품 출고거절-기존물품 재배송 완료) */
    EXCHANGE_FAIL_RESHIP_REJECTED_DO = 'exchange_fail_reship_rejected_do',
    /** 교환거절 (새물품 출고거절-기존물품 재배송 지연) */
    EXCHANGE_FAIL_RESHIP_REJECTED_DD = 'exchange_fail_reship_rejected_dd',
    /** 교환실패 (교환품 미수령) */
    EXCHANGE_FAIL_RETURN_NO = 'exchange_fail_return_no',
    /** 교환품수령완료 */
    EXCHANGE_RETURN_OK = 'exchange_return_ok',
    /** 교환품검수통과 */
    EXCHANGE_INSPECT_PASS = 'exchange_inspect_pass',
    /** 교환새물품 출고완료 */
    EXCHANGE_RESHIP_OK = 'exchange_reship_ok',
    /** 교환새물품 출고지연 */
    EXCHANGE_RESHIP_DELAY = 'exchange_reship_delay',
    /** 교환새물품 배송중 */
    EXCHANGE_DELIVERY_ING = 'exchange_delivery_ing',
    /** 교환새물품 배송완료 */
    EXCHANGE_DELIVERY_OK = 'exchange_delivery_ok',
    /** 교환새물품 배송지연 */
    EXCHANGE_DELIVERY_DELAY = 'exchange_delivery_delay',
    /** 접수 거절 */
    CHECK_REJECTED = 'check_rejected',
    /** 출고 거절 */
    SHIP_REJECTED = 'ship_rejected',
    /** 구매확정 */
    ORDER_FINISHED_REVIEWED = 'order_finished_reviewed',
    CHECK_REQUIRED_IN_ADVANCE = 'check_required_in_advance',
    PENDING = 'pending',
    SUBSCRIBING = 'subscribing',
    SUBSCRIPTION_CANCELLING = 'subscription_cancelling',
    SUBSCRIPTION_END = 'subscription_end',
    SUBSCRIPTION_CANCELLED = 'subscription_cancelled',
    SUBSCRIPTION_FAILED_DUE_TO_PAYMENT = 'subscription_failed_due_to_payment',
    SUBSCRIPTION_ORDER_FAIL_CHECK_REJECTED = 'subscription_order_fail_check_rejected',
    SUBSCRIPTION_ORDER_FAIL_SHIP_REJECTED = 'subscription_order_fail_ship_rejected',
    SUBSCRIPTION_ORDER_FAIL_CHECK_REJECTED_CONFIRMED = 'subscription_order_fail_check_rejected_confirmed',
    SUBSCRIPTION_ORDER_FAIL_SHIP_REJECTED_CONFIRMED = 'subscription_order_fail_ship_rejected_confirmed',
    SUBSCRIPTION_DIRECT_REFUND_CONFIRMED = 'subscription_direct_refund_confirmed',
    UNPAID_IN_ADVANCE = 'unpaid_in_advance',
    /** 쿠폰발급 안내 */
    COUPON_ISSUED = 'coupon_issued',
    /** 선물완료 */
    GIFT_ORDER_OK = 'gift_order_ok',
    /** 선물취소 - 수동취소 */
    GIFT_ORDER_CANCELLED = 'gift_order_cancelled',
    /** 선물취소 - 기한경과 */
    GIFT_ORDER_EXPIRED = 'gift_order_expired',
}

export interface INote {
    note: string;
    carrierCode?: string;
    carrierNumber?: string;
    attributeOptions: {
        sellableSkuId: string;
        title: string;
    };
}

export interface IOrderStatusDto {
    userNotes: Partial<INote>;
    channelAdminNotes: Partial<INote>;
    supplierNotes: Partial<INote>;
    createDt: ISODate;
    updateDt: ISODate;
    initiatorId: string;
    initiatorName: string;
    initiatorType: InitiatorTypeEnum;
    status: OrderStatusEnum;
}
