/**
 * Two letter language code
 */
export enum LanguageEnum {
    /**
     * Korean
     */
    ko = 'ko',

    /**
     * English
     */
    en = 'en',

    /**
     * Traditional Chinese
     */
    tc = 'tc',

    /**
     * Simplified Chinese
     */
    sc = 'sc',
}

export enum CurrencyCodeEnum {
    Krw = 'KRW',
    Usd = 'USD',
    Hkd = 'HKD',
    Cny = 'CNY',
}

/**
 * Two letter country code
 */
export enum CountryEnum {
    kr = 'KR',
    hk = 'HK',
    cn = 'CN',
}

/**
 * Five character locale code representing language and country
 */
export enum LocaleEnum {
    ko = 'ko',
    en = 'en',
    zhHk = 'zh-HK',
    zhCn = 'zh-CN',
}

export type TranslationCollection = {
    [key in LocaleEnum as string]: Record<string, string | TranslationCollection>;
};

export type CountryData = {
    /**
     * Three letter currency code of marketplace.
     * @example KRW, USD, HKD, etc.
     */
    currencyCode: string;

    /**
     * Localized currency symbol
     * @example '원', 'HK$', '元'
     */
    currencySymbol: string;

    /**
     * Example phone number for input placeholders
     */
    phonePlaceholder: string;

    phoneLength: number;
    phoneRegex: RegExp;
    postalCodeRegex: RegExp;
};

export type LanguageData = {
    language: LanguageEnum;
    momentLocale: string;
};

export type LocaleQuery = LocaleEnum | string | undefined;
