import { useDesignComponents } from '@hooks/use-design-components';

import { useDefaultBackAction } from '@hooks/use-default-back-action';
import { Design } from '@type/harmony-config';
import { ComponentType, useMemo } from 'react';
import HeaderD from './designD/header';
import HeaderC from './designC/header';

type HeaderType =
    | 'main' // 메인형
    | 'stay' // 이탈방지형
    | 'full-modal' // 풀모달형
    | 'complete' // 완료형
    | 'product-detail' // 상품상세형
    | 'page-layout' // 페이지레이아웃형
    | 'default' // 기본형
    | 'brand-page' //브랜드페이지형
    | 'my-page'; //마이페이지형

const headerElementsByTypeMap: Record<string, HeaderType[]> = {
    logo: ['main'],
    notification: ['main'],
    title: ['stay', 'full-modal', 'complete', 'default', 'my-page'],
    back: ['stay', 'product-detail', 'page-layout', 'default', 'brand-page', 'my-page'],
    close: ['full-modal'],
    home: ['complete', 'page-layout', 'default', 'brand-page', 'my-page'],
    search: ['default', 'page-layout', 'brand-page'],
};

interface HeaderProps {
    type: HeaderType;
    title?: string;
    onBackClick?: () => void;
    onCloseClick?: () => void;
    hideOnScroll?: boolean;
}

export interface HeaderInnerProps extends HeaderProps {
    hasLogo: boolean;
    hasNotification: boolean;
    hasTitle: boolean;
    hasBack: boolean;
    hasClose: boolean;
    hasHome: boolean;
    hasSearch: boolean;
}

const Header: React.FC<HeaderProps> = ({ type, onBackClick, ...props }) => {
    const Component = useDesignComponents<ComponentType<HeaderInnerProps>>({
        [Design.DesignC]: HeaderC,
        [Design.DesignD]: HeaderD,
    });

    const { defaultBackAction } = useDefaultBackAction();

    const headerElementsProps = useMemo(() => {
        return {
            hasLogo: headerElementsByTypeMap.logo.includes(type),
            hasNotification: headerElementsByTypeMap.notification.includes(type),
            hasTitle: headerElementsByTypeMap.title.includes(type),
            hasBack: headerElementsByTypeMap.back.includes(type),
            hasClose: headerElementsByTypeMap.close.includes(type),
            hasHome: headerElementsByTypeMap.home.includes(type),
            hasSearch: headerElementsByTypeMap.search.includes(type),
        };
    }, [type]);

    return <Component type={type} onBackClick={onBackClick || defaultBackAction} {...headerElementsProps} {...props} />;
};

export default Header;
