/** @type {import('./types/harmony-config').IHarmonyConfig} */
const CONFIG = {
    title: 'BMW JOY MALL',
    description: 'JoyMall',
    domain: 'joymall',
    membership: 'joymall',
    memberGroup: '',
    pointSystem: 'BMW COIN',
    testEmail: 'super-klife@freedgrouptech.com',
    testPassword: 'asdf123',
    locales: ['ko'],
    defaultLocale: 'ko',
    defaultCurrency: 'KRW',
    backgroundColor: '#FFFFFF',
    primaryTextColor: '#000000',
    primaryFont: 'bmw',
    secondaryFont: 'bmw',
    marketplaceTemplate: ['tangible', 'intangible'],
    loginType: ['membership', 'authHeader'],
    isPwa: false,
    // Keep at bottom of file for pipeline build
    designTemplate: 'designC',
};

module.exports = CONFIG;
