const login = {
    login: {
        oauthLogin: '간편 로그인',
        id: '아이디',
        password: '비밀번호',
        isFirstTime: '처음 방문이신가요?',
        signUpNew: '회원가입',
        emptyId: '아이디를 입력해 주세요.',
        emptyPassword: '비밀번호를 입력해 주세요.',
        emptyIdAndPassword: '아이디, 비밀번호를 입력해 주세요.',
        incorrectIdOrPassword: '아이디 또는 비밀번호를 잘못 입력했습니다. \n입력하신 내용을 다시 확인해주세요',
        findId: '아이디 찾기',
        findPassword: '비밀번호 찾기',

        idPlaceholder: '아이디를 입력해주세요.',
        passwordPlaceholder: '비밀번호를 입력해주세요.',

        login: '로그인',
        guestOrder: '비회원 주문',
        kakaoLogin: '카카오 로그인',
        naverLogin: '네이버 로그인',
        googleLogin: '구글 로그인',
        nonMember: '비회원 주문조회',
        myPage: 'My Page',
        sir: '님',
        hello: '안녕하세요.',
        hello2: '{name}님 안녕하세요!',
        memberInfo: '회원정보 입력하기',
        home: '홈으로',
        congrats: '{name} 님 가입을 축하합니다.',
        congrats2: '{name}님 가입을 축하드립니다.',
        congrats3: '가입을 축하드립니다.',
        signUp: '가입하기',
        detail: '자세히',

        findIdPassword: '아이디/비밀번호 찾기',
        findIdDescription: '입력한 이메일 주소로 인증코드를 보내드립니다.\n이메일을 확인 후 인증코드를 입력해 주시기 바랍니다.',
        findPasswordDescription: '가입 시 사용하신 아이디와 이메일을 입력해주세요.\n입력한 이메일 주소로 인증코드를 보내드립니다.',
        idIsIntro: '고객님의 아이디는 ',
        idIsOutro: ' 입니다.',
        idIs: '고객님의 아이디는 <style>{id}</style> 입니다.',

        navigateToLogin: '로그인 하러 가기',
        resetPassword: '비밀번호 재설정하기',

        setNewPassword: '새 비밀번호 설정하기',
        pleaseSetNewPassword: '새로운 비밀번호를 재설정해 주세요.',
        newPasswordSet: '비밀번호가 재설정되었습니다.',
        newPasswordSetFailed: '비밀번호 재설정에 실패했습니다.',

        newPasswordSetPleaseLogin: '비밀번호가 변경되었습니다. 다시 로그인해 주세요.',

        // NOTE: (James) This is only temporary before non-member purchase is enabled
        authSettingDisabled: '회원가입 설정이 꺼져있습니다. 채널관리자에게 문의 해주세요.',

        findByEmail: '이메일로 찾기',
        findByPass: '휴대폰 본인인증으로 찾기',
        verifyByPass: '휴대폰 본인인증하기',

        checked: '확인이 완료되었습니다.',
    },
};

export default login;
