import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Circle,
    Divider,
    Heading,
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    VStack,
    useDisclosure,
} from '@chakra-ui/react';
import Icon from '@designs/primitives/common/icon';
import { useClipBoard } from '@hooks/use-clip-board';
import { PostData } from '@hooks/use-customer/types';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { useEffect, useState } from 'react';

type TestViewerProps = {
    postData: PostData;
};

export const TestViewer = ({ postData }: TestViewerProps) => {
    const disclosure = useDisclosure();
    const [cookies, setCookies] = useState<string[]>([]);
    const [query, setQuery] = useState<ParsedUrlQuery>({});
    const [localStorageData, setLocalStorageData] = useState<Record<string, string>>({});

    const router = useRouter();
    const copy = useClipBoard();

    useEffect(() => {
        setCookies(document.cookie.split(';'));
    }, []);

    useEffect(() => {
        if (!router.isReady) {
            return;
        }

        setQuery(router.query);
    }, [router.isReady, router.query]);

    useEffect(() => {
        const entries = new Array(localStorage.length)
            .fill(null)
            .map((_, index) => {
                const key = localStorage.key(index);
                if (!key) {
                    return [];
                }

                const value = localStorage.getItem(key);

                return [key, value];
            })
            .filter((item) => !!item.length);

        setLocalStorageData(Object.fromEntries(entries));
    }, []);

    return (
        <>
            <Circle size="32px" bgColor="orange" color="black" pos="fixed" bottom="100px" left="32px" onClick={disclosure.onOpen} zIndex="docked">
                <Icon>cookie</Icon>
            </Circle>
            <Modal {...disclosure}>
                <ModalOverlay />
                <ModalContent p="32px">
                    <ModalHeader />
                    <ModalCloseButton />
                    <Accordion allowMultiple allowToggle>
                        <VStack spacing="16px" w="100%" align="stretch" divider={<Divider variant="solid" />}>
                            <AccordionItem>
                                <AccordionButton>
                                    <Heading flex={1}>Local Storage</Heading>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel>
                                    {localStorageData && (
                                        <>
                                            {Object.entries(localStorageData).map(([key, value], index) => (
                                                <Box key={index} mt="8px">
                                                    {key}: {value}
                                                </Box>
                                            ))}
                                        </>
                                    )}
                                    <Button
                                        mt="32px"
                                        color="white"
                                        onClick={() => {
                                            copy(JSON.stringify(localStorageData, null, 4));
                                        }}
                                    >
                                        Copy
                                    </Button>
                                </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionButton>
                                    <Heading flex={1}>POST data</Heading>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel>
                                    {postData && (
                                        <>
                                            {Object.entries(postData).map(([key, value], index) => (
                                                <Box key={index} mt="8px">
                                                    {key}: {value}
                                                </Box>
                                            ))}
                                        </>
                                    )}
                                    <Button
                                        mt="32px"
                                        color="white"
                                        onClick={() => {
                                            copy(JSON.stringify(postData, null, 4));
                                        }}
                                    >
                                        Copy
                                    </Button>
                                </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionButton>
                                    <Heading flex={1}>Query Strings</Heading>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel>
                                    {Object.entries(query).map(([key, value], index) => (
                                        <Box key={index} mt="8px">
                                            {key}: {value}
                                        </Box>
                                    ))}
                                    <Button
                                        mt="32px"
                                        color="white"
                                        onClick={() => {
                                            copy(JSON.stringify(query, null, 4));
                                        }}
                                    >
                                        Copy
                                    </Button>
                                </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionButton>
                                    <Heading flex={1}>Cookies</Heading>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel>
                                    {cookies.map((cookie, index) => (
                                        <Box key={index} mt="8px">
                                            {cookie}
                                        </Box>
                                    ))}
                                    <Button
                                        mt="32px"
                                        color="white"
                                        onClick={() => {
                                            copy(document.cookie);
                                        }}
                                    >
                                        Copy
                                    </Button>
                                </AccordionPanel>
                            </AccordionItem>
                        </VStack>
                    </Accordion>
                </ModalContent>
            </Modal>
        </>
    );
};
