import { useHarmony } from '@hooks/use-harmony';
import { InnerHtmlProps } from '..';
import { Box, forwardRef } from '@chakra-ui/react';

const InnerHtmlD = forwardRef<InnerHtmlProps, 'div'>(({ content, textColor, ...props }) => {
    const { theme } = useHarmony();

    return (
        <div {...props}>
            <Box
                dangerouslySetInnerHTML={{ __html: content }}
                fontFamily={theme.font.default.light}
                whiteSpace="pre-wrap"
                fontSize="14px"
                lineHeight="22px"
                color={textColor ?? theme.text.body1}
                __css={{
                    p: {
                        lineHeight: '22px',
                        color: textColor ?? theme.text.body1,
                        letterSpacing: '-0.14px',
                        fontWeight: 'thin',
                    },
                    img: {
                        width: '100%',
                        height: 'auto',
                    },
                    '> *': {
                        margin: 'unset',
                        padding: 'unset',
                        font: 'unset',
                        fontSize: 'revert',
                    },
                }}
            />
        </div>
    );
});

export default InnerHtmlD;
