import { IAgreeTermDto } from '@models/term/requestDto/agree-term.dto';

export default class TermsUtils {
    private static _AGREED_TERMS = 'agreed-terms';

    static saveAgreedTerms(terms: IAgreeTermDto[]) {
        sessionStorage.setItem(this._AGREED_TERMS, JSON.stringify(terms));
    }

    static getAgreedTerms(): IAgreeTermDto[] {
        return JSON.parse(sessionStorage.getItem(this._AGREED_TERMS) as string);
    }
}
