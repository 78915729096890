import * as React from 'react';
import { SVGProps } from 'react';

const SvgCheckIndeterminateSmall = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
        <path d="M14 25.5v-3h20v3z" />
    </svg>
);

export default SvgCheckIndeterminateSmall;
