import { CurrencyCodeEnum } from '@locales/types';
import CurrencyRates from '@models/currency-rates/currency-rates';
import moment from 'moment';
import { NextIntlProvider } from 'next-intl';
import { useRouter } from 'next/router';
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { LocaleService } from '@locales/locale.service';

export type GlobalizationProps = {
    localeService: LocaleService;
    currencyService: CurrencyRates;
    globalCurrency: CurrencyCodeEnum;
    setGlobalCurrency: (currency: CurrencyCodeEnum) => void;
};

export const GlobalizationContext = createContext<GlobalizationProps>({} as GlobalizationProps);

interface GlobalizationProviderProps {
    children: ReactNode;
}

const GlobalizationProvider: React.FC<GlobalizationProviderProps> = ({ children }) => {
    const [currency, setCurrency] = useState<CurrencyCodeEnum>(CurrencyCodeEnum.Hkd);
    const { locale } = useRouter();

    // TODO: Fetch currency conversion rates for the base currency set in the app
    // const { data } = useSWR<ICurrencyRatesDto>();

    useEffect(() => {
        const storedCurrency = CurrencyRates.getCurrencyFromStorage();
        if (currency !== storedCurrency) {
            setCurrency(storedCurrency);
        }
    }, []);

    const setGlobalCurrency = (currency: CurrencyCodeEnum) => {
        setCurrency(currency);
        CurrencyRates.setCurrencyInStorage(currency);
    };

    const memoizedValues = useMemo<GlobalizationProps>(
        () => ({
            localeService: new LocaleService(locale),
            // TODO: pass currency conversion rates to the currency service
            currencyService: new CurrencyRates(currency),
            globalCurrency: currency,
            setGlobalCurrency,
        }),
        [locale, currency]
    );

    useEffect(() => {
        moment.locale(memoizedValues.localeService.languageData.momentLocale);
    }, [memoizedValues.localeService]);

    return (
        <GlobalizationContext.Provider value={memoizedValues}>
            <NextIntlProvider messages={memoizedValues.localeService.localeTranslations}>{children}</NextIntlProvider>
        </GlobalizationContext.Provider>
    );
};

export default GlobalizationProvider;

export function useGlobalization(): GlobalizationProps {
    const context = useContext(GlobalizationContext);

    return context;
}
