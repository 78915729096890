import styled from '@emotion/styled';
import { Button } from 'src/legacy/components/blockui';
import { IThemeProps } from '@type/common';
import { CustomButtonProps } from '@legacy/designs/custom-button/custom-button';

const CustomButtonC = styled(Button)<IThemeProps & CustomButtonProps>`
    ${({ theme, variant, color, disabled, fontWeight = 400, width }) => `
    ${width ? `width: ${width};` : ''}
    padding: 0px;
    height: 48px;
    font-family: ${theme.font.default.regular};
    border: ${variant === 'outline' ? `solid 1px ${color} !important` : undefined};
    border-radius: 8px;
    font-weight: ${fontWeight};
    &:hover {
        background-color: ${disabled ? '' : `${variant === 'outline' ? 'white' : theme.primary.default} !important`} ;
        opacity: ${disabled ? '' : '1'} !important;
    }
    &:disabled {
        color:${variant === 'outline' ? theme.gray[300] : theme.gray[400]}; !important;
        background-color: ${variant === 'outline' ? theme.white : theme.gray[200]} !important;
        opacity: 100% !important;
        border:${variant !== 'outline' && '1px solid ${theme.gray[300]'} !important;
    }
    `}
`;

export default CustomButtonC;
