import ConversionUtils from '@utils/conversion.utils';
import DiscountItem from './discount-item';
import { IFinalCartCalculationsDto } from './responseDto/final-cart-calculations.dto';

class FinalCartCalculations {
    private _groupDeliveryDiscount: number;
    private _productDiscountItems: DiscountItem[];
    private _regionalFees: number;
    private _totalDeliveryFee: number;
    private _totalPgAmountToPay: number;
    private _totalProductPrice: number;
    private _totalDiscount: number;

    private _totalDiscountExceptCoupon: number;
    private _totalUsedPoints: number;

    constructor(finalCartCalculationsDto?: IFinalCartCalculationsDto) {
        this._groupDeliveryDiscount = finalCartCalculationsDto?.groupDeliveryDiscount || 0;
        this._productDiscountItems = finalCartCalculationsDto?.productDiscountItems?.map((item) => new DiscountItem(item)) || [];
        this._regionalFees = finalCartCalculationsDto?.regionalFees || 0;
        this._totalDeliveryFee = finalCartCalculationsDto?.totalDeliveryFee || 0;
        this._totalProductPrice = finalCartCalculationsDto?.totalProductPrice || 0;
        this._totalPgAmountToPay = finalCartCalculationsDto?.totalPgAmountToPay || 0;
        this._totalDiscount = finalCartCalculationsDto?.totalDiscountPriceForProduct || 0;

        this._totalDiscountExceptCoupon = this._getTotalDiscountExceptCoupon(this._productDiscountItems);
        this._totalUsedPoints = this._getTotalUsedPoints(this._productDiscountItems);
    }

    private _getTotalDiscountExceptCoupon(productDiscountItems: DiscountItem[]): number {
        return productDiscountItems
            .filter((item) => !item.isCouponDiscount)
            .reduce<number>((accum, item) => {
                return accum + item.totalDiscountPrice;
            }, 0);
    }

    private _getTotalUsedPoints(productDiscountItems: DiscountItem[]): number {
        return productDiscountItems.find((item) => item.isSkuPointDiscount)?.totalDiscountPrice || 0;
    }

    get totalProductPrice(): number {
        return this._totalProductPrice;
    }

    get totalProductPriceString(): string {
        return ConversionUtils.numberToString(this._totalProductPrice);
    }

    get totalDeliveryFee(): number {
        return this._totalDeliveryFee;
    }

    get totalDeliveryFeeString(): string {
        return ConversionUtils.numberToString(this._totalDeliveryFee);
    }

    get totalPgAmountToPay(): number {
        return this._totalPgAmountToPay;
    }

    get totalPgAmountToPayString(): string {
        return ConversionUtils.numberToString(this._totalPgAmountToPay);
    }

    get groupDeliveryDiscount(): number {
        return this._groupDeliveryDiscount;
    }

    get groupDeliveryDiscountString(): string {
        return ConversionUtils.numberToString(this._groupDeliveryDiscount);
    }

    get deliveryFeeAfterDiscount(): number {
        return this._totalDeliveryFee + this._regionalFees - this._groupDeliveryDiscount;
    }

    get hasRegionalFee(): boolean {
        return this._regionalFees > 0;
    }

    get regionalFeesString(): string {
        return ConversionUtils.numberToString(this._regionalFees);
    }

    get deliveryFeeAfterDiscountString(): string {
        return ConversionUtils.numberToString(this.deliveryFeeAfterDiscount);
    }

    get combinedProductAndDeliverFee(): number {
        return this._totalProductPrice + this._totalDeliveryFee;
    }

    get totalCombinedFee(): number {
        return this.combinedProductAndDeliverFee - this._groupDeliveryDiscount + this._regionalFees;
    }

    get getTotalCombinedFeeString(): string {
        return ConversionUtils.numberToString(this.totalCombinedFee);
    }

    get hasDiscount(): boolean {
        return this._totalDiscount > 0;
    }

    get totalDiscountString(): string {
        return ConversionUtils.numberToString(this._totalDiscount);
    }

    get productDiscountItems(): DiscountItem[] {
        return this._productDiscountItems;
    }

    get totalProductPriceExceptCoupon(): number {
        return this._totalProductPrice - this._totalDiscountExceptCoupon;
    }

    get totalPgAmountToPayAfterDiscount(): number {
        return Math.max(this._totalPgAmountToPay - this._totalDiscount, 0);
    }

    get totalPgAmountToPayAfterDiscountString(): string {
        return ConversionUtils.numberToString(this.totalPgAmountToPayAfterDiscount);
    }

    appliedCouponDiscount(): number {
        const totalCouponDiscount = this._totalDiscount - this._totalDiscountExceptCoupon;
        return Math.min(this.totalProductPriceAfterPoints(), totalCouponDiscount);
    }

    totalProductPriceAfterPoints(): number {
        return this._totalProductPrice - this._totalUsedPoints;
    }

    totalProductPriceAfterPointsString(): string {
        return ConversionUtils.numberToString(this.totalProductPriceAfterPoints());
    }
}

export default FinalCartCalculations;
