import { Flex, HStack } from '@chakra-ui/react';
import { useAllocateSpaceForFixedBottomElement } from '@hooks/use-allocate-space-for-fixed-bottom-element';
import { useExtension } from '@hooks/use-extension';
import { useHarmony } from '@hooks/use-harmony';
import { BottomButtonsProps } from '..';

const BottomButtonsA: React.FC<BottomButtonsProps> = ({ children, isFixed, ...props }) => {
    const { theme } = useHarmony();
    const { extensionStatus } = useExtension();
    const { ref, spacingBox } = useAllocateSpaceForFixedBottomElement([children]);

    return (
        <>
            <Flex ref={ref} w="100%" justifyContent="center" bgColor={theme.background.white} {...props}>
                <HStack spacing="8px" w="100%" maxW={extensionStatus?.maxScreenWidth} p="8px 16px 24px">
                    {children}
                </HStack>
            </Flex>
            {isFixed && spacingBox}
        </>
    );
};

export default BottomButtonsA;
