import { IAcrossAdnDto } from './responseDto/across-adn.dto';

class DbScripts {
    private _registrationDbScript: string[];
    private _participationEventDbScript: string[];

    constructor(dto: IAcrossAdnDto['scriptSetting']['dbScripts']) {
        this._registrationDbScript = this._extractScriptParams(dto.registrationDbScript);
        this._participationEventDbScript = this._extractScriptParams(dto.participationEventDbScript);
    }

    private _extractScriptParams(script: string): string[] {
        const re = /fn_across_adn_btn_ok\([\s\t\n]*'(.+)',[\s\t\n]*'(.+)'[\s\t\n]*\)/g;
        const matches = re.exec(script);

        return !!matches ? matches.slice(1) : [];
    }

    get registrationDbScript() {
        return this._registrationDbScript;
    }

    get participationEventDbScript() {
        return this._participationEventDbScript;
    }
}

export default DbScripts;
