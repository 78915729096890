import CONFIG from '@config';
import { Design } from '@type/harmony-config';
import { ColorHex } from '@type/models';
import { FontType, FontWeight, IHarmonyTheme } from '@type/theme';
import { hexToCSSFilter } from 'hex-to-css-filter';

export default class ThemeUtils {
    static readonly zIndex = {
        base: 0,
        below: -1,
        above: 1,
        badge: 2,
        dropdown: 50,
        header: 100,
        floating: 200,
        fullDrawer: 1300,
        overlay: 1400,
        drawer: 1500,
        alert: 1600,
        toast: 2000,
    } as const;

    /**
     * Get font string based on font weight and type.
     */
    static getFont(weight: FontWeight, type: FontType, theme: IHarmonyTheme): string {
        switch (weight) {
            case 'thin':
                return theme.font[type].light;
            case 'medium':
                return theme.font[type].medium;
            case 'semiBold':
                return theme.font[type].semiBold;
            case 'bold':
                return theme.font[type].bold;
            case 'extraBold':
                return theme.font[type].extraBold;
            case 'regular':
            default:
                return theme.font[type].regular;
        }
    }

    /**
     * Generates css filter settings to convert a black svg to the design hex color code.
     */
    static colorHexToCssFilter(colorHex: ColorHex): string {
        return hexToCSSFilter(colorHex).filter.slice(0, -1);
    }

    static hexToRgba = (hex: string, alpha: number) => {
        const [r, g, b] = ThemeUtils._hexToRgbValues(hex);
        return `rgba(${r},${g},${b},${alpha})`;
    };

    static isColorLight = (hex: string) => {
        const [r, g, b] = ThemeUtils._hexToRgbValues(hex);

        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        return luminance > 0.5;
    };

    private static _hexToRgbValues = (hex: ColorHex): [r: number, g: number, b: number] => {
        const parsed = hex.match(/\w\w/g)?.map((x) => parseInt(x, 16)) as [number, number, number];
        return parsed || [0, 0, 0];
    };

    static get headerHeight() {
        switch (CONFIG.designTemplate) {
            case Design.DesignA:
                return 50;
            case Design.DesignB:
                return 52;
            case Design.DesignC:
                return 54;
            case Design.DesignD:
                return 56;
            default:
                return 50;
        }
    }

    static get bottomBarHeight() {
        switch (CONFIG.designTemplate) {
            case Design.DesignA:
                return 0;
            case Design.DesignB:
                return 60;
            case Design.DesignC:
                return 82;
            case Design.DesignD:
                return 72;
            default:
                return 0;
        }
    }
}
