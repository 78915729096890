import { Body1BProps } from '..';
import AbstractedText from '@components/abstracted-text';

const Body1BD: React.FC<Body1BProps> = (props) => {
    return (
        <AbstractedText fontSize="16px" weight="thin" lineHeight="16px" {...props}>
            {props.children}
        </AbstractedText>
    );
};

export default Body1BD;
