import Link, { LinkProps } from 'next/link';
import { ReactNode } from 'react';

interface CustomLinkProps extends Omit<LinkProps, 'href'> {
    /** Allow undefined or null for href. */
    href: LinkProps['href'] | undefined | null;
    children: ReactNode;
}

/**
 * An wrapper that allows undefined or null for href,
 * and that doesn't use next's `Link` component if there's no href.
 */
const CustomLink = ({ children, ...props }: CustomLinkProps) => {
    if (!props.href) {
        return <>{children}</>;
    }

    return (
        <>
            <Link {...props} href={props.href}>
                {children}
            </Link>
        </>
    );
};

export default CustomLink;
