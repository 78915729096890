import styled from '@emotion/styled';
import { FailureSymbolProps } from '..';

const FailureSymbolC: React.FC<FailureSymbolProps> = ({}) => {
    return (
        <FailCircle>
            <CrossOne />
            <CrossTwo />
        </FailCircle>
    );
};

export default FailureSymbolC;

const FailCircle = styled.div`
    border-radius: 999px;
    width: 46px;
    height: 46px;
    position: relative;
    background: #e3211e;
`;

const Cross = styled.div`
    background: white;
    width: 28px;
    height: 5px;
    border-radius: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
`;

const CrossOne = styled(Cross)`
    transform: translate(-50%, -50%) rotate(-45deg);
`;

const CrossTwo = styled(Cross)`
    transform: translate(-50%, -50%) rotate(45deg);
`;
