import CacheKeys from '@api/cache-keys';
import Cart from '@models/cart';
import { ICreateCartOrderDto } from '@models/cart/requestDto/create-cart.dto';
import { ICreateSubscriptionOrderDto } from '@models/subscription-order/requestDto/create-subscription-order.dto';
import { IQuerySubscriptionDto } from '@models/subscription/requestDto/query-subscritption.dto';
import { PointHistoryQuery, SkuQuantity, SkuQueryString } from '@type/api';
import { CouponId, ProductId, SkuId, SubscriptionId, TransactionId, UserId } from '@type/models';
import GuestUtils from '@utils/guest.utils';

abstract class TransactionCacheKeys extends CacheKeys {
    protected static _skuQuantitiesToQuery(skuQuantityQueries: SkuQueryString[]): string {
        return skuQuantityQueries.join('&skus=');
    }

    protected static _cartOrderQuery(postalCode?: string, transactionCouponId?: CouponId): string {
        const couponIdQuery = transactionCouponId ? `transaction_coupon_id=${transactionCouponId}` : '';
        return postalCode ? `?postal_code=${postalCode}&${couponIdQuery}` : `?${couponIdQuery}`;
    }

    protected static _directOrderQuery(directOrder: ICreateCartOrderDto): string {
        const postalQuery = this._cartOrderQuery(directOrder.postalCode, directOrder.transactionCouponId);
        const orderQuery = `product_id=${directOrder.productId}&skus=${directOrder.skus.join('&skus=')}`;
        const isGiftOrderQuery = directOrder.isGiftOrder ? 'is_gift_order=true' : '';
        return postalQuery ? `${postalQuery}&${orderQuery}&${isGiftOrderQuery}` : `?${orderQuery}&${isGiftOrderQuery}`;
    }

    static tangibleOrderAggregateCacheKey(monthRange?: number): string {
        return `/skus/status/counts-${monthRange}-tangible`;
    }

    static intangibleOrderAggregateCacheKey(monthRange?: number): string {
        return `/skus/status/counts-${monthRange}-intangible`;
    }

    static giftOrderAggregateCacheKey(): string {
        return `/skus/status/counts-gift`;
    }

    static deliveryTrackingCacheKey(skuId: SkuId): string {
        return `/skus/${skuId}/delivery-tracking`;
    }

    static skuClaimCacheKey(skuId: SkuId): string {
        return `/skus/${skuId}/claims`;
    }

    static skuOrderStatusListCacheKey(skuId: SkuId): string {
        return `/skus/${skuId}/order-status`;
    }

    static transactionCacheKey(transactionId?: TransactionId): string {
        if (GuestUtils.isGuest) {
            return this.guestTransactionCacheKey();
        }

        if (!!transactionId) {
            return this.transactionByIdCacheKey(transactionId);
        }

        return '';
    }

    private static transactionByIdCacheKey(transactionId: TransactionId): string {
        return `/transactions/${transactionId}`;
    }

    private static guestTransactionCacheKey(): string {
        return `/guest/transactions/${GuestUtils.getGuestInfo()?.transactionSn}`;
    }

    static calcOrderPriceCacheKey(productId: ProductId, skuQuantities: SkuQuantity[]): string {
        return `/direct/order?product_id=${productId}&skus=${this._skuQuantitiesToQuery(Cart.skuQuantityToQuery(skuQuantities))}`;
    }

    static calcSubscriptionOrderPriceCacheKey(subscriptionOrder: ICreateSubscriptionOrderDto): string {
        return `/subscription/order/${Object.entries(subscriptionOrder).toString()}`;
    }

    static cartCacheKey(customerId?: UserId): string {
        return `/cart/${customerId}`;
    }

    static cartOrderCacheKey(postalCode?: string, transactionCouponId?: CouponId): string {
        return `/cart/order${this._cartOrderQuery(postalCode, transactionCouponId)}`;
    }

    static directOrderCacheKey(directOrder: ICreateCartOrderDto): string {
        return `/direct/order${this._directOrderQuery(directOrder)}`;
    }

    static availablePointsCacheKey(): string {
        return `/users/point`;
    }

    static pointHistoryCacheKey(query: PointHistoryQuery): string {
        return `/users/point/history/${Object.values(query).join(',')}`;
    }

    static subscriptionHistoryCacheKey(query: IQuerySubscriptionDto): string {
        return `/subscription/${Object.entries(query).toString()}`;
    }

    static subscriptionCacheKey(subscriptionId: SubscriptionId): string {
        return `/subscription/${subscriptionId}`;
    }

    static subscriptionPaymentDetailsCacheKey(subscriptionId: SubscriptionId, monthRange?: number): string {
        return `/subscription/${subscriptionId}/payment-details?monthRange=${monthRange}`;
    }

    static checkMIDTypeCacheKey(pgSettingId: string): string {
        return `/pg-setting/${pgSettingId}`;
    }

    static transactionsCacheKey(): string {
        return `/transactions`;
    }
}

export default TransactionCacheKeys;
