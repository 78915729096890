import ConversionUtils from '@utils/conversion.utils';
import { ITierDto } from './responseDto/customer.dto';

export enum TierEnum {
    Blue = 'blue',
    Silver = 'silver',
    Gold = 'gold',
    Black = 'black',
}

class Tier {
    private _id: string;
    private _name: TierEnum;
    private _pointsAccumulationRate: number;

    constructor(tierDto: ITierDto) {
        this._id = tierDto.id;
        this._name = tierDto.name;
        this._pointsAccumulationRate = tierDto.pointsAccumulationRate;
    }

    get id(): string {
        return this._id;
    }

    get name(): TierEnum {
        return this._name;
    }

    get pointsAccumulationRate(): number {
        return this._pointsAccumulationRate;
    }

    /**
     * Returns the points per product price
     * */
    pointsPerProductPrice(productPrice: number): string {
        return ConversionUtils.numberToString(Math.floor(this._pointsAccumulationRate * productPrice));
    }
}

export default Tier;
