const home = {
    home: {
        view: '전체보기',
        percentSale: 'UP TO 30% SALE',
        saleStarts: '곧 세일이 시작됩니다',
        saleHasEnded: '세일이 종료되었습니다',
        countdownDay: '일',
        countdownDay2: 'D',
        countdownHour: '시간',
        countdownHour2: 'H',
        countdownMin: '분',
        countdownMin2: 'M',
        countdownSec: '초',
        countdownSec2: 'S',
        countdown: '남았어요!',
        goToCategory: '카테고리 페이지로 이동',
        main: '메인',
        timeSale: '원데이 특가',
        best: '베스트',
        familyMonth: '가정의달',
        event: '이벤트',
        goDetailsPage: '자세히보기',
        ignoreForToday: '오늘 그만 보기',
        mypage: '마이페이지',
    },
};

export default home;
