import { useDesignComponents } from '@hooks/use-design-components';

import { ModalProps } from '@chakra-ui/react';
import { Design } from '@type/harmony-config';
import { BaseDialogueComponentProps } from '@type/theme';
import { useTranslations } from 'next-intl';
import { ComponentType } from 'react';
import PopUpD from './designD';

export interface PopUpProps extends BaseDialogueComponentProps<ModalProps> {}

const PopUp: React.FC<PopUpProps> = ({ onClose, onConfirmButtonClick, onCancelButtonClick, buttonText, cancelButtonText, isCloseOnConfirm = true, ...props }) => {
    const t = useTranslations();
    const Component = useDesignComponents<ComponentType<PopUpProps>>({
        [Design.DesignA]: PopUpD,
        [Design.DesignD]: PopUpD,
    });

    return (
        <Component
            onClose={onClose}
            buttonText={buttonText ?? t('common.confirm')}
            cancelButtonText={cancelButtonText ?? t('common.cancel')}
            onConfirmButtonClick={() => {
                onConfirmButtonClick?.();
                if (isCloseOnConfirm) {
                    onClose();
                }
            }}
            onCancelButtonClick={() => {
                onCancelButtonClick?.();
                onClose();
            }}
            {...props}
        />
    );
};

export default PopUp;
