export enum TransactionErrorsEnum {
    NotFoundTransaction = 'not_found',
    GuestUserPhoneError = 'guest_user_phone_error',
    GuestUserPasswordError = 'guest_user_password_error',
    ExternalPointErrorMustBeResolved = 'external_point_error_must_be_resolved',
}

export enum AuthErrorsEnum {
    InvalidPassword = 'invalid_password',
    IdVerificationFailed = 'id_verification_failed',
    UserNotExist = 'user_not_exist',
    DuplicatedPassword = 'duplicate_password',
    PwResetRequired = 'pw_reset_required',
}

export enum ChannelErrorsEnum {
    CouponDeprecated = 'coupon_deprecated',
    LgeAppPushDisabled = 'lge_app_push_disabled',
    CouponDownloaded = 'coupon_downloaded',
    CouponOutOfStock = 'coupon_out_of_stock',
    CouponExpired = 'coupon_expired',
    CouponIssuanceStopped = 'coupon_issuance_stopped',
}
