import { useDesignComponents } from '@hooks/use-design-components';

import styled from '@emotion/styled';
import { IconButton, IconButtonProps, IconVariant } from 'src/legacy/components/blockui';
import { Design } from '@type/harmony-config';

interface CustomIconButtonProps extends IconButtonProps {
    padding?: string;
}

const CustomIconButton: React.FC<CustomIconButtonProps> = ({ children, padding, ...props }) => {
    const iconVariant = useDesignComponents<IconVariant>({
        [Design.DesignA]: IconVariant.outlined,
        [Design.DesignB]: IconVariant.rounded,
        [Design.DesignC]: IconVariant.outlined,
    });
    return (
        <CustomButton variant={iconVariant} padding={padding} {...props}>
            {children}
        </CustomButton>
    );
};

export default CustomIconButton;

const CustomButton = styled(IconButton)<CustomIconButtonProps>`
    padding: ${({ padding }) => padding || '6px'} !important;
`;
