import styled from '@emotion/styled';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { ReactNode, useEffect, useState } from 'react';
import { Checkable } from '../types';

type StyledRadioContainerProps = {
    gap?: string;
    /**
     * @default: true
     */
    row?: boolean;
    /**
     * @default: false
     */
    wrap?: boolean;
};

export type RadioGroupProps = StyledRadioContainerProps & {
    items: Array<Checkable>;
    onToggle: (value: Checkable) => void;
    disabled?: boolean;
    margin?: string;
    color?: string;
    hideLabel?: boolean;
    initialChecked?: Checkable;
    checked?: Checkable;
    labelColor?: string;
    className?: string;
    renderLabel?: (children: ReactNode) => ReactNode;
};

type RadioDisabledProps = {
    disabled?: boolean;
};

const RadioGroup = ({
    items,
    onToggle,
    row = true,
    wrap = false,
    disabled = false,
    margin,
    color,
    hideLabel,
    initialChecked,
    checked,
    labelColor,
    gap,
    className,
    renderLabel,
    ...props
}: RadioGroupProps) => {
    const defaultChecked = initialChecked ?? items[0];
    const [value, setValue] = useState<Checkable>(defaultChecked);

    useEffect(() => {
        checked && setValue(checked);
    }, [checked]);

    return (
        <RadioGroupPrimitive.Root
            defaultValue={defaultChecked.label}
            value={checked ? value.label : undefined}
            onValueChange={(label: string) => onToggle(items.find((item) => item.label === label) ?? defaultChecked)}
            {...props}
        >
            <StyledContainer row={row} wrap={wrap} gap={gap}>
                {items.map((item) => {
                    return (
                        <StyledRadioContainer key={item.value as string} margin={margin}>
                            <StyledRadio value={item.label} id={'r-' + item.label} disabled={disabled} color={color} className={className}>
                                <StyledIndicator disabled={disabled} color={color} />
                            </StyledRadio>
                            <div>
                                {!hideLabel && (
                                    <StyledLabel htmlFor={'r-' + item.label} color={labelColor}>
                                        {renderLabel ? renderLabel(item.label) : item.label}
                                    </StyledLabel>
                                )}
                                {item.component}
                            </div>
                        </StyledRadioContainer>
                    );
                })}
            </StyledContainer>
        </RadioGroupPrimitive.Root>
    );
};

const StyledIndicator = styled(RadioGroupPrimitive.Indicator)<RadioDisabledProps>`
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 50%;
    border: 2px solid ${(props) => (props.disabled ? props.theme.gray[900] + '50' : props.color || props.theme.primary.lighten[300])};
    margin: -2px;
    &::after {
        content: '';
        display: block;
        width: 0.675rem;
        height: 0.675rem;
        border-radius: 50%;
        background: ${(props) => (props.disabled ? props.theme.gray[900] + '50' : props.color || props.theme.primary.lighten[300])};
    }
`;

const StyledRadio = styled(RadioGroupPrimitive.Item)<RadioDisabledProps>`
    all: unset;
    background: white;
    width: 1.075rem;
    height: 1.075rem;
    border-radius: 50%;
    border: 2px solid ${(props) => props.theme.gray[900]}50;
    &:hover {
        border-color: ${(props) => (props.disabled ? props.theme.gray[900] + '50' : props.color || props.theme.primary.lighten[300])};
    }
`;

const StyledContainer = styled.div<StyledRadioContainerProps>`
    display: flex;
    gap: ${({ gap }) => gap || 0};
    flex-direction: ${(props) => (props.row ? 'row' : 'column')};
    flex-wrap: ${(props) => (props.wrap ? 'wrap' : 'none')};
`;

const StyledRadioContainer = styled.div<{ margin?: string }>`
    display: flex;
    flex-shrink: 0;
    margin: ${({ margin }) => margin || '20px'};
    align-items: center;
    cursor: pointer;
`;

const StyledLabel = styled.label<{ color?: string }>`
    color: ${({ color }) => color || 'black'};
    font-size: 1rem;
    line-height: 1rem;
    padding-left: 0.5rem;
    cursor: pointer;
`;

export default RadioGroup;
