import styled from '@emotion/styled';
import { BlockHStack, BlockLoader } from 'src/legacy/components/blockui';

const LegacyFullPageLoader: React.FC = () => {
    return (
        <LoaderContainer>
            <BlockHStack height="100%" align="center" alignItems="center">
                <BlockLoader />
            </BlockHStack>
        </LoaderContainer>
    );
};

export default LegacyFullPageLoader;

const LoaderContainer = styled.div`
    height: 80vh;
    width: 100%;
`;
