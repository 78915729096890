const pages = {
    pages: {
        saleStartsSoon: '곧 세일이 시작됩니다',
        saleHasEnded: '세일이 종료되었습니다',
        countdown: '{day}일 {hour}시간 {min}분 {second}초 남았어요',
        onGoingEvent: '진행중인 이벤트',
        expiredEvent: '종료된 이벤트',
        eventHasEnded: '이벤트가 종료되었습니다.',
        now: '현재 ',
        count: '{count}명이 ',
        count2: '{count}명 ',
        participated: '참여중입니다.',
        participated2: '참여 중',
        alreadyParticipated: '응모완료',
        alreadyParticipated2: '응모 완료',
        participateSuccess: '이벤트에 응모했습니다.',
        pending: '곧 응모가 시작됩니다',
        pending2: '이벤트가 곧 시작됩니다.',
        notLoggedIn: `로그인이 필요한 서비스입니다.\n로그인 후 이용해 주세요.`,
        noOneIsParticipating: '현재 참여중인 사람이 없습니다. \n위의 버튼을 클릭해 이벤트에 응모해보세요!',
        noOneIsParticipating2: '현재 참여중인 사람이 없습니다. \n이벤트에 참여해보세요!',
        noActiveEvents: '진행 중인 이벤트가 없습니다.',
        noExpiredEvents: '종료된 이벤트가 없습니다.',
        eventEnded: '이벤트가 종료 되었습니다.',
        participationEnded: '응모가 종료되었습니다.',
        period: '기간',
        serviceIsBeingPrepared: '서비스 준비중 입니다.',
        pleaseLookForward: '곧 찾아올 서비스를 기대해 주세요!',
    },
};

export default pages;
