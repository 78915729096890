import { Box, Center, VStack } from '@chakra-ui/react';
import BottomButtons from '@designs/grouped/common/bottom-buttons';
import DetailsBox from '@designs/grouped/common/complete-page/details-box';
import FailHeading from '@designs/grouped/common/complete-page/fail-heading';
import SuccessHeading from '@designs/grouped/common/complete-page/success-heading';
import FullPageLoader from '@designs/grouped/common/full-page-loader';
import Header from '@designs/grouped/main/header';
import MainButton from '@designs/primitives/common/button/main-button';
import Body2B from '@designs/primitives/typography/body-2-b';
import { useHarmony } from '@hooks/use-harmony';
import { CompletePageContentInnerProps } from '..';

const CompletePageContentC: React.FC<CompletePageContentInnerProps> = ({ variant, pageTitle, heading, description, details, buttons, isLoading, additionalContent }) => {
    const { theme } = useHarmony();

    return (
        <>
            <Header type="complete" title={pageTitle} />
            {isLoading ? (
                <FullPageLoader />
            ) : (
                <>
                    <Center w="100%" pos="absolute" top="50%" transform="auto" translateY="-50%">
                        <Box w="100%" px="16px">
                            <VStack w="100%" spacing="4px">
                                {variant === 'success' && <SuccessHeading>{heading}</SuccessHeading>}
                                {variant === 'error' && <FailHeading>{heading}</FailHeading>}
                                {description && (
                                    <Body2B color={theme.text.body1} textAlign="center" whiteSpace="pre-wrap">
                                        {description}
                                    </Body2B>
                                )}
                            </VStack>
                            <Box marginTop="8px">{additionalContent}</Box>
                        </Box>
                    </Center>
                    {details.map((details, index) => (
                        <DetailsBox details={details} key={index} />
                    ))}
                    <BottomButtons isFixed>
                        {buttons.map(({ children, ...props }, index) => (
                            <MainButton key={index} {...props}>
                                {children}
                            </MainButton>
                        ))}
                    </BottomButtons>
                </>
            )}
        </>
    );
};

export default CompletePageContentC;
