const footer = {
    footer: {
        address: '주소',
        ceo: '대표이사',
        companyRegistration: '사업자등록번호',
        mailReport: '통신판매사업신고',
        email: '이메일',
        faq: 'FAQ',
        notice: '공지사항',
        inquiry: '1:1문의',
        customerCenter: '고객센터',
    },
};

export default footer;
