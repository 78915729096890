import { ImageUrl } from '@type/models';

export enum FrameComponentTypeEnum {
    Header = 'header',
    TopMenu = 'top_menu',
    BottomMenu = 'bottom_menu',
    Footer = 'footer',
}

export interface IFrameDto {
    componentType: string;
}

export interface IFrameHeaderDto extends IFrameDto {
    componentType: 'header';
    headerLogoImage: ImageUrl;
}

export interface IFrameMenuItemDto extends IFrameDto {
    name: string;
    isForPage: boolean;
    categoryName: string | null;
    categoryId: string | null;
    pageUrl: string | null;
}

export enum FrameTopMenuItemTypeEnum {
    Self = 'self',
    Category = 'category',
}

export interface IFrameTopMenuDto extends IFrameDto {
    componentType: 'top_menu';
    itemType: FrameTopMenuItemTypeEnum;
    items: IFrameMenuItemDto[];
}

export interface IFrameBottomMenuDto extends IFrameDto {
    componentType: 'bottom_menu';
    isEditable: boolean;
    items: IFrameMenuItemDto[];
}

export interface IFrameFooterDto extends IFrameDto {
    componentType: 'footer';
    footerCopyright: string;
    footerBusinessInfo: string;
}

export type IAllFramesDto = IFrameHeaderDto | IFrameTopMenuDto | IFrameBottomMenuDto | IFrameFooterDto;
export type IFrameArrayDto = IAllFramesDto[];
export type IFrameTypeDtoMap = Map<FrameComponentTypeEnum | string, IAllFramesDto>;
