import ApiService from '@api/api.service';
import { authClient } from '@api/auth/auth.client';
import HARMONY_CONFIG from '@config';
import { IAcrossAdnDto } from '@models/across-settings/responseDto/across-adn.dto';
import { IBrandDetailDto } from '@models/brand/responseDto/brand-detail.dto';
import { ICategoryDto } from '@models/category-collection/responseDto/category.dto';
import { ISubcategoryDto } from '@models/category-collection/responseDto/subcategory.dto';
import { IChannelComponentDto } from '@models/channel-component/responseDto/channel-component.dto';
import { IPostParticipantsDto } from '@models/channel-settings/requestDto/post-participants.dto';
import { IChannelSettingsDto, IEventCommentItemDto } from '@models/channel-settings/responseDto/channel-settings.dto';
import { IQueryCouponDto } from '@models/coupon/requestDto/query-coupon.dto';
import { ICouponDto } from '@models/coupon/responseDto/coupon.dto';
import { IFaqCategoryDto } from '@models/faq-category/responseDto/faq-category.dto';
import { IFaqCollectionDto } from '@models/faq-collection/responseDto/faq-collection.dto';
import { IFrameArrayDto } from '@models/frame-collection/responseDto/frame.dto';
import { IGiftSettingDto } from '@models/gift-settings/responseDto/gift-setting.dto';
import { IGoogleAnalyticsPlusDto } from '@models/google-analytics/responseDto/google-analytics-plus.dto';
import { IGoogleAnalyticsDto } from '@models/google-analytics/responseDto/google-analytics.dto';
import { IGpsSettingDto } from '@models/gps-settings/responseDto/gps-setting.dto';
import { ICreateUpdateInquiryDto } from '@models/inquiry-collection/requestDto/create-inquiry.dto';
import { IInquiryQueryDto } from '@models/inquiry-collection/requestDto/query-inquiry.dto';
import { IInquiryCollectionDto } from '@models/inquiry-collection/responseDto/inquiry-collection.dto';
import { IInquiryDto } from '@models/inquiry-collection/responseDto/inquiry.dto';
import { IKeywordDto } from '@models/keyword/responseDto/keyword.dto';
import { INoticeCollectionDto } from '@models/notice-collection/responseDto/notice-collection.dto';
import { INoticeDto } from '@models/notice-collection/responseDto/notice.dto';
import { IOfflineStoreItemDto } from '@models/offline-store-item/responseDto/offline-store-item.dto';
import { IOfflineStoreListItemDto } from '@models/offline-store-list-item/responseDto/offline-store-list-item.dto';
import { IGetOfflineStoresDto } from '@models/offline-store/requestDto/get-offline-stores.dto';
import { IOfflineStoreDto, IOfflineStoreMembershipDto, IOfflineStoreSettingDto } from '@models/offline-store/responseDto/offline-store.dto';
import { IPageDto } from '@models/page/responseDto/page.dto';
import { IPopupDto } from '@models/popup/responseDto/popup.dto';
import { IProductPageSettingsDto } from '@models/product-page-settings/responseDto/product-page-settings.dto';
import { ISellPricePolicyDto } from '@models/sell-price-policy/responseDto/sell-price-policy.dto';
import { ITermDto } from '@models/term/responseDto/term.dto';
import { FaqContentQueryOptions, PaginatedQuery, TermDisplayArea } from '@type/api';
import { NoticeId, PaginatedResults } from '@type/models';
import EnvironmentUtils from '@utils/environment.utils';
import { AxiosResponse } from 'axios';
import snakecaseKeys from 'snakecase-keys';

/**
 * Api client handling channel related logic
 * @link https://api-channel-dev.harmonycvm.com/docs#/
 */
class ChannelClient {
    private static readonly CHANNELS_B_API_PREFIX = '/api/v1/external/b/channels/';
    private static readonly CHANNELS_API_PREFIX = '/api/v1/external/e/channels/';
    private static readonly _client = new ApiService(`https://api-channel${EnvironmentUtils.API_ENV}.harmonycvm.com`, authClient);

    /**
     * Returns channel specific components intended for the home page.
     */
    static async getChannelComponents(): Promise<IChannelComponentDto[]> {
        return this._client.get<IChannelComponentDto[]>(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/components`);
    }

    /**
     * Returns channel specific frames
     */
    static async getChannelFrames(): Promise<IFrameArrayDto> {
        return this._client.get<IFrameArrayDto>(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/frames`);
    }

    /**
     * Returns popup data
     */
    static async getPopup(): Promise<IPopupDto> {
        return this._client.get<IPopupDto>(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/active-pop-up`);
    }

    /**
     * Returns category data
     */
    static async getCategories(): Promise<ICategoryDto[]> {
        return this._client.get<ICategoryDto[]>(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/categories`);
    }

    /**
     * Returns dynamic page data
     */
    static async getPages(path: string): Promise<IPageDto> {
        return this._client.get<IPageDto>(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/pages-v2`, {
            params: {
                page_path: `/${path}`,
            },
        });
    }

    /**
     * Returns an array of terms and conditions
     */
    static async getTerms(displayArea: TermDisplayArea): Promise<ITermDto[]> {
        return this._client.get<ITermDto[]>(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/terms`, {
            params: {
                display_area: displayArea,
            },
        });
    }

    /**
     * Returns an faq collection
     */
    static async getFaqs(query: FaqContentQueryOptions): Promise<IFaqCollectionDto> {
        return this._client.get<IFaqCollectionDto>(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/faqs`, {
            params: {
                ...(query.categoryId && { faq_category_id: query.categoryId }),
                page: query.page,
                items_per_page: query.itemsPerPage,
            },
        });
    }

    /**
     * Returns an faq collection
     */
    static async getFaqCategories(): Promise<IFaqCategoryDto[]> {
        return this._client.get<IFaqCategoryDto[]>(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/faqs/categories`);
    }

    /**
     * Returns list of popular keywords for the search pages.
     */
    static async getPopularKeywords(): Promise<IKeywordDto[]> {
        return this._client.get<IKeywordDto[]>(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/popular-keywords`);
    }

    /**
     * Returns paginated list of notices.
     */
    static async getNoticeBoards(query?: PaginatedQuery): Promise<INoticeCollectionDto> {
        return this._client.get<INoticeCollectionDto>(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/notice_boards`, {
            params: {
                page: query?.page || 1,
                items_per_page: query?.itemsPerPage || 10,
            },
        });
    }

    /**
     * Returns one single notice.
     */
    static async getNotice(noticeId: NoticeId): Promise<INoticeDto> {
        return this._client.get<INoticeDto>(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/notice_boards/${noticeId}`);
    }

    /**
     * Get all inquiries
     */
    static async getInquiries(options?: IInquiryQueryDto): Promise<IInquiryCollectionDto> {
        return this._client.get<IInquiryCollectionDto>(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/inquiries`, {
            params: {
                page: options?.page || 1,
                items_per_page: options?.itemsPerPage || 10,
                months: options?.months || 3,
                status: options?.status,
            },
        });
    }

    /**
     * Get a specific inquiry
     */
    static async getInquiry(inquiryId: string): Promise<IInquiryDto> {
        return this._client.get<IInquiryDto>(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/inquiries/${inquiryId}`);
    }

    /**
     * Post new inquiry
     */
    static async postInquiry(createInquiryDto: ICreateUpdateInquiryDto): Promise<IInquiryDto> {
        return this._client.post<IInquiryDto>(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/inquiries`, createInquiryDto);
    }

    /**
     * Updates inquiry
     */
    static async putInquiry(inquiryId: string, updateInquiryDto: ICreateUpdateInquiryDto): Promise<IInquiryDto> {
        return this._client.put<IInquiryDto>(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/inquiries/${inquiryId}`, updateInquiryDto);
    }

    /**
     * Deletes a inquiry
     */
    static async deleteInquiry(inquiryId: string): Promise<void> {
        return this._client.delete<void>(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/inquiries/${inquiryId}`);
    }

    /**
     * Fetches theming information and other settings about the channel.
     */
    static async getChannelSettings(): Promise<IChannelSettingsDto> {
        return this._client.get<IChannelSettingsDto>(`${this.CHANNELS_API_PREFIX}basic-setting`, {
            params: {
                domain: HARMONY_CONFIG.domain,
            },
        });
    }

    /**
     * Fetches product detail page settings
     */
    static async getProductPageSettings(): Promise<IProductPageSettingsDto> {
        return this._client.get<IProductPageSettingsDto>(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/product-page-setting`);
    }

    /**
     * Increase view count on custom page
     */
    static async patchViewCount(pageId: string): Promise<void> {
        return this._client.patch<void>(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/pages/${pageId}/view-count`);
    }

    /**
     * Increase view count on event page
     */
    static async patchEventViewCount(eventId: string): Promise<boolean> {
        return this._client.patch<boolean>(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/events/${eventId}/view-count`);
    }

    /**
     * Participates user in event
     */
    static async postParticipants(eventId: string, { externalUserId, ...payload }: IPostParticipantsDto): Promise<boolean> {
        if (!!externalUserId) {
            return this._client.post<boolean>(
                `${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/events/${eventId}/lge/participants?external_user_id=${externalUserId}`,
                payload
            );
        }

        return this._client.post<boolean>(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/events/${eventId}/participants`, payload);
    }

    /**
     * Returns true/false user has participated
     */
    static async getCheckParticipation(eventId: string, externalUserId?: string) {
        if (!!externalUserId) {
            return this._client.get<{ result: boolean; participatedDates: string[] }>(
                `${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/events/${eventId}/lge/is-participated?external_user_id=${externalUserId}`
            );
        }

        return this._client.get<{ result: boolean; participatedDates: string[] }>(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/events/${eventId}/is-participated`);
    }

    /**
     * Fetches comments on event
     */
    static async getParticipantComments(eventId: string, query?: PaginatedQuery): Promise<PaginatedResults<IEventCommentItemDto>> {
        return this._client.get<PaginatedResults<IEventCommentItemDto>>(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/events/${eventId}/participant-comments`, {
            params: {
                page: query?.page || 1,
                per_page: query?.itemsPerPage || 20,
            },
        });
    }

    /**
     * Gets applicable coupons (returns list of coupons held by the user)
     */
    static async getCoupons(query: IQueryCouponDto): Promise<PaginatedResults<ICouponDto>> {
        const { page, itemsPerPage, status, ...remainingQuery } = query;
        return this._client.get<PaginatedResults<ICouponDto>>(`/coupon-app${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/coupons`, {
            params: {
                page: page || 1,
                per_page: itemsPerPage || 20,
                status: status ? status.join('&status=') : undefined,
                ...snakecaseKeys(remainingQuery),
            },
            paramsSerializer: (params) => {
                return Object.entries(params)
                    .map(([key, value]) => `${key}=${value}`)
                    .join('&');
            },
        });
    }

    /**
     * Gets specific product applicable coupons
     */
    static async postProductCoupons(applicableCouponDto: IQueryCouponDto): Promise<PaginatedResults<ICouponDto>> {
        return this._client.post<PaginatedResults<ICouponDto>>(`/coupon-app${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/product-coupons`, applicableCouponDto);
    }

    /**
     * Register coupon code
     */
    static async postCouponCode(couponCode: string): Promise<AxiosResponse> {
        return this._client.instance.post<void>(`/coupon-app${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/coupons/redeem`, { coupon_code: couponCode });
    }

    /**
     * Get Available Coupons For Product (returns list of coupons for the product regardless of whether user has it or not)
     */
    static async getProductCoupons(productId: string): Promise<ICouponDto[]> {
        return this._client.get<ICouponDto[]>(`/coupon-app${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/products/${productId}/coupons`);
    }

    /**
     * Get Available Coupons For Event Page (returns list of coupons for the product regardless of whether user has it or not)
     */
    static async getEventPageCoupons(eventPageId: string): Promise<ICouponDto[]> {
        return this._client.get<ICouponDto[]>(`/coupon-app${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/event-detail-page/${eventPageId}/coupons`);
    }

    /**
     * Download Specific Coupon
     */
    static async postDownloadCoupon(couponId: string): Promise<AxiosResponse> {
        return this._client.instance.post<void>(`/coupon-app${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/coupons/${couponId}/product-page/download`);
    }

    /**
     * Download All available Coupons
     */
    static async postDownloadAllCoupons(couponIds: string[]): Promise<AxiosResponse> {
        return this._client.instance.post<void>(`/coupon-app${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/coupons/product-page/download`, {
            coupon_ids: couponIds,
        });
    }

    static async postDownloadEventPageCoupons(couponIds: string[]): Promise<AxiosResponse> {
        return this._client.instance.post<void>(`/coupon-app${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/coupons/event-detail-page/download`, {
            coupon_ids: couponIds,
        });
    }

    /**
     * Returns brand details
     */
    static async getBrandDetails(brandId: string): Promise<IBrandDetailDto> {
        return this._client.get<IBrandDetailDto>(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/brands/${brandId}`);
    }

    static async postBrandCategories(categoryIds: { categoryIds: string[] }): Promise<ISubcategoryDto[]> {
        return this._client.post<ISubcategoryDto[]>(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/brand-categories`, categoryIds);
    }

    /**
     * Returns whether specific brand exists on the channel and whether it is active
     */
    static async getBrandStatus(brandId: string): Promise<{ exists: boolean; isActive: boolean }> {
        return this._client.get<{ exists: boolean; isActive: boolean }>(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/brands/${brandId}/status`);
    }

    static async getSellPricePolicy(): Promise<ISellPricePolicyDto> {
        return this._client.get<ISellPricePolicyDto>(`${this.CHANNELS_B_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/sell-price-policy`);
    }

    /**
     * Get Google Analytics Tag (Gtag)
     */
    static async getGoogleAnalyticsTag() {
        return this._client.get<IGoogleAnalyticsDto>(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/marketing-settings/google-analytics`);
    }

    static async getGoogleAnalyticsPlus() {
        return this._client.get<IGoogleAnalyticsPlusDto>(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/marketing-settings/google-analytics-plus`);
    }

    static async getAcrossAdn(): Promise<IAcrossAdnDto> {
        return this._client.get<IAcrossAdnDto>(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/marketing-settings/across-adn`);
    }

    static async getOfflineStores(params: IGetOfflineStoresDto): Promise<IOfflineStoreItemDto[]> {
        return this._client.get(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/offline-stores`, { params });
    }

    static async getOfflineStore(offlineStoreId: string): Promise<IOfflineStoreDto> {
        return this._client.get(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/offline-stores/${offlineStoreId}`);
    }

    static async postOfflineStore(offlineStoreIds: string[]): Promise<IOfflineStoreItemDto[]> {
        return this._client.post(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/offline-stores`, { offlineStoreIds });
    }

    static async getOfflineStoreSetting(): Promise<IOfflineStoreSettingDto> {
        return this._client.get(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/offline-store-setting`);
    }

    static async getGiftSetting(): Promise<IGiftSettingDto> {
        return this._client.get(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/gift-setting`);
    }

    static async getGpsSetting(): Promise<IGpsSettingDto> {
        return this._client.get(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/gps-setting`);
    }

    static async getOfflineStoreMembership(): Promise<IOfflineStoreMembershipDto> {
        return this._client.get<IOfflineStoreMembershipDto>(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/customer/offline-store-membership`);
    }

    static async getAllOfflineStores(): Promise<IOfflineStoreListItemDto[]> {
        return this._client.get<IOfflineStoreListItemDto[]>(`${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/offline-stores-all`);
    }

    static async postImageLinkZoneCouponDownload(couponIds: string[]): Promise<string> {
        return this._client.post<string>(`/coupon-app${this.CHANNELS_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/coupons/issuances/image-link-zone-page/download`, {
            coupon_issuance_ids: [...couponIds],
        });
    }
}

export { ChannelClient };
