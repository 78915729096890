import { useMemo } from 'react';
import useSWR, { Fetcher, SWRConfiguration, SWRResponse, Key } from 'swr';

type TransformedFetchConfig<T, D> = SWRConfiguration<D> & {
    transformer?: (data: D) => T;
};

type TransformedFetchReturn<T, D> = SWRResponse<D> & {
    transformedData: T | undefined;
};

/**
 * A wrapper around SWR that allows you to transform the data returned by the fetcher.
 *
 * Use this instead of SWR when you want to transform response data before using it such as class instantiation.
 */
export function useTransformedFetch<T, D>(key: Key, fetcher: Fetcher<D>, config: TransformedFetchConfig<T, D>): TransformedFetchReturn<T, D> {
    const fetcherState = useSWR<D>(key, fetcher, config);

    return useMemo<TransformedFetchReturn<T, D>>(
        () => ({
            ...fetcherState,
            transformedData: fetcherState.data && config.transformer ? config.transformer(fetcherState.data) : undefined,
        }),
        [fetcherState, config]
    );
}
