import BaseOrder, { OrderType } from '@models/base-order';
import GroupedProduct from '@models/grouped-product';
import ProductSellableSku from '@models/grouped-product/product-sellable-sku';
import { ProductId, SkuId } from '@type/models';
import DeliveryGroupedProduct from './delivery-grouped-product';
import { ICreateCartOrderDto } from './requestDto/create-cart.dto';
import { ICartDto } from './responseDto/cart.dto';

class Cart extends BaseOrder {
    private static _ORDER_PRODUCT = 'order-product';
    private static _ORDER_SKU_QUANTITIES = 'order-sku-quantities';

    public static MAX_ITEM_COUNT = 50;

    constructor(cartDto: ICartDto) {
        super(cartDto);
    }

    /**
     * Save product ID, sku quantities, and previous route in local storage to be retrieved by the payment page.
     */
    static updateDirectOrderStorage(directOrder: ICreateCartOrderDto): void {
        sessionStorage.setItem(this._ORDER_PRODUCT, directOrder.productId);
        sessionStorage.setItem(this._ORDER_SKU_QUANTITIES, JSON.stringify(directOrder.skus));
    }

    /**
     * Save product ID, sku quantities, and previous route in local storage to be retrieved by the payment page.
     */
    static getDirectOrderStorage(): ICreateCartOrderDto {
        const skuQuantities = sessionStorage.getItem(this._ORDER_SKU_QUANTITIES);
        return {
            type: OrderType.Cart,
            productId: sessionStorage.getItem(this._ORDER_PRODUCT) || '',
            skus: skuQuantities ? JSON.parse(skuQuantities) : [],
        };
    }

    get type(): OrderType.Cart {
        return OrderType.Cart;
    }

    get deliveryGroupedProducts(): DeliveryGroupedProduct[] {
        return this._deliveryGroupedProducts;
    }

    get nonDeliveryGroupedProducts(): GroupedProduct[] {
        return this._nonDeliveryGroupedProducts;
    }

    get firstNonDeliveryGroupedProducts(): GroupedProduct | undefined {
        return this._nonDeliveryGroupedProducts[0];
    }

    get hasItems(): boolean {
        return this.deliveryGroupedProducts.length > 0 || this.nonDeliveryGroupedProducts.length > 0;
    }

    get hasDeliveryAndNonDelivery(): boolean {
        return this.deliveryGroupedProducts.length > 0 && this.nonDeliveryGroupedProducts.length > 0;
    }

    get allSkus(): ProductSellableSku[] {
        return this._allSkus;
    }

    get allSkusToEnable(): SkuId[] {
        return this._allSkus.filter((item) => item.isActive).map((item) => item.id);
    }

    get allSelectedSkus(): ProductSellableSku[] {
        return this._allSkus.filter((sku) => sku.isSelected);
    }

    get allItemsDefaultMID(): boolean {
        if (!this.allSkus) return true;
        return this._allSkus.map((sku) => sku.pgSettingId).every((id) => id === null);
    }

    /**
     * Product IDs of all Grouped Products with selected skus
     */
    get allSelectedProductIds(): Set<ProductId> {
        return new Set(this.allSelectedSkus.map((sku) => sku.sellableProductId));
    }

    /**
     * Number of selected skus
     */
    get totalSelectedSkuCount(): number {
        return this.allSelectedSkus.length;
    }

    /**
     * Number of skus in cart
     */
    get totalCartItemCount(): number {
        return this.allSkus.length;
    }

    get allSkusSelected(): boolean {
        return this.totalSelectedSkuCount === this._allSkus.length;
    }

    get soldOutSkus(): ProductSellableSku[] {
        return this._allSkus.filter((sku) => sku.isSoldOut);
    }

    get hasSoldOutSkus(): boolean {
        return this.soldOutSkus.length > 0;
    }

    get firstSkuPgSettingId(): string | null {
        return this._allSkus[0]?.pgSettingId;
    }

    /**
     * Indicates whether or not a particular sku is present in the cart
     */
    hasSkuInCart(skuId: SkuId): boolean {
        return this._allSkus.some((sku) => sku.id === skuId);
    }

    /**
     * Returns inventory count of sku after deducting the number of items added to cart
     */
    getRemainingSkuInventoryCount(skuId: SkuId): number {
        const cartSku = this._allSkus.find((sku) => sku.id === skuId);
        if (!cartSku) return -1;
        return cartSku.leftInventoryCount - cartSku.quantity;
    }
}

export default Cart;
