import { OrderStatusEnum } from '@models/order-status/responseDto/order-status.dto';
import { SalesTypeEnum } from '@models/product/responseDto/component-product.dto';
import { CategoryId, FaqCategoryId, ImagePath, ImageUrl, PortalId, ProductClassEnum, SkuId } from './models';

export type PaginatedQuery = {
    /**
     * @default 1
     */
    page?: number;
    itemsPerPage?: number;
};

export type FaqContentQueryOptions = PaginatedQuery & {
    categoryId?: FaqCategoryId;
};

export type ProductQuery = PaginatedQuery & {
    categoryId?: CategoryId;
    sellerPortalId?: PortalId;
    searchKeyword?: string;
    minSellPrice?: number;
    maxSellPrice?: number;
    ordering?: string;
    salesType?: SalesTypeEnum | string;
    brandId?: string;
    displayOutOfStock?: boolean;
};

export type PointHistoryQuery = PaginatedQuery & {
    pointType?: string;
    monthRange?: number;
};

export enum ReviewSortEnum {
    LikeCount = '-like_count',
    CreateDt = '-create_dt',
    ScoreDesc = '-score',
    Score = 'score',
}

export enum ImageUploadContextEnum {
    Review = 'review',
}

export enum SkuClaimUpdateContextEnum {
    OrderFinished = 'order_finished',
    RefundRequested = 'refund_requested',
    ExchangeRequested = 'exchange_requested',
}

type OrderFinished = {
    type: SkuClaimUpdateContextEnum.OrderFinished;
};

type RefundRequested = {
    type: SkuClaimUpdateContextEnum.RefundRequested;
    refundReason: string;
    imagePaths: ImagePath[];
};

type ExchangeRequested = {
    type: SkuClaimUpdateContextEnum.ExchangeRequested;
    exchangeReason: string;
    selectedSkuId: string;
    selectedSkuTitle: string;
    imagePaths: ImagePath[];
};

export type SkuClaimUpdateContext = OrderFinished | RefundRequested | ExchangeRequested;

export type ReviewQuery = PaginatedQuery & {
    gender?: string;
    age?: string;
    score?: string;
    ordering?: ReviewSortEnum | string;
    sellableSkuId?: string;
};

export type AccessTokens = {
    accessToken: string;
    refreshToken: string;
};

export type TransactionQuery = PaginatedQuery & {
    monthRange?: number;
    statuses?: OrderStatusEnum[];
    productClass?: ProductClassEnum;
    /**
     * @default -paid_date
     */
    ordering?: string;
    isGiftOrder?: boolean;
};

export type ImageUploadPaths = {
    default: ImageUrl[];
    mobile: ImageUrl[];
};

/**
 * Tuple containing the sku id and the number of items of that sku
 */
export type SkuQuantity = [SkuId, number];

/**
 * Sku ID and quantity query string formatted like 582acc78-a41b-40ba-943e-5605c70293ab__2
 */
export type SkuQueryString = string;

export type UpdatedCartLine = {
    skuId: SkuId;
    quantity: number;
    leftInventoryCount: number;
};

export enum TermDisplayArea {
    Footer = 'footer',
    Trx = 'trx',
    Register = 'register',
}

export type RecommendedType = 'category' | 'partner';
