import { DrawerProps, ModalProps } from '@chakra-ui/react';
import '@emotion/react';
import ComponentProduct from '@models/product/component-product';
import { IColorToken } from './color-token';
import { Design } from './harmony-config';
import { GlobalFontSize, ProductId } from './models';

export interface IHarmonyFont {
    light: string;
    medium: string;
    regular: string;
    semiBold: string;
    bold: string;
    extraBold: string;
}

export interface IHarmonyTheme extends IColorToken {
    white: '#FFFFFF';
    black: '#000000';
    primary: {
        default: string;
        lighten: {
            100: string;
            200: string;
            300: string;
        };
        darken: {
            100: string;
            200: string;
            300: string;
        };
        /** @deprecated */
        100: string;
        /** @deprecated */
        200: string;
        /** @deprecated */
        300: string;
        /** @deprecated */
        400: string;
        /** @deprecated */
        500: string;
        /** @deprecated */
        600: string;
        /** @deprecated */
        700: string;
        /** @deprecated */
        800: string;
        /** @deprecated */
        900: string;
    };
    secondary: {
        default: string;
        lighten: {
            100: string;
            200: string;
            300: string;
        };
        darken: {
            100: string;
            200: string;
            300: string;
        };
        /** @deprecated */
        100: string;
        /** @deprecated */
        200: string;
        /** @deprecated */
        300: string;
        /** @deprecated */
        400: string;
        /** @deprecated */
        500: string;
        /** @deprecated */
        600: string;
        /** @deprecated */
        700: string;
        /** @deprecated */
        800: string;
        /** @deprecated */
        900: string;
    };
    gray: {
        50: '#F5F5F5';
        100: '#E6E6E6';
        200: '#DBDBDB';
        300: '#C2C2C2';
        400: '#A8A8A8';
        500: '#8F8F8F';
        600: '#757575';
        700: '#5C5C5C';
        800: '#424242';
        900: '#292929';
    };
    status: {
        error: '#D23B27';
        success: '#009518';
        information: '#005AE0';
        warning: '#FF8A01';
        successLighten: { 100: '#50C34F'; 200: '#76D075'; 300: '#9CDD9B' };
        successDarken: { 100: '#339332'; 200: '#2D802C'; 300: '#266D25' };
    };
    font: {
        primary: string;
        default: IHarmonyFont;
        secondary: IHarmonyFont;
    };
    fontSize: GlobalFontSize;
    productStyle: 1 | 2 | 3;
    background: IColorToken['background'] & {
        /** @deprecated */
        config: string;
    };
    dropShadow: {
        lg: string;
        buttonBottom: string;
        shadow1: string;
        shadow2: string;
        shadow3: string;
        dropdown: string;
        rankingItem: string;
        productSummary: string;
        wishlist: string;
    };
    overlay: {
        black: string;
        white: string;
    };
}

declare module '@emotion/react' {
    export interface Theme extends IHarmonyTheme {}
}

export type FontType = 'default' | 'secondary';
export type FontWeight = 'thin' | 'medium' | 'regular' | 'semiBold' | 'bold' | 'extraBold';
export type FontSizes = string;

/** Breakpoints for responsive layout. The value is min-width. */
export enum BreakpointsEnum {
    /** ~579px */
    Mobile = '0px',

    /** 580px~1199px */
    Tablet = '580px',

    /** 1200px~ */
    Desktop = '1200px',
}

export type DesignComponentMap = Partial<Record<Design, any>>;

export type Selectable<T> = {
    label: string;
    value: T;
};

export type BaseSelectableComponentProps<T> = {
    items: Selectable<T>[];
    selectedItem?: Selectable<T>;
    onChange: (item: Selectable<T>) => void;
};

export type BaseDialogueComponentProps<T extends ModalProps | DrawerProps> = Omit<T, 'children'> & {
    /**
     * 'alert' displays only one button, 'confirm' displays two buttons
     */
    variant: 'alert' | 'confirm';
    message: string;

    /**
     * if not provided, default value will be set to 'confirm'
     */
    buttonText?: string;
    /**
     * if not provided, default value will be set to 'cancel'
     */
    cancelButtonText?: string;

    /**
     * if not provided, default value will be set to onClose function
     */
    onConfirmButtonClick?: () => void;

    onConfirmButtonClickAsync?: () => Promise<void>;

    onCancelButtonClick?: () => void;

    /**
     * @default true
     */
    isCloseOnConfirm?: boolean;

    errorMessage?: string;
};

export interface BaseProductCardProps {
    product: ComponentProduct;
    onClick?: (productId: ProductId) => void;
    isDisabled?: boolean;
    trackItemSelect?: boolean;

    /** Only used in `withProductCardHOC`. */
    handleClick?: () => void;
}
