const sub = {
    sub: {
        subHistory: '나의 구독내역',
        month: '최근 {number}개월',
        year: '최근 {number}년',
        subscribing: '구독중',
        appForCancel: '해지신청',
        cancelComplete: '해지완료',
        unsubscribe: '구독취소',
        cancelSubscribe: '구독해지',
        deliveryProduct: '배송상품',
        serviceAndCoup: '서비스&쿠폰상품',
        subscriptionDetails: '구독 상세내역',
        coupons: '모바일 상품권',
        subInfo: '구독 정보',
        viewAllPayDetails: '회차별 결제내역 모두보기',
        goToCouponBox: '쿠폰함으로 이동',
        viewSubDetails: '구독 상세 보기',
        paymentCompleted: '결제가 완료되었어요.',
        checkHistory: '구독 상세내역에서',
        checkHistory2: '자세한 구독내역을 확인하실 수 있습니다.',
        paymentFailed: '결제실패',
        check: '확인',
        paymentFailedTitle: '결제에 실패했어요.',
        failedBody: '결제 진행중 문제가 생겼어요.',
        failedBody2: '문의사항은 1:1문의 부탁드립니다.',
        noSubscriptions: '현재 구독 중인 상품이 없습니다.',
        nextPayment: '다음 결제일',
        nextDelivery: '다음 배송일 ',
        expectedNextDelivery: '다음배송 예정일',
        firstSub: '첫 구독 결제일: ',
        shipping: '배송 주소:',
        shippingInfo: '배송 정보',
        trackShipping: '배송조회/리뷰쓰기',
        deliveryTracking: '배송조회',
        receiver: '받으시는분',
        deliveryRequirements: '배송 요청사항',
        paymentDetails: '회차별 결제내역',
        shippingDetails: '회차별 배송조회',
        status: '구독상태',
        billingCycle: '결제 주기',
        subFee: '구독 요금',
        lumpsum: '일시불',
        inquiries: '상품관련 문의사항',
        customerService: '구독해지, 상품 교환,환불 관련 문의는 ({number})으로 문의 바랍니다',
        unsubscribeCancel: '구독취소/해지',
        productInformation: '상품정보',
        enterReason: '취소/해지 사유를 입력해 주세요.',
        reason: '사유',
        reasonPlaceholder: '취소/해지 사유를 알려주시면 빠르게 도와드릴 수 있어요.',
        cancelInstructions: '구독 취소/해지 안내사항',
        cancelInstructions2: '구독해지 안내사항',
        instruction1: '출고된 상품이 없는 경우 구독 취소 처리되며, 환불이 진행됩니다.',
        instruction2: '출고된 상품이 있는 경우 구독 해지 신청으로 처리되며, 신청이 완료되면 고객센터에서 상세히 안내드리겠습니다.',
        instruction3: '환불 금액은 주문 상품 중 출고되지 않은 상품 금액과 배송비로 계산되며, 정확한 금액은 신청 후 산정됩니다.',
        instruction4: '실제 환불 시점은 결제 수단에 따라 상이할 수 있습니다.',
        cancelNotification1: '구독해지 시 주문 건의 구독해지 & 마지막 결제 건의 환불이 가능합니다.',
        cancelNotification2: '마지막 결제 건에 포함된 상품이 모두 접수완료 처리가 되지 않았다면 결제 건의 환불이 진행됩니다.',
        cancelNotification3: '마지막 결제 건에 포함된 상품이 모두 접수완료 처리 이후 상태라면 구독해지만 진행되고 환불되지 않습니다. (상품은 모두 출고가 진행됩니다.)',
        cancelNotification4:
            '마지막 결제 건에 포함된 상품이 접수완료 처리 전과 처리 후가 섞여 있다면 관련하여 고객센터에서 안내를 드릴 예정입니다. (이때 접수완료 되지 않은 상품은 출고되지 않습니다.)',
        cancelRequest: '구독해지 요청',
        areYouSureCancel: '구독을 취소하시겠습니까?',
        cancelRequested: '구독해지 신청일',
        cancelled: '구독해지 완료일',
        subCancelled: '구독 취소일',
        addShipping: '배송지추가',
        saveChange: '변경저장',
        cancelCompleteSuccess: '구독취소/해지 완료',
        cancelCompleteSuccessTitle: '구독취소/해지가 접수 되었어요.',
        subCancelledSuccess: '취소한 구독',
        subCancelFailed: '구독취소/해지 실패',
        subCancelFailed2: '구독해지/취소에 실패 했어요.',
        pleaseContact: '고객센터로 연락 부탁 드립니다.',
        cancelRequestSuccess: '구독 해지 요청 완료',
        cancelRequestSuccessContent: '구독 해지 요청이 완료되었습니다.',
        cancelRequestFailed: '구독 해지 요청 실패',
        cancelRequestFailedContent: '구독 해지에 실패했습니다.\n다시 시도해 주세요.',
        deliverySoon: '곧 받을 배송이에요.',
        deliveryDetails: '개의 배송내역',
        productQuant: '주문상품 (총{number}개)',
        changeDeliveryFrequency: '배송주기를 변경하면\n선택한 첫 배송 희망일과 결제주기가 모두 초기화 됩니다.\n변경하시겠습니까?',
        changeDate: '첫 배송일을 변경하면 결제주기 금액이 변경될 수 있습니다. 변경하시겠습니까?',
        changeDateSub: '변경된 결제금액을 꼭 확인해 주세요.',
        subCount: '개의 구독중인 상품이 있습니다.',
        paymentCount: '개의 결제내역',
        subCancellingCount: '개의 해지신청된 상품이 있습니다',
        subCancelledCount: '개의 해지완료된 상품이 있습니다',
        singlePurchaseCount: '개의 단건구매 상품이 있습니다',
        agreeTerms: '쇼핑몰 구매 약관에 동의해주세요.',
        round: '회차',
        payDate: '결제 일시',
        amountOfPayment: '결제 금액',
        daysLeftUntilPaymentDate: '일 뒤 결제',
        deliveryOkDescription: ' *배송완료 후 7일뒤 자동 구매확정되며 반품/교환은 고객센터로 문의 바랍니다.',
        orderConfirmed: '구매확정 완료',
        cancelCancel: '해지신청 취소하기',
        noSubs: '구독중인 상품이 없습니다.',
        noSubCancelReq: '해지신청 상품이 없습니다.',
        noSubCancelCompleted: '구독 해지완료 상품이 없습니다.',
        changeProduct: `옵션을 삭제하면 선택한 배송주기가 초기화 됩니다.\n변경하시겠어요?`,
        changeProduct2: '옵션 재선택 후 결제주기를 다시 선택해 주세요.',
        allOptionsReset: `옵션을 삭제하면 선택한 모든 옵션이 초기화 됩니다.\n변경하시겠어요?`,
        confirmProductChange: `선택한 상품을 삭제 시 선택한 모든 옵션이 초기화됩니다.\n변경하시겠습니까?\n*상품 재선택 후 결제주기를 다시 선택해 주세요.`,
        singlePurchase: '단건구매',
        pleaseCheck: '구매 전 확인해 주세요!',
        singlePurchaseDescription:
            '· 1회만 받아보기로 구매 시 정기결제가 시작되지 않습니다.\n· 1회만 받아보기로 구매한 상품의 교환/반품은 구독취소/구독해지와 무관하게 처리됩니다.\n· 1회만 받아보기는 횟수 제한없이 구매 가능합니다.',
    },
};

export default sub;
