import { Circle, VStack } from '@chakra-ui/react';
import Icon from '@designs/primitives/common/icon';
import Title1A from '@designs/primitives/typography/title-1-a';
import { useHarmony } from '@hooks/use-harmony';
import { SuccessHeadingProps } from '..';

const SuccessHeadingB: React.FC<SuccessHeadingProps> = ({ children }) => {
    const { theme } = useHarmony();

    return (
        <VStack spacing="24px" w="100%">
            <Circle size="90px" bgColor={theme.white} boxShadow={theme.dropShadow.shadow2}>
                <Icon size={90} color={theme.status.success}>
                    check
                </Icon>
            </Circle>
            <Title1A color={theme.text.title1} textAlign="center" whiteSpace="pre-wrap">
                {children}
            </Title1A>
        </VStack>
    );
};

export default SuccessHeadingB;
