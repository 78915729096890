import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { IThemeProps } from '@type/common';
import ThemeUtils from '@utils/theme.utils';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

interface PageLoadingIndicatorProps {}

const PageLoadingIndicator: React.FC<PageLoadingIndicatorProps> = () => {
    const [isLoadingPage, setIsLoadingPage] = useState<boolean>(false);
    const router = useRouter();
    const { theme } = useHarmony();

    useEffect(() => {
        function startLoading() {
            setIsLoadingPage(true);
        }

        function stopLoading() {
            setIsLoadingPage(false);
        }

        router.events.on('routeChangeStart', startLoading);
        router.events.on('routeChangeComplete', stopLoading);
        router.events.on('routeChangeError', stopLoading);
    }, []);

    return isLoadingPage ? (
        <LoadingContainer>
            <LoadingBar theme={theme} />
        </LoadingContainer>
    ) : (
        <></>
    );
};

export default PageLoadingIndicator;

const LoadingContainer = styled('div')`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    height: 4px;
    z-index: ${ThemeUtils.zIndex.toast};
`;

const LoadingBar = styled('div')<IThemeProps>`
    ${({ theme }) => `
        background: ${theme.primary.default};
        height: 100%;
        width: 100%;
        position: absolute;
        left: -100%;
        animation: loader 2s 0s infinite ease-in-out;
        @keyframes loader {
            0% { transform: translateX(0); }
            100% { transform: translateX(200%); }
        }
    `}
`;
