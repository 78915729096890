import ApiService from '@api/api.service';
import { NiceVerificationRequest } from '@hooks/use-nice-pass-module';
import { ICustomerDto } from '@models/customer/responseDto/customer.dto';
import { ExtensionCodeEnum } from '@models/extension-status/responseDto/extension-status.dto';

/**
 * Service for handling mobile webview related logic
 */
export class MobileWebviewService {
    static isWebview(): boolean {
        return window.flutter_inappwebview !== undefined;
    }
    /**
     * Pass customer and access token to mobile app for extended in-app features
     */
    static sendAuth(customerDto: ICustomerDto): void {
        const accessToken = ApiService.getAccessToken();
        if (window.flutter_inappwebview?.callHandler && accessToken) {
            window.flutter_inappwebview.callHandler('mobileAuthHandler', JSON.stringify({ customer: customerDto, accessToken }));
        }
    }

    static sendExtensionSettings(extensionStatusDto: { extensionCode: ExtensionCodeEnum; isInstalled: boolean }[]): void {
        if (window.flutter_inappwebview?.callHandler) {
            window.flutter_inappwebview.callHandler('mobileExtensionSettingsHandler', JSON.stringify(extensionStatusDto));
        }
    }

    static sendIsBottomBarOpen(isBottomBarOpen: boolean): void {
        if (window.flutter_inappwebview?.callHandler) {
            window.flutter_inappwebview.callHandler('mobileIsBottomBarOpenHandler', JSON.stringify(isBottomBarOpen));
        }
    }

    static sendIsModalOpen(isModalOpen: boolean): void {
        if (window.flutter_inappwebview?.callHandler) {
            window.flutter_inappwebview.callHandler('mobileIsModalOpenHandler', JSON.stringify(isModalOpen));
        }
    }

    static sendNicePassRequest(data: NiceVerificationRequest): void {
        if (window.flutter_inappwebview?.callHandler) {
            window.flutter_inappwebview.callHandler('nicePassRequestHandler', JSON.stringify(data));
        }
    }

    static sendNicePassEncodedData(encodeData: string): void {
        if (window.flutter_inappwebview?.callHandler) {
            window.flutter_inappwebview.callHandler('nicePassEncodedDataHandler', JSON.stringify(encodeData));
        }
    }

    static sendGeolocationRequest(isUsingGps: boolean): void {
        if (window.flutter_inappwebview?.callHandler) {
            window.flutter_inappwebview.callHandler('geolocationRequestHandler', JSON.stringify(isUsingGps));
        }
    }
}
