import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { Design } from '@type/harmony-config';
import { IHarmonyTheme } from '@type/theme';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(switchAnatomy.keys);

export const switchTheme = (theme: IHarmonyTheme) =>
    defineMultiStyleConfig({
        variants: {
            [Design.DesignD]: definePartsStyle({
                track: {
                    width: '32px',
                    height: '18px',
                    bg: theme.gray[200],
                    _checked: {
                        bg: theme.background.primary,
                    },
                    p: 0,
                },
                thumb: {
                    m: '2px',
                    width: '14px',
                    height: '14px',
                    border: 'none',
                    boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.25)`,
                    _checked: {
                        border: `1px solid ${theme.gray[600]}`,
                    },
                },
            }),
        },
    });
