import { useDisclosure } from '@chakra-ui/react';
import FullModal from '@components/full-modal';
import CompletePageContent, { CompletePageContentProps } from '@designs/pages/complete-page-content';
import ThemeUtils from '@utils/theme.utils';
import { useRouter } from 'next/router';
import { ReactElement, ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';

export interface ICompleteFullModalOptions extends CompletePageContentProps {}

type CompleteFullModalContextType = {
    showCompleteFullModal: (options: ICompleteFullModalOptions) => void;
};

export const CompleteFullModalContext = createContext<CompleteFullModalContextType>({} as CompleteFullModalContextType);

interface CompleteFullModalProviderProps {
    children: ReactNode;
}

export const CompleteFullModalProvider: React.FC<CompleteFullModalProviderProps> = ({ children }): ReactElement => {
    const router = useRouter();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [completeFullModalOptions, setCompleteFullModalOptions] = useState<ICompleteFullModalOptions | null>(null);

    const showCompleteFullModal = useCallback((options: ICompleteFullModalOptions) => {
        setCompleteFullModalOptions(options);
        onOpen();
    }, []);

    useEffect(() => {
        onClose();
    }, [router.asPath]);

    return (
        <CompleteFullModalContext.Provider value={{ showCompleteFullModal }}>
            {children}
            <FullModal isOpen={isOpen} onClose={onClose} hasHeader={false} zIndex={ThemeUtils.zIndex.alert}>
                <CompletePageContent variant="success" heading="" buttons={[]} {...completeFullModalOptions} />
            </FullModal>
        </CompleteFullModalContext.Provider>
    );
};

export const useCompleteFullModal = (): CompleteFullModalContextType => useContext(CompleteFullModalContext);
