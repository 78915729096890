import styled from '@emotion/styled';
import { DesignBContainer } from '@styles/styled-components';
import { FailureSymbolProps } from '..';

const FailureSymbolB: React.FC<FailureSymbolProps> = ({}) => {
    return (
        <FailCircle>
            <CrossOne />
            <CrossTwo />
        </FailCircle>
    );
};

export default FailureSymbolB;

const FailCircle = styled(DesignBContainer)`
    border-radius: 999px;
    width: 90px;
    height: 90px;
    position: relative;
`;

const Cross = styled.div`
    background: #d41e05;
    width: 55px;
    height: 7px;
    border-radius: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
`;

const CrossOne = styled(Cross)`
    transform: translate(-50%, -50%) rotate(-45deg);
`;

const CrossTwo = styled(Cross)`
    transform: translate(-50%, -50%) rotate(45deg);
`;
