import IconButton from '@designs/primitives/common/icon-button';
import { useHarmony } from '@hooks/use-harmony';
import { SearchButtonProps } from '..';

const SearchButtonD: React.FC<SearchButtonProps> = (props) => {
    const { theme } = useHarmony();

    return (
        <IconButton {...props} size={24} color={theme.gray[600]}>
            search
        </IconButton>
    );
};

export default SearchButtonD;
