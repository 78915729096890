import { OrderStatusEnum } from '@models/order-status/responseDto/order-status.dto';

export enum StatusButtonEnum {
    DeliveryTracking = '배송조회',
    WriteReview = '리뷰 작성하기',
    WithdrawOrder = '주문취소',
    PurchaseConfirmation = '구매확정',
    ReturnRequest = '반품요청',
    ExchangeRequest = '교환요청',
    OrderCancelComplete = '주문취소 요청 완료',
    ExchangeRequestComplete = '교환요청 완료',
    CompletedReturnRequest = '반품요청 완료',
    ReviewCompleted = '리뷰 작성완료',
}

type OrderStatusDetails = {
    name: string;
    detailName: string;
    buttonList: StatusButtonEnum[];
};

type StatusItemMap = {
    [key in OrderStatusEnum]?: OrderStatusDetails;
};

type GiftStatusItemMap = {
    [key in OrderStatusEnum]?: {
        nameForSender: string;
        nameForReceiver?: string;
    };
};

class TransactionStatusUtils {
    private static _STATUS_MAP: StatusItemMap = {
        [OrderStatusEnum.RECEIVED]: {
            name: '발행완료',
            detailName: '발행완료',
            buttonList: [],
        },
        [OrderStatusEnum.EXPIRED]: {
            name: '기간만료',
            detailName: '기간만료',
            buttonList: [],
        },
        [OrderStatusEnum.EXPIRED_CONFIRMED]: {
            name: '환불완료(기간만료)',
            detailName: '환불완료(기간만료)',
            buttonList: [],
        },
        [OrderStatusEnum.USED]: {
            name: '사용완료',
            detailName: '사용완료',
            buttonList: [],
        },
        [OrderStatusEnum.PARTIALLY_USED]: {
            name: '사용완료',
            detailName: '사용완료',
            buttonList: [],
        },
        [OrderStatusEnum.USED_CONFIRMED]: {
            name: '',
            detailName: '',
            buttonList: [],
        },
        [OrderStatusEnum.ISSUE_CANCELLED]: {
            name: '환불완료(발행취소)',
            detailName: '환불완료(발행취소)',
            buttonList: [],
        },
        [OrderStatusEnum.MANUALLY_ISSUE_CANCELLING]: {
            name: '취소요청',
            detailName: '취소요청',
            buttonList: [],
        },
        [OrderStatusEnum.MANUALLY_ISSUE_CONFIRMED]: {
            name: '발행완료',
            detailName: '발행완료',
            buttonList: [],
        },
        [OrderStatusEnum.MANUALLY_ISSUE_REQUIRED]: {
            name: '발행예정',
            detailName: '발행예정',
            buttonList: [],
        },
        [OrderStatusEnum.NEW_NOTICE]: {
            name: '공지사항',
            detailName: '공지사항',
            buttonList: [],
        },
        [OrderStatusEnum.SHIP_OK_DIRECT_DELIVERY]: {
            name: '출고완료',
            detailName: '직접배송',
            buttonList: [],
        },
        [OrderStatusEnum.ORDER_FINISHED_NOTICE]: {
            name: '구매확정 안내',
            detailName: '구매확정 안내',
            buttonList: [],
        },
        [OrderStatusEnum.ORDER_FINISHED]: {
            name: '구매확정',
            detailName: '',
            buttonList: [StatusButtonEnum.WriteReview],
        },
        [OrderStatusEnum.ORDER_FINISHED_REVIEWED]: {
            name: '구매확정',
            detailName: '',
            buttonList: [StatusButtonEnum.ReviewCompleted],
        },
        [OrderStatusEnum.PAYMENT_REQUIRED]: {
            name: '결제대기',
            detailName: '',
            buttonList: [StatusButtonEnum.WithdrawOrder],
        },
        [OrderStatusEnum.PAYMENT_FAIL_VALIDATION]: {
            name: '결제인증 실패',
            detailName: '',
            buttonList: [],
        },
        [OrderStatusEnum.PAYMENT_FAIL_INVALID_PAYMENT_TRIAL]: {
            name: '잘못된 방식의 결제 유도',
            detailName: '',
            buttonList: [],
        },
        [OrderStatusEnum.PAYMENT_FAIL_TIME_OUT]: {
            name: '결제실패',
            detailName: '',
            buttonList: [],
        },
        [OrderStatusEnum.PAYMENT_FAIL_ERROR]: {
            name: '결제실패',
            detailName: '',
            buttonList: [],
        },
        [OrderStatusEnum.CHECK_REQUIRED]: {
            name: '결제완료',
            detailName: '',
            buttonList: [StatusButtonEnum.WithdrawOrder],
        },
        [OrderStatusEnum.ORDER_FAIL_CHECK_REJECTED]: {
            name: '주문실패',
            detailName: '접수거절',
            buttonList: [],
        },
        [OrderStatusEnum.SHIP_REQUIRED]: {
            name: '접수완료',
            detailName: '',
            buttonList: [],
        },
        [OrderStatusEnum.SHIP_OK]: {
            name: '출고완료',
            detailName: '출고완료',
            buttonList: [StatusButtonEnum.DeliveryTracking],
        },
        [OrderStatusEnum.ORDER_FAIL_SHIP_REJECTED]: {
            name: '주문실패',
            detailName: '출고거절',
            buttonList: [],
        },
        [OrderStatusEnum.SHIP_DELAY]: {
            name: '출고지연',
            detailName: '출고지연',
            buttonList: [],
        },
        [OrderStatusEnum.DELIVERY_ING]: {
            name: '배송중',
            detailName: '',
            buttonList: [StatusButtonEnum.DeliveryTracking],
        },
        [OrderStatusEnum.DELIVERY_OK]: {
            name: '배송완료',
            detailName: '',
            buttonList: [StatusButtonEnum.PurchaseConfirmation, StatusButtonEnum.ReturnRequest, StatusButtonEnum.ExchangeRequest],
        },
        [OrderStatusEnum.DELIVERY_DELAY]: {
            name: '배송지연',
            detailName: '',
            buttonList: [StatusButtonEnum.DeliveryTracking],
        },
        [OrderStatusEnum.REFUND_REQUESTED]: {
            name: '반품요청',
            detailName: '반품요청',
            buttonList: [],
        },
        [OrderStatusEnum.REFUND_CHECKED]: {
            name: '반품요청',
            detailName: '접수완료',
            buttonList: [],
        },
        [OrderStatusEnum.REFUND_AGREED]: {
            name: '반품진행중',
            detailName: '판매자 동의완료',
            buttonList: [],
        },
        [OrderStatusEnum.REFUND_FAIL_CHECK_REJECTED]: {
            name: '반품거절',
            detailName: '반품접수거절',
            buttonList: [StatusButtonEnum.PurchaseConfirmation, StatusButtonEnum.ReturnRequest, StatusButtonEnum.ExchangeRequest],
        },
        [OrderStatusEnum.REFUND_FAIL_AGREE_REJECTED]: {
            name: '반품거절',
            detailName: '반품동의거절',
            buttonList: [StatusButtonEnum.PurchaseConfirmation, StatusButtonEnum.ReturnRequest, StatusButtonEnum.ExchangeRequest],
        },
        [OrderStatusEnum.REFUND_FAIL_INSPECT_REJECTED]: {
            name: '반품실패',
            detailName: '검수부결-기존 상품 배송중',
            buttonList: [StatusButtonEnum.DeliveryTracking],
        },
        [OrderStatusEnum.REFUND_FAIL_INSPECT_REJECTED_DO]: {
            name: '반품실패',
            detailName: '검수부결-기존 상품 배송완료',
            buttonList: [StatusButtonEnum.PurchaseConfirmation, StatusButtonEnum.ReturnRequest, StatusButtonEnum.ExchangeRequest],
        },
        [OrderStatusEnum.REFUND_FAIL_INSPECT_REJECTED_DD]: {
            name: '반품실패',
            detailName: '검수부결-기존 상품 배송지연',
            buttonList: [StatusButtonEnum.DeliveryTracking],
        },
        [OrderStatusEnum.REFUND_FAIL_RETURN_NO]: {
            name: '반품실패',
            detailName: '반품 미수령',
            buttonList: [StatusButtonEnum.PurchaseConfirmation, StatusButtonEnum.ReturnRequest, StatusButtonEnum.ExchangeRequest],
        },
        [OrderStatusEnum.REFUND_RETURN_OK]: {
            name: '반품진행중',
            detailName: '상품 수령완료',
            buttonList: [],
        },
        [OrderStatusEnum.REFUND_INSPECT_PASS]: {
            name: '반품진행중',
            detailName: '검수완료',
            buttonList: [],
        },
        [OrderStatusEnum.REFUND_FINISHED_NORMAL]: {
            name: '환불완료',
            detailName: '반품완료',
            buttonList: [],
        },
        [OrderStatusEnum.REFUND_FINISHED_ORDER_CANCELED]: {
            name: '환불완료',
            detailName: '주문취소',
            buttonList: [],
        },
        [OrderStatusEnum.REFUND_FINISHED_ORDER_CANCELED_CONFIRMED]: {
            name: '환불완료',
            detailName: '주문취소',
            buttonList: [],
        },
        [OrderStatusEnum.REFUND_FINISHED_CHECK_REJECTED]: {
            name: '환불완료',
            detailName: '접수거절',
            buttonList: [],
        },
        [OrderStatusEnum.REFUND_FINISHED_SHIP_REJECTED]: {
            name: '환불완료',
            detailName: '출고거절',
            buttonList: [],
        },
        [OrderStatusEnum.EXCHANGE_REQUESTED]: {
            name: '교환요청',
            detailName: '교환요청',
            buttonList: [],
        },
        [OrderStatusEnum.EXCHANGE_CHECKED]: {
            name: '교환요청',
            detailName: '접수완료',
            buttonList: [],
        },
        [OrderStatusEnum.EXCHANGE_AGREED]: {
            name: '교환진행중',
            detailName: '판매자 동의완료',
            buttonList: [],
        },
        [OrderStatusEnum.EXCHANGE_FAIL_CHECK_REJECTED]: {
            name: '교환실패',
            detailName: '교환접수거절',
            buttonList: [StatusButtonEnum.PurchaseConfirmation, StatusButtonEnum.ReturnRequest, StatusButtonEnum.ExchangeRequest],
        },
        [OrderStatusEnum.EXCHANGE_FAIL_AGREE_REJECTED]: {
            name: '교환실패',
            detailName: '교환동의거절',
            buttonList: [StatusButtonEnum.PurchaseConfirmation, StatusButtonEnum.ReturnRequest, StatusButtonEnum.ExchangeRequest],
        },
        [OrderStatusEnum.EXCHANGE_FAIL_INSPECT_REJECTED]: {
            name: '교환실패',
            detailName: '기존 상품 배송중',
            buttonList: [StatusButtonEnum.DeliveryTracking],
        },
        [OrderStatusEnum.EXCHANGE_FAIL_INSPECT_REJECTED_DO]: {
            name: '교환거절',
            detailName: '기존 상품 배송완료',
            buttonList: [StatusButtonEnum.PurchaseConfirmation, StatusButtonEnum.ReturnRequest, StatusButtonEnum.ExchangeRequest],
        },
        [OrderStatusEnum.EXCHANGE_FAIL_INSPECT_REJECTED_DD]: {
            name: '교환거절',
            detailName: '기존 상품 배송지연',
            buttonList: [StatusButtonEnum.DeliveryTracking],
        },
        [OrderStatusEnum.EXCHANGE_FAIL_RESHIP_REJECTED]: {
            name: '교환거절',
            detailName: '기존 상품 배송중',
            buttonList: [StatusButtonEnum.DeliveryTracking],
        },
        [OrderStatusEnum.EXCHANGE_FAIL_RESHIP_REJECTED_DO]: {
            name: '교환거절',
            detailName: '기존 상품 배송완료',
            buttonList: [StatusButtonEnum.PurchaseConfirmation, StatusButtonEnum.ReturnRequest, StatusButtonEnum.ExchangeRequest],
        },
        [OrderStatusEnum.EXCHANGE_FAIL_RESHIP_REJECTED_DD]: {
            name: '교환거절',
            detailName: '기존 상품 배송지연',
            buttonList: [StatusButtonEnum.DeliveryTracking],
        },
        [OrderStatusEnum.EXCHANGE_FAIL_RETURN_NO]: {
            name: '교환실패',
            detailName: '기존 상품 미수령',
            buttonList: [StatusButtonEnum.PurchaseConfirmation, StatusButtonEnum.ReturnRequest, StatusButtonEnum.ExchangeRequest],
        },
        [OrderStatusEnum.EXCHANGE_RETURN_OK]: {
            name: '교환진행중',
            detailName: '기존 상품 수령완료',
            buttonList: [],
        },
        [OrderStatusEnum.EXCHANGE_INSPECT_PASS]: {
            name: '교환진행중',
            detailName: '기존 상품 검수완료',
            buttonList: [],
        },
        [OrderStatusEnum.EXCHANGE_RESHIP_OK]: {
            name: '교환진행중',
            detailName: '새상품 출고완료',
            buttonList: [StatusButtonEnum.DeliveryTracking],
        },
        [OrderStatusEnum.EXCHANGE_RESHIP_DELAY]: {
            name: '새상품 출고지연',
            detailName: '',
            buttonList: [],
        },
        [OrderStatusEnum.EXCHANGE_DELIVERY_ING]: {
            name: '교환진행중',
            detailName: '새상품 배송중',
            buttonList: [StatusButtonEnum.DeliveryTracking],
        },
        [OrderStatusEnum.EXCHANGE_DELIVERY_OK]: {
            name: '교환완료',
            detailName: '',
            buttonList: [StatusButtonEnum.PurchaseConfirmation, StatusButtonEnum.ReturnRequest, StatusButtonEnum.ExchangeRequest],
        },
        [OrderStatusEnum.EXCHANGE_DELIVERY_DELAY]: {
            name: '교환진행중',
            detailName: '새상품 배송지연',
            buttonList: [StatusButtonEnum.DeliveryTracking],
        },
        // TODO
        [OrderStatusEnum.ORDER_CANCELLED]: {
            name: '',
            detailName: '',
            buttonList: [],
        },
        [OrderStatusEnum.CHECK_REJECTED]: {
            name: '',
            detailName: '',
            buttonList: [],
        },
        [OrderStatusEnum.SHIP_REJECTED]: {
            name: '',
            detailName: '',
            buttonList: [],
        },
        [OrderStatusEnum.CHECK_REQUIRED_IN_ADVANCE]: {
            name: '결제완료',
            detailName: '',
            buttonList: [],
        },
        [OrderStatusEnum.SUBSCRIPTION_ORDER_FAIL_CHECK_REJECTED]: {
            name: '주문실패',
            detailName: '접수거절',
            buttonList: [],
        },
        [OrderStatusEnum.SUBSCRIPTION_ORDER_FAIL_SHIP_REJECTED]: {
            name: '주문실패',
            detailName: '출고거절',
            buttonList: [],
        },
        [OrderStatusEnum.SUBSCRIPTION_ORDER_FAIL_CHECK_REJECTED_CONFIRMED]: {
            name: '주문실패',
            detailName: '접수거절확인',
            buttonList: [],
        },
        [OrderStatusEnum.SUBSCRIPTION_ORDER_FAIL_SHIP_REJECTED_CONFIRMED]: {
            name: '주문실패',
            detailName: '출고거절확인',
            buttonList: [],
        },
        [OrderStatusEnum.SUBSCRIPTION_DIRECT_REFUND_CONFIRMED]: {
            name: '환불완료',
            detailName: '직접환불',
            buttonList: [],
        },
        [OrderStatusEnum.SUBSCRIBING]: {
            name: '구독중',
            detailName: '구독중',
            buttonList: [],
        },
        [OrderStatusEnum.SUBSCRIPTION_CANCELLING]: {
            name: '구독해지 신청',
            detailName: '구독해지 신청',
            buttonList: [],
        },
        [OrderStatusEnum.SUBSCRIPTION_CANCELLED]: {
            name: '구독해지 완료',
            detailName: '구독해지 완료',
            buttonList: [],
        },
        [OrderStatusEnum.UNPAID_IN_ADVANCE]: {
            name: '결제예정',
            detailName: '결제예정',
            buttonList: [],
        },
        [OrderStatusEnum.REFUND_FINISHED_MANUALLY_ISSUE_CANCELLED]: {
            name: '환불완료(주문취소)',
            detailName: '환불완료(주문취소)',
            buttonList: [],
        },
        [OrderStatusEnum.REFUND_FINISHED_MANUALLY_ISSUE_REJECTED]: {
            name: '환불완료(주문거절)',
            detailName: '환불완료(주문거절)',
            buttonList: [],
        },
        [OrderStatusEnum.COUPON_ISSUED]: {
            name: '쿠폰발급 안내',
            detailName: '쿠폰발급 안내',
            buttonList: [],
        },
    };

    private static _GIFT_STATUS_MAP: GiftStatusItemMap = {
        [OrderStatusEnum.GIFT_ORDER_OK]: {
            nameForSender: '선물완료',
        },
        [OrderStatusEnum.GIFT_ORDER_CANCELLED]: {
            nameForSender: '선물취소 - 수동취소',
        },
        [OrderStatusEnum.GIFT_ORDER_EXPIRED]: {
            nameForSender: '선물취소 - 기한경과',
        },
        [OrderStatusEnum.CHECK_REQUIRED]: {
            nameForSender: '선물 등록완료',
        },
        [OrderStatusEnum.ORDER_FAIL_CHECK_REJECTED]: {
            nameForSender: '선물 등록완료',
            nameForReceiver: '선물 등록완료',
        },
        [OrderStatusEnum.REFUND_FINISHED_CHECK_REJECTED]: {
            nameForSender: '환불완료',
            nameForReceiver: '환불완료',
        },
        [OrderStatusEnum.SHIP_REQUIRED]: {
            nameForSender: '선물 등록완료',
            nameForReceiver: '배송준비',
        },
        [OrderStatusEnum.SHIP_DELAY]: {
            nameForSender: '선물 등록완료',
            nameForReceiver: '배송준비',
        },
        [OrderStatusEnum.ORDER_FAIL_SHIP_REJECTED]: {
            nameForSender: '선물 등록완료',
            nameForReceiver: '배송준비',
        },
        [OrderStatusEnum.SHIP_OK]: {
            nameForSender: '선물 등록완료',
            nameForReceiver: '배송준비',
        },
        [OrderStatusEnum.REFUND_FINISHED_SHIP_REJECTED]: {
            nameForSender: '환불완료',
            nameForReceiver: '환불완료',
        },
        [OrderStatusEnum.SHIP_OK_DIRECT_DELIVERY]: {
            nameForSender: '선물 등록완료',
            nameForReceiver: '배송중',
        },
        [OrderStatusEnum.DELIVERY_ING]: {
            nameForSender: '선물 등록완료',
            nameForReceiver: '배송중',
        },
        [OrderStatusEnum.DELIVERY_DELAY]: {
            nameForSender: '선물 등록완료',
            nameForReceiver: '배송중',
        },
        [OrderStatusEnum.DELIVERY_OK]: {
            nameForSender: '선물 등록완료',
            nameForReceiver: '배송완료',
        },
        [OrderStatusEnum.ORDER_FINISHED]: {
            nameForSender: '선물 등록완료',
            nameForReceiver: '구매확정',
        },
        [OrderStatusEnum.PAYMENT_REQUIRED]: {
            nameForSender: '결제대기',
            nameForReceiver: '결제대기',
        },
    };

    static transactionStatusName(status: OrderStatusEnum): string {
        return TransactionStatusUtils._STATUS_MAP[status]?.name || '-';
    }

    static transactionStatusDetailName(status: OrderStatusEnum): string {
        return TransactionStatusUtils._STATUS_MAP[status]?.detailName || '';
    }

    static transactionButtonList(status: OrderStatusEnum): StatusButtonEnum[] {
        return TransactionStatusUtils._STATUS_MAP[status]?.buttonList || [];
    }

    static giftStatusNameForSender(status?: OrderStatusEnum): string {
        if (!status) {
            return '-';
        }

        return TransactionStatusUtils._GIFT_STATUS_MAP[status]?.nameForSender || '-';
    }

    static giftStatusNameForReceiver(status?: OrderStatusEnum): string {
        if (!status) {
            return '-';
        }

        return TransactionStatusUtils._GIFT_STATUS_MAP[status]?.nameForReceiver || '-';
    }
}

export default TransactionStatusUtils;
