import { Box, VStack } from '@chakra-ui/react';
import Divider from '@designs/primitives/common/divider';
import { useHarmony } from '@hooks/use-harmony';
import BodyText from '@legacy/components/common/text/body-text';
import { DetailsBoxProps } from '..';
import DetailItem from '../../detail-item';

const DetailsBoxB: React.FC<DetailsBoxProps> = ({ details: { data, title, description }, isInfoVertical }) => {
    const { theme } = useHarmony();

    return (
        <Box p="10px 16px" border="1px solid" borderColor={theme.gray[100]} bgColor={theme.gray[50]} borderRadius="16px">
            <VStack spacing="12px" align="stretch">
                {(!!title || !!description) && (
                    <>
                        <VStack spacing="4px" align="stretch">
                            <BodyText size="15px" lineHeight="18px" weight="bold" color={theme.primary.default}>
                                {title}
                            </BodyText>
                            <BodyText size="14px" lineHeight="18px" color={theme.gray[800]}>
                                {description}
                            </BodyText>
                        </VStack>
                        <Divider borderColor={theme.gray[100]} />
                    </>
                )}
                {Object.entries(data).map(([label, value]) => !!value && <DetailItem key={label} label={label} value={value} isVertical={isInfoVertical} />)}
            </VStack>
        </Box>
    );
};

export default DetailsBoxB;
