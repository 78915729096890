import { Button, HStack, forwardRef } from '@chakra-ui/react';
import Body1C from '@designs/primitives/typography/body-1-c';
import { useHarmony } from '@hooks/use-harmony';
import { MainButtonInnerProps } from '..';

const MainButtonD: React.FC<MainButtonInnerProps> = forwardRef<MainButtonInnerProps, 'button'>(({ children, rightIcon, leftIcon, ...props }, ref) => {
    const { theme } = useHarmony();

    const minHeight = {
        s: '40px',
        m: '44px',
    }[props.size];

    const styles = {
        primary: {
            variant: 'solid',
        },
        secondary: {
            variant: 'outline',
            borderColor: theme.stroke.primary,
        },
    }[props.variant];

    return (
        <Button minH={minHeight} color={theme.text.title1} borderRadius="8px" {...props} {...styles} ref={ref}>
            <HStack spacing="4px">
                {leftIcon}
                <Body1C color="inherit">{children}</Body1C>
                {rightIcon}
            </HStack>
        </Button>
    );
});

export default MainButtonD;
