import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { Design } from '@type/harmony-config';
import { IHarmonyTheme } from '@type/theme';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(radioAnatomy.keys);

export const radioTheme = (theme: IHarmonyTheme) =>
    defineMultiStyleConfig({
        variants: {
            [Design.DesignD]: definePartsStyle({
                control: {
                    width: '18px',
                    height: '18px',
                    borderRadius: '50%',
                    border: `1px solid ${theme.gray[400]}`,
                    bgColor: theme.white,
                    _checked: {
                        bgColor: theme.background.primary,
                        border: '1px solid',
                        borderColor: theme.background.primary,
                        _before: {
                            color: theme.black,
                        },
                        _hover: {
                            bg: theme.background.primary,
                            border: '1px solid',
                            borderColor: theme.background.primary,
                        },
                    },
                    _disabled: {
                        bgColor: theme.background.gray100,
                        _before: {
                            color: theme.gray[400],
                        },
                    },
                },
                label: {
                    marginInlineStart: '4px',
                },
            }),
        },
    });
