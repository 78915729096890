import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { IThemeProps } from '@type/common';
import Link from 'next/link';
import RoutesEnum from 'src/routes/routes';
import { ShoppingCartButtonInternalProps } from '..';
import CustomIconButton from '../../../components/common/custom-icon-button';
import BodyText from '../../../components/common/text/body-text';
import DomainUtils from '@utils/domain.utils';

const ShoppingCartButton: React.FC<ShoppingCartButtonInternalProps> = ({ cart, size, color, padding, fill }) => {
    const { theme } = useHarmony();

    return (
        <Link href={RoutesEnum.Cart}>
            <IconContainer>
                <CustomIconButton size={size ? size : '25px'} color={color ? color : theme.black} padding={padding} fill={fill}>
                    shopping_cart
                </CustomIconButton>
                {(cart?.totalCartItemCount || 0) > 0 && (
                    <Count theme={theme} backgroundColor={DomainUtils.isShoppingeasy ? theme.primary.default : theme.status.error}>
                        <BodyText size="14px" font="secondary" color={theme.white} lineHeight="12px">
                            {cart?.totalCartItemCount}
                        </BodyText>
                    </Count>
                )}
            </IconContainer>
        </Link>
    );
};

export default ShoppingCartButton;

const IconContainer = styled.div`
    position: relative;
`;

const Count = styled.div<IThemeProps & { backgroundColor: string }>`
    background: ${({ backgroundColor }) => backgroundColor};
    padding: 2px 6px 1px 6px;
    min-width: 20px;
    width: auto;
    height: 20px;
    position: absolute;
    top: -2px;
    right: -3px;
    border-radius: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
