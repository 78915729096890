import ChannelCacheKeys from '@api/channel/cache-keys';
import { ChannelClient } from '@api/channel/channel.client';
import FullPageLoader from '@designs/grouped/common/full-page-loader';
import { ThemeProvider } from '@emotion/react';
import styled from '@emotion/styled';
import ChannelSettings from '@models/channel-settings';
import { IChannelSettingsDto } from '@models/channel-settings/responseDto/channel-settings.dto';
import ThemeDesignSetting from '@models/channel-settings/theme-design-setting';
import FrameCollection from '@models/frame-collection';
import { IFrameArrayDto } from '@models/frame-collection/responseDto/frame.dto';
import ProductPageSettings from '@models/product-page-settings';
import { IProductPageSettingsDto } from '@models/product-page-settings/responseDto/product-page-settings.dto';
import theme from '@styles/theme';
import { IThemeProps } from '@type/common';
import { IHarmonyTheme } from '@type/theme';
import ThemeUtils from '@utils/theme.utils';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import GlobalStyle from 'src/legacy/components/blockui/theme/GlobalStyle';
import useSWR from 'swr';
import useFontLoader from './use-font-loader';
import { FontLoader } from '@components/font-loader';

export type HarmonyProps = {
    isLoading: boolean;
    isFontLoaded: boolean;
    theme: IHarmonyTheme;
    settings: ChannelSettings | undefined;
    designSettings: ThemeDesignSetting | undefined;
    frameCollection: FrameCollection | undefined;
    productPageSettings: ProductPageSettings | undefined;
};

export const HarmonyContext = createContext<HarmonyProps>({} as HarmonyProps);

interface HarmonyProviderProps {
    children: ReactNode;
}

const HarmonyProvider: React.FC<HarmonyProviderProps> = ({ children }) => {
    const isFontLoaded = useFontLoader();

    const channelSettingsDto = useSWR<IChannelSettingsDto>(ChannelCacheKeys.basicSettingCacheKey(), () => ChannelClient.getChannelSettings());
    const frameArrayDto = useSWR<IFrameArrayDto>(ChannelCacheKeys.framesCacheKey(), () => ChannelClient.getChannelFrames());
    const productPageSettingsDto = useSWR<IProductPageSettingsDto>(ChannelCacheKeys.productPageSettingsCacheKey(), () => ChannelClient.getProductPageSettings());

    const channelSettings = useMemo<ChannelSettings | undefined>(() => {
        return channelSettingsDto.data ? new ChannelSettings(channelSettingsDto.data) : undefined;
    }, [channelSettingsDto]);
    const frameCollection = useMemo<FrameCollection | undefined>(() => {
        return frameArrayDto.data ? new FrameCollection(frameArrayDto.data) : undefined;
    }, [frameArrayDto.data]);
    const productPageSettings = useMemo<ProductPageSettings | undefined>(
        () => (productPageSettingsDto.data ? new ProductPageSettings(productPageSettingsDto.data) : undefined),
        [productPageSettingsDto]
    );

    const memoizedValues = useMemo<HarmonyProps>(
        () => ({
            isLoading: channelSettingsDto.isValidating,
            isFontLoaded,
            theme: channelSettings?.themeDesignSetting?.theme || theme,
            settings: channelSettings,
            designSettings: channelSettings?.themeDesignSetting,
            frameCollection,
            productPageSettings,
        }),
        [channelSettingsDto.isValidating, isFontLoaded, channelSettings, frameCollection, productPageSettings]
    );

    return (
        <ThemeProvider theme={memoizedValues.theme}>
            <GlobalStyle />
            {!!memoizedValues.designSettings && <FontLoader fonts={memoizedValues.designSettings.fonts} font={memoizedValues.theme.font} />}
            <HarmonyContext.Provider value={memoizedValues}>
                <BackgroundContainer theme={theme}>{children}</BackgroundContainer>
                {memoizedValues.isLoading && !channelSettings && (
                    <FixedCover theme={memoizedValues.theme}>
                        <FullPageLoader />
                    </FixedCover>
                )}
            </HarmonyContext.Provider>
        </ThemeProvider>
    );
};

export default HarmonyProvider;

/**
 * Wrapper context hook to house any desired hook logic
 */
export function useHarmony(): HarmonyProps {
    const context = useContext(HarmonyContext);

    return context;
}

const FixedCover = styled.div<IThemeProps>`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${ThemeUtils.zIndex.toast};
    background: ${({ theme }) => theme.background.config};
`;

const BackgroundContainer = styled.div<IThemeProps>`
    ${({ theme }) => `
        background: ${theme.background.config};
        min-height: 100vh;
    `}
`;
