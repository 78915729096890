import { useEffect, useRef, useState } from 'react';

const RECENT_KEYWORDS_STORAGE_KEY = 'recentSearches';
const MAX_RECENT_KEYWORDS = 10;

export type UseRecentKeywordsResult = ReturnType<typeof useRecentKeywords>;

export const useRecentKeywords = () => {
    const isLoadedRef = useRef(false);
    const [recentKeywords, setRecentKeywords] = useState<string[]>([]);

    const addKeyword = (keyword: string) => {
        setRecentKeywords((prev) => [keyword, ...prev.filter((item) => item !== keyword)].slice(0, MAX_RECENT_KEYWORDS));
    };

    const removeKeyword = (keyword: string) => {
        setRecentKeywords((prev) => prev.filter((item) => item !== keyword));
    };

    const clearAllKeywords = () => {
        setRecentKeywords([]);
    };

    useEffect(() => {
        if (isLoadedRef.current === true) {
            localStorage.setItem(RECENT_KEYWORDS_STORAGE_KEY, JSON.stringify(recentKeywords));
        } else {
            try {
                const storageData = localStorage.getItem(RECENT_KEYWORDS_STORAGE_KEY);
                if (storageData) {
                    setRecentKeywords(JSON.parse(storageData));
                }
            } catch (e) {
            } finally {
                isLoadedRef.current = true;
            }
        }
    }, [recentKeywords]);

    return { recentKeywords, addKeyword, removeKeyword, clearAllKeywords };
};
