import ChannelCacheKeys from '@api/channel/cache-keys';
import { ChannelClient } from '@api/channel/channel.client';
import SellPriceUnitPolicy from '@models/sell-price-policy';
import { ISellPricePolicyDto } from '@models/sell-price-policy/responseDto/sell-price-policy.dto';
import React, { PropsWithChildren, useContext, useMemo } from 'react';
import useSWR from 'swr';

export type SellPricePolicyProps = {
    sellPriceUnitType: string | undefined;
    currencyType: string | undefined;
    getPriceWithUnitPolicy: (price: number | undefined) => string | undefined;
};

export const SellPricePolicyContext = React.createContext<SellPricePolicyProps>({} as SellPricePolicyProps);

export const SellPricePolicyProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { data: priceUnitPolicy } = useSWR<ISellPricePolicyDto>(ChannelCacheKeys.getSellPriceUnitPolicy(), () => ChannelClient.getSellPricePolicy());
    const sellPriceUnitPolicy = useMemo<SellPriceUnitPolicy | undefined>(() => {
        return priceUnitPolicy ? new SellPriceUnitPolicy(priceUnitPolicy) : undefined;
    }, [priceUnitPolicy]);

    const sellPriceUnitType = sellPriceUnitPolicy?.sellPriceUnitType;
    const currencyType = sellPriceUnitPolicy?.currencyType;

    const getPriceWithUnitPolicy = (price: number | undefined) => {
        return sellPriceUnitPolicy?.getPriceStringWithUnit(price);
    };

    return <SellPricePolicyContext.Provider value={{ sellPriceUnitType, currencyType, getPriceWithUnitPolicy }}>{children}</SellPricePolicyContext.Provider>;
};

export const useSellPricePolicy = () => {
    const context = useContext(SellPricePolicyContext);
    return context;
};
