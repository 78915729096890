import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { IHarmonyTheme } from '@type/theme';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(menuAnatomy.keys);

export const menuTheme = (theme: IHarmonyTheme) =>
    defineMultiStyleConfig({
        baseStyle: definePartsStyle({
            list: {
                padding: 0,
                minWidth: 'auto',
                overflow: 'hidden',
            },
            item: {
                backgroundColor: theme.background.white,
                _hover: {
                    backgroundColor: theme.background.gray50,
                },
                '@media (pointer:coarse)': {
                    _hover: { backgroundColor: theme.background.white },
                },
            },
        }),
    });
