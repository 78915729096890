import { ImageUrl, ISODate, ProductId } from '@type/models';

export enum InventoryStatusEnum {
    Enough = 'enough',
    Shortage = 'shortage',
    SoldOut = 'sold_out',
}

export enum ActiveStatusEnum {
    Active = 'active',
    Inactive = 'inactive',
}

export enum RegisterStatusEnum {
    Requested = 'requested',
    RequestedAgain = 'requested_again',
    Rejected = 'rejected',
    Approved = 'approved',
}

export enum SalesTypeEnum {
    Normal = 'normal',
    Subscription = 'subscription',
    Hybrid = 'hybrid',
}

export enum SalesStatusEnum {
    Selling = 'selling',
    Stopped = 'stopped',
    Banned = 'banned',
    SupplyStopped = 'supply_stopped',
}

export enum DeliveryFrequencyTypeEnum {
    Fixed = 'fixed',
    User = 'user',
}

export enum DeliveryFrequencyOptionsTypeEnum {
    OnceEveryNWeeks = 'once_every_n_weeks', // N주 1회
    OnceEveryNMonths = 'once_every_n_months', // N월 1회
    // N_DAYS_PER_WEEK = 'n_days_per_week', // 매주 N회 - out of scope
    // N_DAYS_PER_MONTH = 'n_days_per_month', // 매월 N회 - out of scope
}

export enum SubscriptionEndTypeEnum {
    Indefinite = 'indefinite', // 무기한
    ByDate = 'by_date', // 날짜 별로
    ByRound = 'by_round', // 횟수 별로
}

export enum PaymentTypeEnum {
    PaymentPerNMonths = 'payment_per_n_months', // 신청일 고정형 N월 단위
    // SubscriptionPeriodSync = 'subscription_period_sync', // 배송주기 연동형 - out of scope
    // Upfront = 'upfront', // 일시불 - out of scope
    // PaymentPerDelivery = 'payment_per_delivery', // 회차별 - out of scope
    NoSubscription = 'no_subscription', // 선택안함
}

export enum DiscountAfterPaymentRuleEnum {
    CHANGE = 'change', // 변경
    MAINTAIN = 'maintain', // 유지/미변경
    DO_NOT_USE = 'do_not_use', // 미사용
}

export enum SubscriptionDeliveryPeriodEnum {
    AnyDay = 'any_day',
    CertainDaysPerWeek = 'certain_days_per_week', // - out of scope
    CertainDaysPerMonth = 'certain_days_per_month', // - out of scope
}

export enum PriceDisplayTypeEnum {
    MinSubPrice = 'min_sub_price',
    MinPurPrice = 'min_pur_price',
}

export interface ISubscriptionInfoDto {
    deliveryFrequencyType: DeliveryFrequencyTypeEnum;
    deliveryFrequencyOptionsTypeList: DeliveryFrequencyOptionsTypeEnum[];
    onceEveryNWeeksSetting: string | null;
    onceEveryNMonthsSetting: string | null;
    nDaysPerWeekSetting: number;
    nDaysPerMonthSetting: number;
    subscriptionEndType: SubscriptionEndTypeEnum;
    subscriptionEndDate: ISODate;
    subscriptionEndRound: ISODate;
    paymentType: PaymentTypeEnum;
    perMonthPaymentRuleSettings: string;
    discountPerPaymentRuleSettings: string;
    discountAfterPaymentType: DiscountAfterPaymentRuleEnum;
    paymentsUntilDiscountChange: number;
    discountToChangeTo: number;
    priceDisplayType: PriceDisplayTypeEnum;
}

/**
 * 0 index represents the subscription price
 * 1 index represents the month count
 * */
export type PricePerMonth = [number, number];

export interface IComponentProductDto {
    id: ProductId;
    brand: string;
    brandId: string | null;
    title: string;
    salesType: SalesTypeEnum;
    salesStatus: SalesStatusEnum;
    subscriptionDeliveryPeriod: SubscriptionDeliveryPeriodEnum;
    deliveryDaysPerWeek: string[];
    deliveryDaysPerMonth: number[];
    subscriptionInfo: ISubscriptionInfoDto | null;
    minSellPrice: number;
    minSellPriceBeforeReduction: number | null;
    minSellPriceAfterReduction: number | null;
    minSubPrice: PricePerMonth;
    minSubMarketPrice: PricePerMonth;
    minPurPrice: number;
    marketPrice: number;
    discountRate: number;
    wishlistId: number | null;
    images: ImageUrl[];
    timesaleApplied: boolean;
    timesaleAppliedNow: boolean;
    inventoryStatus: InventoryStatusEnum | string;
    hasAgeLimitation: boolean;
}
