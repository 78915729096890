import styled from '@emotion/styled';
import { InternalToastProps } from '..';

const ToastC = styled.div<InternalToastProps>`
    ${({ theme, getFontSize, color }) => `
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 13px 16px;
        gap: 8px;
        border-radius: 4px;
        background-color: ${color || theme.gray[800]};
        font-family: ${theme.font.primary};
        font-size: ${getFontSize ? getFontSize('16px') : '14px'};
        line-height: 22px;
        color: ${theme.white};
    `}
`;

export default ToastC;
