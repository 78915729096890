import RoundedDrawer from 'src/legacy/components/common/rounded-drawer';
import BodyText from 'src/legacy/components/common/text/body-text';
import styled from '@emotion/styled';
import { BlockHStack, Modal, ModalContent, BlockVStack } from 'src/legacy/components/blockui';
import { useHarmony } from '@hooks/use-harmony';
import { PaddedContainer } from '@styles/styled-components';
import { IThemeProps, ScreenSizeProps } from '@type/common';
import { useTranslations } from 'next-intl';
import { AlertProps, AlertStatusEnum, AlertTypeEnum, Buttons } from '..';
import AlertButtons from '../alert-buttons';
import AlertIcon from '../alert-icon';
import ThemeUtils from '@utils/theme.utils';
import { useExtension } from '@hooks/use-extension';

const AlertA: React.FC<AlertProps> = ({ type = AlertTypeEnum.Drawer, status = AlertStatusEnum.Alert, buttons = { type: 'default' }, open, children, onClose }) => {
    const { theme } = useHarmony();
    const t = useTranslations();
    const { extensionStatus } = useExtension();

    if (type === AlertTypeEnum.Modal) {
        return (
            <Modal open={open}>
                <StyledModalContent maxScreenWidth={extensionStatus?.maxScreenWidth}>
                    <BlockVStack spacing="16px" alignItems="center" pt="16px">
                        <BlockVStack spacing="none" alignItems="center">
                            <AlertIcon status={status} />
                            <BodyText wordBreak="break-word" whiteSpace="pre-wrap" weight="bold" size="16px" lineHeight="18px" textAlign="center">
                                {children}
                            </BodyText>
                        </BlockVStack>
                        <ButtonContainer theme={theme} buttons={buttons} status={status} type={type}>
                            <AlertButtons buttons={buttons} status={status} onClose={onClose} />
                        </ButtonContainer>
                    </BlockVStack>
                </StyledModalContent>
            </Modal>
        );
    }

    return (
        <StyledDrawer anchor="bottom" open={open} onClose={() => onClose?.()}>
            <PaddedContainer padding="40px 0 0 0">
                <BlockVStack alignItems="center" align="center">
                    <AlertIcon status={status} />
                    <PaddedContainer>
                        <BlockHStack align="center">
                            <BodyText textAlign="center" whiteSpace="pre-wrap" lineHeight="1.4rem" size="16px" weight="bold">
                                {children}
                            </BodyText>
                        </BlockHStack>
                    </PaddedContainer>
                    <ButtonContainer theme={theme} buttons={buttons} status={status} type={type}>
                        <AlertButtons buttons={buttons} status={status} onClose={onClose} />
                    </ButtonContainer>
                </BlockVStack>
            </PaddedContainer>
        </StyledDrawer>
    );
};

export default AlertA;

const StyledModalContent = styled(ModalContent)<ScreenSizeProps>`
    max-width: min(100vw, ${({ maxScreenWidth }) => (maxScreenWidth ? maxScreenWidth - 32 : 0)}px);
    padding: 16px;
    background: white;
    border-radius: 16px;
    overflow: hidden;
    z-index: ${ThemeUtils.zIndex.alert} !important;
`;

const ButtonContainer = styled.div<IThemeProps & { buttons: Buttons; status: AlertStatusEnum; type: AlertTypeEnum }>`
    ${({ theme, buttons, status, type }) => `
        display: grid;
        grid-template-columns: ${buttons.type === 'default' || buttons.type === 'async-confirm' ? '1fr' : '1fr 1fr'};
        column-gap: 8px;
        width: 100%;
        height: 72px;
        padding: 12px 16px;
        margin-top: ${status === AlertStatusEnum.None || type === AlertTypeEnum.Modal ? '0' : '40px'};
        font-family: ${theme.font.default.bold};
    `}
`;

const StyledDrawer = styled(RoundedDrawer)`
    z-index: ${ThemeUtils.zIndex.alert} !important;
`;
