const notification = {
    notification: {
        notification: '알림',
        unread: '읽지 않은 알림',
        count: '건',
        settings: '알림설정',
        empty: '받은 알림이 없습니다',
        empty2: '알림이 없습니다.',
        close: '닫기',
        isMarketingNotified: '마케팅 수신동의',
        isMarketingNotifiedDesc: '마케팅 수신에 동의하시면 혜택과 소식을 받으실 수 있습니다.',
        isNoticeNotified: '공지사항',
        isNoticeNotifiedDesc: '새로운 공지사항을 알림받습니다.',
        isTrxNotified: '주문/배송',
        isTrxNotifiedDesc: '주문한 상품의 상태를 알림받습니다.',
        isReviewNotified: '리뷰',
        isReviewNotifiedDesc: '리뷰 작성 기간, 신고 등을 알림받습니다.',
        isProductNotified: '상품',
        isProductNotifiedDesc: '품절임박 상품, 품절상품 재입고 알림을 받습니다.',
        viewTerms: '약관보기',
        settingUpdate: '[{type}]의 알림을 받습니다.',
        dozensUnread: '개의 확인하지 않은 알림이 있습니다.',
        edit: '편집',
    },
};

export default notification;
