import safeWindow from '@services/safe-window.service';

export type GuestInfo = {
    guestUserPassword: string;
    guestPhoneNumber: string;
    transactionSn: string;
};

class GuestUtils {
    private static _GUEST_INFO = 'guest-info';

    static get isGuest() {
        return !!this.getGuestInfo()?.transactionSn;
    }

    static setGuestInfo(guestInfo: GuestInfo) {
        if (!safeWindow) {
            return;
        }

        safeWindow.sessionStorage.setItem(this._GUEST_INFO, JSON.stringify(guestInfo));
    }

    static getGuestInfo(): GuestInfo | null {
        if (!safeWindow) {
            return null;
        }

        return JSON.parse(`${safeWindow.sessionStorage.getItem(this._GUEST_INFO)}`);
    }

    static getGuestInfoHeaders() {
        const guestInfo = this.getGuestInfo();

        if (!guestInfo) {
            return null;
        }

        return {
            'x-unsigned-secret-key': guestInfo.guestUserPassword,
            'x-phone-number': guestInfo.guestPhoneNumber,
            'transaction-sn': guestInfo.transactionSn,
        };
    }

    static removeGuestInfo() {
        if (!safeWindow) {
            return;
        }

        safeWindow.sessionStorage.removeItem(this._GUEST_INFO);
    }
}

export default GuestUtils;
