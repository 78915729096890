import Frame from './frame';
import FrameMenuItem from './frame-menu-item';
import { FrameTopMenuItemTypeEnum, IFrameTopMenuDto } from './responseDto/frame.dto';

class FrameTopMenu extends Frame {
    private _itemType: FrameTopMenuItemTypeEnum;
    private _items: FrameMenuItem[];

    constructor(frameTopMenuDto: IFrameTopMenuDto) {
        super(frameTopMenuDto);
        this._itemType = frameTopMenuDto.itemType;
        this._items = frameTopMenuDto.items.map((item, index) => new FrameMenuItem(item, index === 0));
    }

    get itemType(): string {
        return this._itemType;
    }

    get items(): FrameMenuItem[] {
        return this._items;
    }
}

export default FrameTopMenu;
