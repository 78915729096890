import { Box, VStack } from '@chakra-ui/react';
import { useHarmony } from '@hooks/use-harmony';
import { DetailsBoxProps } from '..';
import DetailItem from '../../detail-item';

const DetailsBoxC: React.FC<DetailsBoxProps> = ({ details }) => {
    const { theme } = useHarmony();

    return (
        <Box position="fixed" bottom="80px" left="0" right="0" bgColor={theme.gray[50]} p="24px 16px" borderTopRadius="20px">
            <VStack spacing="16px" align="stretch">
                {Object.entries(details.data).map(([label, value]) => !!value && <DetailItem key={label} label={label} value={value} />)}
            </VStack>
        </Box>
    );
};

export default DetailsBoxC;
