import { Box, Drawer, DrawerContent, DrawerOverlay, DrawerProps, Flex } from '@chakra-ui/react';
import Header from '@designs/grouped/main/header';
import { useExtension } from '@hooks/use-extension';
import { useIsDesktop } from '@hooks/use-is-desktop';
import { useEffect, useMemo } from 'react';
import { uuid } from 'uuidv4';

const OPENED_MODALS = 'opened-modals';

export interface FullModalProps extends DrawerProps {
    title?: string;
    hasHeader?: boolean;
    zIndex?: number;
}

const FullModal: React.FC<FullModalProps> = ({ children, title, hasHeader = true, zIndex, ...props }) => {
    const { extensionStatus } = useExtension();
    const isDesktop = useIsDesktop();
    const modalId = useMemo(() => uuid(), []);

    /**
     * Blocked body scroll manually to use multiple chakra modal.
     *
     * Because chakra modals block each other's scrolls.
     */
    useEffect(() => {
        let openedModals = new Set<string>(sessionStorage.getItem(OPENED_MODALS) || []);

        const update = (isOpen: boolean) => {
            if (isOpen) {
                openedModals.add(modalId);
            } else {
                openedModals.delete(modalId);
            }

            window.document.body.style.overflowY = openedModals.size > 0 ? 'hidden' : '';
        };

        update(props.isOpen);

        return () => {
            update(false);
        };
    }, [modalId, props.isOpen]);

    return (
        <Drawer {...props} placement="bottom" blockScrollOnMount={false}>
            <DrawerOverlay />
            <DrawerContent height="var(--chakra-vh)" zIndex={zIndex} maxW={`${extensionStatus?.maxScreenWidth}px !important`} m="0 auto" mr={isDesktop ? 'calc(100vw / 2 - 600px)' : undefined}>
                <Flex flexDir="column" w={`min(100vw, ${extensionStatus?.maxScreenWidth}px)`} h="100%" margin="0 auto">
                    {hasHeader && <Header type="full-modal" title={title} onCloseClick={props.onClose} />}
                    <Box overflowY="auto" minH="0px" flex={1}>
                        <Box>{children}</Box>
                    </Box>
                </Flex>
            </DrawerContent>
        </Drawer>
    );
};

export default FullModal;
