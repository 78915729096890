export enum CartDiscountTypeEnum {
    Subscription = 'subscription',
    Coupon = 'coupon',
    SkuPoint = 'sku_point',
}

export interface IDiscountItem {
    discountType: CartDiscountTypeEnum;
    totalDiscountPrice: number;
}

export interface IFinalCartCalculationsDto {
    groupDeliveryDiscount: number;
    productDiscountItems?: IDiscountItem[];
    regionalFees: number;
    totalDeliveryFee: number;
    totalPgAmountToPay: number;
    totalProductPrice: number;
    totalDiscountPriceForProduct: number;
}
