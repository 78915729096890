import CONFIG from '@config';
import DomainUtils from '@utils/domain.utils';
import EnvironmentUtils from '@utils/environment.utils';
import GuestUtils from '@utils/guest.utils';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import Cookies from 'js-cookie';
import snakecaseKeys from 'snakecase-keys';
import AuthClient from './auth/auth.client';

type ClientOptions = {
    isCamelcaseApi?: boolean;
};

export enum CustomHeaderEnum {
    Retry = 'retry',
    WithoutDeepCaseChange = 'without-deep-case-change',
}

/**
 * Error intercepting middleware to run custom logic on a caught error.
 * Return undefined if not logic should be run.
 */
type ErrorMiddleware = (client: AxiosInstance, error: AxiosError) => Promise<AxiosResponse<any, any> | undefined>;

export enum HttpStatusCode {
    OK = 200,
    Created = 201,
    NoContent = 204,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    Gone = 410,
    UnprocessableEntity = 422,
    InternalServerError = 500,
    Conflict = 409,
}

interface ErrorResponse {
    code: string;
    data: any[];
    message: string;
}
export interface InterceptedErrorResponse {
    error: ErrorResponse;
}

/**
 * API fetching service class that instantiates new axios instance when provided a base URL.
 * Response and error interceptors are also applied to each instance.
 */
class ApiService {
    private static readonly _ACCESS_TOKEN = `access-token-${CONFIG.domain}`;
    private static readonly _REFRESH_TOKEN = `refresh-token-${CONFIG.domain}`;
    /**
     * To solve the security issue with the Naver browser, cookies always require an expiration time.
     *
     * Originally, the access token's expiration time is 2 hours. But due to side effects, it's set to a long time.
     */
    private static readonly _ACCESS_TOKEN_EXPIRES = 9999;
    /**
     * Same as the access token, the refresh token's expiration time is 1 day originally.
     */
    private static readonly _REFRESH_TOKEN_EXPIRES = 9999;

    /**Timeout setting for axios in ms */
    private readonly _TIMEOUT = 10000;

    private readonly _authClient: AuthClient;
    private readonly _options: ClientOptions;

    private _errorMiddleware: ErrorMiddleware | undefined = undefined;

    static getAccessToken(): string | undefined {
        if (typeof window === 'undefined') return;
        return Cookies.get(this._ACCESS_TOKEN) || undefined;
    }

    static getRefreshToken(): string | undefined {
        return Cookies.get(this._REFRESH_TOKEN) || undefined;
    }

    static setAccessToken(accessToken: string): void {
        GuestUtils.removeGuestInfo();

        Cookies.set(this._ACCESS_TOKEN, accessToken, {
            domain: EnvironmentUtils.cookieDomain,
            expires: this._ACCESS_TOKEN_EXPIRES,
            secure: DomainUtils.isNhPay || DomainUtils.isNhPayNormal,
        });
    }

    static setRefreshToken(refreshToken: string): void {
        Cookies.set(this._REFRESH_TOKEN, refreshToken, {
            domain: EnvironmentUtils.cookieDomain,
            expires: this._REFRESH_TOKEN_EXPIRES,
            secure: DomainUtils.isNhPay || DomainUtils.isNhPayNormal,
        });
    }

    static deleteTokens(): void {
        Cookies.remove(this._ACCESS_TOKEN);
        Cookies.remove(this._REFRESH_TOKEN);
    }

    private _instance: AxiosInstance;

    constructor(baseURL: string, authClient: AuthClient, options: ClientOptions = {}) {
        this._instance = axios.create({
            baseURL,
            headers: {
                Accept: 'application/json',
            },
            withCredentials: true,
            timeout: this._TIMEOUT,
        });

        this._options = options;
        this._instance.interceptors.response.use(this._responseIntercept, this._responseInterceptError(this._instance));
        this._authClient = authClient;
    }

    private _snakecaseKeys = (data: Record<string, unknown>) => {
        return this._options.isCamelcaseApi ? data : snakecaseKeys(data, { deep: true });
    };

    private _responseIntercept = (response: AxiosResponse) => {
        const { data, ...rest } = response;

        return {
            ...rest,
            data: this._options.isCamelcaseApi ? data : camelcaseKeys(data, { deep: rest.config.headers?.[CustomHeaderEnum.WithoutDeepCaseChange] ? false : true }),
        };
    };

    private _responseInterceptError = (client: AxiosInstance) => async (error: AxiosError) => {
        try {
            if (this._errorMiddleware) {
                const result = await this._errorMiddleware(client, error);
                if (result) {
                    return result;
                }
            }

            if (error.response?.status === HttpStatusCode.InternalServerError) {
                return Promise.reject(error);
            }

            if (error.response?.status === HttpStatusCode.Gone) {
                return error.response;
            }

            const originalRequest = error.config;
            if (error.response?.status === HttpStatusCode.Unauthorized && !originalRequest.url?.includes('login')) {
                if (!originalRequest.headers?.[CustomHeaderEnum.Retry]) {
                    originalRequest.headers = {
                        ...originalRequest.headers,
                        [CustomHeaderEnum.Retry]: true,
                    };

                    try {
                        if (!!ApiService.getRefreshToken()) {
                            await this._authClient.postRefreshToken();
                        } else {
                            return Promise.reject(error);
                        }
                    } catch (err) {
                        return Promise.reject(err);
                    }

                    return client({ ...originalRequest, headers: { ...originalRequest.headers, Authorization: `Bearer ${ApiService.getAccessToken()}` } });
                } else {
                    return Promise.reject(error);
                }
            }

            if (error.code === 'ECONNABORTED') {
                // TODO: handle axios timeout error
                console.log('timeout occurred');
                return Promise.reject(error);
            }

            return Promise.reject(error);
        } catch (middlewareError) {
            return Promise.reject(middlewareError);
        }
    };

    /**
     * Use this if you need access to the raw response including status codes.
     * Otherwise, you can use the get, post, put, patch, and delete wrapper methods to only return data.
     *
     * NOTE: If using this, you must manually change request data to snakecase keys and include auth headers.
     */
    public get instance(): AxiosInstance {
        return this._instance;
    }

    /**
     * Optionally register a custom error interception middleware to handle possible errors unique
     * to that instance of ApiService.
     * @param middleware ErrorMiddleware to run when an error is intercepted
     * @returns ApiService in order to allow a chained configuration
     */
    public registerErrorMiddleware(middleware: ErrorMiddleware): ApiService {
        this._errorMiddleware = middleware;
        return this;
    }

    /**
     * Takes a request configuration and passes in the Authorization header if present
     */
    public configWithAuth(config?: AxiosRequestConfig): AxiosRequestConfig | undefined {
        const accessToken = ApiService.getAccessToken();

        if (!accessToken) return config;
        return {
            ...config,
            withCredentials: true,
            headers: {
                ...config?.headers,
                Authorization: `Bearer ${accessToken}`,
            },
        };
    }

    public async get<T = void>(url: string, config?: AxiosRequestConfig): Promise<T> {
        try {
            const response = await this._instance.get<T>(url, this.configWithAuth(config));
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    public async post<T = void>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        try {
            const response = await this._instance.post<T>(url, this._snakecaseKeys(data || {}), this.configWithAuth(config));
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    public async put<T = void>(url: string, data: any, config?: AxiosRequestConfig): Promise<T> {
        try {
            const response = await this._instance.put<T>(url, this._snakecaseKeys(data || {}), this.configWithAuth(config));
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    public async patch<T = void>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        try {
            const response = await this._instance.patch<T>(url, this._snakecaseKeys(data || {}), this.configWithAuth(config));
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    public async delete<T = void>(url: string, config?: AxiosRequestConfig): Promise<T> {
        try {
            const response = await this._instance.delete<T>(url, this.configWithAuth(config));
            return response.data;
        } catch (err) {
            throw err;
        }
    }
}

export default ApiService;
