const reviewHistory = {
    reviewHistory: {
        reviewHistory: '리뷰 내역',
        empty1: '리뷰 내역이 없습니다.',
        empty2: '주문 내역에서 리뷰 가능한 상품을 확인 후',
        empty3: '리뷰를 작성해 주세요.',
    },
};

export default reviewHistory;
