import { HStack } from '@chakra-ui/react';
import Body1B from '@designs/primitives/typography/body-1-b';
import { useHarmony } from '@hooks/use-harmony';
import { DetailItemProps } from '..';

const DetailItemC: React.FC<DetailItemProps> = ({ label, value }) => {
    const { theme } = useHarmony();

    return (
        <HStack justify="space-between" w="100%">
            <Body1B color={theme.text.body2} textAlign="center">
                {label}
            </Body1B>
            <Body1B color={theme.text.body2} textAlign="center">
                {value}
            </Body1B>
        </HStack>
    );
};

export default DetailItemC;
