import { IKeywordDto } from './responseDto/keyword.dto';

class Keyword {
    private _index: number;
    private _keyword: string;

    constructor(keyword: IKeywordDto) {
        this._index = keyword.index;
        this._keyword = keyword.keyword;
    }

    get index(): number {
        return this._index;
    }

    get keyword(): string {
        return this._keyword;
    }
}

export default Keyword;
