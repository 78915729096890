import { useDesignComponents } from '@hooks/use-design-components';

import { IconButtonProps } from '@designs/primitives/common/icon-button';
import RoutesEnum from '@routes';
import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import HomeButtonD from './designD/home-button';

export interface HomeButtonProps extends Omit<IconButtonProps, 'children'> {}

const HomeButton: React.FC<HomeButtonProps> = (props) => {
    const Component = useDesignComponents<ComponentType<HomeButtonProps>>({
        [Design.DesignD]: HomeButtonD,
    });

    return <Component href={RoutesEnum.Home} {...props} />;
};

export default HomeButton;
