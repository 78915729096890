import { Button, HStack, forwardRef } from '@chakra-ui/react';
import Body1C from '@designs/primitives/typography/body-1-c';
import { useHarmony } from '@hooks/use-harmony';
import { MainButtonInnerProps } from '..';

const MainButtonB: React.FC<MainButtonInnerProps> = forwardRef<MainButtonInnerProps, 'button'>(({ children, rightIcon, leftIcon, fontSize, fontWeight, ...props }, ref) => {
    const { theme } = useHarmony();

    const minHeight = {
        s: '40px',
        m: '48px',
    }[props.size];

    const styles = {
        primary: {
            variant: 'solid',
            color: theme.white,
        },
        secondary: {
            variant: 'outline',
            color: theme.primary.default,
            borderColor: theme.stroke.primary,
        },
    }[props.variant];

    return (
        <Button minH={minHeight} borderRadius="8px" {...props} {...styles} ref={ref}>
            <HStack spacing="4px">
                {leftIcon}
                <Body1C color="inherit" fontSize={fontSize} fontWeight={fontWeight}>
                    {children}
                </Body1C>
                {rightIcon}
            </HStack>
        </Button>
    );
});

export default MainButtonB;
