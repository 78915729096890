import { useTranslations } from 'next-intl';
import { useToast } from './use-toast';

type Copy = (text: string) => Promise<void>;

export function useClipBoard(): Copy {
    const t = useTranslations();
    const { showToast } = useToast();

    const copy: Copy = (text) => {
        if (navigator.clipboard) {
            return navigator.clipboard
                .writeText(text)
                .then(() => {
                    showToast({ description: t('common.copied') });
                })
                .catch(() => {
                    showToast({ description: t('common.copied') });
                });
        } else {
            return new Promise((resolve, reject) => {
                try {
                    const tempInput = document.createElement('input');
                    tempInput.value = text;
                    document.body.appendChild(tempInput);
                    tempInput.select();
                    document.execCommand('copy');
                    document.body.removeChild(tempInput);
                    showToast({ description: t('common.copied') });
                    resolve();
                } catch (e) {
                    reject(e);
                }
            });
        }
    };

    return copy;
}
