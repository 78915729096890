import styled from '@emotion/styled';
import * as PrimitiveSelect from '@radix-ui/react-select';
import React, { ReactNode, useEffect, useState } from 'react';
import BlockIcon from '../Icon';
import { BaseProps, BlockSelectable } from '../types';

type Align = 'left' | 'right';

export type SelectProps = BaseProps & {
    items: ReadonlyArray<BlockSelectable>;
    onSelect?: (selected: BlockSelectable | null) => void;
    defaultValue?: BlockSelectable;
    value?: BlockSelectable | null;
    width?: number | string;
    height?: number | string;
    fontSize?: string;
    padding?: string;
    itemPadding?: string;
    align?: Align;
    disabled?: boolean;
    enableReinitialize?: boolean;

    /**
     * Wrap the text shown in the trigger
     * @default true
     */
    wrap?: boolean;

    /**
     * Display something in front of selected text in the trigger
     */
    preLabel?: ReactNode;

    /**
     * Display placeholder which is not selectable menu option
     */
    placeholder?: string;

    /**
     * This will override the default arrow down icon.
     */
    icon?: ReactNode;

    /**
     * Renders outline around select trigger.
     * @default true
     */
    showOutline?: boolean;
};

const Select = React.forwardRef<HTMLButtonElement, SelectProps>(
    (
        {
            items,
            defaultValue,
            value,
            onSelect,
            width,
            height,
            disabled = false,
            enableReinitialize = false,
            preLabel,
            placeholder,
            icon,
            showOutline = true,
            fontSize,
            padding,
            wrap = true,
            itemPadding,
            ...props
        }: SelectProps,
        ref
    ) => {
        const [selected, setSelected] = useState<BlockSelectable | null>(items.length > 0 ? items[0] : null);

        const handleValueChange = (label: string) => {
            // TODO: Change this hack, standardize on value as string going forward
            const tempSelected = items.find((item) => item.label === label);

            if (tempSelected) {
                setSelected(tempSelected);
                onSelect && onSelect(tempSelected);
            }
        };

        useEffect(() => {
            if (!enableReinitialize || !defaultValue) return;
            setSelected(defaultValue);
        }, [enableReinitialize, defaultValue]);

        useEffect(() => {
            if (value && value.value !== selected?.value) return setSelected(value);
        }, [value]);

        const { align, ...remainingProps } = props;

        return (
            <PrimitiveSelect.Root defaultValue={defaultValue?.label || selected?.label} value={selected?.label} onValueChange={handleValueChange}>
                <StyledTrigger
                    height={height}
                    width={width}
                    ref={ref}
                    data-test-id={props.testId}
                    disabled={disabled}
                    padding={padding}
                    showOutline={showOutline ? 1 : 0}
                    fontSize={fontSize}
                    {...props}
                >
                    {preLabel && preLabel}
                    {wrap && <StyledValue disabled={disabled} placeholder={placeholder} />}
                    {!wrap && (
                        <AbsoluteWidth>
                            <StyledValue disabled={disabled} placeholder={placeholder} />
                        </AbsoluteWidth>
                    )}
                    <IconContainer>
                        <PrimitiveSelect.Icon>{icon ? icon : <BlockIcon>arrow_drop_down</BlockIcon>}</PrimitiveSelect.Icon>
                    </IconContainer>
                </StyledTrigger>
                <StyledContent fontSize={fontSize} {...remainingProps}>
                    <PrimitiveSelect.Viewport>
                        {/* TODO: Temporary workaround to get dropdown effect instead of default radix effect */}
                        {/* Issue tracking https://github.com/radix-ui/primitives/issues/1247 */}
                        {selected && (
                            <StyledSelectedItem value={selected.label}>
                                <PrimitiveSelect.ItemText>{selected.label}</PrimitiveSelect.ItemText>
                            </StyledSelectedItem>
                        )}
                        {items
                            .filter((item) => item.label !== selected?.label)
                            .map((item) => (
                                <StyledItem itemPadding={itemPadding} value={item.label} key={item.value}>
                                    <PrimitiveSelect.ItemText>{item.label}</PrimitiveSelect.ItemText>
                                </StyledItem>
                            ))}
                    </PrimitiveSelect.Viewport>
                </StyledContent>
            </PrimitiveSelect.Root>
        );
    }
);

const StyledTrigger = styled(PrimitiveSelect.Trigger)<{
    height?: number | string;
    width?: number | string;
    showOutline?: number;
    padding?: string;
    fontSize?: string;
}>`
    height: ${(props) => props.height || 'auto'};
    width: ${(props) => props.width || 'auto'};
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: ${({ padding }) => padding || '0.25rem 1rem'};
    flex-shrink: 0;
    outline: 1px solid ${(props) => (props.showOutline ? props.theme.gray[200] : 'none')};
    background: white;
    font-family: ${(props) => props.theme.font.primary};
    font-size: ${({ fontSize }) => fontSize || '14px'};
    border: none;
    cursor: pointer;
    overflow: hidden;
    position: relative;

    &:focus {
        outline-color: ${(props) => props.theme.primary.lighten[300]};
    }
`;

const StyledValue = styled(PrimitiveSelect.Value)<{ disabled: boolean }>`
    color: ${(props) => (props.disabled ? props.theme.gray[600] : 'initial')};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StyledContent = styled(PrimitiveSelect.Content)<{ fontSize?: string }>`
    width: 100%;
    overflow: hidden;
    background-color: ${(props) => props.theme.white};
    box-shadow:
        0px 10px 38px -10px rgba(22, 23, 24, 0.35),
        0px 10px 20px -15px rgba(22, 23, 24, 0.2);
    position: relative;

    font-family: ${(props) => props.theme.font.primary};
    font-size: ${({ fontSize }) => fontSize || '14px'};
`;

const StyledSelectedItem = styled(PrimitiveSelect.Item)`
    padding: 0.25rem 1rem;
    outline: none;
    background-color: #cce4ff;

    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 100ms;

    &:hover {
        opacity: 75%;
    }
`;

const StyledItem = styled(PrimitiveSelect.Item)<{ itemPadding?: string }>`
    padding: ${({ itemPadding }) => itemPadding || '0.25rem 1rem'};
    outline: none;
    cursor: pointer;

    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 100ms;

    &:hover {
        background-color: #cce4ff;
        opacity: 65%;
    }
`;

const IconContainer = styled.div`
    margin-left: auto;
`;

const AbsoluteWidth = styled.div`
    position: absolute;
    left: 1rem;
    right: 2.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

Select.displayName = 'Select';

export default Select;
