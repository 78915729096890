const orderDetail = {
    orderDetail: {
        shippingInfo: '배송지 정보',
        receiver: '받으시는 분',
        phone: '연락처',
        noPhone: '휴대폰 번호 미등록',
        address: '주소',
        deliveryRequest: '배송요청사항',
        deliveryRequest2: '요청사항',
        orderInfo: '주문정보',
        sender: '주문자 명',
        paymentInfo: '결제금액 정보',
        totalProduct: '총 상품금액',
        totalShipping: '총 배송비',
        defaultShipping: '기본 배송비',
        deliveryFee: '배송비',
        combinedShipping: '묶음배송 할인',
        additionalShipping: '지역별 추가 배송비',
        finalPayment: '최종 결제금액',
        paymentMethodInfo: '결제수단 정보',
        creditCard: '신용카드',
        paidDate: '거래일시',
        installmentPeriod: '할부기간',
        refundAmount: '환불금액 정보',
        refundMethod: '환불수단',
        refund: '환불금액',
        refundPoint: '환불포인트',
        mayDiffer: '결제수단에 따라 실제 환불 시점은 상이할 수 있습니다.',
        cancelAll: '주문 전체취소',
        pointsUsed: '총 포인트',
        returnPoint: '반환 {pointName}',
        regional: '지역별 추가 배송비',
        installments: '{number}  개월',
        totalDel: '총 배송 횟수',
        time: '회',
        totalProductAmount: '총 상품 금액',
        totalDiscountAmount: '총 할인 금액',
        billingCycleDiscount: '결제주기 할인',
        couponDiscount: '쿠폰할인',
        usage: '사용정보',
        payment: '결제정보',
        duration: '유효기간',
        expired: '기간만료',
        exchange: '교환처',
        dist: '유통사',
        unlinkedNote:
            '1~2 영업일 이내에 주문하신 상품권이 등록된 휴대전화 번호로 문자 발송되는 상품권입니다. 구매 이후에 장기간 문자를 수신받지 못하신 경우, 고객센터({contact})에 문의해주세요',
        pleaseNote: '알려 드립니다!',
        external: '상품권면참조',
        availablePoints: '사용가능 금액: ',
        pleaseContact: `기프티콘 정보가 보이지 않으시면\n고객센터로 문의해주세요.\n고객센터: {number}`,
        copyNumber: '번호복사',
        couponDiscount2: '쿠폰 할인',
        checkGuestOrder: '비회원 주문 확인',
        needGuestInfo: '비회원 주문을 확인하기 위해서는\n아래 정보가 필요합니다.',
        ordererPhone: '주문자 휴대번호',
        guestOrderPassword: '주문 시 입력한 비밀번호',
        enterOrderSn: '주문번호를 입력해 주세요.',
        enterOrdererPhone: '주문자 휴대폰번호를 입력해 주세요.',
        enterPassword: '비밀번호를 입력해 주세요.',
        notFoundSn: '일치하는 주문번호가 없습니다.',
        notMatchPhoneNumber: '휴대폰번호가 일치하지 않습니다.',
        notMatchPassword: '비밀번호가 일치하지 않습니다.',
    },
};

export default orderDetail;
