import { useDesignComponents } from '@hooks/use-design-components';

import { ButtonProps } from '@chakra-ui/react';
import CustomLink from '@components/custom-link';
import { Design } from '@type/harmony-config';
import { LinkProps } from 'next/link';
import { ComponentType } from 'react';
import MainButtonA from './designA/main-button';
import MainButtonB from './designB/main-button';
import MainButtonC from './designC/main-button';
import MainButtonD from './designD/main-button';

export interface MainButtonProps extends ButtonProps {
    size?: 's' | 'm';
    variant?: 'primary' | 'secondary';
    href?: LinkProps['href'];
    replaceLink?: boolean;
}

export interface MainButtonInnerProps extends MainButtonProps {
    size: 's' | 'm';
    variant: 'primary' | 'secondary';
}

const MainButton: React.FC<MainButtonProps> = (props) => {
    const Component = useDesignComponents<ComponentType<MainButtonInnerProps>>({
        [Design.DesignA]: MainButtonA,
        [Design.DesignB]: MainButtonB,
        [Design.DesignC]: MainButtonC,
        [Design.DesignD]: MainButtonD,
    });

    return (
        <CustomLink href={props.href} replace={props.replaceLink}>
            <Component type="button" flex={1} size="m" variant="primary" {...props} />
        </CustomLink>
    );
};

export default MainButton;
