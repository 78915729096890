abstract class IdBaseModel {
    protected _id: string;

    constructor(id: string | number) {
        this._id = String(id);
    }

    get id(): string {
        return this._id;
    }
}

export default IdBaseModel;
