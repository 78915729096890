import { Global, css, SerializedStyles } from '@emotion/react';
import { FC } from 'react';

const FONTS: SerializedStyles = css`
    @font-face {
        font-family: 'Lexend Deca';
        src: url('/fonts/LexendDeca-ExtraLight.ttf');
        font-weight: 200;
    }

    @font-face {
        font-family: 'Lexend Deca';
        src: url('/fonts/LexendDeca-Regular.ttf');
        font-weight: 400;
    }

    @font-face {
        font-family: 'Lexend Deca';
        src: url('/fonts/LexendDeca-Bold.ttf');
        font-weight: 700;
    }

    @font-face {
        font-family: 'Lexend Deca';
        src: url('/fonts/LexendDeca-Black.ttf');
        font-weight: 900;
    }

    @font-face {
        font-family: 'Nanum Square';
        src: url('/fonts/NanumSquareL.ttf');
        font-weight: 300;
    }

    @font-face {
        font-family: 'Nanum Square';
        src: url('/fonts/NanumSquareR.ttf');
        font-weight: 400;
    }

    @font-face {
        font-family: 'Nanum Square';
        src: url('/fonts/NanumSquareB.ttf');
        font-weight: 700;
    }

    @font-face {
        font-family: 'Nanum Square';
        src: url('/fonts/NanumSquareEB.ttf');
        font-weight: 800;
    }
`;

const ANIMATIONS: SerializedStyles = css`
    @keyframes openDrawer {
        from {
            left: -100%;
        }
        to {
            left: 0%;
        }
    }
`;

const GLOBAL_STYLES: SerializedStyles = css`
    ${FONTS}
    ${ANIMATIONS}

  * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-size: 100%;
        font: inherit;
        line-height: 1;
        vertical-align: baseline;
        border: 0;
    }

    /* HTML5 display-role reset for older browsers */
    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    menu,
    nav,
    section {
        display: block;
    }

    body {
        line-height: 1;
        font-family: 'Nanum Square';
    }

    ol,
    ul {
        list-style: none;
    }

    blockquote,
    q {
        quotes: none;
    }

    blockquote:before,
    blockquote:after,
    q:before,
    q:after {
        content: '';
        content: none;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    input {
        background: none;
        border: none;
    }

    input:focus {
        outline: none;
    }
`;

const GlobalStyle: FC = () => <Global styles={GLOBAL_STYLES} />;

export default GlobalStyle;
