import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import { ComponentType, ReactNode } from 'react';
import DetailItemA from './designA/detail-item';
import DetailItemB from './designB/detail-item';
import DetailItemC from './designC/detail-item';
import DetailItemD from './designD/detail-item';

export interface DetailItemProps {
    label: ReactNode;
    value: ReactNode;
    isVertical?: boolean;
}

const DetailItem: React.FC<DetailItemProps> = (props) => {
    const Component = useDesignComponents<ComponentType<DetailItemProps>>({
        [Design.DesignA]: DetailItemA,
        [Design.DesignB]: DetailItemB,
        [Design.DesignC]: DetailItemC,
        [Design.DesignD]: DetailItemD,
    });

    return <Component {...props} />;
};

export default DetailItem;
