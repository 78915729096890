import { BaseProps } from '../types';
import styled from '@emotion/styled';
import palette from '../theme/palette';
import React from 'react';

export type FormErrorMessageProps = BaseProps &
    Omit<React.HTMLProps<HTMLSpanElement>, 'as'> & {
        size?: string;
    };

/**
 * Form error message to display errors from inputs
 */
const FormErrorMessage: React.FC<FormErrorMessageProps> = ({ testId, children, ...props }) => {
    return (
        <StyledMessage test-data-id={testId} {...props}>
            {children}
        </StyledMessage>
    );
};

export default FormErrorMessage;

const StyledMessage = styled.span<FormErrorMessageProps>`
    color: ${palette.red[400]};
    font-size: ${({ size }) => size || '0.75rem'};
    display: block;
`;
