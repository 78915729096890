import * as DialogPrimitive from '@radix-ui/react-dialog';
import { ReactNode } from 'react';

export type ModalProps = {
    children?: ReactNode;
    open?: boolean;
};

function NewModal({ children, open = true }: ModalProps) {
    return <DialogPrimitive.Root open={open}>{children}</DialogPrimitive.Root>;
}

export default NewModal;
