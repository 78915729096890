type NativeWindowType = Window & typeof globalThis;

class SafeWindowService {
    static nativeWindow(): NativeWindowType | undefined {
        return typeof window !== 'undefined' ? window : undefined;
    }
}

const safeWindow = SafeWindowService.nativeWindow();
export default safeWindow;
