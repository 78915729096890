import { SalesTypeEnum } from '@models/product/responseDto/component-product.dto';
import { CouponId, ImageUrl, ISODate, ProductClassEnum } from '@type/models';
import { CouponStatusEnum } from '../requestDto/query-coupon.dto';

export interface ICouponDto {
    id: string;
    couponId: CouponId;
    issuedDt: ISODate;
    usedDt: ISODate;
    couponCode: string;
    status: CouponStatusEnum;
    couponUsageScope: CouponUsageScopeEnum;
    couponDistributionType: string;
    couponCouponDisplayType: string;
    couponImageUrl: ImageUrl;
    couponName: string;
    couponDescription: string;
    couponApplicableCategoryRange: CouponProductRangeEnum;
    couponApplicableCategoryIds: string[] | null;
    couponApplicableBrandRange: CouponProductRangeEnum;
    couponApplicableBrandIds: string[] | null;
    couponApplicableProductTypes: ProductClassEnum[];
    couponApplicableProductRange: CouponProductRangeEnum;
    couponApplicableSalesType: SalesTypeEnum;
    couponDiscountType: DiscountTypeEnum;
    couponDiscountAmount: number;
    couponMinPurchaseAmount: number;
    couponMaxDiscountAmount: number | null;
    isCouponStackable: boolean;
    couponUsageStartDt: ISODate;
    couponUsageExpDt: ISODate;
    couponBalanceUsageType: string;
    couponUsageExpDays?: number;
    couponUsageExpType?: CouponUsageExpTypeEnum;
    isApplicableForOrderProducts?: boolean;
    isDownloaded?: boolean;
    couponDisplayedIssuanceStatus: DisplayedIssuanceStatusEnum;
}

export enum DiscountTypeEnum {
    Ratio = 'ratio',
    Amount = 'amount',
}

export enum CouponProductRangeEnum {
    All = 'all',
    Select = 'select',
}

export enum CouponUsageExpTypeEnum {
    Fixed = 'fixed',
    BasedOnIssueDate = 'based_on_issue_date',
}

export enum DisplayedIssuanceStatusEnum {
    Activated = 'activated',
    Paused = 'paused',
    Expired = 'expired',
    OutOfStock = 'out_of_stock',
    Deleted = 'deleted',
}

export enum CouponUsageScopeEnum {
    Online = 'online',
    Offline = 'offline',
    Integrated = 'integrated',
}
