import { ISODate } from '@type/models';
import DateUtils from '@utils/date.utils';
import moment from 'moment';
import 'moment/locale/ko';
import { LanguageEnum } from 'src/locales/types';
import IdBaseModel from './id-base';

abstract class DateBaseModel extends IdBaseModel {
    protected _createDt: ISODate;
    protected _updateDt: ISODate | null | undefined;

    constructor(id: string | number, createDt: ISODate, updateDt?: ISODate | null) {
        super(id);
        this._createDt = createDt;
        this._updateDt = updateDt;
    }

    get updateDate(): Date | undefined {
        return this._updateDt ? new Date(this._updateDt) : undefined;
    }

    get updateDateString(): string {
        return this.updateDate ? DateUtils.dateToDotString(this.updateDate) : '';
    }

    get createDate(): Date | undefined {
        return this._createDt ? new Date(this._createDt) : undefined;
    }

    get createDateString(): string {
        return this.createDate ? DateUtils.dateToDotString(this.createDate) : '';
    }

    get createDateTimeString(): string {
        return this.createDate ? DateUtils.dateToDateTimeString(this.createDate) : '';
    }

    /**Displays the create date as N time ago if more than a month ago, otherwise in date and time format */
    createdNTimeAgoString(language: LanguageEnum): string {
        if (!this.createDate) return '';
        const currentDate = moment(new Date());
        const createdDate = moment(this.createDate);

        if (language !== LanguageEnum.ko) {
            if (createdDate.isBefore(createdDate.subtract(1, 'month'))) {
                return createdDate.format('YYYY.MM.DD HH:MM');
            }

            return moment(this.createDate).locale(language).fromNow();
        }

        const months = currentDate.diff(createdDate, 'months');
        const weeks = currentDate.diff(createdDate, 'weeks');
        const days = currentDate.diff(createdDate, 'days');
        const hour = currentDate.diff(createdDate, 'hour');
        const minutes = currentDate.diff(createdDate, 'minutes');

        if (months >= 1) return createdDate.locale(language).format('YYYY-MM-DD ( HH:MM )');
        if (weeks >= 1) return weeks + '주 전';
        if (days >= 1) return days + '일 전';
        if (hour >= 1) return hour + '시간 전';
        if (minutes > 2) return minutes + '분 전';
        return '방금 전';
    }
}

export default DateBaseModel;
