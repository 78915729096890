import fallbackImage from '@public/image/fallback.png';
import DomainUtils from '@utils/domain.utils';
import ValidationUtils from '@utils/validation.utils';
import Image, { ImageProps } from 'next/image';
import { useState } from 'react';

export interface CustomImageProps extends ImageProps {
    src: string;
}

/**
 * Wrapper component for Next/Image.
 * If the image is imported locally or if it originates from a known domain, then the image is optimized.
 * Otherwise, it served unoptimized. This allows for there to be images from unknown domain sources.
 *
 * NOTE: This image component should be used in place of the Next/Image component.
 */
const CustomImage: React.FC<CustomImageProps> = ({ src, alt, unoptimized, ...props }) => {
    const [isOptimizedImageError, setIsOptimizedImageError] = useState<boolean>(false);
    const [isImageError, setIsImageError] = useState<boolean>(false);

    const handleError = () => {
        if (isOptimizedImageError || unoptimized) {
            setIsOptimizedImageError(true);
            setIsImageError(true);
        } else {
            setIsOptimizedImageError(true);
        }
    };

    return (
        <Image
            src={!src || isImageError ? fallbackImage : src}
            alt={alt}
            onError={handleError}
            sizes={'(max-width: 360px) 100vw, 360px'}
            unoptimized={isOptimizedImageError || unoptimized || DomainUtils.isShoppingeasy || (ValidationUtils.isUrl(src) && !ValidationUtils.isImageUrlFromKnownDomain(src))}
            draggable={false}
            {...props}
        />
    );
};

export default CustomImage;
