export interface ICreateUpdateInquiryDto {
    questionType: string;
    title: string;
    contents: string;
    customerPhoneNumber: string;
    customerEmail: string;
    images: string[];
}

export enum QuestionTypeEnum {
    Placeholder = 'placeholder',
    Return = 'return',
    Refund = 'refund',
    Cancel = 'cancel',
    Delivery = 'delivery',
    AfterService = 'after_service',
    OrderPayment = 'order_payment',
    Membership = 'membership',
    Other = 'etc',
    Report = 'report',
    OperationFault = 'operation_fault',
}
