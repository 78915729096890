import { Box } from '@chakra-ui/react';
import { useEffect, useMemo, useRef, useState } from 'react';

export const useAllocateSpaceForFixedBottomElement = (dependency?: unknown[]) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const [height, setHeight] = useState<number>();

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        setHeight(ref.current.offsetHeight);
    }, [dependency]);

    const spacingBox = useMemo(() => <Box height={`${height}px`} />, [height]);

    return { ref, spacingBox };
};
