import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import { PartialAbstractedTextProps } from '../../../../components/abstracted-text';
import Title1AD from './designD/title-1-a';

export interface Title1AProps extends PartialAbstractedTextProps {}

const Title1A: React.FC<Title1AProps> = (props) => {
    const Component = useDesignComponents<ComponentType<Title1AProps>>({
        [Design.DesignD]: Title1AD,
    });

    return <Component {...props} />;
};

export default Title1A;
