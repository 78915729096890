import { ITrackingDetail } from './tracking-detail.dto';

export enum DeliveryStepsEnum {
    ProductAcquisition = '상품인수',
    ProductIsMoving = '상품이동중',
    DeliveryArrival = '배송지도착',
    StartDelivery = '배송출발',
    DeliveryCompleted = '배송완료',
}

export interface IDeliveryTrackingDto {
    carrierName: string;
    invoiceNo: string;
    level: number;
    trackingDetails: ITrackingDetail[];
}
