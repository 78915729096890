import { AddressId, CustomerId } from '@type/models';
import TextUtils from '@utils/text.utils';
import { IAddressDto } from './responseDto/address.dto';

class Address {
    private _id: AddressId;
    private _customerId: CustomerId;
    private _name: string;
    private _receiverName: string;
    private _receiverPhoneNumber: string;
    private _postalCode: string;
    private _baseAddress: string;
    private _detailAddress: string;
    private _comment: string;
    private _isBaseAddress: boolean;

    constructor(addressDto: IAddressDto) {
        this._id = addressDto.id;
        this._customerId = addressDto.customerId;
        this._name = addressDto.name;
        this._receiverName = addressDto.receiverName;
        this._receiverPhoneNumber = addressDto.receiverPhoneNumber;
        this._postalCode = addressDto.postalCode;
        this._baseAddress = addressDto.baseAddress;
        this._detailAddress = addressDto.detailAddress;
        this._comment = addressDto.comment;
        this._isBaseAddress = addressDto.isBaseAddress;
    }

    get id(): string {
        return this._id;
    }

    get customerId(): string {
        return this._customerId;
    }

    get receiverName(): string {
        return this._receiverName;
    }

    get name(): string {
        return this._name;
    }

    get receiverPhoneNumber(): string {
        return this._receiverPhoneNumber;
    }

    get receiverPhoneNumberFormatted(): string {
        return TextUtils.formatPhoneNumber(this._receiverPhoneNumber);
    }

    get baseAddress(): string {
        return this._baseAddress;
    }

    get detailAddress(): string {
        return this._detailAddress;
    }

    get postalCode(): string {
        return this._postalCode;
    }

    get comment(): string {
        return this._comment;
    }

    get isBaseAddress(): boolean {
        return this._isBaseAddress;
    }

    get sanitizedPostalCode(): string {
        return Address.sanitizePostalCode(this._postalCode);
    }

    static sanitizePostalCode(postalCode: string): string {
        return postalCode.replace('-', '').toString();
    }
}

export default Address;
