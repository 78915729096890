import styled from '@emotion/styled';

export type DividerType = {
    marginVertical?: number | string;
    marginHorizontal?: number | string;
};

const computeSize = (size: string | number) => {
    return typeof size === 'string' ? size : `${size / 16}rem`;
};

const Divider = styled.div<DividerType>`
    margin: ${({ marginVertical = '16', marginHorizontal = '0' }) => {
        const marginVerticalPixel = computeSize(marginVertical);
        const _marginHorizontalPixel = computeSize(marginHorizontal);
        return marginVerticalPixel + ' ' + _marginHorizontalPixel;
    }};
    border-top: ${(props) => `1px solid ${props.theme.gray[200]}`};
`;

export default Divider;
