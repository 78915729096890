import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';
import { IHarmonyTheme } from '@type/theme';

export const dividerTheme = (theme: IHarmonyTheme) =>
    defineStyleConfig({
        baseStyle: {
            borderStyle: 'solid',
            borderColor: theme.stroke.divider,
            opacity: 1,
        },
        variants: {
            thick: defineStyle({
                borderWidth: '8px',
            }),
            medium: defineStyle({
                borderWidth: '4px',
            }),
        },
    });
