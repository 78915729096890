import { useRouter } from 'next/router';
import { ReactElement, ReactNode, createContext, useContext, useEffect, useLayoutEffect, useState } from 'react';

const REFERRER_PATH_KEY = 'referrer-path';
const CURRENT_PATH_KEY = 'current-path';

type ReferrerProps = {
    /**
     * The current route the user is on.
     */
    currentPath: string | undefined;

    /**
     * The previous route the user was on.
     */
    referrerPath: string | undefined;

    /**
     * Whether or not the user can go back while remaining in the app.
     */
    canGoBack: boolean;
};

type ReferrerProviderProps = {
    children: ReactNode;
};

export const ReferrerContext = createContext<ReferrerProps>({} as ReferrerProps);

export const ReferrerProvider: React.FC<ReferrerProviderProps> = ({ children }): ReactElement => {
    const router = useRouter();
    const [currentPath, setCurrentPath] = useState<string | undefined>(undefined);
    const [referrerPath, setReferrerPath] = useState<string | undefined>(undefined);

    /**
     * Update referrer and current paths on session storage.
     *
     * It should be executed before the process of getting paths from storage.
     */
    useLayoutEffect(() => {
        if (!router.isReady) {
            return;
        }

        const currentPath = sessionStorage.getItem(CURRENT_PATH_KEY);
        if (currentPath === router.asPath) {
            return;
        }

        if (currentPath) {
            sessionStorage.setItem(REFERRER_PATH_KEY, currentPath);
        }

        sessionStorage.setItem(CURRENT_PATH_KEY, router.asPath);
    }, [router]);

    /** Get paths from session storage. */
    useEffect(() => {
        setReferrerPath(sessionStorage.getItem(REFERRER_PATH_KEY) || undefined);
        setCurrentPath(sessionStorage.getItem(CURRENT_PATH_KEY) || undefined);
    }, [router]);

    return (
        <ReferrerContext.Provider
            value={{
                currentPath,
                referrerPath,
                canGoBack: !!referrerPath && referrerPath !== router.asPath,
            }}
        >
            {children}
        </ReferrerContext.Provider>
    );
};

/**
 * Access the previous route and whether or not the user can go back while remaining in the app.
 */
export const useReferrer = () => {
    return useContext(ReferrerContext);
};
