export type ImageRatio = [number, number];
export type ImageUrl = string;

/**
 * Partial image path to fetch image from internal DB
 */
export type ImagePath = string;

export type UserId = string;
export type TermId = number;
export type CustomerId = string;
export type PortalId = string;
export type ChannelId = string;
export type ProductId = string;
export type CouponId = string;
export type CategoryId = string;
export type FaqCategoryId = number;
export type SkuId = string;
export type CarrierId = string;
export type InquiryId = string;
export type AddressId = string;
export type NoticeId = number;
export type ReviewId = string;
export type NotificationId = number;
export type TransactionId = string;
export type SkuClaimId = string;
export type ThemeId = string;
export type DesignId = string;
export type SubscriptionId = string;

export type ISODate = string;
/**
 * Simple year-month-day date format
 * Used for customer birth dates.
 * @example 2000-11-10
 */
export type BasicDate = string;

/**
 * Standard 3 letter currency code
 */
export type CurrencyCode = string;

/**
 * Standard 2 letter country code
 */
export type CountryCode = string;

/**
 * Stringified HTML content
 */
export type HTMLString = string;

export type Points = number;
export type MultiPointIndex = number;
export enum PointUsageType {
    Used = 'used',
    Earned = 'earned',
    EarnedCanceled = 'earned_canceled',
    Adjusted = 'adjusted',
    Canceled = 'canceled',
    Expired = 'expired',
}

/**
 * @example #FF671D
 */
export type ColorHex = string;
export type ColorPalette = ColorHex[];
export type ColorPaletteKeys = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
export type ColorPaletteRecord = Record<ColorPaletteKeys, ColorHex>;

export type GlobalFontSize = 'medium' | 'large';

/**
 * One letter code representing each gender or anonymous
 * @example "F", "M", "anonymous"
 */
export type GenderCode = string;

export type Dictionary = Record<string, string>;
export type SkuPointUsage = Map<number, Points[]>;

export enum ProductClassEnum {
    Tangible = 'tangible',
    Intangible = 'intangible',
    GiftCard = 'giftcard',
    Wincube = 'wincube',
    EVoucher = 'e_voucher',
    Ticket = 'ticket',
    Lease = 'lease',
    All = 'all',
}

export type PaginatedStats = {
    itemsPerPage: number;
    page: number;
    total: number;
};

export type PaginatedResults<T> = PaginatedStats & {
    items: T[];
};

export type ListResult<T> = {
    list: T[];
};
