import styled from '@emotion/styled';
import { IThemeProps, ScreenSizeProps } from '@type/common';
import ThemeUtils from '@utils/theme.utils';
import { BlockVStack } from 'src/legacy/components/blockui';
import BodyText from 'src/legacy/components/common/text/body-text';

export const PaddedContainer = styled.div<{ padding?: string }>`
    padding: ${({ padding }) => padding || '16px'};
`;

export const VerticalPadContainer = styled.div<{ padding?: string }>`
    padding: ${({ padding }) => padding || '16px'} 0;
`;

export const HorizPadContainer = styled.div<{ padding?: string }>`
    padding: 0 ${({ padding }) => padding || '16px'};
`;

export const FullWidthContainer = styled.div`
    width: 100%;
`;

export const FlexContainer = styled.div`
    display: flex;
`;

export const RelativeContainer = styled.div`
    position: relative;
`;

export const AbsoluteContainer = styled.div<{ top?: string; left?: string; right?: string; bottom?: string; transform?: string }>`
    position: absolute;
    ${({ top, left, right, bottom, transform }) => `
        ${top ? `top: ${top};` : ''}
        ${left ? `left: ${left};` : ''}
        ${right ? `right: ${right};` : ''}
        ${bottom ? `bottom: ${bottom};` : ''}
        ${transform ? `transform: ${transform};` : ''}
    `}
`;

export const VerticalDivider = styled.div<IThemeProps & { width?: string; height?: string; color?: string }>`
    width: ${({ width }) => width || '2px'};
    height: ${({ height }) => height || '100%'};
    background-color: ${({ theme, color }) => color || theme.gray[600]};
`;

export const FormSection = styled.div<{ gap?: string }>`
    display: flex;
    flex-direction: column;
    gap: ${({ gap }) => gap || '6px'};
`;

export const FloatingMenuPadding = styled.div`
    padding-bottom: ${ThemeUtils.bottomBarHeight}px;
`;

export const HalfContainer = styled.div`
    width: 50%;
`;

export const FullSizeContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

export const GreyOrderContainer = styled(BlockVStack)<IThemeProps>`
    ${({ theme }) => `
        background-color: ${theme.gray[50]};
        text-align: right;
        font-size: 14px;
    `}
`;

export const RoundedBorderBox = styled.div<IThemeProps>`
    ${({ theme }) => `
    border: solid 1px ${theme.gray[50]};
    border-radius: 8px;
`}
`;

export const FixedContainer = styled.div<Required<ScreenSizeProps>>`
    position: fixed;
    width: min(100vw, ${({ maxScreenWidth }) => maxScreenWidth}px);
    bottom: 0;
    z-index: ${ThemeUtils.zIndex.above};
    pointer-events: none;
    > * {
        pointer-events: auto;
    }
`;

export const ElevatedDrawerBar = styled.div`
    z-index: ${ThemeUtils.zIndex.floating};
    position: sticky;
    bottom: 0;
`;

export const Badge = styled.div`
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const GreySection = styled(PaddedContainer)<IThemeProps>`
    ${({ theme }) => `
        background-color: ${theme.gray[50]};
        width: 100%;
    `}
`;

export const FloatingContainer = styled(PaddedContainer)``;

export const AutoImage = styled.img<{ borderRadius?: string; opacity?: number; filter?: string }>`
    width: 100%;
    height: auto;
    border-radius: ${({ borderRadius }) => borderRadius};
    opacity: ${({ opacity }) => opacity};
    filter: ${({ filter }) => filter};
`;

export const ClickableContainer = styled.div`
    cursor: pointer;
`;

export const NoWrapText = styled(BodyText)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

export const DesignBContainer = styled(PaddedContainer)<{ background?: string }>`
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    background-color: ${({ background }) => `
        ${background || 'white'};    
    `};
`;

export const DesignBAccordionContainer = styled.div<IThemeProps>`
    ${({ theme }) => `
        border-radius: 8px;
        border: solid 1px ${theme.gray[300]};
        padding: 8px;
    `}
`;

export const DesignBDropShadow = styled.div`
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
`;

export const DesignCDropShadow = styled.div`
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08);
`;

export const LineClipText = styled(BodyText)<{ lines?: number }>`
    ${({ lines = 3 }) => `
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        display: -webkit-box;
        -webkit-box-pack:start;
        -webkit-box-align:start;
        -webkit-line-clamp: ${lines};
        -webkit-box-orient: vertical;
    `}
`;

export const HiddenForm = styled.form`
    display: none;
`;

export const NoWrapTextContainer = styled.div`
    width: 100%;
    position: relative;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
`;

export const AlignedText = styled(NoWrapText)<{ align: string }>`
    text-align: ${({ align }) => align};
`;

export const StatBlock = styled.div<IThemeProps & { color?: string }>`
    border: ${({ theme, color }) => `solid 1px ${color ?? theme.gray[200]}`};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    padding: 4px;
    height: 44px;
    width: 100%;
    cursor: pointer;
`;

export const SymbolContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    min-height: 200px;
`;
