import styled from '@emotion/styled';
import { useExtension } from '@hooks/use-extension';
import { ScreenSizeProps } from '@type/common';
import { DrawerProps } from 'src/legacy/components/blockui';
import CustomDrawer from './custom-drawer';

interface RoundedDrawerProps extends DrawerProps {}

const RoundedDrawer: React.FC<RoundedDrawerProps> = ({ children, ...props }) => {
    const { extensionStatus } = useExtension();
    return (
        <StyledDrawer {...props} anchor="bottom" maxScreenWidth={extensionStatus?.maxScreenWidth}>
            {children}
        </StyledDrawer>
    );
};

export default RoundedDrawer;

const StyledDrawer = styled(CustomDrawer)<ScreenSizeProps>`
    ${({ maxScreenWidth }) => `
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        overflow: hidden;
        width: min(100vw, ${maxScreenWidth}px) !important;
        left: calc(50% - (min(100vw, ${maxScreenWidth}px) * 0.5)) !important;
        background-color: #ffffff !important;
    `}
`;
