import { useDesignComponents } from '@hooks/use-design-components';

import { IconButtonProps } from '@designs/primitives/common/icon-button';
import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import SearchButtonD from './designD/search-button';
import RoutesEnum from '@routes';

export interface SearchButtonProps extends Omit<IconButtonProps, 'children'> {}

const SearchButton: React.FC<SearchButtonProps> = (props) => {
    const Component = useDesignComponents<ComponentType<SearchButtonProps>>({
        [Design.DesignD]: SearchButtonD,
    });

    return <Component href={RoutesEnum.Search} {...props} />;
};

export default SearchButton;
