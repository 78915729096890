import RoutesEnum from '@routes';
import { ExtensionCodeEnum, ExtensionStatusEnum, IExtensionStatusDto } from './responseDto/extension-status.dto';

const EXTENSION_RELATED_PAGES: Partial<Record<ExtensionCodeEnum, RoutesEnum[]>> = {
    [ExtensionCodeEnum.Wishlist]: [RoutesEnum.Wishlist],
    [ExtensionCodeEnum.Review]: [RoutesEnum.ReviewHistory],
    [ExtensionCodeEnum.ProductInquiry]: [RoutesEnum.ProductInquiry],
    [ExtensionCodeEnum.Search]: [RoutesEnum.Search],
    [ExtensionCodeEnum.Cart]: [RoutesEnum.Cart, RoutesEnum.CartOrder],
    [ExtensionCodeEnum.Notification]: [RoutesEnum.Notification],
    [ExtensionCodeEnum.Coupon]: [RoutesEnum.Coupons],
    [ExtensionCodeEnum.ExternalPoint]: [RoutesEnum.PointHistory],
    [ExtensionCodeEnum.NoticeBoard]: [RoutesEnum.Notice],
    [ExtensionCodeEnum.Inquiry]: [RoutesEnum.Inquiry],
    [ExtensionCodeEnum.OfflineStore]: [RoutesEnum.OfflineStore],
};

class Extension {
    private _code: ExtensionCodeEnum;
    private _status: ExtensionStatusEnum;
    private _relatedPages: RoutesEnum[];

    static readonly ENABLED_STATUSES = [ExtensionStatusEnum.Enabled, ExtensionStatusEnum.InstalledConfiguredEnabled];

    constructor(installStatusDto: IExtensionStatusDto) {
        this._code = installStatusDto.extension.code;
        this._status = installStatusDto.status;
        this._relatedPages = EXTENSION_RELATED_PAGES[this._code] || [];
    }

    get isEnabled() {
        return Extension.ENABLED_STATUSES.includes(this._status);
    }

    get relatedPages() {
        return this._relatedPages;
    }
}

export default Extension;
