import { PointTypeCodeEnum } from '@models/point-history';
import { PointUsageType } from '@type/models';

const pointHistory = {
    pointHistory: {
        pointHistory: '포인트 내역',
        all: '전체',
        month: '최근 {number}개월',
        year: '최근 {number}년',
        [PointUsageType.Used]: '사용',
        [PointUsageType.Canceled]: '사용취소',
        [PointUsageType.Expired]: '소멸',
        [PointUsageType.Earned]: '적립',
        [PointUsageType.EarnedCanceled]: '적립취소',
        sn: '주문번호 {sn}',
        pointEarned: '포인트 적립 ({date})',
        pointExpired: '유효기간 만료 ({date})',
        [PointTypeCodeEnum.Event]: '이벤트',
        [PointTypeCodeEnum.ContractPromotion]: '신규가입',
        [PointTypeCodeEnum.ReRegister]: '재가입',
        empty: '사용내역이 없습니다.',
    },
};

export default pointHistory;
