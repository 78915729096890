const wishlist = {
    wishlist: {
        items: '총',
        favorite: '개의 찜한 상품',
        noWishlist: '찜한 상품이 없습니다',
        favorite2: '개의 상품이 있습니다.',
    },
};

export default wishlist;
