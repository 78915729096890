import AvailablePoints from '@models/available-points';
import DeliveryGroupedProduct from '@models/cart/delivery-grouped-product';
import { ICartDto, ICartMetadataDto } from '@models/cart/responseDto/cart.dto';
import FinalCartCalculations from '@models/final-cart-calculations/final-cart-calculations';
import GroupedProduct from '@models/grouped-product';
import ProductSellableSku from '@models/grouped-product/product-sellable-sku';
import { PGTypeEnum } from '@models/payment-method/responseDto/payment-method.dto';
import { ISubscriptionOrderDto } from '@models/subscription-order/responseDto/subscription-order.dto';
import { IPointTypeAmount, ITransactionProduct, ITransactionProductSku } from '@models/transaction/requestDto/create-transaction.dto';
import { SkuQuantity, SkuQueryString } from '@type/api';
import { CouponId, ProductClassEnum, ProductId, SkuPointUsage } from '@type/models';

export enum OrderType {
    Cart = 'cart',
    Subscription = 'subscription',
}

type PointCouponIntegration = {
    pointUsage?: SkuPointUsage;
    availablePoints?: AvailablePoints;
    requestedCouponAmount?: number;
    skuCouponId?: CouponId;
};

abstract class BaseOrder {
    private static _ORDER_PREVIOUS_ROUTE = 'order-previous-route';

    protected _cartMetadata?: ICartMetadataDto;
    protected _deliveryGroupedProducts: DeliveryGroupedProduct[];
    protected _finalCartCalculations: FinalCartCalculations;
    protected _nonDeliveryGroupedProducts: GroupedProduct[];

    protected _allSkus: ProductSellableSku[] = [];
    protected _productIdMap: Map<ProductId, GroupedProduct> = new Map<ProductId, GroupedProduct>();

    protected _isAgeVerificationRequired: boolean;

    protected _pgType: PGTypeEnum;

    private _originalDto: ICartDto | ISubscriptionOrderDto;

    constructor(orderDto: ICartDto | ISubscriptionOrderDto) {
        this._cartMetadata = orderDto.cartMetadata;
        this._deliveryGroupedProducts = orderDto.deliveryGroupedProducts.map((product) => new DeliveryGroupedProduct(product));
        this._finalCartCalculations = new FinalCartCalculations(orderDto.finalCartCalculations);
        this._nonDeliveryGroupedProducts = orderDto.nonDeliveryGroupedProducts.map((product) => new GroupedProduct(product));

        this._isAgeVerificationRequired = orderDto.isAdultVerificationRequired;

        this._pgType = orderDto.pgType;

        this._originalDto = orderDto;

        this._setCombinedData();
    }

    /**
     * Converts sku quantity tuples to sku quantity query strings
     */
    static skuQuantityToQuery(skuQuantities: SkuQuantity[]): SkuQueryString[] {
        return skuQuantities.map((skuQuantity) => `${skuQuantity[0]}__${skuQuantity[1]}`);
    }

    /**
     * Save product ID, sku quantities, and previous route in local storage to be retrieved by the payment page.
     */
    static updateOrderPreviousStorage(previousRoute: string): void {
        sessionStorage.setItem(this._ORDER_PREVIOUS_ROUTE, previousRoute);
    }

    static getOrderPreviousRouteStorage(): string {
        return sessionStorage.getItem(this._ORDER_PREVIOUS_ROUTE) || '';
    }

    private _setCombinedData(): void {
        for (const group of this._deliveryGroupedProducts) {
            for (const product of group.products) {
                for (const sku of product.productSellableSkus) {
                    this._allSkus.push(sku);
                }
                this._productIdMap.set(product.productSellableId, product);
            }
        }

        for (const product of this._nonDeliveryGroupedProducts) {
            for (const sku of product.productSellableSkus) {
                this._allSkus.push(sku);
            }
            this._productIdMap.set(product.productSellableId, product);
        }
    }

    get isAgeVerificationRequired(): boolean {
        return this._isAgeVerificationRequired;
    }

    get pgType(): PGTypeEnum {
        return this._pgType;
    }

    get finalCartCalculations(): FinalCartCalculations {
        return this._finalCartCalculations;
    }

    get originalDto(): ICartDto | ISubscriptionOrderDto {
        return this._originalDto;
    }

    get allProductsIntangible(): boolean {
        return Array.from(this._productIdMap.values()).every((item) => item.isIntangible);
    }

    get productIds() {
        return Array.from(this._productIdMap, ([key]) => key);
    }

    get productClass(): ProductClassEnum {
        switch (true) {
            // TODO: handle more product classes as needed
            case this.allProductsIntangible:
                return ProductClassEnum.GiftCard;
            default:
                return ProductClassEnum.Tangible;
        }
    }

    get transactionProducts(): ITransactionProduct[] {
        const isFromCart = this._cartMetadata && this._cartMetadata.lines.length > 0;
        const selectedLineSkuIds = this._cartMetadata?.lines.filter((line) => line.isSelected).map((line) => line.skuId);
        const applicableSkus = isFromCart ? this._allSkus.filter((sku) => selectedLineSkuIds?.includes(sku.id)) : this._allSkus;
        return applicableSkus.map((sku) => ({
            productSellableId: sku.sellableProductId,
            calculatedSkuSellPrice: sku.calculatedSkuSellPrice,
            title: sku.title,
            productSellableSkus: [
                {
                    skuId: sku.id,
                    quantity: sku.quantity,
                    requestedSkuPoints: [],
                },
            ],
        }));
    }

    getProductById(id: ProductId): GroupedProduct | undefined {
        return this._productIdMap.get(id);
    }

    /**
     *  Apply points to transaction product skus
     */
    getTransactionProductsWithPoints(pointCouponData: PointCouponIntegration): ITransactionProduct[] {
        return this.transactionProducts.map<ITransactionProduct>((product, i) => {
            const transactionSkus: ITransactionProductSku[] = product.productSellableSkus.map((sku) => {
                const pointTypeAmounts: IPointTypeAmount[] = [];
                if (pointCouponData.availablePoints && pointCouponData.pointUsage) {
                    const totalPointsPerSku = pointCouponData.pointUsage.get(i);
                    for (let i = 0; i < pointCouponData.availablePoints.availablePoints.length; i++) {
                        if (totalPointsPerSku && totalPointsPerSku[i] > 0) {
                            pointTypeAmounts.push({
                                requestedPointAmount: totalPointsPerSku ? totalPointsPerSku[i] : 0,
                                type: pointCouponData.availablePoints.availablePoints[i].pntSct,
                            });
                        }
                    }
                }

                return {
                    ...sku,
                    requestedSkuPoints: pointTypeAmounts,
                    requestedCouponAmount: pointCouponData.requestedCouponAmount || 0,
                    skuCouponId: pointCouponData.skuCouponId,
                };
            });

            return {
                ...product,
                productSellableSkus: transactionSkus,
            };
        });
    }

    replaceFinalCartCalculations(finalCartCalculations: FinalCartCalculations): this {
        /** do shallow copy just to change the reference and trigger react rerendering. */
        const shallowCopied = Object.assign(Object.create(Object.getPrototypeOf(this)), this);
        shallowCopied._finalCartCalculations = finalCartCalculations;

        return shallowCopied;
    }
}

export default BaseOrder;
