import { Box, VStack } from '@chakra-ui/react';
import { useHarmony } from '@hooks/use-harmony';
import BodyText from '@legacy/components/common/text/body-text';
import { DetailItemProps } from '..';

const DetailItemA: React.FC<DetailItemProps> = ({ label, value }) => {
    const { theme } = useHarmony();

    return (
        <VStack spacing="4px" w="100%">
            <Box p="4px 16px" bgColor={theme.gray[100]}>
                <BodyText size="16px" weight="medium" lineHeight="24px">
                    {label}
                </BodyText>
            </Box>
            <BodyText size="15px" lineHeight="20px" textAlign="center">
                {value}
            </BodyText>
        </VStack>
    );
};

export default DetailItemA;
