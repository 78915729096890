import { DeliveryFrequencyOptionsTypeEnum, PaymentTypeEnum } from '@models/product/responseDto/component-product.dto';
import { SubscriptionProductClassEnum } from '@models/transaction/requestDto/create-transaction.dto';
import { BasicDate } from '@type/models';
import ConversionUtils from '@utils/conversion.utils';
import DateUtils from '@utils/date.utils';
import { ISubscriptionPricingItemDto } from './responseDto/grouped-product.dto';

class SubscriptionPricingItem {
    private _totalProductSubscriptionPrice: number;
    private _totalProductSubscriptionPricePerMonth: number;
    private _totalDeliveryCount: number;
    private _deliveryDates: BasicDate[];
    private _firstDeliveryDate: BasicDate;
    private _deliveryFrequencyOption: DeliveryFrequencyOptionsTypeEnum;
    private _discountRate: number;
    private _deliveryFrequency: number;
    private _subscriptionPaymentPeriod: number;
    private _subscriptionPaymentPolicy: PaymentTypeEnum;
    private _nextPaymentCalculation: ISubscriptionPricingItemDto;
    private _futurePaymentCalculations: ISubscriptionPricingItemDto[];

    constructor(pricingItem: ISubscriptionPricingItemDto) {
        this._totalProductSubscriptionPrice = pricingItem.totalProductSubscriptionPrice;
        this._totalProductSubscriptionPricePerMonth = pricingItem.totalProductSubscriptionPricePerMonth;
        this._totalDeliveryCount = pricingItem.totalDeliveryCount;
        this._deliveryDates = pricingItem.deliveryDates;
        this._firstDeliveryDate = pricingItem.firstDeliveryDate;
        this._deliveryFrequencyOption = pricingItem.deliveryFrequencyOption;
        this._discountRate = pricingItem.discountRate;
        this._deliveryFrequency = pricingItem.deliveryFrequency;
        this._subscriptionPaymentPeriod = pricingItem.subscriptionPaymentPeriod;
        this._subscriptionPaymentPolicy = pricingItem.subscriptionPaymentPolicy;
        this._nextPaymentCalculation = pricingItem.nextPaymentCalculation;
        this._futurePaymentCalculations = pricingItem.futurePaymentCalculations;
    }

    get subscriptionPaymentPolicy(): PaymentTypeEnum {
        return this._subscriptionPaymentPolicy;
    }

    get subscriptionPaymentPolicyIsMonthly(): boolean {
        return this._subscriptionPaymentPolicy === PaymentTypeEnum.PaymentPerNMonths && this._subscriptionPaymentPeriod > 0;
    }

    get deliveryFrequencyOption(): DeliveryFrequencyOptionsTypeEnum {
        return this._deliveryFrequencyOption;
    }

    get subscriptionPaymentPeriod(): number {
        return this._subscriptionPaymentPeriod;
    }

    get totalDeliveryCount(): number {
        return this._totalDeliveryCount;
    }

    get totalProductSubscriptionPriceString(): string {
        return ConversionUtils.numberToString(this._totalProductSubscriptionPrice);
    }

    get totalProductSubscriptionPricePerMonthString(): string {
        return ConversionUtils.numberToString(this._totalProductSubscriptionPricePerMonth);
    }

    get showMonthlyConversion(): boolean {
        return this.subscriptionPaymentPolicyIsMonthly && this.subscriptionPaymentPeriod > 1;
    }

    get deliveryDates(): Date[] {
        let deliveryDates: string[] = [...this._deliveryDates];
        for (let i = 0; i < this._futurePaymentCalculations.length; i++) {
            deliveryDates = deliveryDates.concat(this._futurePaymentCalculations[i].deliveryDates);
        }
        return deliveryDates.map((day) => new Date(day));
    }

    get discountRate(): number {
        return this._discountRate;
    }

    get firstDeliveryBasicDate(): BasicDate {
        return this._firstDeliveryDate;
    }

    /**First delivery date formatted as 2023. 03. 01 (Mon) */
    get firstDeliveryDateFormatted(): string {
        return `${DateUtils.dateToYYYYMMDDString(this._firstDeliveryDate)} (${DateUtils.dateToShortDayString(this._firstDeliveryDate)})`;
    }

    get firstDeliveryDayOfWeek(): string {
        return DateUtils.dateToLongDayString(this._firstDeliveryDate);
    }

    get deliveryDayOfMonth(): number {
        return new Date(this._firstDeliveryDate).getDate();
    }

    get deliveryFrequency(): number {
        return this._deliveryFrequency;
    }

    // TODO: hard coded for now as all products are tangible. update once value is provided.
    get subscriptionProductClass(): SubscriptionProductClassEnum {
        return SubscriptionProductClassEnum.Tangible;
    }

    get isWeeklyDelivery(): boolean {
        return this.deliveryFrequencyOption === DeliveryFrequencyOptionsTypeEnum.OnceEveryNWeeks;
    }

    get isMonthlyDelivery(): boolean {
        return this.deliveryFrequencyOption === DeliveryFrequencyOptionsTypeEnum.OnceEveryNMonths;
    }
}

export default SubscriptionPricingItem;
