import ApiService, { HttpStatusCode } from '@api/api.service';
import HARMONY_CONFIG from '@config';
import { ICreateAddressDto } from '@models/address/requestDto/create-address.dto';
import { IAddressDto } from '@models/address/responseDto/address.dto';
import { AuthSettingDto } from '@models/auth-setting/responseDto/auth-setting.dto';
import { AuthTypeEnum, IOAuth } from '@models/auth-setting/responseDto/oauth.dto';
import { PrepareVerifyCustomerIdentityDto } from '@models/customer-identity-verification/requestDto/prepare-verify-customer-identity.dto';
import { VerifyCustomerIdentityDto } from '@models/customer-identity-verification/requestDto/verify-customer-identity.dto';
import { CustomerIdentityVerificationDto } from '@models/customer-identity-verification/responseDto/customer-identity-verification.dto';
import { NiceModulePrepDataDto } from '@models/customer-identity-verification/responseDto/nice-module-prep-data.dto';
import { ConfirmResetPasswordEmailVerificationCodeDto } from '@models/customer/requestDto/confirm-reset-password-email-verification-code.dto';
import { FindEmailVerificationCodeDto } from '@models/customer/requestDto/find-email-verification-code.dto';
import { ResetPasswordEmailVerificationCodeDto } from '@models/customer/requestDto/reset-password-email-verification-code.dto';
import { ISignupCustomerDto } from '@models/customer/requestDto/signup-customer.dto';
import { IUpdateCustomerDto } from '@models/customer/requestDto/update-customer.dto';
import { ConfirmEmailVerificationToFindUsernameResponseDto } from '@models/customer/responseDto/customer-username-found.dto';
import { ICustomerDto } from '@models/customer/responseDto/customer.dto';
import { MemberSignupDto } from '@models/member/requestDto/member-signup.dto';
import { IUpdateNotificationSettingDto } from '@models/notification-setting/requestDto/update-notification-setting.dto';
import { INotificationSettingDto } from '@models/notification-setting/responseDto/notification-setting.dto';
import { INotificationCollectionDto } from '@models/notification/responseDto/notification-collection.dto';
import { IOauthSignupCustomerDto } from '@models/oauth-terms/requestDto/oauth-signup-customer.dto';
import { OauthRegisterDto, OauthTermsDto } from '@models/oauth-terms/responseDto/oauth-terms.dto';
import { IPointHistoryDto } from '@models/point-history/responseDto/point-history.dto';
import { AccessTokens, PaginatedQuery, PointHistoryQuery } from '@type/api';
import { Dictionary, NotificationId, PaginatedResults } from '@type/models';
import EnvironmentUtils from '@utils/environment.utils';
import { IncomingHttpHeaders } from 'http';
import queryString from 'query-string';
import snakecaseKeys from 'snakecase-keys';

import { DataPrepVerificationType } from '@hooks/use-nice-pass-module';
import { VerificationType } from '@hooks/use-verify-identification';
import { TemporaryResetPasswordVerificationDto } from '@models/customer-identity-verification/requestDto/temporary-reset-password-verification.dto';
import { IVerifyCustomerToFindIdDto } from '@models/customer-identity-verification/requestDto/verify-customer-to-find-id.dto';
import { ChangeTemporaryPasswordDto } from '@models/customer/requestDto/change-temporary-password.dto';
import { IResetPasswordViaEmailVerificationDto } from '@models/customer/requestDto/reset-password-via-email-verification.dto copy';
import { IResetPasswordDto } from '@models/customer/requestDto/reset-password.dto';
import { LoginDto, LoginStatus } from '@models/login-token-based/responseDto/login.dto';
import { IOfflineStoreMembershipDto } from '@models/offline-store/responseDto/offline-store.dto';

/**
 * Api client handling auth related logic
 * @link https://api-auth-dev.harmonycvm.com/docs#/
 */
class AuthClient {
    private static readonly _LOGIN_REDIRECT = 'login-redirect';
    private static readonly _AUTH_HEADER = 'authorization';

    private readonly _AUTH_API_PREFIX = '/api/v1/external/e/channels/';
    private readonly _REFRESH_ENDPOINT = '/refresh-token-token-based';
    private readonly _client: ApiService;

    constructor() {
        this._client = new ApiService(`https://api-auth${EnvironmentUtils.API_ENV}.harmonycvm.com`, this).registerErrorMiddleware(async (_, error) => {
            const originalRequest = error.config;
            if (error.response?.status === HttpStatusCode.Forbidden && originalRequest.url?.includes(this._REFRESH_ENDPOINT)) {
                originalRequest.headers = {
                    ...originalRequest.headers,
                    retry: false,
                };
                return Promise.reject(error);
            }
        });
    }

    static getLoginRedirect(): string | undefined {
        if (typeof window === 'undefined') return;
        return sessionStorage.getItem(this._LOGIN_REDIRECT) || undefined;
    }

    static setLoginRedirect(redirect: string): void {
        sessionStorage.setItem(this._LOGIN_REDIRECT, redirect);
    }

    static deleteLoginRedirect(): void {
        sessionStorage.removeItem(this._LOGIN_REDIRECT);
    }

    static extractAuthorizationHeader(headers: IncomingHttpHeaders): string | null {
        const authorizationToken = headers[AuthClient._AUTH_HEADER];
        return authorizationToken ? (authorizationToken as string).trim() : null;
    }

    /**
     * Returns the authorization code provided by the oauth redirect url.
     * This is needed when authenticating with third party auth services.
     */
    private _getOauthCode(authType: string): string | undefined {
        switch (authType) {
            case AuthTypeEnum.Naver:
            case AuthTypeEnum.Kakao:
                const search = queryString.parse(location.search) as { code?: string };
                return search.code;
            case AuthTypeEnum.Google:
                const hash = queryString.parse(location.hash) as { access_token?: string };
                return hash.access_token;
        }
    }

    /**
     * Username and password register.
     */
    async register(email: string, password: string) {
        return this._client.post<ICustomerDto>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/register`, {
            channel_id: EnvironmentUtils.CHANNEL_ID,
            email,
            password,
        });
    }

    /**
     * Username and password login.
     * Sets JWT access token as a cookie.
     */
    async login(username: string, password: string) {
        try {
            ApiService.deleteTokens();

            const formData = new FormData();
            formData.append('username', username.toLowerCase());
            formData.append('password', password);

            const {
                data: { accessToken, refreshToken, temporaryAccessToken, customerData },
            } = await this._client.instance.post<LoginDto>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/login-token-based`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (customerData?.isPasswordResetRequired && !customerData.isAgreedOnRegistrationTerms && temporaryAccessToken) {
                ApiService.setAccessToken(temporaryAccessToken);
                return LoginStatus.VerificationRequired;
            }

            if (customerData?.isPasswordResetRequired && customerData.isAgreedOnRegistrationTerms && temporaryAccessToken) {
                ApiService.setAccessToken(temporaryAccessToken);
                return LoginStatus.PasswordResetRequired;
            }

            ApiService.setAccessToken(accessToken);
            ApiService.setRefreshToken(refreshToken);
            return LoginStatus.Successful;
        } catch (e) {
            throw e;
        }
    }

    /**
     * NOTE: Not currently accepted by backend
     */
    async logout() {
        ApiService.deleteTokens();
        return;
        // return this._client.post<void>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/logout`);
    }

    async deregisterAccount() {
        try {
            await this._client.post<void>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/customer/deregister`);
            ApiService.deleteTokens();
        } catch (err) {
            throw err;
        }
    }

    /**
     * Get channel oauth URL by auth type.
     */
    async getOauthUrl(authType: string) {
        try {
            const urlData = await this._client.get<{ url: string }>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/oauth/${authType}/authorization_url`, {
                params: {
                    redirect_uri: `${EnvironmentUtils.publicUrl}/my/oauth-process?auth_type=${authType}`,
                },
            });
            return urlData.url;
        } catch (err) {
            throw err;
        }
    }

    /**
     * Get channel oauth URL by auth type.
     */
    async getOauthUrlMap(oauths: IOAuth[]) {
        try {
            const urlData = await Promise.all(oauths.map((oauth) => this.getOauthUrl(oauth.authType)));
            const oauthUrlMap: Dictionary = {};

            for (let i = 0; i < urlData.length; i++) {
                oauthUrlMap[oauths[i].authType] = urlData[i];
            }

            return oauthUrlMap;
        } catch (err) {
            throw err;
        }
    }

    /**
     * Register customer using oauth
     */
    async oauthRegister(oauthSignupCustomerDto: IOauthSignupCustomerDto, isIdVerificationRequired: boolean) {
        try {
            const response = await this._client.instance.post<OauthRegisterDto>(
                `${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/oauth/register-token-based/v2`,
                snakecaseKeys(oauthSignupCustomerDto),
                {
                    params: {
                        id_verification_service_type: 'nice-pass',
                        is_id_verification_required: isIdVerificationRequired,
                    },
                }
            );

            ApiService.setAccessToken(response.data.accessToken);
            ApiService.setRefreshToken(response.data.refreshToken);

            return response;
        } catch (error) {
            throw error;
        }
    }

    /**
     * Register a member
     */
    async registerMember(externalAuthId: string, customerData: ISignupCustomerDto, customerId: string) {
        try {
            const response = await this._client.instance.post<MemberSignupDto>(
                `${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/membership/${HARMONY_CONFIG.membership}/register`,
                {
                    external_auth_id: externalAuthId,
                    customer_data: snakecaseKeys(customerData, { deep: true }),
                    customer_id: customerId,
                }
            );

            return response;
        } catch (error) {
            throw error;
        }
    }

    /**
     * Register a customer via sign up page
     */
    async registerCustomer(signupCustomerDto: ISignupCustomerDto, isIdVerificationRequired: boolean) {
        try {
            await this._client.instance.post<string>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/id-pw-register`, snakecaseKeys(signupCustomerDto), {
                params: {
                    is_id_verification_required: isIdVerificationRequired,
                    id_verification_service_type: 'nice-pass',
                },
            });
        } catch (error) {
            throw error;
        }
    }

    /**
     * Get or create customer with oauth.
     */
    async getOauthCustomer(authType: string) {
        try {
            const response = await this._client.instance.post<OauthTermsDto>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/oauth/${authType}/login-token-based`, {
                code: this._getOauthCode(authType),
                redirect_uri: `${EnvironmentUtils.publicUrl}/my/oauth-process?auth_type=${authType}`,
            });

            if (response.status === HttpStatusCode.OK || response.status === HttpStatusCode.Created) {
                ApiService.setAccessToken(response.data.accessToken);
                ApiService.setRefreshToken(response.data.refreshToken);
            }

            return response;
        } catch (e) {
            throw e;
        }
    }

    async postRefreshToken() {
        try {
            const accessToken = await this._client.post<AccessTokens>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}${this._REFRESH_ENDPOINT}`, {
                refresh_token: ApiService.getRefreshToken(),
                grant_type: 'refresh_token',
            });
            ApiService.setAccessToken(accessToken.accessToken);
        } catch (error) {
            ApiService.deleteTokens();
            throw error;
        }
    }

    /**
     * Get customer information if JWT token is present.
     */
    async getCustomer() {
        return this._client.get<ICustomerDto>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/customer`);
    }

    /**
     * Patch customer information.
     */
    async patchCustomer(customerInfo: IUpdateCustomerDto) {
        return this._client.patch<ICustomerDto>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/customer`, customerInfo);
    }

    async getCustomerNotifications(query: PaginatedQuery) {
        return this._client.get<INotificationCollectionDto>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/customer/notifications`, {
            params: {
                items_per_page: query.itemsPerPage,
                page: query.page || 1,
            },
        });
    }

    async getNotificationSettings() {
        return this._client.get<INotificationSettingDto>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/customer/notification-setting`);
    }

    async putNotificationSettings(notificationSetting: IUpdateNotificationSettingDto) {
        return this._client.put<INotificationSettingDto>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/customer/notification-setting`, notificationSetting);
    }

    /**
     * Get customer address list
     */
    async getCustomerAddresses(isBaseAddress?: boolean) {
        return this._client.get<IAddressDto[]>(
            `${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/customer/address`,
            isBaseAddress
                ? {
                      params: {
                          is_base_address: isBaseAddress,
                      },
                  }
                : undefined
        );
    }

    /**
     * Delete customer address by id
     */
    async deleteCustomerAddresses(addressId: string) {
        return this._client.delete<void>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/customer/address/${addressId}`);
    }

    /**
     * Create a new customer address
     */
    async createCustomerAddresses(address: ICreateAddressDto) {
        return this._client.post<IAddressDto>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/customer/address`, address);
    }

    /**
     * Update a customer address
     */
    async updateCustomerAddresses(addressId: string, address: ICreateAddressDto) {
        return this._client.patch<IAddressDto>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/customer/address/${addressId}`, address);
    }

    /**
     * Marks a list of notifications as read.
     */
    async postReadNotifications(ids: NotificationId[]) {
        return this._client.post<void>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/customer/notifications/bulk-read`, ids);
    }

    /**
     * Marks a list of notifications as read.
     */
    async getCustomerByExternalToken(externalUserId: string) {
        try {
            const tokens = await this._client.get<AccessTokens>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/membership/${HARMONY_CONFIG.membership}/user`, {
                params: {
                    external_user_id: externalUserId.replaceAll(' ', '+'),
                },
            });

            ApiService.setAccessToken(tokens.accessToken);
            ApiService.setRefreshToken(tokens.refreshToken);
        } catch (err) {
            throw err;
        }
    }

    /**
     * Get point transaction history
     */
    async getPointHistory(query: PointHistoryQuery) {
        return this._client.get<PaginatedResults<IPointHistoryDto>>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/users/point/history`, {
            params: snakecaseKeys(query),
        });
    }

    async checkDuplicate(fieldName: string, fieldValue: string) {
        return this._client.get<string>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/customer-field-duplicates`, { params: snakecaseKeys({ fieldValue, fieldName }) });
    }

    /**
     * Request for email verification
     */
    async requestEmailVerification(email: string) {
        return this._client.post<string>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/email-verification`, { email });
    }

    /**
     * Confirm for email verification
     */
    async confirmEmailVerification(email: string, emailVerificationCode: string) {
        return this._client.post<string>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/confirm-email-verification`, snakecaseKeys({ email, emailVerificationCode }));
    }

    /**
     * Get End User Setting for sign up
     */
    async getEndUserSetting() {
        return this._client.get<AuthSettingDto>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/auth-setting`);
    }

    /**
     * Request for email verification "to find username"
     */
    async requestEmailVerificationToFindUsername(email: string) {
        return this._client.post<string>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/find-id-verification-request`, {
            email,
        });
    }

    /**
     * Confirm email verification "to find username"
     */
    async confirmEmailVerificationToFindUsername(findEmailVerificationDto: FindEmailVerificationCodeDto) {
        return this._client.post<ConfirmEmailVerificationToFindUsernameResponseDto>(
            `${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/find-id-verification`,
            snakecaseKeys(findEmailVerificationDto)
        );
    }

    /**
     * Request for email verification "to reset password"
     */
    async requestEmailVerificationToResetPassword(resetPasswordEmailVerificationCodeDto: ResetPasswordEmailVerificationCodeDto) {
        return this._client.post<string>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/reset-pw-verification-request`, {
            email: resetPasswordEmailVerificationCodeDto.email,
            username: resetPasswordEmailVerificationCodeDto.username.toLowerCase(),
        });
    }

    /**
     * Confirm email verification "to reset password"
     */
    async confirmEmailVerificationToToResetPassword(confirmResetPasswordEmailVerificationCodeDto: ConfirmResetPasswordEmailVerificationCodeDto) {
        return this._client.post<string>(
            `${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/reset-pw-verification-confirm`,
            snakecaseKeys(confirmResetPasswordEmailVerificationCodeDto)
        );
    }

    async resetPasswordVerification(dto: IResetPasswordVerificationDto) {
        return this._client.post<null>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/verification/nice-pass/reset-pw-verification`, dto);
    }

    /**
     * Reset Password via email verification
     */
    async resetPasswordViaEmailVerification(resetPasswordDto: IResetPasswordViaEmailVerificationDto) {
        return this._client.post<string>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/reset-pw-via-verification`, snakecaseKeys(resetPasswordDto));
    }

    /** Reset Password via PASS verification */
    async resetPassword(dto: IResetPasswordDto) {
        return this._client.post<string>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/verification/nice-pass/reset-pw`, snakecaseKeys(dto));
    }

    /**
     * Change Temporary password
     */
    async changeTemporaryPassword(changeTemporaryPasswordDto: ChangeTemporaryPasswordDto) {
        return this._client.post<string>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/customer/reset-temporary-pw`, snakecaseKeys(changeTemporaryPasswordDto));
    }

    /**
     * Validate Membership Customer Token
     *
     * Currently, used in KBpay only.
     */
    async postTokenValidation() {
        return this._client.post<string>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/membership/${HARMONY_CONFIG.membership}/user/token-validation`);
    }

    /**
     * Prepare customer identity verification data for id verification type
     */
    async prepareCustomerIdentityVerificationData(prepareVerifyCustomerIdentityDto: PrepareVerifyCustomerIdentityDto, verificationType: DataPrepVerificationType) {
        return this._client.post<NiceModulePrepDataDto>(
            `${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/verification/nice-pass/data-prep`,
            prepareVerifyCustomerIdentityDto,
            {
                params: { verification_type: verificationType },
            }
        );
    }

    /**
     * Verify customer identity as callback
     */
    async verifyCustomerIdentity(verifyCustomerIdentityDto: VerifyCustomerIdentityDto, verificationType: VerificationType) {
        return this._client.post<CustomerIdentityVerificationDto>(
            `${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/verification/nice-pass/verification`,
            verifyCustomerIdentityDto,
            {
                params: { verification_type: verificationType },
            }
        );
    }

    async verifyCustomerToFindId(dto: IVerifyCustomerToFindIdDto) {
        return this._client.post<{ username: string }>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/verification/nice-pass/find-id-verification`, dto);
    }

    /**
     * Verify customer identity to reset password
     */
    async resetTemporaryPasswordVerification(temporaryResetPasswordVerificationDto: TemporaryResetPasswordVerificationDto) {
        return this._client.post<null>(
            `${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/verification/nice-pass/reset-temporary-pw-verification`,
            temporaryResetPasswordVerificationDto
        );
    }

    async validateCustomerUsername(username: string) {
        return this._client.post<{ customerId: string }>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/validate-customer-username`, { username });
    }

    async getOfflineStoreMembership() {
        return this._client.get<IOfflineStoreMembershipDto>(`${this._AUTH_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/customer/offline-store-membership`);
    }
}

const authClient = new AuthClient();
export { authClient };
export default AuthClient;
