import HARMONY_CONFIG from '@config';
import { CountryCode } from '@type/models';
import 'moment/locale/ko';
import en from './en';
import ko from './ko';
import { LanguageData, LanguageEnum, LocaleEnum, LocaleQuery, TranslationCollection } from './types';

/**
 * Service to help facilitate all localization related needs.
 */
export class LocaleService {
    static defaultLocale = HARMONY_CONFIG.defaultLocale;
    private _locale: LocaleEnum;
    private _language: LanguageEnum;
    // private _countryData: CountryData;
    private _languageData: LanguageData;

    constructor(locale: LocaleQuery) {
        this._locale = this._parseLocale(locale);
        this._language = this._extractLanguageFromLocale(this._locale);
        // this._countryData = this._getCountryData(this._locale);
        this._languageData = this._getLanguageData(this._locale);
    }

    private _parseLocale(locale: LocaleQuery): LocaleEnum {
        if (!locale) {
            return LocaleService.defaultLocale;
        }

        if (Object.values(LocaleEnum).includes(locale as LocaleEnum)) {
            return locale as LocaleEnum;
        } else {
            return LocaleService.defaultLocale;
        }
    }

    private _extractLanguageFromLocale(locale: LocaleQuery): LanguageEnum {
        if (!locale || locale.length < 2) return LanguageEnum.ko;
        const language = locale.slice(0, 2);
        if (Object.values(LanguageEnum).includes(language as LanguageEnum)) {
            return language as LanguageEnum;
        } else {
            return LanguageEnum.ko;
        }
    }

    private _getLanguageData(locale: LocaleEnum): LanguageData {
        const language = this._extractLanguageFromLocale(locale);

        switch (language) {
            case LanguageEnum.en:
                return {
                    language: LanguageEnum.en,
                    momentLocale: 'en',
                };
            case LanguageEnum.tc:
                return {
                    language: LanguageEnum.tc,
                    momentLocale: 'zh-hk',
                };
            case LanguageEnum.sc:
                return {
                    language: LanguageEnum.sc,
                    momentLocale: 'zh-cn',
                };
            case LanguageEnum.ko:
            default:
                return {
                    language: LanguageEnum.ko,
                    momentLocale: 'ko',
                };
        }
    }

    get isEnglish(): boolean {
        return this._language === LanguageEnum.en;
    }

    get isKorean(): boolean {
        return this._language === LanguageEnum.ko;
    }

    /**
     * Returns the translations for a given locale.
     * If no locale is given, then the default locale from the HARMONY_CONFIG file will be used.
     */
    get localeTranslations(): TranslationCollection {
        const language = this._extractLanguageFromLocale(this._locale);
        switch (language) {
            case LanguageEnum.en:
                return en;
            // TODO: Add traditional and simplified chinese in future
            // case LanguageEnum.tc:
            //     return tc; //traditional chinese
            // case LanguageEnum.sc:
            //     return sc; //traditional chinese
            case LanguageEnum.ko:
            default:
                return ko;
        }
    }

    // /**
    //  * Get data specific to a country
    //  */
    // get countryData(): CountryData {
    //     return this._countryData;
    // }

    /**
     * Get data specific to a language
     */
    get languageData(): LanguageData {
        return this._languageData;
    }

    static countryByCode(code: CountryCode): string {
        switch (code) {
            case 'KR':
            default:
                return '한국';
        }
    }

    // /**
    //  * Ensures the phone number does not exceed the phone number length of the region
    //  */
    // sanitizePhoneNumber(number: string): string {
    //     return number.slice(0, this.countryData.phoneLength);
    // }
}
