import { IFrameDto } from './responseDto/frame.dto';

abstract class Frame {
    protected _componentType: string;

    constructor(frameDto: IFrameDto) {
        this._componentType = frameDto.componentType;
    }

    get componentType(): string {
        return this._componentType;
    }
}

export default Frame;
