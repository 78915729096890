enum RoutesEnum {
    Home = '/',
    Custom = '/[...page]',
    NotFoundError = '/404',
    InternalServerError = '/500',
    Category = '/category',
    Search = '/search',
    Cart = '/cart',
    CartOrder = '/cart/order',
    My = '/my',
    Login = '/login',
    UserInfo = '/user-info',
    ProductDetail = '/product-detail',
    CategoryDetail = '/category-detail',
    Faq = '/faq',
    Notice = '/notice',
    JoinComplete = '/join-complete',
    Event = '/event',
    PhotoReviewAll = '/photo-review-all',
    Notification = '/notification',
    OrderHistory = '/order-history',
    CancelHistory = '/cancel-history',
    ProductInquiry = '/product-inquiry',
    ReviewHistory = '/review-history',
    Wishlist = '/wishlist',
    AddressList = '/address-list',
    Inquiry = '/inquiry',
    InquiryCreate = '/inquiry/create',
    InquiryUpdate = '/inquiry/update',
    DirectOrder = '/direct/order',
    OrderSuccess = '/orderSuccess',
    OrderFail = '/orderFail',
    GiftOrder = '/gift/order',
    GiftOrderSuccess = '/gift/order-success',
    PointHistory = '/point-history',
    Coupons = '/coupons',
    Subscriptions = '/subscriptions',
    MembershipError = '/membership-error',
    SubscriptionSuccess = '/SubscriptionSuccess',
    SubscriptionFail = '/subscriptionFail',
    BrandDetail = '/brand-detail',
    OauthSignup = '/oauth-signup',
    MembershipSignup = '/membership-signup',
    Terms = '/terms-agree',
    OauthProcess = '/my/oauth-process',
    NormalSignup = '/normal-signup',
    FindIdPassword = '/find-id-password',
    GuestOrder = '/guest/order',
    GuestOrderDetail = '/guest/order-detail',
    IdentityVerificationProcess = '/identity-verification/process',
    IdentityVerificationFail = '/identity-verification/fail',
    PasswordChange = '/password-change',
    OfflineStore = '/offline-store',
    GiftHistory = '/gift-history',
    ReceiveGift = '/gift/receive',
    ReceiveGiftFail = '/gift/receive/fail',
}

export default RoutesEnum;
