import { TextProps } from '@chakra-ui/react';
import { FontType, FontWeight } from '@type/theme';
import ThemeUtils from '@utils/theme.utils';
import { useHarmony } from './use-harmony';

type UseTypographyStylesParams = {
    fontSize: TextProps['fontSize'];
    lineHeight: TextProps['lineHeight'];
    font: FontType;
    weight: FontWeight;
};

type TypographyStyles = {
    fontSize: TextProps['fontSize'];
    lineHeight: TextProps['lineHeight'];
    fontFamily: string;
    letterSpacing?: string;
};

/** Returns common styles for Text, Input, Textarea, etc... */
export const useTypographyStyles = ({ fontSize, lineHeight, font, weight }: UseTypographyStylesParams): TypographyStyles => {
    const { theme, designSettings } = useHarmony();

    const calculatedFontSize = typeof fontSize === 'string' ? designSettings?.getFontSize(fontSize) || fontSize : fontSize;
    const calculatedLineHeight = typeof lineHeight === 'string' ? designSettings?.getFontSize(lineHeight) || lineHeight : lineHeight;

    return {
        fontSize: calculatedFontSize,
        lineHeight: calculatedLineHeight,
        fontFamily: ThemeUtils.getFont(weight, font, theme),
        letterSpacing: designSettings?.getLetterSpacing(),
    };
};
