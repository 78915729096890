import { IProductSellableSkuDto } from '@models/grouped-product/responseDto/product-sellable-sku.dto';
import { PGTypeEnum } from '@models/payment-method/responseDto/payment-method.dto';
import { DeliveryFrequencyOptionsTypeEnum, PaymentTypeEnum } from '@models/product/responseDto/component-product.dto';
import { BasicDate, CouponId, CurrencyCode, ProductClassEnum, ProductId } from '@type/models';
import { ITransactionInfoDto } from '../responseDto/transaction-info.dto';

export enum DeliveryNoteEnum {
    Doorstep = 'doorstep',
    SecurityOffice = 'securityOffice',
    DeliveryBox = 'deliveryBox',
    FrontOfHouse = 'frontOfHouse',
    ContactBeforeShipping = 'contactBeforeShipping',
    BeCarefulWhenShipping = 'beCarefulWhenShipping',
    DirectInput = 'directInput',
}

export enum SubscriptionProductClassEnum {
    Tangible = 'tangible',
    GiftCard = 'giftcard',
    Ticket = 'ticket',
}

export enum PaymentCodeEnum {
    CreditCard = 'creditcard',
    NaverPay = 'naverpay',
    Toss = 'toss',
    SamsungPay = 'samsungpay',
    KakaoPay = 'kakaopay',
    PayCo = 'payco',
    Cellphone = 'cellphone',
    Bank = 'bank',
    VBank = 'vbank',
}

export enum MobileOsEnum {
    Aos = 'aos',
    Ios = 'ios',
}

export interface IPointTypeAmount {
    requestedPointAmount: number;
    type: string;
}

export interface ITransactionProductSku extends Pick<IProductSellableSkuDto, 'skuId' | 'quantity'> {
    requestedSkuPoints: IPointTypeAmount[];
    requestedCouponAmount?: number;
    skuCouponId?: CouponId;
}

export interface ITransactionProduct {
    productSellableId: ProductId;
    productSellableSkus: ITransactionProductSku[];
}

export interface ICustomerSubscriptionInfo {
    subscriptionProductClass: SubscriptionProductClassEnum;
    firstDeliveryDate: BasicDate;
    deliveryFrequencyOption: DeliveryFrequencyOptionsTypeEnum;
    deliveryFrequency: number;
    subscriptionPaymentType: PaymentTypeEnum;
    subscriptionPaymentFrequency: number;
}

export interface ICreateTransactionDto {
    userId?: string;
    isGiftOrder?: boolean;
    guestUserPassword: string;
    transactionInfo: Partial<ITransactionInfoDto>;
    paymentMethod: {
        pgType: PGTypeEnum | string;
        paymentCode: PaymentCodeEnum | string;
        mobileOs?: MobileOsEnum;
    };
    totalPointAmount: number;
    totalCouponAmount: number;
    requestedTransactionCouponAmount?: number;
    transactionCouponId?: CouponId;
    products: ITransactionProduct[];
    currency: CurrencyCode;
    customerSubscriptionInfo?: ICustomerSubscriptionInfo;
    isCardPointUsedFirst?: boolean;
    productClass?: ProductClassEnum;
}
