import { useEffect, RefObject } from 'react';

type Event = MouseEvent | TouchEvent;

const useOnClickOutside = <T extends HTMLElement = HTMLElement>(ref: RefObject<T>, handler: (event: Event) => void) => {
    useEffect(() => {
        const listener = (event: Event) => {
            const el = ref?.current;
            const isClickOutside = !!el?.contains && !el.contains((event?.target as Node) || null);
            if (isClickOutside) {
                handler(event);
            }
        };
        setTimeout(() => document.addEventListener('click', listener), 100);
        return () => {
            document.removeEventListener('click', listener);
        };
    }, [ref, handler]);
};

export default useOnClickOutside;
