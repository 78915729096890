import { Flex } from '@chakra-ui/react';
import Body2B from '@designs/primitives/typography/body-2-b';
import Body2C from '@designs/primitives/typography/body-2-c';
import { useHarmony } from '@hooks/use-harmony';
import { DetailItemProps } from '..';

const DetailItemB: React.FC<DetailItemProps> = ({ label, value, isVertical }) => {
    const { theme } = useHarmony();

    return (
        <Flex gap="0px" w="100%" flexDir={isVertical ? 'column' : 'row'} justify={isVertical ? '' : 'space-between'}>
            <Body2C color={theme.text.title1} textAlign="center">
                {label}
            </Body2C>
            <Body2B color={theme.text.body1} textAlign="center">
                {value}
            </Body2B>
        </Flex>
    );
};

export default DetailItemB;
