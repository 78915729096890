import IconButton from '@designs/primitives/common/icon-button';
import { useHarmony } from '@hooks/use-harmony';
import { HomeButtonProps } from '..';

const HomeButtonD: React.FC<HomeButtonProps> = (props) => {
    const { theme } = useHarmony();

    return (
        <IconButton {...props} size={24} color={theme.gray[600]} fill={false}>
            home
        </IconButton>
    );
};

export default HomeButtonD;
