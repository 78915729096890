import { Flex, HStack, StackProps } from '@chakra-ui/react';
import CONFIG from '@config';
import { useHarmony } from '@hooks/use-harmony';
import CustomImage from '@legacy/components/common/custom-image';
import RoutesEnum from '@routes';
import Link from 'next/link';
import { ReactNode, useState } from 'react';

/** Unified logo height for all designs. */
const LOGO_HEIGHT = 24;

/** Unified logo container width for all designs. */
const LOGO_CONTAINER_WIDTH = 200;

interface LogoProps extends Omit<StackProps, 'children' | 'width' | 'height'> {
    height?: number;
    rightSideElement?: ReactNode;
}

const Logo: React.FC<LogoProps> = ({ rightSideElement, height = LOGO_HEIGHT, ...props }) => {
    const { frameCollection } = useHarmony();
    const [imageRatio, setImageRatio] = useState<number>(1);
    const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);

    const logoImageUrl = frameCollection?.headerImage;

    const handleLoadingComplete = ({ naturalWidth, naturalHeight }: { naturalWidth: number; naturalHeight: number }) => {
        setImageRatio(naturalWidth / naturalHeight);
        setIsImageLoaded(true);
    };

    if (!logoImageUrl) {
        return <></>;
    }

    return (
        <HStack w={`${LOGO_CONTAINER_WIDTH}px`} opacity={isImageLoaded ? 1 : 0} {...props}>
            <Link href={RoutesEnum.Home}>
                <Flex cursor="pointer">
                    <CustomImage
                        src={logoImageUrl}
                        alt={CONFIG.title}
                        /**
                         * If layout=fixed with incorrect width and height,
                         * naturalWidth and naturalHeight are incorrect also.
                         * So change to the layout not fixed until image loaded.
                         */
                        layout={isImageLoaded ? 'fixed' : 'responsive'}
                        width={imageRatio * height}
                        height={height}
                        onLoadingComplete={handleLoadingComplete}
                    />
                </Flex>
            </Link>
            {rightSideElement}
        </HStack>
    );
};

export default Logo;
