import Modal, { ModalProps } from '@designs/grouped/common/modal';
import PopUp, { PopUpProps } from '@designs/grouped/common/pop-up';
import { BaseDialogueComponentProps } from '@type/theme';
import { ReactElement, ReactNode, createContext, useCallback, useContext, useState } from 'react';
import { uuid } from 'uuidv4';
import { useHarmony } from './use-harmony';

const alertComponentMap = {
    modal: Modal,
    popup: PopUp,
};

type AlertQueueItem = {
    id: string;
    options: IAlertOptions;
    Component: React.FC<ModalProps> | React.FC<PopUpProps>;
    isOpen: boolean;
};

export interface IAlertOptions extends BaseDialogueComponentProps<any> {
    type: keyof typeof alertComponentMap;
}

type AlertContextType = {
    showAlert: (options: Partial<IAlertOptions>) => void;
};

export const AlertContext = createContext<AlertContextType>({} as AlertContextType);

interface AlertProviderProps {
    children: ReactNode;
}

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }): ReactElement => {
    const { designSettings } = useHarmony();
    const [alertQueue, setAlertQueue] = useState<AlertQueueItem[]>([]);

    const showAlert = useCallback(({ message = '', variant = 'alert', type = designSettings?.isDesignB ? 'popup' : 'modal', ...options }: Partial<IAlertOptions>) => {
        setAlertQueue((prev) => [
            ...prev,
            {
                id: uuid(),
                options: {
                    ...options,
                    message,
                    variant,
                    type,
                },
                Component: alertComponentMap[type],
                isOpen: true,
            },
        ]);
    }, []);

    const handleClose = (queueId: string) => {
        setAlertQueue((prev) => prev.map((queueItem) => (queueItem.id === queueId ? { ...queueItem, isOpen: false } : queueItem)));
    };

    const removeFromQueue = (queueId: string) => {
        setAlertQueue((prev) => prev.filter((queueItem) => queueItem.id !== queueId));
    };

    return (
        <AlertContext.Provider value={{ showAlert }}>
            {children}
            {alertQueue.map(({ id, Component, options, isOpen }) => (
                <Component key={id} isOpen={isOpen} onClose={() => handleClose(id)} onCloseComplete={() => removeFromQueue(id)} {...options} />
            ))}
        </AlertContext.Provider>
    );
};

export const useAlert = (): AlertContextType => useContext(AlertContext);
