import { IFontsDto } from '@models/channel-settings/responseDto/channel-settings.dto';
import { IHarmonyTheme } from '@type/theme';
import Head from 'next/head';

const fontFaces: Record<string, string> = {
    apple: `@font-face {
    font-family: 'appleLight';
    src: url('/fonts/AppleSDGothicNeoL.ttf');
}

@font-face {
    font-family: 'appleRegular';
    src: url('/fonts/AppleSDGothicNeoR.ttf');
}

@font-face {
    font-family: 'appleMedium';
    src: url('/fonts/AppleSDGothicNeoM.ttf');
}

@font-face {
    font-family: 'appleSemiBold';
    src: url('/fonts/AppleSDGothicNeoSB.ttf');
}

@font-face {
    font-family: 'appleBold';
    src: url('/fonts/AppleSDGothicNeoB.ttf');
}

@font-face {
    font-family: 'appleExtraBold';
    src: url('/fonts/AppleSDGothicNeoEB.ttf');
}`,
    poppins: `@font-face {
    font-family: 'poppinsLight';
    src: url('/fonts/Poppins-Light.ttf');
}

@font-face {
    font-family: 'poppinsMedium';
    src: url('/fonts/Poppins-Medium.ttf');
}

@font-face {
    font-family: 'poppinsRegular';
    src: url('/fonts/Poppins-Regular.ttf');
}

@font-face {
    font-family: 'poppinsSemiBold';
    src: url('/fonts/Poppins-SemiBold.ttf');
}

@font-face {
    font-family: 'poppinsBold';
    src: url('/fonts/Poppins-Bold.ttf');
}

@font-face {
    font-family: 'poppinsExtraBold';
    src: url('/fonts/Poppins-ExtraBold.ttf');
}`,
    noto: `@font-face {
    font-family: 'noto';
    src: url('/fonts/NotoSansKR-VariableFont_wght.otf');
    font-weight: 350 700;
}`,
    montserrat: `@font-face {
    font-family: 'montserrat';
    src: url('/fonts/Montserrat-VariableFont_wght.ttf');
    font-weight: 300 800;
}`,
    bmw: `@font-face {
    font-family: 'bmwLight';
    src: url('/fonts/BMWTypeNextKr-Light.otf');
}

@font-face {
    font-family: 'bmwRegular';
    src: url('/fonts/BMWTypeNextKr-Regular.otf');
}

@font-face {
    font-family: 'bmwMedium';
    src: url('/fonts/BMWTypeNextKr-Regular.otf');
}

@font-face {
    font-family: 'bmwSemiBold';
    src: url('/fonts/BMWTypeNextKr-Bold.otf');
}

@font-face {
    font-family: 'bmwBold';
    src: url('/fonts/BMWTypeNextKr-Bold.otf');
}

@font-face {
    font-family: 'bmwExtraBold';
    src: url('/fonts/BMWTypeNextKr-Bold.ttf');
}`,
    pretendard: `@font-face {
    font-family: 'pretendardLight';
    src: url('/fonts/Pretendard-Light.otf');
}

@font-face {
    font-family: 'pretendardMedium';
    src: url('/fonts/Pretendard-Medium.otf');
}

@font-face {
    font-family: 'pretendardRegular';
    src: url('/fonts/Pretendard-Regular.otf');
}

@font-face {
    font-family: 'pretendardSemiBold';
    src: url('/fonts/Pretendard-SemiBold.otf');
}

@font-face {
    font-family: 'pretendardBold';
    src: url('/fonts/Pretendard-Bold.otf');
}

@font-face {
    font-family: 'pretendardExtraBold';
    src: url('/fonts/Pretendard-ExtraBold.otf');
}`,
    kb: `@font-face {
    font-family: 'kbLight';
    src: url('/fonts/KBFGTextL.otf');
}

@font-face {
    font-family: 'kbRegular';
    src: url('/fonts/KBFGTextM.otf');
}

@font-face {
    font-family: 'kbMedium';
    src: url('/fonts/KBFGTextM.otf');
}

@font-face {
    font-family: 'kbSemiBold';
    src: url('/fonts/KBFGTextM.otf');
}

@font-face {
    font-family: 'kbBold';
    src: url('/fonts/KBFGTextB.otf');
}

@font-face {
    font-family: 'kbExtraBold';
    src: url('/fonts/KBFGTextB.otf');
}`,
};

type FontLoaderProps = {
    fonts: Required<IFontsDto>;
    font: IHarmonyTheme['font'];
};

export const FontLoader = ({ fonts, font }: FontLoaderProps) => {
    return (
        <Head>
            <style>
                {`
                    ${fontFaces[fonts.primary]}
                    ${fontFaces[fonts.secondary]}
                    html {
                        font-family: ${font.primary};
                    }
                `}
            </style>
        </Head>
    );
};
