import styled from '@emotion/styled';
import { Button } from 'src/legacy/components/blockui';
import { IThemeProps } from '@type/common';
import { CustomButtonProps } from '../custom-button';

const CustomButtonB = styled(Button)<IThemeProps & CustomButtonProps>`
    ${({ theme, variant, color, disabled, borderRadius, height = '48px', width }) => `
    padding: 0;
    height: ${height};
    font-family: ${theme.font.default.regular};
    background-color:${variant === 'outline' && theme.white};
    border: ${variant === 'outline' ? `solid 1px ${color}` : undefined};
    border-radius: ${borderRadius || '8px'};
    &:hover {
        background-color: ${disabled ? '' : `${variant === 'outline' ? 'white' : theme.primary.default} !important`} ;
        opacity: ${disabled ? '' : '1'} !important;
    }
    &:disabled {
        color: ${variant === 'outline' ? theme.gray[300] : theme.white} !important;
        background-color: ${variant === 'outline' ? theme.white : theme.gray[300]} !important;
        opacity: 100% !important;
        border:1px solid ${theme.gray[300]} !important;
    }
    ${width && `width: ${width};`}
`}
`;

export default CustomButtonB;
