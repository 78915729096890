import Logo from '@designs/primitives/main/header/logo';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useReferrer } from '@hooks/use-referrer';
import ShoppingCartButton from '@legacy/designs/shopping-cart-button';
import ThemeUtils from '@utils/theme.utils';
import Link from 'next/link';
import { BlockHStack } from 'src/legacy/components/blockui';
import CustomIconButton from 'src/legacy/components/common/custom-icon-button';
import BodyText from 'src/legacy/components/common/text/body-text';
import RoutesEnum from 'src/routes/routes';
import { PopupHeaderProps } from '..';

const PopupHeaderA: React.FC<PopupHeaderProps> = ({ logoOnDirect, title, showLogo, showCart, showSearch, showHome, showBack, onBack, className }) => {
    const { canGoBack } = useReferrer();

    return (
        <Bar
            className={className}
            css={css`
                position: sticky;
                top: 0px;
                left: 0px;
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                height: 50px;
                padding: 0px 16px;
                background-color: white;
                .title_wrap {
                    flex: 1 1 0;
                    min-width: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            `}
        >
            {((!canGoBack && logoOnDirect) || showLogo) && <Logo />}
            {showBack && (canGoBack || !logoOnDirect) && (
                <CustomIconButton size="32px" padding="0" onClick={onBack} className="back-button">
                    chevron_left
                </CustomIconButton>
            )}
            <div className="title_wrap">
                {title && (
                    <BodyText as="h1" size="18px" weight="bold" font="default">
                        {title}
                    </BodyText>
                )}
            </div>
            <BlockHStack alignItems="center" spacing="sm">
                {showSearch && (
                    <Link href={RoutesEnum.Search}>
                        <CustomIconButton size="25px" padding="0" className="product-search">
                            search
                        </CustomIconButton>
                    </Link>
                )}
                {showCart && <ShoppingCartButton />}
                {showHome && (
                    <Link href={RoutesEnum.Home}>
                        <CustomIconButton size="25px" padding="0" className="home">
                            home
                        </CustomIconButton>
                    </Link>
                )}
            </BlockHStack>
        </Bar>
    );
};

export default PopupHeaderA;

const Bar = styled.header`
    position: sticky;
    top: 0px;
    left: 0px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    padding: 0px 16px;
    z-index: ${ThemeUtils.zIndex.header};
    background-color: white;
`;
