import { DeliveryNoteEnum, PaymentCodeEnum } from '@models/transaction/requestDto/create-transaction.dto';

const order = {
    order: {
        order: '주문',
        ordererDetails: '주문자 정보',
        ordererName: '주문자명',
        ordererNamePlace: '이름',
        makePayment: '원 결제하기',
        orderProducts: '주문 상품',
        orderProducts2: '주문자 정보',
        finalPayment: '최종 결제금액',
        payment: '결제금액',
        paymentMethod: '결제수단',
        creditCard: '신용카드',
        nhCard: 'NH농협카드(채움)',
        payOnnuri: '충전형 온누리상품권 결제',
        outOfStockMessage: '주문하시려는 상품의',
        outOfStockMessage2: '재고가 부족합니다.',
        agreeToTerms: '쇼핑몰 구매약관에 모두 동의합니다.',
        details: '자세히',
        sameAsShipping: '배송지 정보와 동일',
        shippingInfo: '배송지 정보',
        change: '변경',
        selectDelivery: '배송요청사항 선택',
        enterShippingRequest: '배송 요청사항을 입력해주세요.',
        changeShipping: '배송지 변경',
        directInputOfAddress: '배송지 직접입력',
        pleaseEnterShipping: '배송지 정보를 입력해 주세요.',
        confirmRecipient: '수령자명을 확인해 주세요.',
        enterOrderingInfo: '주문자 정보를 입력해 주세요.',
        confirmOrderer: '주문자명을 확인해 주세요.',
        ordererPhone: '주문자 휴대폰번호를 확인해 주세요.',
        selectDeliveryType: '배송요청사항을 선택해 주세요.',
        mobilePhone: '휴대폰번호',
        recipient: '수령자명',
        orderSuccess: '주문완료',
        viewOrderDetails: '주문 상세 보기',
        viewGiftDetails: '선물 상세보기',
        orderHasBeenPlaced: '주문이 완료되었습니다',
        orderNum: '주문번호',
        shipping: '배송지',
        orderProduct: '주문상품',
        multiSkuLabel: '{title} 외 {quantity}개 상품',
        amountPaid: '결제금액',
        goToHome: '홈으로 이동',
        orderFailed: '주문실패',
        paymentFailed: '결제에 실패하였습니다',
        pleaseTryAgain: '다시 시도해 주세요',
        pleaseContact: '고객센터에 문의해주세요',
        failedCancel: '주문취소 실패',
        orderCancelFailed: '주문취소에 실패하였습니다.',
        orderCancelFailed2: '다시 시도해주세요.',
        shoppingeasyFailedHelper: '현대카드 M포인트 사용시 카드사 정책문제로 결제 오류가 발생할 수 있습니다.',
        failedHelper2: '고객센터(070-7668-1717)로 문의 주시면 신속히 도와드릴 수 있도록 하겠습니다.',
        redeemPoints: '포인트 {purpose}하기',
        retained: '보유 포인트: {points}',
        retainedPoint: '보유 {pointName}',
        useAll: '모두 사용',
        use: '사용',
        invalidAmount: '상품금액보다 많이 사용할 수 없습니다.',
        invalidAmount2: '보유 포인트 금액이 없습니다.',
        invalidAmount3: '보유 포인트 금액이 없음',
        contact: '고객센터: {number}',
        expectedAmount: '결제 예정 금액',
        orderIsSub: '해당 상품은 월 정기결제 상품입니다.',
        firstCycleFinalPrice: '첫 회차 최종 결제 금액',
        firstCycleFinalPriceTooltip: '현재 결제할 가격입니다.\n 배송주기 기간에 따라 배송 회차가 다르기 때문에 다른 결제시 금액이 변동될 수 있습니다.',
        subscriptionInfo: '첫 결제일은 {firstDate} 입니다',
        firstPaymentDateIs: '첫 결제일은',
        usePoints: '포인트 사용여부',
        pointNote: '*NH 포인트 사용 체크 시 보유한 포인트 전액 사용 되며, 부족한 금액은 선택한 농협카드로 결제됩니다.',
        pointNote2: '*정기구독 한 품목당 첫 결제에만 NH 포인트 사용이 적용됩니다.',
        intangibleOrderSuccess:
            '1~2 영업일 이내에 주문 시 기입한\n휴대폰 번호로 모바일 상품권이 문자 발송됩니다.\n장기간 문자를 수신하지 못하신 경우\n고객센터({contact})로 문의해주세요.',
        [DeliveryNoteEnum.Doorstep]: '부재시 문 앞에 놔주세요.',
        [DeliveryNoteEnum.SecurityOffice]: '부재 시 경비실에 맡겨주세요',
        [DeliveryNoteEnum.DeliveryBox]: '부재 시 택배함에 넣어주세요',
        [DeliveryNoteEnum.FrontOfHouse]: '부재 시 집 앞에 놔주세요',
        [DeliveryNoteEnum.ContactBeforeShipping]: '배송 전 연락바랍니다',
        [DeliveryNoteEnum.BeCarefulWhenShipping]: '배송 시 주의해주세요',
        [DeliveryNoteEnum.DirectInput]: '직접입력',
        coupon: '쿠폰',
        pleaseSelectCoupons: '사용할 쿠폰을 선택해 주세요.',
        pointsOrCouponsOnly: '이 상품은 포인트 또는 쿠폰으로만\n구매 가능한 상품입니다.',
        couponsAreOnlyAvailableFirstPayment: '*쿠폰은 첫 결제시에만 적용됩니다.',
        pointsOnly: '이 상품은 포인트로만\n구매 가능한 상품입니다.',
        informing: '알려 드립니다!',
        cardUnavailable: '해당 상품은 신용카드로 결제 불가능한 상품입니다.',
        insufficientPoints: '포인트가 부족하여\n결제할 수 없습니다.',
        invalidPointUnit: '{unit} 단위로만 포인트를 사용할 수 있습니다.',
        minPointUsageAlert: '최소 {amount}{pointSystem} 이상 사용해 주세요.',
        cannotOrderWhenDrafted: '채널 운영대기 상태일 때는 주문이 불가합니다.',
        firstPaymentOnly: '*쿠폰은 첫 결제시에만 적용됩니다.',
        reasonForFail: '실패사유',
        serviceCenter: '고객센터',
        contactService: '고객센터에 문의해주세요.',
        joymallPointsAlert: '10만원 초과 상품에는 상품가격의 50%까지\nBMW COIN을 사용할 수 있습니다.',
        pointsAlert: `포인트를 사용하시면 사용된 쿠폰이 초기화됩니다.\n포인트를 사용하시겠습니까?`,
        usePointsButton: '포인트 사용하기',
        [PaymentCodeEnum.CreditCard]: '신용카드',
        [PaymentCodeEnum.NaverPay]: '네이버페이',
        [PaymentCodeEnum.Toss]: '토스',
        [PaymentCodeEnum.SamsungPay]: '삼성페이',
        [PaymentCodeEnum.KakaoPay]: '카카오페이',
        [PaymentCodeEnum.PayCo]: '페이코',
        [PaymentCodeEnum.Cellphone]: '휴대폰 결제',
        [PaymentCodeEnum.Bank]: '계좌이체',
        [PaymentCodeEnum.VBank]: '가상계좌',
        shouldToSetupPg: 'PG 연동완료 시 주문이 가능합니다.',
        guestOrderInfo: '비회원 주문 확인 정보',
        guestOrderDescription: '비회원 주문 후 주문내역 확인 시 다음 정보가 필요합니다.',
        guestOrderRequirments: '주문번호(주문완료 시), 주문자 휴대폰번호, 비밀번호',
        enterPassword: '비밀번호를 입력해 주세요.',

        giftOrderDescription: '선물을 전달받은 사람이 배송정보를 입력하여야 상품 접수와 배송이 시작됩니다.',

        verifyAgeGuide: '미성년자 구매불가 상품이 포함되어\n성인만 주문 가능합니다.',
        verifyAge: '성인인증 하러가기',
        ageVerifySuccessful: '성인인증 완료',
        ageVerifyFail: '미성년자 구매불가',
        ageLegal: '성인으로 주문 가능합니다.',

        ageVerificationRequired: '성인인증 시 주문 가능합니다.',
        underAgeCannotOrder: '미성년자는 주문할 수 없습니다.',
        differentVerifiedUser: '회원의 본인인증 정보와 다릅니다.',

        vBankInfo: '무통장 입금 정보',
        vBankDescription: '아래 가상 계좌로 입금해 주시면 정상적으로 결제 완료처리가 됩니다.',
        orderInfo: '주문 정보',
        bankName: '입금은행',
        accountNumber: '계좌번호',
        accountHolder: '예금주',
        vBankExpDate: '입금기한',
        vBankAuthDate: '입금일',
    },
};

export default order;
