import { useDesignComponents } from '@hooks/use-design-components';

import { BoxProps } from '@chakra-ui/react';
import { useExtension } from '@hooks/use-extension';
import { Design } from '@type/harmony-config';
import ThemeUtils from '@utils/theme.utils';
import { ComponentType } from 'react';
import BottomButtonsA from './designA/bottom-buttons';
import BottomButtonsB from './designB/bottom-buttons';
import BottomButtonsC from './designC/bottom-buttons';
import BottomButtonsD from './designD/bottom-buttons';

const fixedProps: BoxProps = {
    position: 'fixed',
    w: '100%',
    bottom: '0px',
    zIndex: ThemeUtils.zIndex.above,
};

export interface BottomButtonsProps extends BoxProps {
    isFixed?: boolean;
}

const BottomButtons: React.FC<BottomButtonsProps> = (props) => {
    const Component = useDesignComponents<ComponentType<BottomButtonsProps>>({
        [Design.DesignA]: BottomButtonsA,
        [Design.DesignB]: BottomButtonsB,
        [Design.DesignC]: BottomButtonsC,
        [Design.DesignD]: BottomButtonsD,
    });
    const { extensionStatus } = useExtension();

    return <Component {...(props.isFixed && { ...fixedProps, maxW: extensionStatus?.maxScreenWidth })} {...props} />;
};

export default BottomButtons;
