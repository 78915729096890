import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import FullPageLoaderD from './designD/full-page-loader';

export interface FullPageLoaderProps {}

const FullPageLoader: React.FC<FullPageLoaderProps> = (props) => {
    const Component = useDesignComponents<ComponentType<FullPageLoaderProps>>({
        [Design.DesignD]: FullPageLoaderD,
    });

    return <Component {...props} />;
};

export default FullPageLoader;
