import { Box, forwardRef } from '@chakra-ui/react';
import { useHarmony } from '@hooks/use-harmony';
import { DesignedContainerProps } from '..';

const DesignedContainerD = forwardRef<DesignedContainerProps, 'div'>(({ children, ...props }, ref) => {
    const { theme } = useHarmony();

    return (
        <Box bgColor={theme.background.white} borderRadius="4px" boxShadow={theme.dropShadow.dropdown} overflow="hidden" {...props} ref={ref}>
            {children}
        </Box>
    );
});

export default DesignedContainerD;
