import { default as CONFIG } from '@config';
import { MarketDomain } from '@type/markets';
import Head from 'next/head';

const IN_APP_MARKET_DOMAINS = [MarketDomain.Klife, MarketDomain.NhPay, MarketDomain.NhPayNormal];

const WebViewOptimization = () => {
    if (!IN_APP_MARKET_DOMAINS.includes(CONFIG.domain)) return null;
    return (
        <Head>
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0, minimum-scale=1, user-scalable=0" viewport-fit="cover"></meta>
        </Head>
    );
};

export default WebViewOptimization;
