import { ISODate, PointUsageType } from '@type/models';
import ConversionUtils from '@utils/conversion.utils';
import DateUtils from '@utils/date.utils';
import { IPointHistoryDto } from './responseDto/point-history.dto';

export enum PointTypeCodeEnum {
    Event = 'event',
    ContractPromotion = 'contract-promotion',
    ReRegister = 're-register',
}

class PointHistory {
    private _transactionSn: string;
    private _createDt: ISODate;
    private _usageType: PointUsageType;
    private _amount: string;
    private _expStartDt: ISODate;
    private _expEndDt: ISODate;
    private _pointTypeCode: string;
    private _pointTitle?: string;
    private _storeName?: string;

    constructor(pointHistoryDto: IPointHistoryDto) {
        this._transactionSn = pointHistoryDto.transactionSn;
        this._createDt = pointHistoryDto.createDt;
        this._usageType = pointHistoryDto.usageType;
        this._amount = pointHistoryDto.amount;
        this._expStartDt = pointHistoryDto.expStartDt;
        this._expEndDt = pointHistoryDto.expEndDt;
        this._pointTypeCode = pointHistoryDto.pointTypeCode;
        this._pointTitle = pointHistoryDto.title;
        this._storeName = pointHistoryDto.storeName;
    }

    get createDt(): ISODate {
        return this._createDt;
    }

    get transactionSn(): string {
        return this._transactionSn;
    }

    get isUsed(): boolean {
        return this._usageType === PointUsageType.Used;
    }

    get isEarned(): boolean {
        return this._usageType === PointUsageType.Earned;
    }

    get isEarnCanceled(): boolean {
        return this._usageType === PointUsageType.EarnedCanceled;
    }

    get isCanceled(): boolean {
        return this._usageType === PointUsageType.Canceled;
    }

    get isExpired(): boolean {
        return this._usageType === PointUsageType.Expired;
    }

    get usageType(): PointUsageType {
        return this._usageType;
    }

    get amount(): string {
        return ConversionUtils.numberToString(Number(this._amount));
    }

    get createDateString(): string {
        return DateUtils.dateToDotString(new Date(this._createDt));
    }

    get createTimeString(): string {
        return DateUtils.dateToShortTimeString(new Date(this._createDt));
    }

    get expStartDateString(): string {
        return DateUtils.dateToDotString(new Date(this._expStartDt));
    }

    get expEndDateString(): string {
        return DateUtils.dateToDotString(new Date(this._expEndDt));
    }

    get pointTypeCode(): PointTypeCodeEnum {
        return this._pointTypeCode as PointTypeCodeEnum;
    }

    get pointTitle(): string {
        return this._pointTitle || '';
    }

    get storeName(): string {
        return this._storeName || '';
    }
}

export default PointHistory;
