import CONFIG from '@config';
import { SalesTypeEnum } from '@models/product/responseDto/component-product.dto';
import { MarketplaceTemplate } from '@type/harmony-config';
import { CountryCode, ImageUrl } from '@type/models';
import { ChannelStatusEnum, IChannelSettingsDto, ICustomerServiceInfoDto, IPartnerDisplayScopeDto, IShareSettingDto } from './responseDto/channel-settings.dto';
import ThemeDesignSetting from './theme-design-setting';

class ChannelSettings {
    private _channelType: string;
    private _channelStatus: ChannelStatusEnum;
    private _platformType: string;
    private _channelName: string;
    private _description: string;
    private _country: CountryCode;
    private _isUsingCustomDomain: boolean;
    private _isGuestOrderEnabled: boolean;
    private _domain: string;
    private _logoImage: ImageUrl;
    private _customerServiceInfo: ICustomerServiceInfoDto;
    private _partnerDisplayScope: IPartnerDisplayScopeDto;
    private _themeDesignSetting: ThemeDesignSetting;
    private _salesSetting: SalesTypeEnum;
    readonly shareSetting?: IShareSettingDto;

    constructor(dto: IChannelSettingsDto) {
        this._channelType = dto.channelType;
        this._channelStatus = dto.channelStatus;
        this._platformType = dto.platformType;
        this._channelName = dto.channelName;
        this._description = dto.description;
        this._country = dto.country;
        this._isUsingCustomDomain = dto.isUsingCustomDomain;
        this._isGuestOrderEnabled = dto.isGuestOrderEnabled;
        this._domain = dto.domain;
        this._logoImage = dto.logoImage;
        this._customerServiceInfo = dto.customerServiceInfo;
        this._partnerDisplayScope = dto.partnerDisplayScope;
        this._salesSetting = dto.salesSettingType;
        this._themeDesignSetting = new ThemeDesignSetting(dto.themeDesignSetting);
        this.shareSetting = dto.shareSetting;
    }

    get productSearchType(): string {
        switch (this._salesSetting) {
            case SalesTypeEnum.Subscription:
                return SalesTypeEnum.Subscription;
            case SalesTypeEnum.Hybrid:
                return `${SalesTypeEnum.Normal},${SalesTypeEnum.Hybrid},${SalesTypeEnum.Subscription}`;
            case SalesTypeEnum.Normal:
            default:
                return SalesTypeEnum.Normal;
        }
    }

    get customerServiceInfo(): ICustomerServiceInfoDto {
        return this._customerServiceInfo;
    }

    get themeDesignSetting(): ThemeDesignSetting {
        return this._themeDesignSetting;
    }

    get isGuestOrderEnabled() {
        return this._isGuestOrderEnabled;
    }

    get logo(): ImageUrl {
        return this._logoImage;
    }

    get isDrafted(): boolean {
        return this._channelStatus === ChannelStatusEnum.Draft;
    }

    get hasNormalSalesType(): boolean {
        return this._salesSetting === SalesTypeEnum.Normal || this._salesSetting === SalesTypeEnum.Hybrid;
    }

    get hasSubscriptionSalesType(): boolean {
        return this._salesSetting === SalesTypeEnum.Subscription || this._salesSetting === SalesTypeEnum.Hybrid;
    }

    get hasTangible(): boolean {
        return CONFIG.marketplaceTemplate.includes(MarketplaceTemplate.Tangible);
    }

    get hasIntangible(): boolean {
        return CONFIG.marketplaceTemplate.includes(MarketplaceTemplate.Intangible);
    }
}

export default ChannelSettings;
