const cart = {
    cart: {
        cart: '장바구니',
        selectAll: '전체선택',
        delete: '선택삭제',
        outOfStock: '품절삭제',
        noProductsInCart: '장바구니에 담긴 상품이 없습니다.',
        continueShopping: '쇼핑 계속하기',
        totalShipping: '배송비 합계',
        bundledShipping: '묶음 배송비',
        shippedTogether: '묶음배송이 가능한 상품들은 판매자의 정책에 따라 배송비가 할인됩니다.',
        productShipping: '상품 배송비',
        freeShipping: '{number}원 이상 구매 시 배송비 무료',
        exceedsQuant: '남은 수량 {number}개 이상 구매할 수 없습니다.',
        cannotDecrease: '1건 이하로 수량 변경할 수 없습니다.',
        productAmount: '상품금액',
        deliveryFee: '배송비',
        repeatDelivery: '상품수량 {number}개마다 배송비 반복부과',
        changeOptions: '옵션변경',
        buyNow: '바로구매',
        itemHasBeenRemoved: '상품이 장바구니에서 삭제되었습니다.',
        change: '변경하기',
        optionOut: '해당 옵션이 품절되어 옵션 변경에 실패하였습니다.',
        quantityExceeded: '해당 옵션의 남은 수량을 초과하여 옵션 변경에 실패하였습니다.',
        changeComplete: '옵션 변경이 완료되었습니다.',
        selectedProd1: '선택한 상품을',
        selectedProd2: '장바구니에서 삭제하시겠습니까?',
        addToWish: '찜한 상품에 추가',
        productsDeleted: '상품이 삭제되었습니다.',
        addedToWish: '찜한 상품에 추가되었습니다.',
        soldOutProducts: '품절된 상품을',
        soldOutProducts2: '장바구니에서 삭제하시겠습니까?',
        total: '총',
        purchase: '구매하기',
        changeQuant: '상품 수량이 변경되었습니다.',
        dueToChangesInProductInformation: '상품정보 변경으로 인해',
        noLongerAvailableForPurchase: '구매가 불가능한 상품이 있습니다.',
        cartIsFull: '장바구니에 담을 수 있는\n상품 최대 개수가 초과되었습니다.\n가장 오래된 상품을 삭제 후 담으시겠습니까?',
        removeCartItem: '삭제 후 담기',
        tempOutOfStock: '일부품절',
    },
};

export default cart;
