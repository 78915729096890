import { useTranslations } from 'next-intl';
import { ReactNode } from 'react';
import ErrorBoundary from './error-boundary';

interface ErrorBoundaryContainerProps {
    children: ReactNode;
}

const ErrorBoundaryContainer: React.FC<ErrorBoundaryContainerProps> = ({ children }) => {
    const t = useTranslations();
    return (
        <ErrorBoundary errorTitle={t('common.error1')} errorMessage={t('common.error2')}>
            {children}
        </ErrorBoundary>
    );
};

export default ErrorBoundaryContainer;
