import { Options } from 'react-barcode';
import { BarcodeTypeEnum, IOfflineStoreSettingDto } from './responseDto/offline-store.dto';

class OfflineStoreSetting {
    private _isDisplayedOnCategoryPage: boolean;
    private _isUsingMembership: boolean;
    private _barcodeType: BarcodeTypeEnum;

    constructor(offlineStoreSettingDto: IOfflineStoreSettingDto) {
        this._barcodeType = offlineStoreSettingDto.barcodeType;
        this._isDisplayedOnCategoryPage = offlineStoreSettingDto.isDisplayedOnCategoryPage;
        this._isUsingMembership = offlineStoreSettingDto.isUsingMembership;
    }

    get isDisplayedOnCategoryPage(): boolean {
        return this._isDisplayedOnCategoryPage;
    }

    get isUsingMembership(): boolean {
        return this._isUsingMembership;
    }

    get barcodeType(): Options['format'] {
        switch (this._barcodeType) {
            case BarcodeTypeEnum.Code128:
                return 'CODE128';
            case BarcodeTypeEnum.Code39:
                return 'CODE39';
            case BarcodeTypeEnum.Codabar:
                return 'codabar';
            case BarcodeTypeEnum.Itf:
                return 'ITF14';
            case BarcodeTypeEnum.EanUpc:
                return 'upc';
            default:
                return undefined;
        }
    }
}

export default OfflineStoreSetting;
