import { BillingClient } from '@api/billing/billing.client';
import BillingCacheKeys from '@api/billing/cache-keys';
import ChannelCacheKeys from '@api/channel/cache-keys';
import { ChannelClient } from '@api/channel/channel.client';
import AcrossSettings from '@models/across-settings';
import ExtensionStatus from '@models/extension-status';
import Extension from '@models/extension-status/extension';
import { IExtensionStatusDto } from '@models/extension-status/responseDto/extension-status.dto';
import GiftSettings from '@models/gift-settings';
import { IGpsSettingDto } from '@models/gps-settings/responseDto/gps-setting.dto';
import OfflineStoreSetting from '@models/offline-store/offline-store-setting';
import RoutesEnum from '@routes';
import { MobileWebviewService } from '@services/mobile-webview.service';
import { ListResult } from '@type/models';
import { useRouter } from 'next/router';
import { ReactNode, createContext, useContext, useEffect, useMemo } from 'react';
import useSWR from 'swr';

export type ExtensionProps = {
    isLoading: boolean;
    extensionStatus: ExtensionStatus | undefined;
    offlineStoreSettings: OfflineStoreSetting | undefined;
    giftSettings: GiftSettings | undefined;
    gpsSettings: IGpsSettingDto | undefined;
    acrossSettings: AcrossSettings | undefined;
};

export const ExtensionContext = createContext<ExtensionProps>({} as ExtensionProps);

interface ExtensionProviderProps {
    children: ReactNode;
}

const ExtensionProvider: React.FC<ExtensionProviderProps> = ({ children }) => {
    const router = useRouter();

    const extensionStatusDto = useSWR<ListResult<IExtensionStatusDto>>(BillingCacheKeys.simpleExtensionStatusCacheKey(), () => BillingClient.getSimpleExtensionStatus());
    const extensionStatus = useMemo<ExtensionStatus | undefined>(
        () => (extensionStatusDto.data ? new ExtensionStatus(extensionStatusDto.data.list) : undefined),
        [extensionStatusDto.data]
    );

    const offlineStoreSettingDto = useSWR(extensionStatus?.offlineStore.isEnabled && ChannelCacheKeys.getOfflineStoreSettingCacheKey(), () =>
        ChannelClient.getOfflineStoreSetting()
    );
    const offlineStoreSettings = useMemo<OfflineStoreSetting | undefined>(
        () => offlineStoreSettingDto.data && new OfflineStoreSetting(offlineStoreSettingDto.data),
        [offlineStoreSettingDto]
    );

    const giftSettingDto = useSWR(extensionStatus?.gift.isEnabled && ChannelCacheKeys.getGiftSettingCacheKey(), () => ChannelClient.getGiftSetting());
    const giftSettings = useMemo<GiftSettings | undefined>(() => giftSettingDto.data && new GiftSettings(giftSettingDto.data), [giftSettingDto]);

    const gpsSettingDto = useSWR(extensionStatus?.gps.isEnabled && ChannelCacheKeys.getGpsSettingCacheKey(), () => ChannelClient.getGpsSetting());

    const acrossAdnDto = useSWR(extensionStatus?.acrossAdn.isEnabled && ChannelCacheKeys.getAcrossAdn(), () => ChannelClient.getAcrossAdn(), {});
    const acrossSettings = useMemo<AcrossSettings | undefined>(() => acrossAdnDto.data && new AcrossSettings(acrossAdnDto.data), [acrossAdnDto]);

    useEffect(() => {
        if (extensionStatusDto.data) {
            MobileWebviewService.sendExtensionSettings(
                extensionStatusDto.data.list.map(({ extension, status }) => ({
                    extensionCode: extension.code,
                    isInstalled: Extension.ENABLED_STATUSES.includes(status),
                }))
            );
        }
    }, [extensionStatusDto.data]);

    if (extensionStatus?.isInvalidPage(router.pathname as RoutesEnum)) {
        router.replace(RoutesEnum.NotFoundError);
        return null;
    }

    const isLoading = extensionStatusDto.isValidating || offlineStoreSettingDto.isValidating || giftSettingDto.isValidating || acrossAdnDto.isValidating;

    return (
        <ExtensionContext.Provider
            value={{
                isLoading,
                extensionStatus,
                offlineStoreSettings,
                giftSettings,
                gpsSettings: gpsSettingDto.data,
                acrossSettings,
            }}
        >
            {children}
        </ExtensionContext.Provider>
    );
};

export default ExtensionProvider;

/**
 * Wrapper context hook to house any desired hook logic
 */
export function useExtension(): ExtensionProps {
    return useContext(ExtensionContext);
}
