import { VStack } from '@chakra-ui/react';
import { useHarmony } from '@hooks/use-harmony';
import { DetailsBoxProps } from '..';
import DetailItem from '../../detail-item';

const DetailsBoxA: React.FC<DetailsBoxProps> = ({ details }) => {
    const { theme } = useHarmony();

    return (
        <VStack spacing="24px" align="stretch">
            {Object.entries(details.data).map(([label, value]) => !!value && <DetailItem key={label} label={label} value={value} />)}
        </VStack>
    );
};

export default DetailsBoxA;
