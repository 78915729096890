import { useDesignComponents } from '@hooks/use-design-components';

import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { useWebviewModal } from '@hooks/use-webview-modal';
import { useWindowSize } from '@hooks/use-window-size';
import { Design } from '@type/harmony-config';
import ThemeUtils from '@utils/theme.utils';
import { useEffect, useState } from 'react';
import { Drawer, DrawerProps } from 'src/legacy/components/blockui';
import { useExtension } from '@hooks/use-extension';

interface CustomDrawerProps extends DrawerProps {
    backgroundColor?: string;
    radius?: string;
    zIndex?: number;
}

const CustomDrawer: React.FC<CustomDrawerProps> = ({ children, radius, backgroundColor: backgroundProps, zIndex = ThemeUtils.zIndex.drawer, ...props }) => {
    const { theme } = useHarmony();
    const { extensionStatus } = useExtension();
    const [maxInnerHeight, setMaxInnerHeight] = useState<number>(0);
    const [_, innerHeight] = useWindowSize();
    const backgroundColor = useDesignComponents<string>({
        [Design.DesignA]: theme.background.config,
        [Design.DesignB]: theme.white,
        [Design.DesignC]: theme.white,
    });
    useWebviewModal(props.open);

    useEffect(() => {
        if (maxInnerHeight !== innerHeight) {
            setMaxInnerHeight(innerHeight);
        }
    }, [innerHeight]);

    return (
        <StyledDrawer
            theme={theme}
            {...props}
            anchor="bottom"
            maxWidth={`${extensionStatus?.maxScreenWidth}px`}
            maxHeight={maxInnerHeight}
            backgroundColor={backgroundProps || backgroundColor}
            radius={radius}
            zIndex={zIndex}
        >
            {children}
        </StyledDrawer>
    );
};

export default CustomDrawer;

const StyledDrawer = styled(Drawer)<DrawerProps & { maxHeight?: number; backgroundColor?: string; radius?: string; zIndex?: number }>`
    max-height: ${({ maxHeight }) => maxHeight}px;
    width: ${({ maxWidth }) => `min(100vw, ${maxWidth})`} !important;
    left: calc(50% - (${({ maxWidth }) => `min(100vw, ${maxWidth})`} * 0.5)) !important;
    background: ${({ backgroundColor }) => backgroundColor} !important;
    border-radius: ${({ radius }) => radius} !important;
    z-index: ${({ zIndex }) => zIndex && `${zIndex} !important`};
`;
