import { BlockVStack } from 'src/legacy/components/blockui';
import EnvironmentUtils from '@utils/environment.utils';
import React, { ErrorInfo, ReactNode } from 'react';
import CustomIcon from './custom-icon';
import EmptyContentPage from './empty-content-page';
import BodyText from './text/body-text';
import Subheading from './text/subheading';

interface ErrorBoundaryProps {
    errorTitle: string;
    errorMessage: string;
    children?: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_: Error): ErrorBoundaryState {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // TODO: Use custom logging or error reporting here. possibly sentry
        if (!EnvironmentUtils.isProduction) {
            console.log({ error, errorInfo });
        }
    }
    render() {
        if (this.state.hasError) {
            return (
                <div data-nosnipept>
                    <EmptyContentPage>
                        <BlockVStack align="center">
                            <CustomIcon size="36px" data-nosnippet>
                                report
                            </CustomIcon>
                            <Subheading data-nosnippet>{this.props.errorTitle}</Subheading>
                            <BodyText data-nosnippet>{this.props.errorMessage}</BodyText>
                        </BlockVStack>
                    </EmptyContentPage>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
