import { ButtonProps } from 'src/legacy/components/blockui';
import { useErrorDrawer } from '@legacy/hooks/use-error-drawer';
import { useState } from 'react';
import CustomButton from '@legacy/designs/custom-button/custom-button';

export interface AsyncButtonProps extends Omit<ButtonProps, 'onClick'> {
    onClickAsync: () => Promise<void>;
}

const AsyncButton: React.FC<AsyncButtonProps> = ({ onClickAsync, ...props }) => {
    const errorDrawer = useErrorDrawer();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const submit = async () => {
        try {
            setIsLoading(true);
            await onClickAsync();
        } catch (error) {
            errorDrawer();
        } finally {
            setIsLoading(false);
        }
    };

    return <CustomButton {...props} isLoading={isLoading} onClick={submit} />;
};

export default AsyncButton;
