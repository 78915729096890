import { BlockVStack } from 'src/legacy/components/blockui';
import { AlertProps, AlertStatusEnum } from '.';
import CustomIcon from 'src/legacy/components/common/custom-icon';
import { useHarmony } from '@hooks/use-harmony';

const AlertIcon: React.FC<AlertProps> = ({ status }) => {
    const { theme } = useHarmony();

    return (
        <>
            {status !== AlertStatusEnum.None && (
                <BlockVStack spacing="16px" mb="16px">
                    {status === AlertStatusEnum.Alert && (
                        <CustomIcon size="30px" color={theme.secondary.default}>
                            report
                        </CustomIcon>
                    )}
                    {status === AlertStatusEnum.Complete && (
                        <CustomIcon size="30px" color={theme.primary.default}>
                            check_circle
                        </CustomIcon>
                    )}
                    {status === AlertStatusEnum.Info && (
                        <CustomIcon size="30px" color={theme.primary.default}>
                            info
                        </CustomIcon>
                    )}
                </BlockVStack>
            )}
        </>
    );
};

export default AlertIcon;
