import { useEffect, useRef } from 'react';

type Options = { enabled?: boolean };

export const useEffectOnce = (callback: () => void, dependencies: any[], { enabled = true }: Options = {}) => {
    const isDoneRef = useRef<boolean>(false);

    useEffect(() => {
        if (!enabled || isDoneRef.current === true) {
            return;
        }

        callback();
        isDoneRef.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dependencies, enabled]);
};
