import { Box, Circle } from '@chakra-ui/react';
import IconButton from '@designs/primitives/common/icon-button';
import { useHarmony } from '@hooks/use-harmony';
import { NotificationButtonInnerProps } from '..';

const NotificationButtonD: React.FC<NotificationButtonInnerProps> = ({ unreadCount, ...props }) => {
    const { theme } = useHarmony();

    return (
        <Box pos="relative">
            {unreadCount > 0 && <Circle pos="absolute" bgColor={theme.icon.secondary} top="0" right="0" size="4px" />}
            <IconButton {...props} size={24} color={theme.gray[600]} fill={false} {...props}>
                notifications
            </IconButton>
        </Box>
    );
};

export default NotificationButtonD;
