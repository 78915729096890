import RoutesEnum from '@routes';
import DomainUtils from '@utils/domain.utils';
import EnvironmentUtils from '@utils/environment.utils';
import { NextRouter, useRouter } from 'next/router';
import { ReactNode, createContext, useCallback, useContext, useEffect } from 'react';

export enum NaverWcsConversionType {
    Purchase = '1',
    SignUp = '2',
    Cart = '3',
    Submission = '4',
    Etc = '5',
}

type ConversionData = {
    conversionType: NaverWcsConversionType;
    conversionAmount?: number;
};

export type NaverWcsValue = {
    submitNaverWebLog: (conversionData?: ConversionData) => void;
};

export const NaverWcsContext = createContext<NaverWcsValue>({} as NaverWcsValue);

interface NaverWcsProviderProps {
    children: ReactNode;
}

/** Context for Naver automatic tracking service */
const NaverWcsProvider: React.FC<NaverWcsProviderProps> = ({ children }) => {
    const isEnabled = DomainUtils.isToyplus && EnvironmentUtils.isProduction;
    const router = useRouter();

    const isConversionScriptPage = useCallback((router: NextRouter) => {
        if ([RoutesEnum.OrderSuccess, RoutesEnum.GiftOrderSuccess, RoutesEnum.JoinComplete].includes(router.pathname as RoutesEnum)) {
            return true;
        }

        return false;
    }, []);

    const submitNaverWebLog = useCallback(
        (conversionData?: ConversionData) => {
            if (!EnvironmentUtils.NAVER_COMMON_KEY || !isEnabled) {
                return;
            }

            if (!window.wcs_add || !window.wcs_add.wa) {
                window.wcs_add = {
                    wa: EnvironmentUtils.NAVER_COMMON_KEY,
                };
            }

            window._nasa = {};
            if (!!conversionData) {
                window._nasa['cnv'] = window.wcs.cnv(conversionData.conversionType, `${conversionData.conversionAmount || 1}`);
            }

            window.wcs.inflow(`https://${EnvironmentUtils.PUBLIC_URL}`);
            window.wcs_do(window._nasa);
        },
        [isEnabled]
    );

    useEffect(() => {
        if (!router.isReady || isConversionScriptPage(router)) {
            return;
        }

        submitNaverWebLog();
    }, [submitNaverWebLog, router.isReady, router.asPath, router, isConversionScriptPage]);

    return <NaverWcsContext.Provider value={{ submitNaverWebLog }}>{children}</NaverWcsContext.Provider>;
};

export default NaverWcsProvider;

export function useNaverWcs() {
    return useContext(NaverWcsContext);
}
