import safeWindow from '@services/safe-window.service';
import { useLayoutEffect, useMemo, useState } from 'react';
import { useExtension } from './use-extension';

type ScreenSize = [number, number];

type UseWindowSizeOptions = {
    /**
     * If true, the window width will not exceed the maximum width outlined in Theme Utils
     */
    maxWidth?: boolean;
};

/**
 * Provides the window size (width, height).
 */
export const useWindowSize = (options?: UseWindowSizeOptions) => {
    const { extensionStatus } = useExtension();
    const [size, setSize] = useState<ScreenSize>([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            if (safeWindow) {
                const width = Math.min(safeWindow.innerWidth, safeWindow.outerWidth);
                const height = Math.min(safeWindow.innerHeight, safeWindow.outerHeight);
                if (width !== size[0] || height !== size[1]) {
                    setSize([width, height]);
                }
            }
        }
        if (safeWindow) {
            safeWindow.addEventListener('resize', updateSize);
            updateSize();
        }
        return () => {
            if (safeWindow) {
                safeWindow.removeEventListener('resize', updateSize);
            }
        };
    }, []);

    const memoizedSize = useMemo<ScreenSize>(
        () => (options?.maxWidth ? [Math.min(size[0], extensionStatus?.maxScreenWidth ?? 0), size[1]] : size),
        [size, extensionStatus?.maxScreenWidth]
    );

    return memoizedSize;
};
